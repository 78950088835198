import axios from 'axios';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { AuthContext } from '../context/AuthContext';
import PatientDob from './PatientDob';

const Patients = ({ patients, userId, fetchUserPatients }) => {
  const { suMyself, suFamily, setUserFamily, setUserNewFamily, postActivity } =
    useContext(AuthContext);
  const [showPatientAddForm, setShowPatientAddForm] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientDob, setPatientDob] = useState('');

  const [patientEditId, setpatientEditId] = useState(null);
  const [ptnLoading, setPtnLoading] = useState(false);

  const handleAddFamilyMember = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least 4 character long.`, {
        autoClose: 3000,
      });
    } else if (patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (patientDob === '' || patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else {
      if (!patientEditId) {
        const check = [...suFamily, ...suMyself].find(
          (item) => item.full_name.toLowerCase() === patientName.toLowerCase()
        );
        if (check) {
          toast.error(
            `A patient already exists with this name in your family, please try another name.`
          );
        } else {
          const patientData = {
            full_name: patientName,
            dob: moment(patientDob).format('YYYY-MM-DD'),
            sex: patientGender,
            user: userId,
          };
          //   console.log(patientData);
          setPtnLoading(false);

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
              patientData
            )
            .then((resp) => {
              // console.log(resp.data);
              setPatientName('');
              setPatientGender('');
              setPatientDob('');
              fetchUserPatients(userId);
              setShowPatientAddForm(false);
              setUserNewFamily(resp.data);
              setPtnLoading(false);
              handlePatientEditClear();
            })
            .catch((error) => {
              setPtnLoading(false);
              console.log(error.response);
            });
        }
      } else {
        const patientData = {
          full_name: patientName,
          dob: moment(patientDob).format('YYYY-MM-DD'),
          sex: patientGender,
        };
        putPatient(patientEditId, patientData, true);
      }
    }
  };

  const putPatient = (id, data, update = false) => {
    setPtnLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp);
        fetchUserPatients(userId);
        if (update) {
          toast.success(`Patient updated successfully.`, {
            autoClose: 3000,
          });
        } else {
          toast.success(`Patient deleted successfully.`, {
            autoClose: 3000,
          });
        }
        setPtnLoading(false);
        handlePatientEditClear();
      })
      .catch((error) => {
        setPtnLoading(false);
        // console.log(error.response);
        if (update) {
          toast.error(`Patient update failed.`, {
            autoClose: 3000,
          });
        } else {
          toast.error(`Patient delete failed.`, {
            autoClose: 3000,
          });
        }
      });
  };

  const handlePatientEdit = (patient) => {
    setPatientName(patient.full_name);
    setPatientGender(patient.sex);
    setPatientDob(new Date(patient.dob));
    setpatientEditId(patient.id);
    // console.log(patient);

    setShowPatientAddForm(true);
  };
  const handlePatientEditClear = () => {
    setPatientDob('');
    setPatientName('');
    setPatientGender('');
    setpatientEditId(null);
    // console.log(patient);
    setShowPatientAddForm(false);
  };

  const handlePatientDlt = (e, patient) => {
    e.stopPropagation();
    swal({
      title: 'Are you sure?',
      text: 'Once delete, it will be permanently deleted.!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // console.log(patient.id);
        const putDate = {
          is_active: false,
        };

        putPatient(patient.id, putDate);
        // console.log(suFamily);
        const newFamily = suFamily.filter((person) => person.id !== patient.id);
        // console.log(newFamily);
        setUserFamily(newFamily);
      }
    });
  };

  return (
    <>
      <div className='row patients'>
        {patients &&
          patients.map((patient) => (
            <div
              className='col-md-6 patients-box'
              key={patient.id}
              onClick={() => handlePatientEdit(patient)}
            >
              <div
                className='single pointer'
                style={{
                  borderColor:
                    patientEditId === patient.id ? '#31b5c4' : '#cacad3',
                }}
              >
                <div className='name-sex'>
                  <h5>{patient.full_name}</h5>
                  <div className='d-flex'>
                    <i
                      className='fa fa-user-edit mr-2 text-primary'
                      onClick={() => handlePatientEdit(patient)}
                    ></i>
                    <i
                      className='far fa-trash-alt ml-1'
                      style={{ zIndex: 100 }}
                      onClick={(e) => handlePatientDlt(e, patient)}
                    ></i>
                  </div>
                </div>
                <p className='text-capitalize'>Sex: {patient.sex}</p>
                <p>
                  Age: {moment().diff(`${patient.dob}`, 'years') + ' Years'}
                </p>
              </div>
            </div>
          ))}
      </div>
      {!showPatientAddForm && (
        <div className='add-another'>
          <button
            className='btn-btn-light'
            onClick={() => {
              setShowPatientAddForm(true);
              postActivity('button', 'Add Patient', window.location.href);
            }}
          >
            Add Patient
          </button>
        </div>
      )}
      {showPatientAddForm && (
        <form onSubmit={(e) => handleAddFamilyMember(e, setUserNewFamily)}>
          <div className='name-gender'>
            <div className='name'>
              <input
                type='text'
                className='nainp'
                placeholder='Person Name'
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
              />
            </div>
            {/* <div className='gender'>
              <h5>Gender*</h5>
              <div className='male-female'>
                <label className='gender'>
                  Male
                  <input
                    type='radio'
                    value='male'
                    onChange={(e) => setPatientGender(e.target.value)}
                    name='gender'
                    checked={patientGender === 'male'}
                  />
                  <span className='checkmark' />
                </label>
                <label className='gender'>
                  Female
                  <input
                    type='radio'
                    value='female'
                    onChange={(e) => setPatientGender(e.target.value)}
                    name='gender'
                    checked={patientGender === 'female'}
                  />
                  <span className='checkmark' />
                </label>
              </div>
            </div> */}
            <div className='gender'>
              <select
                className='nainp form-select form-select-sm'
                value={patientGender}
                onChange={(e) => setPatientGender(e.target.value)}
              >
                <option disabled value=''>
                  Select gender
                </option>
                <option value='male'>M</option>
                <option value='female'>F</option>
                <option value='common'>T</option>
              </select>
            </div>
          </div>
          <div className='age-rx'>
            <PatientDob setPatientDob={setPatientDob} patientDob={patientDob} />
          </div>
          <button className='btn btn-info' disabled={ptnLoading}>
            {patientEditId ? 'Update' : 'Add'} Family Member
            {ptnLoading && (
              <Spinner size='sm' animation='border' className='ml-2' />
            )}
          </button>
          <button
            className='btn btn-danger ml-3'
            onClick={(e) => {
              e.preventDefault();
              handlePatientEditClear();
            }}
          >
            Clear
          </button>
        </form>
      )}
    </>
  );
};

export default Patients;
