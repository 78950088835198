import React, { Component } from 'react';
import SingleCartSummery from '../components/SingleCartSummery';
import DwnldIcon from '../assets/img/download-icon.png';
import AmarlabLogo from '../assets/img/logo.png';
import DownloadWhite from '../assets/img/download-white.png';
import PdfIcon from '../assets/img/pdf.png';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import LoadingGif from '../components/LoadingGif';
import { AuthContext } from '../context/AuthContext';
import InvoicePdf from '../components/invoice/InvoicePdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

class OrderDetails extends Component {
  state = {
    order: null,
    userLocalId: null,
    discountPrice: null,
    materialPerson: null,
    discount: null,
    feesZero: false,
    fees: { meterial_fee: 50, collection_fee: 200 },
    loading: true,
    reports: null,
    patientOrders: [],
    discountPercentage: null,
  };

  getFees = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => {
        // this.setState({
        //   fees: resp.data.results[0],
        // });
        // console.log('fees = ', resp.data.results[0]);
      })
      .catch((error) => { });
  };

  handleMeterialPerson = (array) => {
    const patients = array
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);
    this.setState({ materialPerson: patients.length });
  };

  serviceFeesRemove = (array) => {
    const testItems = array
      .filter((item) => {
        return item.order_type === 'diagnostic';
      })
      .map((item) => {
        return item.purchasable_order_item.testitem_purchasable_oi
          .diagnostic_test.name;
      });

    const checkOne = testItems.includes('Covid RT PCR');
    const checkTwo = testItems.includes('Covid Antigen');

    // console.log(checkTwo);
    if (checkOne || checkTwo) {
      this.setState({ fees: { meterial_fee: 0, collection_fee: 0 } });
    }
  };

  petientReports = (reports) => {
    const patientDetails = reports.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    let patientOrders = [];
    filteredPatient.forEach((patient) => {
      const result = reports.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        reports: result,
      };
      patientOrders.push(newArray);
    });
    // console.log(patientOrders);
    this.setState({ reports: patientOrders });
  };

  handlePatientOrders = (order) => {
    const itemArray = order.orderitem;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    // console.log(itemArray);
    // console.log(filteredPatient);
    let patientOrders = [];

    filteredPatient.forEach((patient) => {
      const result = itemArray.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        testItem: result,
      };
      patientOrders.push(newArray);
      // console.log(newArray);
    });
    patientOrders.sort((p) => (p.patient.is_account ? -1 : 1));
    // setCtCPatient(patientOrders);
    this.setState({ patientOrders: patientOrders });
    // console.log(patientOrders);
  };

  fetchSingleOrder = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-tree/${id}/?user=${this.state.userLocalId}`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.handlePatientOrders(resp.data);

        if (resp.data.orderdiscount) {
          // console.log(parseInt(order.total_price));
          // console.log(parseInt(order.orderdiscount.discount_price));
          const discountPercentage =
            (parseInt(resp.data.orderdiscount.discount_price) /
              parseInt(resp.data.total_price)) *
            100;
          // console.log(discountPercentage);
          this.setState({ discountPercentage: discountPercentage });
          // setDiscountPercentClipboard(discountPercentage);
          // const singleDiscount =
          //   parseInt(order.orderdiscount.discount_price) / order.orderitem.length;
          // setSingleDiscountTk(singleDiscount);
          // console.log(singleDiscount);
        }

        // console.log(resp.data);

        this.petientReports(resp.data.orderreport);

        this.setState({ loading: false });

        const discount = resp.data.orderitem.reduce(
          (total, item) => total + parseInt(item.discount_price),
          0
        );

        if (!resp.data.orderdiscount && discount > 0) {
          // console.log(discount);
          this.setState({ discount: discount });
        }

        this.serviceFeesRemove(resp.data.orderitem);
        if (this.state.userLocalId !== resp.data.user.id) {
          this.props.history.push('/order-history');
          toast.error("You don't have permission to show the order.", {
            autoClose: 3000,
          });
        } else {
          this.setState({ order: resp.data });
          if (
            resp.data &&
            resp.data.orderdiscount &&
            resp.data.orderdiscount.discount_price
          ) {
            this.setState({
              discountPrice: parseInt(resp.data.orderdiscount.discount_price),
            });
          } else {
            // const discount = resp.data.orderitem.reduce(
            //   (total, item) => total + parseInt(item.discount_price),
            //   0
            // );
            // if (!resp.data.orderdiscount && discount > 0) {
            //   // console.log(discount);
            //   this.setState({ discountPrice: discount });
            // }
          }
          this.handleMeterialPerson(resp.data.orderitem);
        }
      })
      .catch((error) => {
        // console.log(error.response);
        this.setState({ loading: false });
        if (error.response.status === 404) {
          toast.error('Sorry, no orders found.', {
            autoClose: 3000,
          });
          this.props.history.push('/order-history');
        }
      });
  };

  goToPayment = () => {
    let user_name, address, user_email;

    if (
      this.state.order.user &&
      this.state.order.user.patient &&
      this.state.order.user.patient.full_name
    ) {
      user_name = this.state.order.user.patient.full_name;
    } else {
      user_name = this.state.order.user.username;
    }

    if (
      this.state.order.orderdetail.address &&
      this.state.order.orderdetail.address.email
    ) {
      user_email = this.state.order.orderdetail.address.email;
    } else {
      user_email = 'info@amarlab.com';
    }

    address =
      this.state.order.orderdetail.address.address +
      ', ' +
      this.state.order.orderdetail.address.thana +
      ', ' +
      this.state.order.orderdetail.address.district;

    const paymentData = {
      total_price:
        parseInt(this.state.order.total_price) +
        parseInt(this.state.order.material_fee) +
        parseInt(this.state.order.collection_fee) -
        this.state.discountPrice,
      category: 'diagnostic-test',
      order_id: '#' + this.state.order.order_id,
      order_quantity: this.state.order.orderitem.length,
      contact_name: user_name,
      contact_email: user_email,
      contact_address: address,
      contact_mobile: this.state.order.orderdetail.address.mobile,
      order_pk: this.state.order.id,
    };
    // console.log(paymentData);

    const patients = this.state.order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);

    const mainPrice = this.state.order.total_price;
    const materialFee = this.state.order.material_fee;
    const collectionFee = this.state.order.collection_fee;
    const couponPrice =
      this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_price
        ? parseInt(this.state.order.orderdiscount.discount_price)
        : `${parseInt('00')}`;

    localStorage.setItem('main_price', JSON.stringify(mainPrice));
    localStorage.setItem('material_fee', JSON.stringify(materialFee));
    localStorage.setItem('collection_fee', JSON.stringify(collectionFee));
    localStorage.setItem('coupon_fee', JSON.stringify(couponPrice));

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/init-payment`, paymentData)
      .then((resp) => {
        console.log('The response is = ', resp.data);
        if (resp.data.response.status === 'SUCCESS') {
          window.location = resp.data.response.GatewayPageURL;
        } else {
          toast.error(
            'Something went wrong, you are not able to payment now, please contact our customar care for cash on delivery.',
            {
              autoClose: 3000,
            }
          );
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Order Details');
    window.scrollTo(0, 0);
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');

    if (!token) {
      this.props.history.push(
        `/login?redirect=${this.props.location.pathname}`
      );
    } else if (!userId) {
      this.props.history.push(
        `/login?redirect=${this.props.location.pathname}`
      );
    } else {
      this.setState({ userLocalId: JSON.parse(userId) });
      setTimeout(() => {
        this.fetchSingleOrder(this.props.match.params.id);
      }, 100);
      this.getFees();
    }
  }

  mainBill = (obj) => {
    const total = parseInt(obj.total_price);
    const pickupChange = obj.collection_fee;

    return (
      total -
      (pickupChange + this.state.materialPerson * obj.orderitem[0].meterial_fee)
    );
  };

  render() {
    const {
      order,
      discountPrice,
      materialPerson,
      loading,
      reports,
      patientOrders,
      discountPercentage,
    } = this.state;

    return (
      <section className='order-place-details details'>
        <div className='container'>
          {loading ? (
            <LoadingGif />
          ) : (
            <>
              {order && (
                <div className='row'>
                  <div className='col-lg-8 left'>
                    <h5 className='main-heading'>
                      {order.orderdetail &&
                        order.orderdetail.payment_status === 'unpaid'
                        ? 'Order Placed (Cash on Delivery)'
                        : 'Order Details'}
                    </h5>
                    <p className='id'>#{order.order_id}</p>
                    <p className='date report-first mobile'>
                      <div className='report_del'>
                        <span>Order Status: </span>{' '}
                        <span
                          className={`text-capitalize ${order.orderdetail.order_status}`}
                        >
                          {order.orderdetail.order_status === 'cancel'
                            ? 'Cancelled'
                            : order.orderdetail.order_status.replace(/_/g, ' ')}
                        </span>
                      </div>
                      {reports && reports.length !== 0 && (
                        <button className='btn btn-primary' disabled>
                          <img src={DownloadWhite} alt='' /> Download Reports
                        </button>
                      )}
                    </p>
                    <p className='mobile font-20 sample_coll'>
                      <span className='font-semibold'>
                        Sample Collection Date:
                      </span>{' '}
                      {moment(order.date).format('DD MMM YYYY')}
                    </p>
                    <div className='location'>
                      <p className='left font-semibold'>
                        Sample Collection Location:
                      </p>
                      <div className='right'>
                        <p className='bottom'>
                          {order.orderdetail.address.address},{' '}
                          {order.orderdetail.address.thana},{' '}
                          {order.orderdetail.address.district}
                        </p>
                      </div>
                    </div>
                    <p className='date report-first mobile'>
                      <div className='report_del'>
                        <span>Report Delivery Status: </span>{' '}
                        <span
                          className={`text-capitalize`}
                        >
                          {order.orderdetail.delivery_copy_status.replace(/_/g, ' ')} Copy
                        </span>
                      </div>
                    </p>
                    {order.orderdetail && order.orderdetail.estimate_date && (
                      <p className='date mb-4'>
                        <span className='font-semibold'>
                          Estimated Report Delivery:{' '}
                        </span>{' '}
                        {moment(order.orderdetail.estimate_date).format(
                          'DD MMM YYYY'
                        )}
                      </p>
                    )}
                    {order.orderdelivery.length !== 0 && (
                      <div className='location'>
                        <p className='left font-semibold'>
                          Report Delivery Status:
                        </p>
                        <div
                          className='right report_delivery'
                          style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                          }}
                        >
                          {order.orderdelivery.map((status, i) => (
                            <p
                              className='top font-20 mb-0 text-capitalize'
                              key={i}
                            >
                              {status.name.replace(/_/g, ' ')}{' '}
                              <p className='date'>
                                &mdash;{' '}
                                {moment(status.created_at).format(
                                  'DD MMM YYYY; hh:mm A'
                                )}
                              </p>
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    <p className='mobile font-20'>
                      <span className='font-semibold'>Contact Number:</span> +88{' '}
                      {order.orderdetail.address.mobile}
                    </p>
                    {order.orderdetail.address.email && (
                      <p className='email font-20'>
                        <span className='font-semibold'>Email:</span>{' '}
                        {order.orderdetail.address.email}
                      </p>
                    )}
                    <div className='cart-summery'>
                      <h5 className='mb-0 font-semibold'>Order Summary</h5>
                    </div>
                    <div className='list summery_cart'>
                      <div className='order_summery'>
                        <div className='lab-logo'>
                          <span>Ordered From:</span>
                          {order.orderitem.length !== 0 ? (
                            <img
                              src={
                                order.orderitem[0].purchasable_order_item
                                  .testitem_purchasable_oi.branch.lab.logo
                              }
                              alt='lab logo'
                            />
                          ) : (
                            <img src={AmarlabLogo} alt='lab logo' />
                          )}
                        </div>
                        <div className='lists'>
                          {order.orderitem.length !== 0 && (
                            <>
                              {patientOrders &&
                                patientOrders.map((patinet, i) => (
                                  <SingleCartSummery key={i} item={patinet} />
                                ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {order.orderdetail &&
                      order.orderdetail.payment_status === 'unpaid' &&
                      (order.orderdetail.order_status !== 'cancel' &&
                        order.orderdetail.order_status !== 'completed') && (
                        <>
                          <div className='bottom bg-primary'>
                            <h5>
                              Order Placed At Cash On Delivery But You Can Still
                              Pay Now For This Order
                            </h5>
                            <button
                              onClick={(e) => {
                                this.goToPayment();
                                this.context.postActivity(
                                  'button',
                                  `Pay Now (${order.order_id})`,
                                  window.location.href
                                );
                              }}
                              className='btn btn-warning'
                            >
                              Pay Now
                            </button>
                          </div>
                          <p className='terms'>
                            * By completing your purchase you agree to this{' '}
                            <Link
                              to='/terms-of-service'
                              className='font-semibold'
                            >
                              Terms of Service.
                            </Link>
                          </p>
                        </>
                      )}
                    <div className='available-reports'>
                      {reports && reports.length !== 0 && (
                        <div className='cart-summery'>
                          <h5 className='mb-0 font-20 font-semibold'>
                            Available Reports
                          </h5>
                          <button className='btn btn-primary' disabled>
                            <img src={DownloadWhite} alt='' /> Download All
                          </button>
                        </div>
                      )}
                      <div className='items'>
                        {reports &&
                          reports.map((report, i) => (
                            <div className='single' key={i}>
                              <div className='card'>
                                <div className='name'>
                                  <span className='font-semibold'>
                                    Patient:
                                  </span>{' '}
                                  {report.patient.full_name},{' '}
                                  <span className='age'>
                                    {moment().diff(
                                      `${report.patient.dob}`,
                                      'years'
                                    ) + ' Years'}
                                  </span>
                                </div>
                                {report.reports.map((file, i) => (
                                  <div className='pdf' key={i}>
                                    <div className='left'>
                                      <img src={PdfIcon} alt='' />
                                      <div className='name-date'>
                                        <p className='name'>
                                          {/* CBC Report_Popular - Part 01 */}
                                          {file.name
                                            ? file.name
                                            : 'Report ' + (i + 1)}
                                        </p>
                                        <p className='date'>
                                          Uploaded on:{' '}
                                          {moment(file.created_at).format(
                                            'DD MMM YYYY; hh:mm A'
                                          )}
                                        </p>
                                        {/* <p className='date'>
                                          Note: {report.note}
                                        </p> */}
                                      </div>
                                    </div>
                                    <div className='right'>
                                      <a
                                        href={file.report}
                                        className='btn btn-primary'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        onClick={() =>
                                          this.context.postActivity(
                                            'button',
                                            `Download Report (${file.id})`,
                                            window.location.href
                                          )
                                        }
                                      >
                                        <img src={DownloadWhite} alt='' />{' '}
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 right'>
                    <div className='card'>
                      <h5 className='title'>Payment Summary</h5>
                      <div className='cost'>
                        <div className='price'>
                          <p>Total Price</p>
                          <p className='bold-text'>
                            ৳ {parseInt(order.total_price)}
                          </p>
                        </div>
                        <div className='price'>
                          <p>Pick up charges</p>
                          <p className='bold-text'>
                            ৳ {parseInt(order.collection_fee)}
                          </p>
                        </div>
                        <div className='price'>
                          <p>
                            Material fee {parseInt(order.material_fee)} *{' '}
                            {materialPerson ? materialPerson : '0'}
                          </p>
                          <p className='bold-text'>
                            ৳ {parseInt(order.material_fee)}
                          </p>
                        </div>
                        {discountPrice && (
                          <div className='price'>
                            <p>Discount</p>
                            <p className='bold-text'>
                              - ৳ {discountPrice || '00'}
                            </p>
                          </div>
                        )}
                        <div className='break'></div>
                        <div className='price'>
                          <p className='bold-text'>Total:</p>
                          <p className='bold-text'>
                            {/* ৳ {parseInt(order.total_price)} */}৳{' '}
                            {discountPrice
                              ? parseInt(order.total_price) +
                              parseInt(order.material_fee) +
                              parseInt(order.collection_fee) -
                              discountPrice
                              : parseInt(order.total_price) +
                              parseInt(order.material_fee) +
                              parseInt(order.collection_fee)}
                          </p>
                        </div>
                      </div>
                      <div className='bottom'>
                        {order.orderdetail &&
                          order.orderdetail.payment_status === 'paid' ? (
                          <>
                            <PDFDownloadLink
                              document={
                                <InvoicePdf
                                  order={order}
                                  materialPerson={materialPerson}
                                  discountPrice={discountPrice}
                                  patientOrders={patientOrders}
                                  discountPercentage={discountPercentage}
                                />
                              }
                              fileName={`Order ${order.order_id} Invoice`}
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  'Loading document...'
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.context.postActivity(
                                        'button',
                                        'Download Invoice',
                                        window.location.href
                                      )
                                    }
                                  >
                                    <img src={DwnldIcon} alt='' />
                                    Download Invoice
                                  </span>
                                )
                              }
                            </PDFDownloadLink>
                            {/* <a
                              onClick={this.downloadPdf}
                              href='?#'
                              className='btn btn-light'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <img src={DwnldIcon} alt='' />
                              Download Invoice
                            </a> */}

                            <p className='mb-0 font-semibold text-uppercase'>
                              {order.orderdetail &&
                                order.orderdetail.payment_status
                                ? order.orderdetail.payment_status
                                : 'UNPAID'}
                            </p>
                          </>
                        ) : ((order.orderdetail.order_status !== 'cancel' &&
                          order.orderdetail.order_status !== 'completed') &&
                          <button
                            onClick={(e) => {
                              this.goToPayment();
                              this.context.postActivity(
                                'button',
                                `Pay Now (${order.order_id})`,
                                window.location.href
                              );
                            }}
                            className='btn btn-primary btn-block primary'
                          >
                            Pay Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
}

export default OrderDetails;
OrderDetails.contextType = AuthContext;
