import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from 'react-share';

import React, { Component } from 'react';
import axios from 'axios';
import LoadingGif from '../components/LoadingGif';
import moment from 'moment';
import { AuthContext } from '../context/AuthContext';

class PressDetails extends Component {
  state = {
    PressRelease: null,
    PressLoading: false,
    currentUrl: 'https://amarlab.com/press/',
    categories: [],
  };

  fetchCategory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/press/category?ordering=sorting`)
      .then((resp) => {
        console.log(resp.data);
        this.setState({ categories: resp.data.results });
        // setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
        // setLoading(false);
      });
  };

  fetchSinglePost = (slug) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/press/post-get/?slug=${slug}&is_active=true`
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.results.length === 0) {
          this.props.history.push('/press');
        } else {
          this.setState({
            PressLoading: false,
            PressRelease: resp.data.results[0],
          });
        }
      })
      .catch((error) => {
        // console.log(error.response);
        this.setState({ PressLoading: false });
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Press Details');
    // console.log(this.props.match.params.slug);
    window.scrollTo(0, 0);
    this.setState({ PressLoading: true });
    this.fetchSinglePost(this.props.match.params.slug);
    // console.log(window.location.href);
    this.setState({ currentUrl: window.location.href });
    this.fetchCategory();
  }

  handleLinkClick = (name) => {
    // console.log(name);
    this.props.history.push(`/press?term=${name}`);
  };

  render() {
    const { PressRelease, PressLoading, currentUrl, categories } = this.state;

    return (
      <div className='press-release amarlab-press'>
        <section className='head'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2 className='title main-heading'>AmarLab Press And Media</h2>
                <p className='main-subheading mb-0'>
                  Our press release and press coverage
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='content'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-3 left'>
                <ul>
                  {categories.length !== 0 &&
                    categories.map((category, i) => (
                      <li
                        key={i}
                        onClick={() => this.handleLinkClick(category.slug)}
                      >
                        {category.name}
                      </li>
                    ))}
                  <li onClick={() => this.handleLinkClick('contact')}>
                    Contact
                  </li>
                </ul>
              </div>
              <div className='col-md-9 right'>
                <h1 className='main-heading'>AmarLab Press</h1>
                {PressLoading ? (
                  <LoadingGif />
                ) : (
                  <>
                    {PressRelease && (
                      <div className='details'>
                        <div className='image'>
                          <img
                            src={PressRelease.image}
                            alt=''
                            className='w-100'
                          />
                        </div>
                        <div className='date'>
                          {moment(PressRelease.date).format('DD MMM YYYY')}
                        </div>
                        <div className='title'>{PressRelease.name}</div>
                        <div className='description'>
                          {PressRelease.description}
                        </div>
                        <div className='share'>
                          <div className='single'>
                            <FacebookShareButton url={currentUrl}>
                              <FacebookIcon size={32} borderRadius={10} />
                            </FacebookShareButton>
                          </div>
                          <div className='single'>
                            <TwitterShareButton url={currentUrl}>
                              <TwitterIcon size={32} borderRadius={10} />
                            </TwitterShareButton>
                          </div>
                          <div className='single'>
                            <WhatsappShareButton url={currentUrl}>
                              <WhatsappIcon size={32} borderRadius={10} />
                            </WhatsappShareButton>
                          </div>
                          <div className='single'>
                            <EmailShareButton url={currentUrl}>
                              <EmailIcon size={32} borderRadius={10} />
                            </EmailShareButton>
                          </div>
                          <div className='single'>
                            <LinkedinShareButton url={currentUrl}>
                              <LinkedinIcon size={32} borderRadius={10} />
                            </LinkedinShareButton>
                          </div>
                          <div className='single'>
                            <PinterestShareButton url={currentUrl}>
                              <PinterestIcon size={32} borderRadius={10} />
                            </PinterestShareButton>
                          </div>
                          <div className='single'>
                            <TelegramShareButton url={currentUrl}>
                              <TelegramIcon size={32} borderRadius={10} />
                            </TelegramShareButton>
                          </div>
                          <div className='single'>
                            <FacebookMessengerShareButton url={currentUrl}>
                              <FacebookMessengerIcon
                                size={32}
                                borderRadius={10}
                              />
                            </FacebookMessengerShareButton>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PressDetails;
PressDetails.contextType = AuthContext;
