import axios from 'axios';
import { useContext } from 'react';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Privilages = () => {
  const { postActivity } = useContext(AuthContext);
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/press/banner-get/?banner_type=poster&ordering=id&limit=100`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        setBanners(resp.data.results);
      });
  }, []);

  return (
    <section className='privilages'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>
              Enjoy Special Offers
            </h5>
            <p className='text-center main-desc mx-auto'>
              {/* There is no greater benchmark for success than customer
              satisfaction. Over the years. */}
            </p>
          </div>
          {banners &&
            banners.map((banner, i) => (
              <div
                className={`${
                  banner.sorting % 2 === 0 ? 'col-md-5 two' : 'col-md-7 one'
                }`}
                key={i}
                onClick={() =>
                  postActivity(
                    'banner',
                    'Privileges Banner',
                    window.location.href
                  )
                }
              >
                <img src={banner.image} alt='' className='img-fluid' />
              </div>
            ))}
          {banners && banners.length > 4 && (
            <div className='col-12'>
              <p className='text-center m-0'>
                <button className='btn btn-default'>See More</button>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Privilages;
