import axios from 'axios';
import { useContext } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';

const ReqOrderCall = () => {
  const { postActivity } = useContext(AuthContext);
  const [number, setNumber] = useState('');

  const handleSumbit = (e) => {
    e.preventDefault();
    postActivity('button', `Quick Order(${number})`, window.location.href);

    // console.log(number);

    let numberPattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    if (!numberPattern.test(number)) {
      toast.error('Please enter a valid number!');
    } else {
      const postData = {
        phone_number: number,
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/order/quick-order/`, postData)
        .then((resp) => {
          // console.log(resp.data);
          toast.success(
            'Your request has been submitted, we will contact you soon.'
          );
          setNumber('');
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  return (
    <section className='global req-order'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5>Need help in booking a test?</h5>
            {/* <h5>Request For Order</h5> */}
            <p>Enter your contact details below and our team will call you.</p>
            <form onSubmit={handleSumbit}>
              <input
                type='number'
                placeholder='Your Mobile Number'
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              <button className='btn btn-primary'>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReqOrderCall;
