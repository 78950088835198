import Test from '../assets/img/test1.jpg';
import CartIcon from '../assets/img/cart.png';
// import Popular from '../assets/img/popular.png';
// import IbnSina from '../assets/img/ibnsina.png';
import Carousel from 'react-multi-carousel';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Modal, ModalBody } from 'react-bootstrap';
import LeftArrow from '../assets/img/left-arrow.png';
import PatientDob from './PatientDob';
import moment from 'moment';
import { useEffect } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 2.5,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 3.4,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 4.2,
    partialVisibilityGutter: 30,
  },
};

const CheckupPackages = ({
  test,
  selectedLabs = [],
  searchArea = '',
  cartPageData,
}) => {
  const {
    carts,
    setCartsItemAdd,
    suMyself,
    suFamily,
    setUserNewFamily,
    isLocalLab,
    setIsLocalLab,
    setUserMyself,
    userDetails,
    setShowLoginModal,
    navAreaName,
    postActivity,
    lastCartPatient,
    setLastCartPatient,
  } = useContext(AuthContext);

  const [labImages, setLabImages] = useState(test.testitem);

  const history = useHistory();
  const [showLabModal, setShowLabModal] = useState(false);
  const [showFamilyModal, setShowFamilyModal] = useState(false);

  const [testItem, setTestItem] = useState(null);
  const [personDetails, setPersonDetails] = useState([]);

  const [showFamily, setShowFamily] = useState(false);
  const [showAddPatientForm, setShowAddPatientForm] = useState(false);
  const [showAddAnotherBtn, setShowAddAnotherBtn] = useState(true);

  const [showMyself, setShowMyself] = useState(true);
  const [orderForRadio, setOrderForRadio] = useState('Myself');

  const [patientName, setPatientName] = useState('');
  const [patientDob, setPatientDob] = useState('');
  const [patientGender, setPatientGender] = useState('');
  // const [userDetails, setUserDetails] = useState(null);

  const [myName, setMyName] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
  );
  const [myDob, setMyDob] = useState(
    (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
  );
  const [myGender, setMyGender] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].sex) || ''
  );

  useEffect(() => {
    if (selectedLabs.length !== 0) {
      const testAllLabs = test.testitem.map((item) => item);
      const shownLabs = testAllLabs.filter((testItems) => {
        return selectedLabs.some((lab) => {
          return lab === testItems.branch.lab.name;
        });
      });
      // console.log(labImages);
      // console.log(shownLabs);
      setLabImages(shownLabs);
    } else {
      if (window.innerWidth > 768) {
        setLabImages(test.testitem.filter((lab) => lab.branch.lab.is_active));
      }
    }
  }, [selectedLabs]);

  useEffect(() => {
    setMyName(
      (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
    );
    setMyDob(
      (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
    );
    setMyGender((suMyself && suMyself.length !== 0 && suMyself[0].sex) || '');
  }, [suMyself]);

  const [allLabs, setAllLabs] = useState(null);

  const [cPAllPat, setCPAllPat] = useState(false);
  const [fCartPage, setFCartPage] = useState(false);
  const [cPPatient, setCPPatient] = useState([]);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    if (getToken) {
      axios.interceptors.request.use((config) => {
        const token = 'Token ' + JSON.parse(getToken);
        config.headers.Authorization = token;
        return config;
      });

      // axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user/`).then((resp) => {
      //   setUserDetails(resp.data);
      //   // //console.log(resp.data);
      // });
    }
    if (cartPageData.params) {
      // console.log(cartPageData.params.formCartPage);
      // console.log(cartPageData.params.showPatient);
      if (
        cartPageData.params.showPatient &&
        cartPageData.params.showPatient.length === 0
      ) {
        setCPAllPat(true);
      } else {
        setCPPatient(cartPageData.params.showPatient);
      }

      if (cartPageData.params.formCartPage) {
        setFCartPage(true);
      }
    }
  }, []);

  const [selectedAreaLab, setSelectedAreaLab] = useState(navAreaName);

  useEffect(() => {
    setSelectedAreaLab(navAreaName);
  }, [navAreaName]);

  useEffect(() => {
    if (searchArea !== '') {
      setSelectedAreaLab(searchArea);
    }
  }, [searchArea]);

  const calculatePercentage = (object) => {
    const value =
      (parseInt(object.purchasable_order_item.sell_price) /
        parseInt(object.purchasable_order_item.price)) *
      100;

    const percentage = (100 - value).toString();
    return parseInt(percentage);
  };

  const subString = (string) => {
    if (string) {
      if (string.length > 90) {
        return string.substring(0, 90) + '.....';
      } else {
        return string;
      }
    } else {
      return '';
    }
  };

  const subStringModal = (string) => {
    if (string) {
      if (string.length > 100) {
        return string.substring(0, 100) + '.....';
      } else {
        return string;
      }
    } else {
      return '';
    }
  };

  const subStringMbl = (string) => {
    if (string) {
      if (string.length > 40) {
        return string.substring(0, 40) + '.....';
      } else {
        return string;
      }
    } else {
      return '';
    }
  };

  const minPrice = (object) => {
    var myArray = object.testitem,
      min = Math.min.apply(
        null,
        myArray.map((item) => {
          if (item && item.purchasable_order_item) {
            return item.purchasable_order_item.sell_price;
          } else {
            return 0;
          }
        })
      );

    // //console.log(min);
    return min;
  };

  const districts = (data) => {
    const locations = data.testitem.map((location) => location.location.name);
    const district = locations.filter((item, i, orig) => {
      return orig.indexOf(item, i + 1) === -1;
    });
    return district;
  };

  const handleAddToCart = (test) => {
    // console.log(test);
    postActivity('test', test.name, window.location.href);
    if (localStorage.getItem('token')) {
      if (carts.length !== 0) {
        // console.log(carts);
        if (carts[0].order_type === 'package') {
          toast.error(
            'Sorry, You cannot order packages and tests at the same time.'
          );
          return;
        }

        let existingCartCovid = false;
        let currentCartCovid = false;

        if (carts[0].test_item.diagnostic_test.category) {
          if (carts[0].test_item.diagnostic_test.category.name === 'Covid') {
            existingCartCovid = true;
          }
        }

        if (test.category && test.is_covid) {
          currentCartCovid = true;
        }

        // console.log(existingCartCovid);
        // console.log(currentCartCovid);

        if (existingCartCovid !== currentCartCovid) {
          toast.error(
            'Sorry, You cannot order covid and non covid tests at the same time.'
          );
          return;
        }
      }

      const labName = localStorage.getItem('lab_name');
      const labLocation = localStorage.getItem('lab_location');
      if (isLocalLab) {
        if (labName && labLocation) {
          // console.log(JSON.parse(labName));
          const allabs = test.testitem
            .filter((lab) => lab.branch !== null)
            .filter((lab) => lab.branch.lab.name === JSON.parse(labName))
            .filter(
              (location) => location.branch.name === JSON.parse(labLocation)
            )
            .filter((lab) => lab.branch.lab.is_active);
          console.log(allabs);
          setAllLabs(allabs);
          setShowLabModal(true);
        }
      } else {
        // setAllLabs(test.testitem);
        setShowLabModal(true);

        let labs = test.testitem
          .map((lab) => lab)
          .filter((lab) => lab.branch.lab.is_active);

        if (selectedLabs && selectedLabs.length !== 0) {
          labs = test.testitem
            .filter((lab) => {
              return selectedLabs.some((name) => name === lab.branch.lab.name);
            })
            .filter((lab) => lab.branch.lab.is_active);
        } else {
          labs = test.testitem
            .map((lab) => lab)
            .filter((lab) => lab.branch.lab.is_active);
        }
        console.log(selectedLabs, 'ab aya maja 2')

        if (selectedAreaLab === 'Dhaka' || selectedAreaLab === 'Savar') {
          const dhakaLabs = labs
            .filter((lab) => lab.location.name === 'Dhaka')
            .filter((lab) => lab.branch.lab.is_active);
          // console.log(dhakaLabs);
          setAllLabs(dhakaLabs);
        } else if (selectedAreaLab === 'Chattogram') {
          const ctgLabs = labs
            .filter((lab) => lab.location.name === 'Chattogram')
            .filter((lab) => lab.branch.lab.is_active);
          // console.log(ctgLabs);
          setAllLabs(ctgLabs);
        }
      }
    } else {
      // toast.error('Please login first.');
      // console.log(test);
      const unknownTestdata = {
        testType: 'diagnostic',
        testSlug: test.slug,
        location: history.location.pathname,
      };

      sessionStorage.setItem('loginAfterData', JSON.stringify(unknownTestdata));
      // console.log(test);
      if (window.innerWidth < 768) {
        setShowLoginModal(true);
      } else {
        history.push('/login');
      }
    }
  };

  const handleLabRadio = (e) => {
    console.log("ei beta", e);
    setTestItem(e);
  };

  const goToFamilyModal = () => {
    if (!isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          setShowLabModal(false);
          setShowFamilyModal(true);
          setPersonDetails(suMyself);
        }
      });
    } else {
      setShowLabModal(false);
      setShowFamilyModal(true);
      setPersonDetails(suMyself);
    }
  };

  const goToLabModal = () => {
    setShowLabModal(true);
    setShowFamilyModal(false);
  };

  const handleOrderFor = (e) => {
    // console.log(e.target.value);
    setPersonDetails([]);
    setOrderForRadio(e.target.value);

    if (e.target.value === 'Myself') {
      setShowFamily(false);
      // setPersonDetails(userSelf);
      setShowMyself(true);
      setPersonDetails(suMyself);
    } else {
      setShowFamily(true);
      setShowMyself(false);
      // setPersonDetails([]);
    }
  };

  const handleFamilyMember = (e, family) => {
    if (e.target.checked) {
      setPersonDetails([...personDetails, family]);
    } else {
      setPersonDetails(
        personDetails.filter((person) => person.id !== family.id)
      );
    }
  };

  const handleAddAnother = () => {
    setShowAddPatientForm(true);
    setShowAddAnotherBtn(false);
  };

  const handleAddFamilyMember = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (patientDob === '' || patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const allPatients = [...suFamily, ...suMyself];
      // console.log(allPatients);
      // console.log(patientName);
      const check = allPatients.find(
        (item) => item.full_name.toLowerCase() === patientName.toLowerCase()
      );
      // console.log(check);
      if (check) {
        toast.error(
          `A patient already exists with this name in your family, please try another name.`
        );
      } else {
        const checkToken = localStorage.getItem('token');
        const user = localStorage.getItem('user_id');

        if (user) {
          axios.interceptors.request.use((config) => {
            const token = 'Token ' + JSON.parse(checkToken);
            config.headers.Authorization = token;
            return config;
          });

          const patientData = {
            full_name: patientName,
            dob: moment(patientDob).format('YYYY-MM-DD'),
            sex: patientGender,
            user: user,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
              patientData
            )
            .then((resp) => {
              //console.log(resp.data);
              setShowAddPatientForm(false);
              setPatientName('');
              setPatientDob('');
              setPatientGender('');
              setShowAddAnotherBtn(true);
              setUserNewFamily(resp.data);
              // addUserFamily(resp.data);
            })
            .catch((error) => {
              //console.log(error.response.status);
              //console.log(error.response.data);
            });
        }
      }
    }
  };

  const directAddToCart = (array, newLastCartPtn) => {
    if (array.length !== 0) {
      let carts = array.map((person, i) => {
        return {
          order_type: 'diagnostic',
          patient: person,
          test_item: testItem,
        };
      });

      const checkCrossSell = carts.find(
        (item) =>
          item.test_item.diagnostic_test.cross_sell &&
          item.test_item.diagnostic_test.cross_sell !== null
      );

      if (checkCrossSell) {
        // console.log(checkCrossSell.test_item.diagnostic_test.cross_sell);

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?slug=${checkCrossSell.test_item.diagnostic_test.cross_sell.slug}`
          )
          .then((resp) => {
            const branch = carts[0].test_item.branch.name;
            const lab = carts[0].test_item.branch.lab.name;
            // console.log(branch);
            // console.log(lab);
            // console.log(resp.data);
            const filtered = resp.data.results[0].testitem
              .filter((item) => item.branch.name === branch)
              .filter((item) => item.branch.lab.name === lab);
            if (filtered.length !== 0) {
              const cressCellArray = lastCartPatient.map((person, i) => {
                return {
                  order_type: 'diagnostic',
                  patient: person,
                  test_item: filtered[0],
                };
              });
              // console.log(cressCellArray);
              carts = [...carts, ...cressCellArray];
            }

            // console.log(carts);
            // console.log(carts[0].test_item.branch.name);
            // console.log(carts[0].test_item.branch.lab.name);
            // return;

            const order = {
              user: userDetails,
              status: '',
              order_item: carts,
            };

            // console.log(order);
            setCartsItemAdd(carts);

            localStorage.setItem(
              'lab_name',
              JSON.stringify(order.order_item[0].test_item.branch.lab.name)
            );
            setIsLocalLab(true);

            localStorage.setItem(
              'lab_location',
              JSON.stringify(order.order_item[0].test_item.branch.name)
            );

            const getOrder = localStorage.getItem('order');
            const userId = localStorage.getItem('user_id');

            if (getOrder) {
              const userdsdsad = JSON.parse(getOrder);
              if (userdsdsad.user.pk === JSON.parse(userId)) {
                localStorage.removeItem('order');
                const allOrders = JSON.parse(getOrder);
                const newItem = [...order.order_item, ...allOrders.order_item];
                //console.log(allOrders);

                const newData = {
                  user: allOrders.user,
                  status: '',
                  order_item: newItem,
                };
                //console.log(newData);

                localStorage.setItem('order', JSON.stringify(newData));
              } else {
                localStorage.removeItem('order');
                localStorage.setItem('order', JSON.stringify(order));
              }
            } else {
              localStorage.setItem('order', JSON.stringify(order));
            }

            toast.success('Test added successfully.', {
              autoClose: 3000,
            });

            setShowFamilyModal(false);
            setPersonDetails([]);
            setTestItem(null);
            setShowFamily(false);
            setShowLabModal(false);
            sessionStorage.removeItem('loginAfterData');
            if (fCartPage) {
              history.push('/cart');
            }

            if (newLastCartPtn && newLastCartPtn.length !== 0) {
              localStorage.setItem(
                'lastCartPatient',
                JSON.stringify(newLastCartPtn)
              );
              setLastCartPatient(newLastCartPtn);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        const order = {
          user: userDetails,
          status: '',
          order_item: carts,
        };

        // console.log(order);
        setCartsItemAdd(carts);

        localStorage.setItem(
          'lab_name',
          JSON.stringify(order.order_item[0].test_item.branch.lab.name)
        );
        setIsLocalLab(true);

        localStorage.setItem(
          'lab_location',
          JSON.stringify(order.order_item[0].test_item.branch.name)
        );

        const getOrder = localStorage.getItem('order');
        const userId = localStorage.getItem('user_id');

        if (getOrder) {
          const userdsdsad = JSON.parse(getOrder);
          if (userdsdsad.user.pk === JSON.parse(userId)) {
            localStorage.removeItem('order');
            const allOrders = JSON.parse(getOrder);
            const newItem = [...order.order_item, ...allOrders.order_item];
            //console.log(allOrders);

            const newData = {
              user: allOrders.user,
              status: '',
              order_item: newItem,
            };
            //console.log(newData);

            localStorage.setItem('order', JSON.stringify(newData));
          } else {
            localStorage.removeItem('order');
            localStorage.setItem('order', JSON.stringify(order));
          }
        } else {
          localStorage.setItem('order', JSON.stringify(order));
        }

        toast.success('Test added successfully.', {
          autoClose: 3000,
        });

        setShowFamilyModal(false);
        setPersonDetails([]);
        setTestItem(null);
        setShowFamily(false);
        sessionStorage.removeItem('loginAfterData');
        setShowLabModal(false);
        if (fCartPage) {
          history.push('/cart');
        }

        if (newLastCartPtn && newLastCartPtn.length !== 0) {
          localStorage.setItem(
            'lastCartPatient',
            JSON.stringify(newLastCartPtn)
          );
          setLastCartPatient(newLastCartPtn);
        }
      }
    }
  };

  const handleOrderNow = () => {
    if (!showFamily) {
      // console.log('update profile');
      handleEditMyself();
    }

    let carts = personDetails.map((person, i) => {
      return {
        order_type: 'diagnostic',
        patient: person,
        test_item: testItem,
      };
    });

    const checkCrossSell = carts.find(
      (item) =>
        item.test_item.diagnostic_test.cross_sell &&
        item.test_item.diagnostic_test.cross_sell !== null
    );

    if (checkCrossSell) {
      // console.log(checkCrossSell.test_item.diagnostic_test.cross_sell);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?slug=${checkCrossSell.test_item.diagnostic_test.cross_sell.slug}`
        )
        .then((resp) => {
          const branch = carts[0].test_item.branch.name;
          const lab = carts[0].test_item.branch.lab.name;
          const filtered = resp.data.results[0].testitem
            .filter((item) => item.branch.name === branch)
            .filter((item) => item.branch.lab.name === lab);
          if (filtered.length !== 0) {
            const cressCellArray = personDetails.map((person, i) => {
              return {
                order_type: 'diagnostic',
                patient: person,
                test_item: filtered[0],
              };
            });
            // console.log(cressCellArray);
            carts = [...carts, ...cressCellArray];
          }

          // console.log(carts);
          // console.log(carts[0].test_item.branch.name);
          // console.log(carts[0].test_item.branch.lab.name);

          const order = {
            user: userDetails,
            status: '',
            order_item: carts,
          };

          // console.log(order);
          setCartsItemAdd(carts);

          localStorage.setItem(
            'lab_name',
            JSON.stringify(order.order_item[0].test_item.branch.lab.name)
          );
          setIsLocalLab(true);

          localStorage.setItem(
            'lab_location',
            JSON.stringify(order.order_item[0].test_item.branch.name)
          );

          const getOrder = localStorage.getItem('order');
          const userId = localStorage.getItem('user_id');

          if (getOrder) {
            const userdsdsad = JSON.parse(getOrder);
            if (userdsdsad.user.pk === JSON.parse(userId)) {
              localStorage.removeItem('order');
              const allOrders = JSON.parse(getOrder);
              const newItem = [...order.order_item, ...allOrders.order_item];
              //console.log(allOrders);

              const newData = {
                user: allOrders.user,
                status: '',
                order_item: newItem,
              };
              //console.log(newData);

              localStorage.setItem('order', JSON.stringify(newData));
            } else {
              localStorage.removeItem('order');
              localStorage.setItem('order', JSON.stringify(order));
            }
          } else {
            localStorage.setItem('order', JSON.stringify(order));
          }

          toast.success('Test added successfully.', {
            autoClose: 3000,
          });

          setShowFamilyModal(false);
          setPersonDetails([]);
          setTestItem(null);
          setShowFamily(false);
          sessionStorage.removeItem('loginAfterData');

          if (fCartPage) {
            history.push('/cart');
          }

          if (lastCartPatient.length === 0) {
            const lastCartPat = personDetails.at(-1);
            localStorage.setItem(
              'lastCartPatient',
              JSON.stringify([lastCartPat])
            );
            setLastCartPatient([lastCartPat]);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      const order = {
        user: userDetails,
        status: '',
        order_item: carts,
      };

      // console.log(order);
      setCartsItemAdd(carts);

      localStorage.setItem(
        'lab_name',
        JSON.stringify(order.order_item[0].test_item.branch.lab.name)
      );
      setIsLocalLab(true);

      localStorage.setItem(
        'lab_location',
        JSON.stringify(order.order_item[0].test_item.branch.name)
      );

      const getOrder = localStorage.getItem('order');
      const userId = localStorage.getItem('user_id');

      if (getOrder) {
        const userdsdsad = JSON.parse(getOrder);
        if (userdsdsad.user.pk === JSON.parse(userId)) {
          localStorage.removeItem('order');
          const allOrders = JSON.parse(getOrder);
          const newItem = [...order.order_item, ...allOrders.order_item];
          //console.log(allOrders);

          const newData = {
            user: allOrders.user,
            status: '',
            order_item: newItem,
          };
          //console.log(newData);

          localStorage.setItem('order', JSON.stringify(newData));
        } else {
          localStorage.removeItem('order');
          localStorage.setItem('order', JSON.stringify(order));
        }
      } else {
        localStorage.setItem('order', JSON.stringify(order));
      }

      toast.success('Test added successfully.', {
        autoClose: 3000,
      });

      setShowFamilyModal(false);
      setPersonDetails([]);
      setTestItem(null);
      setShowFamily(false);
      sessionStorage.removeItem('loginAfterData');
      if (fCartPage) {
        history.push('/cart');
      }

      if (lastCartPatient.length === 0 || fCartPage) {
        const lastCartPat = personDetails.at(-1);
        localStorage.setItem('lastCartPatient', JSON.stringify([lastCartPat]));
        setLastCartPatient([lastCartPat]);
      }
    }
  };

  const handleLabDistrict = (e, test) => {
    //console.log(e.target.value);
    // console.log(test.testitem);
    setSelectedAreaLab(e.target.value);
    let labs = test.testitem
      .map((lab) => lab)
      .filter((lab) => lab.branch.lab.is_active);
    if (selectedLabs && selectedLabs.length !== 0) {
      labs = test.testitem
        .filter((lab) => {
          return selectedLabs.some((name) => name === lab.branch.lab.name);
        })
        .filter((lab) => lab.branch.lab.is_active);
    } else {
      labs = test.testitem
        .map((lab) => lab)
        .filter((lab) => lab.branch.lab.is_active);
    }

    if (e.target.value === 'all') {
      // console.log(labs);
      setAllLabs(labs);
    } else if (e.target.value === 'Dhaka' || e.target.value === 'Savar') {
      const dhakaLabs = labs
        .filter((lab) => lab.location.name === 'Dhaka')
        .filter((lab) => lab.branch.lab.is_active);
      // console.log(dhakaLabs);
      setAllLabs(dhakaLabs);
    } else if (e.target.value === 'Chattogram') {
      const ctgLabs = labs
        .filter((lab) => lab.location.name === 'Chattogram')
        .filter((lab) => lab.branch.lab.is_active);
      // console.log(ctgLabs);
      setAllLabs(ctgLabs);
    }
  };

  const handleEditMyself = () => {
    if (myName.trim() === '') {
      toast.error(`Please enter your name.`, {
        autoClose: 3000,
      });
    } else if (myName.length < 4) {
      toast.error(`Your name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (myGender === '') {
      toast.error(`Please enter your gender.`, {
        autoClose: 3000,
      });
    } else if (myDob === '' || myDob === null) {
      toast.error(`Please enter your date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const putMyself = {
        full_name: myName,
        dob: moment(myDob).format('YYYY-MM-DD'),
        sex: myGender,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/user_management/patient/${suMyself[0].id}/`,
          putMyself
        )
        .then((resp) => {
          // console.log(resp.data);
          setUserMyself([]);
          setUserMyself([resp.data]);
          // toast.success(`Profile updated successfully.`, {
          //   autoClose: 3000,
          // });
          setPersonDetails([resp.data]);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    }
  };

  return (
    <>
      <div className='card'>
        {/* <img src={Test} className='card-img-top' alt='...' /> */}
        <div className='card-body'>
          <h5 className='card-title'><Link to={'/diagnostic-test?slug='+test.slug} rel="noopener noreferrer">{test.name}</Link></h5>
          <p className='card-text web'><Link to={'/diagnostic-test?slug='+test.slug} rel="noopener noreferrer">{subString(test.description)}</Link></p>
          <p className='card-text mobile'>{subStringMbl(test.description)}</p>
          <div className='places'>
            {searchArea && searchArea !== '' ? (
              <p>
                {searchArea === 'Dhaka' || searchArea === 'Savar'
                  ? 'Dhaka'
                  : 'ctg'}
              </p>
            ) : (
              <>
                {districts(test) &&
                  districts(test).map((district, i) => (
                    <p className='' key={i}>
                      {district === 'Dhaka' ? 'Dhaka' : ''}
                      {district === 'Chattogram' ? 'Ctg' : ''}
                    </p>
                  ))}
              </>
            )}
            {/* <button className='btn btn-light mr-1'>Dhaka</button>
            <button className='btn btn-light'>Ctg</button> */}
          </div>
          <div className='hospitals'>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              className=''
              containerClass='container carousel-container'
              dotListClass=''
              draggable
              focusOnSelect={false}
              infinite
              itemClass='test'
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
              showDots={false}
              sliderClass=''
              slidesToSlide={1}
              swipeable
            >
              {labImages.map((location, i) => (
                <div key={i}>
                  {location && location.branch && location.branch.lab ? (
                    <img
                      src={location.branch ? location.branch.lab.logo : ''}
                      alt=''
                    />
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </Carousel>
          </div>
          <div className='footer'>
            <div className='from-section'>
              <p className='from'>From</p>
              <p className='price mb-0'>৳ {minPrice(test)}</p>
            </div>
            <button
              className='btn btn-default'
              onClick={() => handleAddToCart(test)}
            >
              <img src={CartIcon} alt='' />
              Add To Cart
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={showLabModal}
        onHide={() => setShowLabModal(false)}
        animation={false}
        className='main-modl'
      >
        <ModalBody className='diagnostic-select'>
          <div className='head'>
            <div>
              <h5>Select Labs</h5>
              <p className='mb-0'>Testing will be done from this lab</p>
            </div>
            <button
              className='btn btn-light'
              onClick={() => setShowLabModal(false)}
            >
              Close
            </button>
          </div>
          <div className='bodies'>
            <div className='details'>
              <div className='left'>
                <h5>{test.name}</h5>
                <p className='mb-0'>{subStringModal(test.description)}</p>
              </div>
              {!isLocalLab && (
                <div className='right'>
                  <select
                    value={selectedAreaLab}
                    onChange={(e) => handleLabDistrict(e, test)}
                  >
                    <option value='all'>All Lab...</option>
                    <option value='Dhaka'>DHAKA</option>
                    {/* <option value='Savar'>SAVAR</option>
                    <option value='Chattogram'>CTG</option> */}
                  </select>
                </div>
              )}
            </div>
            <div className='lists'>
              {allLabs &&
                allLabs.map((hospital, i) => (
                  <div key={i}>
                    {hospital && hospital.branch && hospital.branch.lab && (
                      <div className='single'>
                        <label className='radio'>
                          <div className='all'>
                            <div className='hospital'>
                              <div className='logo'>
                                {hospital &&
                                  hospital.branch &&
                                  hospital.branch.lab && (
                                    <img
                                      src={hospital.branch.lab.logo || ''}
                                      alt=''
                                    />
                                  )}
                              </div>
                              <div className='name'>
                                <h5>
                                  {hospital &&
                                    hospital.branch &&
                                    hospital.branch.lab && (
                                      <h5>{hospital.branch.lab.name || ''}</h5>
                                    )}
                                </h5>
                                <p>Branch: {hospital.location.name}</p>
                              </div>
                            </div>
                            {parseInt(hospital.purchasable_order_item.price) >
                              parseInt(
                                hospital.purchasable_order_item.sell_price
                              ) && (
                              <div className='wever'>
                                <span className='btn btn-success'>
                                  {`- ${calculatePercentage(hospital)}%`}
                                </span>
                              </div>
                            )}
                            <div className='price'>
                              {parseInt(hospital.purchasable_order_item.price) >
                                parseInt(
                                  hospital.purchasable_order_item.sell_price
                                ) && (
                                <div className='old'>
                                  ৳{' '}
                                  {hospital && hospital.purchasable_order_item
                                    ? hospital.purchasable_order_item.price
                                    : ''}
                                </div>
                              )}
                              <div className='new'>
                                ৳{' '}
                                {hospital && hospital.purchasable_order_item
                                  ? hospital.purchasable_order_item.sell_price
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <input
                            type='radio'
                            name='radio'
                            onChange={() => handleLabRadio(hospital)}
                            checked={hospital === testItem}
                          />
                          <span className='checkmark' />
                        </label>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className='lists'>
              {allLabs && allLabs.length === 0 && (
                <p className='text-failed not-found'>
                  Sorry, no test available in selected area for your selected
                  lab.
                </p>
              )}
            </div>
            {lastCartPatient.length === 0 || cPAllPat ? (
              <button
                className='btn btn-primary btn-block next-step'
                onClick={goToFamilyModal}
                disabled={!testItem}
              >
                Select & Next
              </button>
            ) : (
              <>
                {cPPatient.length === 0 ? (
                  <button
                    className='btn btn-primary btn-block next-step'
                    onClick={() => directAddToCart(lastCartPatient)}
                    disabled={!testItem}
                  >
                    Add To Cart
                  </button>
                ) : (
                  <button
                    className='btn btn-primary btn-block next-step'
                    onClick={() => directAddToCart(cPPatient, cPPatient)}
                    disabled={!testItem}
                  >
                    Add To Cart
                  </button>
                )}
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={showFamilyModal}
        onHide={() => setShowFamilyModal(false)}
        animation={false}
        className='main-modl'
      >
        <ModalBody className='diagnostic-select patient family'>
          <div className='head'>
            <div className='descrip'>
              <img src={LeftArrow} alt='' onClick={goToLabModal} />
              <div className='left'>
                <h5>Patient Info</h5>
                <p className='mb-0'>Report will be generated with this name</p>
              </div>
            </div>
            <button
              className='btn btn-light'
              onClick={() => setShowFamilyModal(false)}
            >
              Close
            </button>
          </div>
          <div className='bodies'>
            <div className='details'>
              <div className='left'>
                <h5>I’m ordering for</h5>
              </div>
              <div className='right'>
                <label className='person first'>
                  Myself
                  <input
                    type='radio'
                    name='family'
                    value='Myself'
                    onChange={handleOrderFor}
                    checked={orderForRadio === 'Myself'}
                  />
                  <span className='checkmark' />
                </label>
                <label className='person'>
                  Family
                  <input
                    type='radio'
                    name='family'
                    value='Family'
                    onChange={handleOrderFor}
                    checked={orderForRadio === 'Family'}
                  />
                  <span className='checkmark' />
                </label>
              </div>
            </div>
            {showMyself && (
              <form>
                <div className='name-gender d-flex justify-content-between'>
                  <div className='name'>
                    <input
                      type='text'
                      className='nainp'
                      placeholder='Your Name'
                      value={myName}
                      onChange={(e) => setMyName(e.target.value)}
                    />
                  </div>
                  {/* <div className='gender'>
                    <h5>Your Gender*</h5>
                    <div className='male-female'>
                      <label className='gender'>
                        Male
                        <input
                          type='radio'
                          value='male'
                          onChange={(e) => setMyGender(e.target.value)}
                          name='my-gender'
                          checked={myGender === 'male'}
                        />
                        <span className='checkmark' />
                      </label>
                      <label className='gender'>
                        Female
                        <input
                          type='radio'
                          value='female'
                          onChange={(e) => setMyGender(e.target.value)}
                          name='my-gender'
                          checked={myGender === 'female'}
                        />
                        <span className='checkmark' />
                      </label>
                    </div>
                  </div> */}
                  <div className='gender'>
                    <select
                      className='nainp form-select form-select-sm'
                      value={myGender}
                      onChange={(e) => setMyGender(e.target.value)}
                    >
                      <option disabled value=''>
                        Select gender
                      </option>
                      <option value='male'>M</option>
                      <option value='female'>F</option>
                      <option value='common'>T</option>
                    </select>
                  </div>
                </div>
                <div className='age-rx'>
                  <PatientDob setPatientDob={setMyDob} patientDob={myDob} />
                </div>
                {/* <button className='btn-btn-light' onClick={handleEditMyself}>
                  Edit Myself
                </button> */}
              </form>
            )}
            {showFamily && (
              <>
                <div className='members'>
                  {suFamily.length === 0 ? (
                    <p>No family member found. Please add some.</p>
                  ) : (
                    <>
                      {suFamily.map((family, i) => (
                        <div key={i}>
                          <label className='individual'>
                            <span className='font-semibold'>
                              {family.full_name}
                            </span>
                            <input
                              type='checkbox'
                              value='Father'
                              onChange={(e) => handleFamilyMember(e, family)}
                            />
                            <span className='checkmark'></span>
                          </label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {showAddPatientForm && (
                  <form>
                    <div className='name-gender d-flex justify-content-between'>
                      <div className='name'>
                        <input
                          type='text'
                          className='nainp'
                          placeholder='Person Name'
                          value={patientName}
                          onChange={(e) => setPatientName(e.target.value)}
                        />
                      </div>
                      {/* <div className='gender'>
                        <h5>Gender*</h5>
                        <div className='male-female'>
                          <label className='gender'>
                            Male
                            <input
                              type='radio'
                              value='male'
                              onChange={(e) => setPatientGender(e.target.value)}
                              name='gender'
                            />
                            <span className='checkmark' />
                          </label>
                          <label className='gender'>
                            Female
                            <input
                              type='radio'
                              value='female'
                              onChange={(e) => setPatientGender(e.target.value)}
                              name='gender'
                            />
                            <span className='checkmark' />
                          </label>
                        </div>
                      </div> */}
                      <div className='gender'>
                        <select
                          className='nainp form-select form-select-sm'
                          value={patientGender}
                          onChange={(e) => setPatientGender(e.target.value)}
                        >
                          <option disabled value=''>
                            Select gender
                          </option>
                          <option value='male'>M</option>
                          <option value='female'>F</option>
                          <option value='trans'>T</option>
                        </select>
                      </div>
                    </div>
                    <div className='age-rx'>
                      <PatientDob
                        setPatientDob={setPatientDob}
                        patientDob={patientDob}
                      />
                    </div>
                    <button
                      className='btn-btn-light'
                      onClick={handleAddFamilyMember}
                    >
                      Add Member
                    </button>
                  </form>
                )}
                {showAddAnotherBtn && (
                  <div className='add-another'>
                    <button
                      className='btn-btn-light'
                      onClick={handleAddAnother}
                    >
                      Add Another
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='foot'>
            <button
              className='btn btn-primary btn-block'
              disabled={!personDetails.length ? 'disabled' : ''}
              onClick={handleOrderNow}
            >
              Add To Cart
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CheckupPackages;
