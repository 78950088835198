import StepOne from '../assets/img/hero-step-1.png';
import StepTwo from '../assets/img/hero-step-2.png';
import StepThree from '../assets/img/hero-step-3.png';
import Sponsor from '../components/Sponsor';
import UsStepOne from '../assets/img/step2.png';
import UsStepTwo from '../assets/img/step3.png';
import UsStepThree from '../assets/img/tag.svg';
import UsStepOneMbl from '../assets/img/step1.png';
// import OrderFind from '../assets/img/test.jpg';
// import OrderFindCard from '../assets/img/order-find-card.jpg';
// import OrderFindArrow from '../assets/img/order-find-arrow.png';
// import HomeCover from '../assets/img/new-cover.png';
// import PrivOne from '../assets/img/privilage1.png';
// import PrivTwo from '../assets/img/privilage2.png';
// import PrivThree from '../assets/img/privilage3.png';
import CategoryCarousel from '../components/CategoryCarousel';
import HeroCarousel from '../components/homepage/HeroCarousel1';
import Review from '../components/homepage/Review';
import BlogCarousel from '../components/homepage/BlogCarousel';
import TestCarousel from '../components/homepage/TestCarousel';
import PackageCarousel from '../components/homepage/PackageCarousel';
import { useContext, useState } from 'react';
import ReviewMobile from '../components/homepage/ReviewMobile';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import SemiHeaderMbl from '../components/SemiHeaderMbl';
import HomeDiagnostic from '../components/modal/HomeDiagnostic';
import axios from 'axios';
import swal from 'sweetalert';
import { AuthContext } from '../context/AuthContext';
import HomePackage from '../components/modal/HomePackage';
import CallForOrder from '../components/CallForOrder';
import Partners from '../components/homepage/Partners';
import Faq from '../components/homepage/Faq';
import ReqOrderCall from '../components/ReqOrderCall';
import Achievements from '../components/Achievements';
import BusinessPartner from '../components/homepage/BusinessPartner';
import Counters from '../components/homepage/Counters';
import Privilages from '../components/homepage/Privilages';
import HeroCarouselMbl from '../components/homepage/HeroCarouselMbl';
import FeaturedIn from '../components/homepage/FeaturedIn';
import AppRecomendation from '../components/AppRecomendation';
import OrderCardSec from '../components/homepage/OrderCardSec';

const HomePage = () => {
  const {
    isLocalLab,
    isLoggedIn,
    postActivity,
    setPageTitle,
    showAppRecomendation,
  } = useContext(AuthContext);

  const [diagModal, setDiagModal] = useState(false);
  const [patientModal, setPatientModal] = useState(false);
  const [testDetails, setTestDetails] = useState(null);

  const [packModal, setPackModal] = useState(false);
  const [patientModalPack, setPatientModalPack] = useState(false);
  const [packDetails, setPackDetails] = useState(null);

  const [isMobileView, setIsMobileView] = useState(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    window.matchMedia("only screen and (max-width: 480px)").matches
  );

  useEffect(() => {
    setPageTitle('Homepage');
    window.scrollTo(0, 0);

    if (isLoggedIn) {
      const getLoginAfter = sessionStorage.getItem('loginAfterData');
      const getLoginAfterData = JSON.parse(getLoginAfter);
      if (getLoginAfter) {
        // console.log(getLoginAfterData);
        if (getLoginAfterData.testType === 'diagnostic') {
          // console.log('show diagnostic modal');
          if (window.innerWidth < 768) {
            window.scrollTo(0, 1000);
          } else {
            window.scrollTo(0, 1700);
          }
          setDiagModal(true);
          handleFetchTestDetails(getLoginAfterData.testSlug);
        } else if (getLoginAfterData.testType === 'package') {
          if (window.innerWidth < 768) {
            window.scrollTo(0, 1650);
          } else {
            window.scrollTo(0, 2250);
          }
          setPackModal(true);
          handleFetchPackageDetails(getLoginAfterData.testSlug);
        }
      }
    }
  }, [isLoggedIn]);

  const handleFetchTestDetails = (slug) => {
    // console.log(test);
    if (localStorage.getItem('token')) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&slug=${slug}`
        )
        .then((resp) => {
          // console.log(resp.data.results);
          setTestDetails(resp.data.results);
        })
        .catch((error) => {
          //console.log(error);
        });
      // setShowModal(true);
    }
  };

  const handleFetchPackageDetails = (slug) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&slug=${slug}`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        setPackDetails(resp.data.results);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const goToFirstModal = () => {
    setDiagModal(true);
    setPatientModal(false);
  };

  const goToSecondModal = () => {
    if (!isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          setDiagModal(false);
          setPatientModal(true);
        }
      });
    } else {
      setDiagModal(false);
      setPatientModal(true);
      // setPersonDetails(suMyself);
    }
  };

  const goToFirstModalPack = () => {
    setPackModal(true);
    setPatientModalPack(false);
  };

  const goToSecondModalPack = () => {
    if (!isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          setPackModal(false);
          setPatientModalPack(true);
        }
      });
    } else {
      setPackModal(false);
      setPatientModalPack(true);
      // setPersonDetails(suMyself);
    }
  };

  return (
    <div className='homepage'>
      <SemiHeaderMbl />
      <section className='hero'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <h5>
                Diagnostics <br /> At Your Doorstep
              </h5>
              <p className='para'>
                Making Healthcare Easily Accessible <br /> to Everyone,
                Everywhere.
              </p>
              <div className='steps d-flex'>
                <div className='one'>
                  <img src={StepOne} alt='' width='23' />
                  <div className='details'>
                    <h5>
                      <span>Step 01:</span> Order tests
                    </h5>
                    <p className='mb-0'>Book tests & checkups</p>
                  </div>
                </div>
                <div className='two'>
                  <img src={StepTwo} alt='' width='23' />
                  <div className='details'>
                    <h5>
                      <span>Step 02:</span> Provide Sample
                    </h5>
                    <p className='mb-0'>From your doorstep</p>
                  </div>
                </div>
              </div>
              <div className='steps second'>
                <div className='one'>
                  <img src={StepThree} alt='' width='23' />
                  <div className='details'>
                    <h5>
                      <span>Step 03:</span> Get Report
                    </h5>
                    <p className='mb-0'>Your report delivered</p>
                  </div>
                </div>
              </div>
              <div className='buttons'>
                <Link
                  to='/diagnostic'
                  onClick={() =>
                    postActivity('button', 'Order Test', window.location.href)
                  }
                  className='btn btn-primary order'
                >
                  Order Test
                </Link>
                <a
                  onClick={() =>
                    postActivity(
                      'button',
                      'Call to Order',
                      window.location.href
                    )
                  }
                  href='tel:09643445566'
                  className='btn btn-light phone order'
                >
                  Call to Order
                </a>
              </div>
            </div>
            <div className='col-md-6 right'>
              <HeroCarousel />
              {/* <img src={HomeCover} alt='' className='img-fluid' /> */}
            </div>
          </div>
        </div>
      </section>
      <section className='hero-mobile'>
        <HeroCarouselMbl />
      </section>
      <Counters />
      <section className='why-us mobile bg-primary text-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-4 first'>
              <div className='single'>
                <div className='icon'>
                  <img src={UsStepOneMbl} alt='' />
                </div>
                <div className='details'>
                  <h5 className='text-center'>Order Diagnostics</h5>
                  <p className='text-center'>Will From your doorstep</p>
                </div>
              </div>
            </div>
            <div className='col-4 second'>
              <div className='single'>
                <div className='icon'>
                  <img src={UsStepOne} alt='' />
                </div>
                <div className='details'>
                  <h5 className='text-center'>Sample Collection</h5>
                  <p className='text-center'>Will From your doorstep</p>
                </div>
              </div>
            </div>
            <div className='col-4 third'>
              <div className='single'>
                <div className='icon'>
                  <img src={UsStepTwo} alt='' />
                </div>
                <div className='details'>
                  <h5 className='text-center'>Report Delivered</h5>
                  <p className='text-center'>Get Your report delivered</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isMobileView && <OrderCardSec isMobileView={isMobileView} />}

      <Partners />

      {/* <section className='order-find'>
        <div className='container'>
          <div className='row'>
            <div className='col-6 first'>
              <div className='card'>
                <img
                  className='card-img-top'
                  src={OrderFindCard}
                  alt='fghfgfdgfdg'
                />
                <div className='card-body'>
                  <h5 className='card-title'>Order Diagnostics</h5>
                  <p className='card-text'>
                    Making Healthcare Easily Accessible to Everyone.
                  </p>
                  <Link to='/diagnostic'>
                    ORDER NOW <img src={OrderFindArrow} alt='' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-6 second'>
              <div className='card'>
                <img
                  className='card-img-top'
                  src={OrderFindCard}
                  alt='fghfgfdgfdg'
                />
                <div className='card-body'>
                  <h5 className='card-title'>Health Packages</h5>
                  <p className='card-text'>
                    Making Healthcare Easily Accessible to Everyone.
                  </p>
                  <Link to='/packages'>
                    ORDER NOW <img src={OrderFindArrow} alt='' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className='why-us services'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h5 className='section-heading text-center'>Why Book With Us?</h5>
              <p className='text-center main-desc mx-auto'>
                {/* There is no greater benchmark for success than customer
                satisfaction. Over the years. */}
              </p>
            </div>
            <div className='col-md-4 second'>
              <div className='d-flex justify-content-center'>
                <div className='icon'>
                  <img src={StepTwo} alt='' width='35' />
                </div>
              </div>
              <div className='details'>
                <h5 className='text-center'>Safe Home Sample Collection</h5>
                <p className='text-center'>
                  A certified professional will collect your sample from your
                  location.
                </p>
              </div>
            </div>
            <div className='col-md-4 first'>
              <div className='d-flex justify-content-center'>
                <div className='icon'>
                  <img src={StepThree} alt='' width='30' />
                </div>
              </div>
              <div className='details'>
                <h5 className='text-center'>Get Digital Report Within A Day</h5>
                <p className='text-center'>
                  Our labs ensure turn-around-time of 24 hours from specimen
                  pickup
                </p>
              </div>
            </div>
            <div className='col-md-4 third'>
              <div className='d-flex justify-content-center'>
                <div className='icon'>
                  <img src={UsStepThree} alt='' width='40' />
                </div>
              </div>
              <div className='details'>
                <h5 className='text-center'>Offers And Affordable Prices</h5>
                <p className='text-center'>
                  Get great discounts and offers on tests and packages.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className='mb-0' id='tests'></p>
      </section>
      {!isMobileView && <OrderCardSec isMobileView={isMobileView} />}
      <ReqOrderCall />
      <Privilages />
      <Achievements />
      <BusinessPartner />
      <section className='blogs'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5 description'>
              <h5 className='main-heading'>Read Top Articles</h5>
              <p>
                Read our thoughts on healthcare, our news and updates from
                Amarlab family.
              </p>
              <a
                href='https://amarlab.com/eng/blog/'
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-primary'
                onClick={() =>
                  postActivity('button', 'See All Blogs', window.location.href)
                }
              >
                See All Articles
              </a>
            </div>
            <div className='col-md-7'>
              <div className='row'>
                <div className='col-12 main-col'>
                  <BlogCarousel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faq />
      <FeaturedIn />

      {/* <section className='category'>
        <div className='container'>
          <div className='row'>
            <CallForOrder />
            <CategoryCarousel />
          </div>
        </div>
        <p className='mb-0' id='tests'></p>
      </section> */}
      {/* <section className='tests'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 description'>
              <div>
                <h5 className='main-heading'>Diagnostic Tests</h5>
                <p className='main-subheading mb-0'>
                  Order Now & Get Up To 10% Instant Discount
                </p>
              </div>
              <Link to='/diagnostic' className='btn btn-primary'>
                VIEW ALL
              </Link>
            </div>
            <div className='col-12 carous'>
              <TestCarousel section={'first'} />
            </div>
            <div className='col-12 carous mobile'>
              <TestCarousel section={'second'} />
            </div>
          </div>
        </div>
        <p className='mb-0' id='packages'></p>
      </section> */}
      {/* <section className='packages'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 description'>
              <div>
                <h5 className='main-heading'>
                  Popular Health Checkup Packages
                </h5>
                <p className='main-subheading mb-0'>
                  Order Now & Get Up To 15% Instant Discount
                </p>
              </div>
              <Link to='/packages' className='btn btn-primary'>
                VIEW ALL
              </Link>
            </div>
            <div className='col-12 main-pac'>
              <PackageCarousel />
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className='review'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h5 className='main-heading'>
                What People Say <br /> About Us
              </h5>
            </div>
            <div className='col-md-12 desktop'>
              <Review />
            </div>
            <div className='col-md-12 mobile'>
              <ReviewMobile />
            </div>
          </div>
        </div>
      </section> */}
      {/* <Sponsor /> */}

      <HomeDiagnostic
        diagModal={diagModal}
        setDiagModal={setDiagModal}
        testDetails={testDetails}
        patientModal={patientModal}
        setPatientModal={setPatientModal}
        goToFirstModal={goToFirstModal}
        goToSecondModal={goToSecondModal}
      />

      <HomePackage
        packModal={packModal}
        setPackModal={setPackModal}
        packDetails={packDetails}
        patientModalPack={patientModalPack}
        setPatientModalPack={setPatientModalPack}
        goToFirstModalPack={goToFirstModalPack}
        goToSecondModalPack={goToSecondModalPack}
      />
      {/* {showAppRecomendation && <AppRecomendation />} */}
    </div>
  );
};

export default HomePage;
