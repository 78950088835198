import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DwnldIcon from '../assets/img/download-icon.png';
import { toast } from 'react-toastify';
import LoadingGif from '../components/LoadingGif';
import { AuthContext } from '../context/AuthContext';

class OrderHistory extends Component {
  state = {
    orders: null,
    next: null,
    offset: 3,
    limit: 3,
    userId: null,
    loading: true,
  };

  mainBill = (obj, pickupChange) => {
    const total = parseInt(obj.total_price);
    // const materialChange = obj.orderitem.length;

    return total - pickupChange;
  };

  getLastDeliveryStatus = (array) => {
    const lastStatus = array.at(-1);
    return lastStatus.name.replace(/_/g, ' ');
  };

  loadMore = () => {
    this.context.postActivity('button', `Load More`, window.location.href);
    this.setState({ offset: this.state.offset + 3 });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-tree/?user=${this.state.userId}&limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          orders: [...this.state.orders, ...resp.data.results],
        });

        this.setState({ next: resp.data.next });
      });
  };

  fetchUserOrder = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-tree/?user=${id}&page=1&limit=3&ofset=0`
      )
      .then((resp) => {
        //console.log("Order Details", resp.data.results);
        this.setState({ orders: resp.data.results });
        this.setState({ next: resp.data.next });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ loading: false });
      });
  };

  goToPayment = (e, order) => {
    e.preventDefault();

    let user_name, address, user_email;

    if (order.user && order.user.patient && order.user.patient.full_name) {
      user_name = order.user.patient.full_name;
    } else {
      user_name = order.user.username;
    }

    if (order.orderdetail.address && order.orderdetail.address.email) {
      user_email = order.orderdetail.address.email;
    } else {
      user_email = 'info@amarlab.com';
    }

    address =
      order.orderdetail.address.address +
      ', ' +
      order.orderdetail.address.thana +
      ', ' +
      order.orderdetail.address.district;

    const paymentData = {
      total_price:
        order.orderdiscount && order.orderdiscount.discount_price
          ? parseInt(order.total_price) +
          parseInt(order.material_fee) +
          parseInt(order.collection_fee) -
          parseInt(order.orderdiscount.discount_price)
          : parseInt(order.total_price) +
          parseInt(order.material_fee) +
          parseInt(order.collection_fee),
      category: 'diagnostic-test',
      order_id: '#' + order.order_id,
      order_quantity: order.orderitem.length,
      contact_name: user_name,
      contact_email: user_email,
      contact_address: address,
      contact_mobile: order.orderdetail.address.mobile,
      order_pk: order.id,
    };
    // console.log(paymentData);

    let collection_fee = 50;
    let meterial_fee = 200;

    const patients = order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);

    const testItems = order.orderitem
      .filter((item) => {
        return item.order_type === 'diagnostic';
      })
      .map((item) => {
        return item.purchasable_order_item.testitem_purchasable_oi
          .diagnostic_test.name;
      });

    const checkOne = testItems.includes('Covid RT PCR');
    const checkTwo = testItems.includes('Covid Antigen');

    if (checkOne || checkTwo) {
      // console.log(testItems);
      collection_fee = 0;
      meterial_fee = 0;
    }

    const mainPrice = order.total_price;
    const materialFee = order.material_fee;
    const collectionFee = order.collection_fee;
    const couponPrice =
      order.orderdiscount && order.orderdiscount.discount_price
        ? parseInt(order.orderdiscount.discount_price)
        : `${parseInt('00')}`;
    // console.log(mainPrice);
    // console.log(materialFee);
    // console.log(collectionFee);
    // console.log(couponPrice);
    localStorage.setItem('main_price', JSON.stringify(mainPrice));
    localStorage.setItem('material_fee', JSON.stringify(materialFee));
    localStorage.setItem('collection_fee', JSON.stringify(collectionFee));
    localStorage.setItem('coupon_fee', JSON.stringify(couponPrice));

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/init-payment`, paymentData)
      .then((resp) => {
        console.log('The response is = ', resp.data);
        if (resp.data.response.status === 'SUCCESS') {
          window.location = resp.data.response.GatewayPageURL;
        } else {
          toast.error(
            'Something went wrong, you are not able to payment now, please contact our customar care for cash on delivery.',
            {
              autoClose: 3000,
            }
          );
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Order History');
    window.scrollTo(0, 0);
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');

    if (!token) {
      this.props.history.push('/login');
    } else if (!userId) {
      this.props.history.push('/login');
    } else {
      this.setState({ userId: JSON.parse(userId) });
      // axios.interceptors.request.use((config) => {
      //   const token = 'Token ' + JSON.parse(token);
      //   config.headers.Authorization = token;
      //   return config;
      // });
      // console.log(JSON.parse(token));
      setTimeout(() => {
        this.fetchUserOrder(JSON.parse(userId));
      }, 100);
    }
  }

  render() {
    const { orders, next, loading } = this.state;

    return (
      <>
        <section className='order-history'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h5 className='main-heading'>My Order History</h5>
              </div>
              {loading ? (
                <LoadingGif />
              ) : (
                <>
                  {orders && orders.length === 0 && (
                    <div className='col-md-12'>
                      <h5 className='mb-0'>Sorry, no orders found!</h5>
                    </div>
                  )}
                  {orders &&
                    orders.map((order, i) => (
                      <div className='col-md-12' key={i}>
                        <div className='single unpaid'>
                          <div className='header'>
                            <div className='left'>
                              <p className='id'>#{order.order_id}</p>
                              <p className='date'>
                                {moment(order.created_at).format(
                                  'DD MMM YYYY; hh:mm A'
                                )}
                              </p>
                            </div>
                            <div className='right'>
                              <p>
                                {/* ৳ {order.total_price} */}৳{' '}
                                {order.orderdiscount &&
                                  order.orderdiscount.discount_price
                                  ? parseInt(order.total_price) +
                                  parseInt(order.material_fee) +
                                  parseInt(order.collection_fee) -
                                  parseInt(order.orderdiscount.discount_price)
                                  : parseInt(order.total_price) +
                                  parseInt(order.material_fee) +
                                  parseInt(order.collection_fee)}
                                <span
                                  className={
                                    'text-uppercase ' +
                                    (order.orderdetail &&
                                      order.orderdetail.payment_status === 'paid'
                                      ? 'text-primary '
                                      : 'text-danger ')
                                  }
                                >
                                  {order.orderdetail &&
                                    order.orderdetail.payment_status
                                    ? order.orderdetail.payment_status
                                    : 'UNPAID'}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className='footer'>
                            <p className='left'>
                              {order.orderdetail.order_status &&
                                <p className='' style={{ marginBottom: '5px' }}>
                                  <span>Order Status: </span>{' '}
                                  <span
                                    // className={
                                    //   'text-center ' +
                                    //   (order.orderdetail &&
                                    //   order.orderdetail.order_status === 'processing'
                                    //     ? 'text-warning '
                                    //     : 'text-primary ')
                                    // }
                                    className={order.orderdetail.order_status}
                                  >
                                    {order.orderdetail.order_status === 'cancel'
                                      ? 'Cancelled'
                                      : order.orderdetail.order_status.replace(
                                        /_/g,
                                        ' '
                                      )}
                                  </span>
                                </p>
                              }
                              {order.orderdetail &&
                                order.orderdetail.estimate_date ? (
                                <>
                                  <span>
                                    Estimated Report Delivery:{' '}
                                    {moment(
                                      order.orderdetail.estimate_date
                                    ).format('DD MMM YYYY')}
                                  </span>
                                </>
                              ) : (
                                ' '
                              )}
                              {order.orderdelivery.length !== 0 && (
                                <>
                                  <span className='text-primary text-capitalize'>
                                    (
                                    {this.getLastDeliveryStatus(
                                      order.orderdelivery
                                    )}
                                    )
                                  </span>
                                </>
                              )}
                            </p>
                            <div className='right'>
                              {order.orderdelivery.length !== 0 && (
                                <>
                                  {order.orderdelivery.at(-1).name ===
                                    'delivered' && (
                                      <>
                                        {order.orderreport && (
                                          <a
                                            href={order.orderreport.report}
                                            className='btn btn-light dnld'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                          >
                                            <img src={DwnldIcon} alt='' />
                                            Download Report
                                          </a>
                                        )}
                                      </>
                                    )}
                                </>
                              )}
                              <Link
                                to={`/order-details/${order.id}`}
                                className='btn btn-primary'
                                onClick={() =>
                                  this.context.postActivity(
                                    'button',
                                    `View Order (${order.order_id})`,
                                    window.location.href
                                  )
                                }
                              >
                                View Details
                              </Link>
                              {order.orderdetail && (
                                (order.orderdetail.payment_status &&
                                  order.orderdetail.payment_status === 'unpaid')) && (
                                  (order.orderdetail.order_status !== 'cancel' &&
                                    order.orderdetail.order_status !== 'completed') &&
                                  <a
                                    href='?#'
                                    onClick={(e) => {
                                      this.goToPayment(e, order);
                                      this.context.postActivity(
                                        'button',
                                        `Pay Now (${order.order_id})`,
                                        window.location.href
                                      );
                                    }}
                                    className='btn btn-success pay'
                                  >
                                    Pay Now
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {orders && orders.length !== 0 && (
                    <div className='col-md-12'>
                      <p className='text-center mb-0 pagi'>
                        <button
                          className='btn btn-light'
                          disabled={next ? '' : 'disabled'}
                          onClick={this.loadMore}
                        >
                          view more
                        </button>
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default OrderHistory;
OrderHistory.contextType = AuthContext;
