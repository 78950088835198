import React, { Component } from 'react';
import { AuthContext } from '../context/AuthContext';
import docOne from '../assets/img/copmany-doc-1.jpg';
import docTwo from '../assets/img/copmany-doc-2.jpg';

class CompanyDoc extends Component {
  state = {};

  componentDidMount() {
    this.context.setPageTitle('Terms Of Service');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <section className='privacy-policy company-doc'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h5 className='main-heading'>Company Doc:</h5>
                <div className='img-item'>
                  <h5 className='title'>Certificate of Incorporation:</h5>
                  <img src={docTwo} alt='' className='img-fluid' />
                </div>
                <div className='img-item'>
                  <h5 className='title'>
                    License for Private Diagnostic Center:
                  </h5>
                  <img src={docOne} alt='' className='img-fluid' />
                  <p className='point'>
                    <span>License No:</span> HSM4518817
                  </p>
                  <p className='point'>
                    <span>Reg. Code:</span> HSM07375
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default CompanyDoc;
CompanyDoc.contextType = AuthContext;
