import axios from 'axios';
import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import PatientModal from './PatientModal';

const HomePackage = ({
  packModal,
  setPackModal,
  packDetails,
  goToSecondModalPack,
  patientModalPack,
  setPatientModalPack,
  goToFirstModalPack,
}) => {
  const {
    isLocalLab,
    suMyself,
    suFamily,
    addUserFamily,
    setUserMyself,
    setCartsItemAdd,
    setIsLocalLab,
    userDetails,
    lastCartPatient,
    setLastCartPatient,
  } = useContext(AuthContext);
  const [defaultHospitalList, setDefaultHospitalList] = useState(null);
  const [hospitalList, setHospitalList] = useState(null);
  const [dhakaHospitalList, setDhakaHospitalList] = useState(null);
  const [savarHospitalList, setSavarHospitalList] = useState(null);
  const [ctgHospitalList, setCtgHospitalList] = useState(null);

  // const [userDetails, setUserDetails] = useState(null);
  const [testItem, setTestItem] = useState(null);

  //patient modal
  const [personDetails, setPersonDetails] = useState([]);
  const [orderForRadio, setOrderForRadio] = useState('Myself');
  const [showFamily, setShowFamily] = useState(false);
  const [showMyself, setShowMyself] = useState(true);

  const [patientName, setPatientName] = useState('');
  const [patientDob, setPatientDob] = useState('');
  const [patientGender, setPatientGender] = useState('');

  const [myName, setMyName] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
  );
  const [myDob, setMyDob] = useState(
    (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
  );
  const [myGender, setMyGender] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].sex) || ''
  );

  useEffect(() => {
    setMyName(
      (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
    );
    setMyDob(
      (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
    );
    setMyGender((suMyself && suMyself.length !== 0 && suMyself[0].sex) || '');
  }, [suMyself]);

  const [showAddPatientForm, setShowAddPatientForm] = useState(false);
  const [showAddAnotherBtn, setShowAddAnotherBtn] = useState(true);

  useEffect(() => {
    if (packDetails) {
      const dhakaArray = packDetails[0].packageitem.filter((location) => {
        return location.test_item.location.name === 'Dhaka';
      });
      const dhakaHospital = [
        {
          description: packDetails[0].description,
          name: packDetails[0].name,
          packageitem: [...dhakaArray],
          purchasable_order_item: packDetails[0].purchasable_order_item,
          slug: packDetails[0].slug,
        },
      ];

      const savarArray = packDetails[0].packageitem.filter((location) => {
        return location.test_item.location.name === 'Savar';
      });
      const savarHospital = [
        {
          description: packDetails[0].description,
          name: packDetails[0].name,
          packageitem: [...savarArray],
          purchasable_order_item: packDetails[0].purchasable_order_item,
          slug: packDetails[0].slug,
        },
      ];

      const ctgArray = packDetails[0].packageitem.filter((location) => {
        return location.test_item.location.name === 'Chattogram';
      });
      const ctgHospital = [
        {
          description: packDetails[0].description,
          name: packDetails[0].name,
          packageitem: [...ctgArray],
          purchasable_order_item: packDetails[0].purchasable_order_item,
          slug: packDetails[0].slug,
        },
      ];

      const labName = localStorage.getItem('lab_name');
      const labLocation = localStorage.getItem('lab_location');

      if (isLocalLab) {
        if (labName && labLocation) {
          const all = packDetails[0].packageitem
            .filter((lab) => lab.test_item.branch.lab !== null)
            .filter(
              (lab) => lab.test_item.branch.lab.name === JSON.parse(labName)
            )
            .filter(
              (lab) => lab.test_item.branch.name === JSON.parse(labLocation)
            );

          const allLabs = [
            {
              description: packDetails[0].description,
              name: packDetails[0].name,
              packageitem: [...all],
              purchasable_order_item: packDetails[0].purchasable_order_item,
              slug: packDetails[0].slug,
            },
          ];

          // console.log(allLabs);
          setDefaultHospitalList(allLabs);
        }
      } else {
        //console.log(dhakaHospital);
        //console.log(ctgHospital);
        // console.log(packDetails);
        setDefaultHospitalList(packDetails);
        setDhakaHospitalList(dhakaHospital);
        setSavarHospitalList(savarHospital);
        setCtgHospitalList(ctgHospital);
        setHospitalList(packDetails);
      }
    }
  }, [packDetails]);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    if (getToken) {
      axios.interceptors.request.use((config) => {
        const token = 'Token ' + JSON.parse(getToken);
        config.headers.Authorization = token;
        return config;
      });

      // axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user/`).then((resp) => {
      //   setUserDetails(resp.data);
      //   // //console.log(resp.data);
      // });
    }
  }, []);

  const smallDescription = (string) => {
    if (string) {
      if (string.length > 100) {
        return string.substring(0, 100) + '.....';
      } else {
        return string;
      }
    } else {
      return '';
    }
  };

  const handleLabDistrict = (e) => {
    // //console.log(e.target.value);
    if (e.target.value === 'all') {
      setDefaultHospitalList(hospitalList);
    } else if (e.target.value === 'dhaka' || e.target.value === 'savar') {
      setDefaultHospitalList(dhakaHospitalList);
    } else if (e.target.value === 'ctg') {
      setDefaultHospitalList(ctgHospitalList);
    }
  };

  const handleFamilyMember = (e, family) => {
    if (e.target.checked) {
      // //console.log('checkbox checked');
      setPersonDetails([...personDetails, family]);
      //console.log([...personDetails, family]);
    } else {
      // //console.log('checkbox unchecked');
      setPersonDetails(
        personDetails.filter((person) => person.id !== family.id)
      );
    }
  };

  const handleAddFamilyMember = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (patientDob === '' || patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const checkToken = localStorage.getItem('token');
      const user = localStorage.getItem('user_id');

      if (user) {
        axios.interceptors.request.use((config) => {
          const token = 'Token ' + JSON.parse(checkToken);
          config.headers.Authorization = token;
          return config;
        });

        const patientData = {
          full_name: patientName,
          dob: moment(patientDob).format('YYYY-MM-DD'),
          sex: patientGender,
          user: user,
        };

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
            patientData
          )
          .then((resp) => {
            //console.log(resp.data);
            setShowAddPatientForm(false);
            setPatientName('');
            setPatientDob('');
            setPatientGender('');
            setShowAddAnotherBtn(true);
            // //console.log(newFamily);
            addUserFamily(resp.data);
          })
          .catch((error) => {
            //console.log(error.response.status);
            //console.log(error.response.data);
          });
      }
    }
  };

  const handleAddAnother = () => {
    setShowAddPatientForm(true);
    setShowAddAnotherBtn(false);
  };

  const calculatePercentage = (object) => {
    const value =
      (parseInt(object.test_item.purchasable_order_item.sell_price) /
        parseInt(object.test_item.purchasable_order_item.price)) *
      100;

    const percentage = (100 - value).toString();
    return parseInt(percentage);
  };

  const handleLabRadio = (lab) => {
    //console.log(lab);
    setTestItem(lab);
  };

  const handleOrderFor = (e) => {
    //console.log(e.target.value);
    setPersonDetails([]);
    setOrderForRadio(e.target.value);

    if (e.target.value === 'Myself') {
      setShowFamily(false);
      // setPersonDetails(userSelf);
      setShowMyself(true);
      setPersonDetails(suMyself);
      // //console.log(userSelf);
    } else if (e.target.value === 'Family') {
      setShowFamily(true);
      setShowMyself(false);
      setPersonDetails([]);
    }
  };

  const handleEditMyself = () => {
    if (myName.trim() === '') {
      toast.error(`Please enter your name.`, {
        autoClose: 3000,
      });
    } else if (myName.length < 4) {
      toast.error(`Your name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (myGender === '') {
      toast.error(`Please enter your gender.`, {
        autoClose: 3000,
      });
    } else if (myDob === '' || myDob === null) {
      toast.error(`Please enter your date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const putMyself = {
        full_name: myName,
        dob: moment(myDob).format('YYYY-MM-DD'),
        sex: myGender,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/user_management/patient/${suMyself[0].id}/`,
          putMyself
        )
        .then((resp) => {
          // console.log(resp.data);
          setUserMyself([resp.data]);
          // toast.success(`Profile updated successfully.`, {
          //   autoClose: 3000,
          // });
          setPersonDetails([resp.data]);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    }
  };

  const handleOrderNow = () => {
    if (!showFamily) {
      // console.log('update profile');
      handleEditMyself();
    }

    const carts = personDetails.map((person, i) => {
      return {
        order_type: 'package',
        patient: person,
        package: testItem,
      };
    });

    // //console.log(carts);

    const order = {
      user: userDetails,
      status: '',
      order_item: carts,
    };

    // //console.log(order);
    setCartsItemAdd(carts);

    localStorage.setItem(
      'lab_name',
      JSON.stringify(order.order_item[0].package.test_item.branch.lab.name)
    );
    localStorage.setItem(
      'lab_location',
      JSON.stringify(order.order_item[0].package.test_item.branch.name)
    );
    setIsLocalLab(true);

    const getOrder = localStorage.getItem('order');
    const userId = localStorage.getItem('user_id');

    if (getOrder) {
      const userdsdsad = JSON.parse(getOrder);
      if (userdsdsad.user.pk === JSON.parse(userId)) {
        localStorage.removeItem('order');
        const allOrders = JSON.parse(getOrder);
        const newItem = [...order.order_item, ...allOrders.order_item];
        //console.log(allOrders);
        const newData = {
          user: allOrders.user,
          status: '',
          order_item: newItem,
        };
        //console.log(newData);
        localStorage.setItem('order', JSON.stringify(newData));
      } else {
        localStorage.removeItem('order');
        localStorage.setItem('order', JSON.stringify(order));
      }
    } else {
      localStorage.setItem('order', JSON.stringify(order));
    }

    toast.success('Test added successfully.', {
      autoClose: 3000,
    });

    setPatientModalPack(false);
    setPersonDetails([]);
    setTestItem(null);
    setShowFamily(false);
    sessionStorage.removeItem('loginAfterData');
    if (lastCartPatient.length === 0) {
      const lastCartPat = personDetails.at(-1);
      localStorage.setItem('lastCartPatient', JSON.stringify([lastCartPat]));
      setLastCartPatient([lastCartPat]);
    }
  };

  const directAddToCart = () => {
    if (lastCartPatient.length !== 0) {
      const carts = lastCartPatient.map((person, i) => {
        return {
          order_type: 'package',
          patient: person,
          package: testItem,
        };
      });
  
      // //console.log(carts);
  
      const order = {
        user: userDetails,
        status: '',
        order_item: carts,
      };
  
      // //console.log(order);
      setCartsItemAdd(carts);
  
      localStorage.setItem(
        'lab_name',
        JSON.stringify(order.order_item[0].package.test_item.branch.lab.name)
      );
      localStorage.setItem(
        'lab_location',
        JSON.stringify(order.order_item[0].package.test_item.branch.name)
      );
      setIsLocalLab(true);
  
      const getOrder = localStorage.getItem('order');
      const userId = localStorage.getItem('user_id');
  
      if (getOrder) {
        const userdsdsad = JSON.parse(getOrder);
        if (userdsdsad.user.pk === JSON.parse(userId)) {
          localStorage.removeItem('order');
          const allOrders = JSON.parse(getOrder);
          const newItem = [...order.order_item, ...allOrders.order_item];
          //console.log(allOrders);
          const newData = {
            user: allOrders.user,
            status: '',
            order_item: newItem,
          };
          //console.log(newData);
          localStorage.setItem('order', JSON.stringify(newData));
        } else {
          localStorage.removeItem('order');
          localStorage.setItem('order', JSON.stringify(order));
        }
      } else {
        localStorage.setItem('order', JSON.stringify(order));
      }
  
      toast.success('Test added successfully.', {
        autoClose: 3000,
      });
  
      setPatientModalPack(false);
      setPersonDetails([]);
      setTestItem(null);
      setShowFamily(false);
      sessionStorage.removeItem('loginAfterData');
      setPackModal(false);
    }
  }

  return (
    <>
      <Modal
        show={packModal}
        onHide={() => {
          setPackModal(false);
          sessionStorage.removeItem('loginAfterData');
        }}
        animation={false}
        className='main-modl'
      >
        <ModalBody className='diagnostic-select'>
          <div className='head'>
            <div>
              <h5>Select Labs</h5>
              <p className='mb-0'>Testing will be done from this lab</p>
            </div>
            <button
              className='btn btn-light'
              onClick={() => {
                sessionStorage.removeItem('loginAfterData');
                setPackModal(false);
              }}
            >
              Close
            </button>
          </div>
          {defaultHospitalList && (
            <div className='bodies'>
              <div className='details'>
                <div className='left'>
                  <h5>{defaultHospitalList[0].name}</h5>
                  <p className='mb-0'>
                    {smallDescription(defaultHospitalList[0].description)}
                  </p>
                </div>
                {!isLocalLab && (
                  <div className='right'>
                    <select onChange={handleLabDistrict}>
                      <option value='all'>All Loc...</option>
                      <option value='dhaka'>DHAKA</option>
                      <option value='savar'>SAVAR</option>
                      <option value='ctg'>CTG</option>
                    </select>
                  </div>
                )}
              </div>
              <div className='lists'>
                {defaultHospitalList[0].packageitem.map((hospital, i) => (
                  <div key={i}>
                    {hospital &&
                      hospital.test_item &&
                      hospital.test_item.branch &&
                      hospital.test_item.branch.lab && (
                        <div className='single'>
                          <label className='radio'>
                            <div className='all'>
                              <div className='hospital'>
                                <div className='logo'>
                                  <img
                                    src={hospital.test_item.branch.lab.logo}
                                    alt=''
                                  />
                                </div>
                                <div className='name'>
                                  <h5>{hospital.test_item.branch.lab.name}</h5>
                                  <p>
                                    Branch: {hospital.test_item.location.name}
                                  </p>
                                </div>
                              </div>
                              {parseInt(
                                hospital.test_item.purchasable_order_item.price
                              ) >
                                parseInt(
                                  hospital.test_item.purchasable_order_item
                                    .sell_price
                                ) && (
                                <div className='wever'>
                                  <span className='btn btn-success'>
                                    {`- ${calculatePercentage(hospital)}%`}
                                  </span>
                                </div>
                              )}
                              <div className='price'>
                                {parseInt(
                                  hospital.test_item.purchasable_order_item
                                    .price
                                ) >
                                  parseInt(
                                    hospital.test_item.purchasable_order_item
                                      .sell_price
                                  ) && (
                                  <div className='old'>
                                    ৳{' '}
                                    {hospital &&
                                    hospital.test_item &&
                                    hospital.test_item.purchasable_order_item
                                      ? hospital.test_item
                                          .purchasable_order_item.price
                                      : ''}
                                  </div>
                                )}
                                <div className='new'>
                                  ৳{' '}
                                  {hospital &&
                                  hospital.test_item &&
                                  hospital.test_item.purchasable_order_item
                                    ? hospital.test_item.purchasable_order_item
                                        .sell_price
                                    : ''}
                                </div>
                              </div>
                            </div>
                            <input
                              type='radio'
                              name='radio'
                              onChange={() => handleLabRadio(hospital)}
                              checked={hospital === testItem}
                            />
                            <span className='checkmark' />
                          </label>
                        </div>
                      )}
                  </div>
                ))}
              </div>
              <div className='lists'>
                {defaultHospitalList &&
                  defaultHospitalList[0].packageitem.length === 0 && (
                    <p className='text-failed not-found'>
                      Sorry, no test available in selected area for your
                      selected lab.
                    </p>
                  )}
              </div>
              {lastCartPatient.length === 0 ? (
                <button
                  className='btn btn-primary btn-block next-step'
                  onClick={() => {
                    goToSecondModalPack();
                    setPersonDetails(suMyself);
                  }}
                  disabled={!testItem}
                >
                  Select & Next
                </button>
              ) : (
                <button
                  className='btn btn-primary btn-block next-step'
                  onClick={() => {
                    directAddToCart();
                    setPersonDetails(suMyself);
                  }}
                  disabled={!testItem}
                >
                  Add To Cart
                </button>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>

      <PatientModal
        showModal={patientModalPack}
        setShowModal={setPatientModalPack}
        goToFirstModal={goToFirstModalPack}
        personDetails={personDetails}
        handleOrderNow={handleOrderNow}
        handleOrderFor={handleOrderFor}
        orderForRadio={orderForRadio}
        showMyself={showMyself}
        myName={myName}
        setMyName={setMyName}
        myDob={myDob}
        setMyDob={setMyDob}
        myGender={myGender}
        setMyGender={setMyGender}
        showFamily={showFamily}
        suFamily={suFamily}
        patientName={patientName}
        setPatientName={setPatientName}
        patientDob={patientDob}
        setPatientDob={setPatientDob}
        patientGender={patientGender}
        setPatientGender={setPatientGender}
        handleFamilyMember={handleFamilyMember}
        showAddPatientForm={showAddPatientForm}
        handleAddFamilyMember={handleAddFamilyMember}
        showAddAnotherBtn={showAddAnotherBtn}
        handleAddAnother={handleAddAnother}
      />
    </>
  );
};

export default HomePackage;
