import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';

const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  loadingPagi,
}) => {
  const paginationRange = useMemo(() => {
    const range = (start, end) => {
      let length = end - start + 1;
      return Array.from({ length }, (_, idx) => idx + start);
    };

    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, 'DOTSR', totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, 'DOTSL', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, 'DOTSL', ...middleRange, 'DOTSR', lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    loadingPagi,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <>
      <div className='d-flex align-items-center'>
        {loadingPagi && (
          <div className='d-flex align-items-center'>
            <Spinner
              animation='border'
              size='sm'
              role='status'
              style={{ borderWidth: '2px' }}
            ></Spinner>
            {/* <span className='visually-hidden ml-2'>Loading...</span> */}
          </div>
        )}
        <ul className='pagination'>
          <li
            className={`paginate_button page-item previous ${
              currentPage <= 1 ? 'disabled li-disabled' : 'pointer'
            }`}
            id='kt_customers_table_previous'
            onClick={onPrevious}
          >
            <div
              href='#'
              aria-controls='kt_customers_table'
              data-dt-idx='0'
              tabIndex='0'
              className='page-link'
            >
              PREV
            </div>
          </li>

          {paginationRange.map((pageNumber) => {
            if (pageNumber === 'DOTSL') {
              return (
                <li
                  key={'DOTSL'}
                  className='paginate_button page-item'
                  style={{ cursor: 'default' }}
                >
                  <div
                    href='#'
                    aria-controls='kt_customers_table'
                    data-dt-idx='1'
                    tabIndex='0'
                    className='page-link'
                  >
                    ...
                  </div>
                </li>
              );
            }
            if (pageNumber === 'DOTSR') {
              return (
                <li
                  key={'DOTSR'}
                  className='paginate_button page-item'
                  style={{ cursor: 'default' }}
                >
                  <div
                    href='#'
                    aria-controls='kt_customers_table'
                    data-dt-idx='1'
                    tabIndex='0'
                    className='page-link'
                  >
                    ...
                  </div>
                </li>
              );
            }

            return (
              <li
                key={pageNumber}
                className={`paginate_button page-item pointer ${
                  pageNumber === currentPage ? 'active' : ''
                }`}
                onClick={() => onPageChange(pageNumber)}
              >
                <div
                  href='#'
                  aria-controls='kt_customers_table'
                  data-dt-idx='1'
                  tabIndex='0'
                  className='page-link'
                >
                  {pageNumber}
                </div>
              </li>
            );
          })}

          <li
            className={`paginate_button page-item next ${
              currentPage >= lastPage ? 'disabled li-disabled' : 'pointer'
            }`}
            id='kt_customers_table_next'
            onClick={onNext}
          >
            <div
              href='#'
              aria-controls='kt_customers_table'
              data-dt-idx='5'
              tabIndex='0'
              className='page-link'
            >
              NEXT
            </div>
          </li>
        </ul>
      </div>
      <p className='count'>
        Showing {(currentPage - 1) * pageSize + 1} to {currentPage * pageSize}{' '}
        of {totalCount} ({lastPage} Pages)
      </p>
    </>
  );
};

export default Pagination;
