import axios from 'axios';
import React, { Component } from 'react';
import { AuthContext } from '../context/AuthContext';
import RightArrow from '../assets/img/right-arrow-link.png';
import Patients from '../components/Patients';
import ResetPassword from '../components/ResetPassword';
import ProfileInfo from '../components/ProfileInfo';

class Profile extends Component {
  state = {
    patients: null,
    patientName: '',
    patientGender: '',
    patientDob: '',
    userId: null,
    showPatientAddForm: false,
    showTab: 'profileInfo',
  };

  fetchUserPatients = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient/?user=${id}&limit=300`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        const patients = resp.data.results
          .filter((patient) => {
            return patient.is_account === false;
          })
          .filter((patient) => {
            return patient.is_active;
          });
        // console.log(patients);
        this.setState({ patients: patients });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Profile');
    window.scrollTo(0, 0);
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');

    if (!token) {
      this.props.history.push('/login');
    } else if (!userId) {
      this.props.history.push('/login');
    } else {
      this.setState({ userId: JSON.parse(userId) });

      setTimeout(() => {
        this.fetchUserPatients(JSON.parse(userId));
      }, 100);
    }
  }

  render() {
    const { showTab } = this.state;

    return (
      <section className='all_patient'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <h2 className='title main-heading'>Profile Settings</h2>
            </div>
            <div className='col-md-3'>
              <div className='navs'>
                <div
                  className={`single ${
                    showTab === 'profileInfo' ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.setState({ showTab: 'profileInfo' });
                    this.context.postActivity(
                      'button',
                      'Myself',
                      window.location.href
                    );
                  }}
                >
                  <p>Myself</p>
                  <img src={RightArrow} alt='' />
                </div>
                <div
                  className={`single ${showTab === 'patients' ? 'active' : ''}`}
                  onClick={() => {
                    this.setState({ showTab: 'patients' });
                    this.context.postActivity(
                      'button',
                      'My Family',
                      window.location.href
                    );
                  }}
                >
                  <p>My Family</p>
                  <img src={RightArrow} alt='' />
                </div>
                <div
                  className={`single ${showTab === 'settings' ? 'active' : ''}`}
                  onClick={() => {
                    this.setState({ showTab: 'settings' });
                    this.context.postActivity(
                      'button',
                      'Privacy Settings',
                      window.location.href
                    );
                  }}
                >
                  <p>Privacy Settings</p>
                  <img src={RightArrow} alt='' />
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              {showTab === 'profileInfo' && (
                <ProfileInfo
                  patients={this.state.patients}
                  userId={this.state.userId}
                  fetchUserPatients={this.fetchUserPatients}
                />
              )}
              {showTab === 'patients' && (
                <Patients
                  patients={this.state.patients}
                  userId={this.state.userId}
                  fetchUserPatients={this.fetchUserPatients}
                />
              )}
              {showTab === 'settings' && <ResetPassword />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Profile;
Profile.contextType = AuthContext;
