// import Labaid from '../assets/img/sponsor2.png';
import { useContext } from 'react';
import IbnSina from '../assets/img/sponsor1.png';
import Popular from '../assets/img/sponsor2.png';
import United from '../assets/img/sponsor3.png';
import Lalpathlabs from '../assets/img/sponsor4.jpg';
import Thyrocare from '../assets/img/sponsor5.png';
// import Truma from '../assets/img/sponsor6.jpg';
import IBF from '../assets/img/sponsor7.jpg';
// import SigmaLab from '../assets/img/sponsor8.jpg';
import Sponsor12 from '../assets/img/sponsor12.jpg';
// import ChevronLab from '../assets/img/sponsor10.jpg';
import { AuthContext } from '../context/AuthContext';
// import _ from 'lodash';

const Sponsor = () => {
  const { postActivity } = useContext(AuthContext);

  return (
    <section className='sponsor'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading'>Our Lab Partners</h5>
          </div>
          <div className='col-12'>
            {/* marquee */}
            <marquee className='images'>
              {/* {_.times(3, (i) => (
                <div key={i}>
                  <img key={i} src={Popular} alt='' />
                </div>
              ))} */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Popular}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IbnSina}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={United}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Lalpathlabs}
                alt=''
                className='lalpaths'
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Thyrocare}
                alt=''
                className='thyrocare'
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Truma}
                alt=''
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IBF}
                alt=''
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={SigmaLab}
                alt=''
              /> */}
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={ChevronLab}
                alt=''
                className='lalpaths'
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Sponsor12}
                alt=''
                className=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Popular}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IbnSina}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={United}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Lalpathlabs}
                alt=''
                className='lalpaths'
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Thyrocare}
                alt=''
                className='thyrocare'
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Truma}
                alt=''
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IBF}
                alt=''
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={SigmaLab}
                alt=''
              /> */}
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={ChevronLab}
                alt=''
                className='lalpaths'
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Sponsor12}
                alt=''
                className=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Popular}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IbnSina}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={United}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Lalpathlabs}
                alt=''
                className='lalpaths'
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Thyrocare}
                alt=''
                className='thyrocare'
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Truma}
                alt=''
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IBF}
                alt=''
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={SigmaLab}
                alt=''
              /> */}
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={ChevronLab}
                alt=''
                className='lalpaths'
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Sponsor12}
                alt=''
                className=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Popular}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IbnSina}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={United}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Lalpathlabs}
                alt=''
                className='lalpaths'
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Thyrocare}
                alt=''
                className='thyrocare'
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Truma}
                alt=''
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IBF}
                alt=''
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={SigmaLab}
                alt=''
              /> */}
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={ChevronLab}
                alt=''
                className='lalpaths'
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Sponsor12}
                alt=''
                className=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Popular}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IbnSina}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={United}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Lalpathlabs}
                alt=''
                className='lalpaths'
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Thyrocare}
                alt=''
                className='thyrocare'
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Truma}
                alt=''
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IBF}
                alt=''
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={SigmaLab}
                alt=''
              /> */}
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={ChevronLab}
                alt=''
                className='lalpaths'
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Sponsor12}
                alt=''
                className=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Popular}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IbnSina}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={United}
                alt=''
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Lalpathlabs}
                alt=''
                className='lalpaths'
              />
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Thyrocare}
                alt=''
                className='thyrocare'
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Truma}
                alt=''
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={IBF}
                alt=''
              />
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={SigmaLab}
                alt=''
              /> */}
              {/* <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={ChevronLab}
                alt=''
                className='lalpaths'
              /> */}
              <img
                onClick={() =>
                  postActivity('banner', 'Lab Partner', window.location.href)
                }
                src={Sponsor12}
                alt=''
                className=''
              />
            </marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
