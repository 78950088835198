import { toast } from 'react-toastify';
import SearchIcon from '../assets/img/search-white.png';
import LeftArrow from '../assets/img/search-left-arrow.png';
import React, { Component } from 'react';
import axios from 'axios';
import CheckupPackages from '../components/CheckupPackages';
import Package from '../components/homepage/Package';
import LoadingGif from '../components/LoadingGif';
import HomeDiagnostic from '../components/modal/HomeDiagnostic';
import swal from 'sweetalert';
import { AuthContext } from '../context/AuthContext';

class Search extends Component {
  state = {
    search: '',
    tests: [],
    showSearch: false,
    searchActive: 'diagnostic',
    offset: 8,
    limit: 8,
    searchTests: [],
    testPrevious: null,
    testNext: null,
    testCount: null,
    searchPackages: [],
    packageCount: null,
    loading: true,

    diagModal: false,
    testDetails: null,
    patientModal: false,
  };

  fetchInitialTests = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&page=1&limit=8&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ tests: resp.data.results });
        this.setState({ loading: false });
      });
  };

  fetchSearchTests = (search) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&search=${search}&page=1&limit=8&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ searchTests: resp.data.results });
        this.setState({ testPrevious: resp.data.previous });
        this.setState({ testNext: resp.data.next });
        this.setState({ testCount: resp.data.count });
        this.setState({ loading: false });
      });
  };

  loadMoreTest = () => {
    this.setState({ offset: this.state.offset + 8 });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&search=${this.state.search}&limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          searchTests: [...this.state.searchTests, ...resp.data.results],
        });
        this.setState({ next: resp.data.next });
      });
  };

  fetchSearchPackages = (search) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&search=${search}&page=1&limit=6&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ searchPackages: resp.data.results });
        this.setState({ packageCount: resp.data.count });
        this.setState({ loading: false });
      });
  };

  handleSearch = (e) => {
    e.preventDefault();

    if (this.state.search.trim() === '') {
      toast.error(`Type something and then search`, {
        autoClose: 3000,
      });
    } else {
      this.context.postActivity(
        'search',
        this.state.search,
        window.location.href
      );
      this.setState({ testCount: true });
      this.setState({ packageCount: true });
      this.setState({ searchTests: [] });
      this.setState({ searchPackages: [] });
      this.setState({ loading: true });
      this.setState({ showSearch: true });
      this.props.history.push(`/search?term=${this.state.search.trim()}`);
      this.fetchSearchTests(this.state.search);
      this.fetchSearchPackages(this.state.search);
    }
  };

  componentDidMount() {
    this.context.setPageTitle('Search');
    window.scrollTo(0, 0);
    let params = new URLSearchParams(this.props.location.search);
    let search = params.get('term');

    if (search) {
      this.setState({ showSearch: true });
      this.setState({ search: search });
      this.fetchSearchTests(search);
      this.fetchSearchPackages(search);
    } else {
      this.fetchInitialTests();
    }
  }

  setDiagModal = (value) => {
    this.setState({ diagModal: value });
  };

  setPatientModal = (value) => {
    this.setState({ patientModal: value });
  };

  goToFirstModal = () => {
    this.setDiagModal(true);
    this.setPatientModal(false);
  };

  goToSecondModal = () => {
    if (!this.context.isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          this.setDiagModal(false);
          this.setPatientModal(true);
        }
      });
    } else {
      this.setDiagModal(false);
      this.setPatientModal(true);
      // setPersonDetails(suMyself);
    }
  };

  setTestDetails = (value) => {
    this.setState({ testDetails: value });
  };

  handleFetchTestDetails = (slug) => {
    // console.log(test);
    if (localStorage.getItem('token')) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&slug=${slug}`
        )
        .then((resp) => {
          // console.log(resp.data.results);
          this.setTestDetails(resp.data.results);
        })
        .catch((error) => {
          //console.log(error);
        });
      // setShowModal(true);
    }
  };

  componentDidUpdate() {
    if (this.context.isLoggedIn) {
      const getLoginAfter = sessionStorage.getItem('loginAfterData');
      const getLoginAfterData = JSON.parse(getLoginAfter);
      if (getLoginAfter) {
        if (getLoginAfterData.testType === 'diagnostic') {
          if (window.innerWidth < 768) {
            window.scrollTo(0, 300);
          } else {
            window.scrollTo(0, 250);
          }
          if (this.state.diagModal === false) {
            sessionStorage.clear();
            this.setDiagModal(true);
            // console.log('component');
            this.handleFetchTestDetails(getLoginAfterData.testSlug);
          }
        }
        // console.log(getLoginAfterData);
      }
    }
  }

  render() {
    const {
      search,
      tests,
      showSearch,
      searchActive,
      searchTests,
      testNext,
      searchPackages,
      testCount,
      packageCount,
      loading,
    } = this.state;

    return (
      <>
        <div className='mobile-semi-nav mobile-semi-nav-src-pg'>
          <section className=''>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <p className='m-0'>
                    <img
                      src={LeftArrow}
                      alt=''
                      onClick={() => this.props.history.push('/')}
                    />
                    Search diagnostics & packages
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className={`search ${showSearch ? 's-off' : 's-on'}`}>
            <div className='container'>
              <div className='row'>
                <form className='col-12' onSubmit={this.handleSearch}>
                  <div className='input-group'>
                    <input
                      type='text'
                      placeholder='Search for diagnostics tests, labs etc.'
                      value={search}
                      onChange={(e) =>
                        this.setState({ search: e.target.value })
                      }
                    />
                    <div className='input-group-append'>
                      <button className='img'>
                        <img src={SearchIcon} alt='' />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          {!showSearch && (
            <section className='default-list checkups'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <h5 className='main-heading'>
                      Popular Diagnostics & Packages
                    </h5>
                  </div>
                </div>
                <div className='row items justify-content-center'>
                  {tests.length !== 0 &&
                    tests.map((test, i) => (
                      <div
                        key={i}
                        className='col-xl-3 col-lg-4 col-sm-6 col-6 single-item'
                      >
                        <CheckupPackages test={test} cartPageData={this.props.location} />
                      </div>
                    ))}
                  {loading && <LoadingGif />}
                </div>
              </div>
            </section>
          )}
          {showSearch && (
            <section className='srch-page'>
              <div className='head'>
                <div
                  className={`left ${
                    searchActive === 'diagnostic' ? 'active' : ''
                  }`}
                  onClick={() => this.setState({ searchActive: 'diagnostic' })}
                >
                  Diagnostics
                </div>
                <div
                  className={`right ${
                    searchActive === 'package' ? 'active' : ''
                  }`}
                  onClick={() => this.setState({ searchActive: 'package' })}
                >
                  Packages
                </div>
              </div>
              <div className='container'>
                {searchActive === 'diagnostic' && (
                  <div className='checkups'>
                    <div className='row results items'>
                      {searchTests.length !== 0 &&
                        searchTests.map((test, i) => (
                          <div
                            key={i}
                            className='col-xl-3 col-lg-4 col-sm-6 col-6 single-item'
                          >
                            <CheckupPackages test={test} cartPageData={this.props.location} />
                          </div>
                        ))}
                      {searchTests.length !== 0 && (
                        <div className='col-12 view-more'>
                          <p className='text-center mb-0'>
                            <button
                              className='btn btn-light'
                              onClick={this.loadMoreTest}
                              disabled={testNext ? '' : 'disabled'}
                            >
                              LOAD MORE
                            </button>
                          </p>
                        </div>
                      )}
                      {testCount === 0 && (
                        <div className='col-12'>
                          <p className='mb-3'>Sorry, no test found.</p>
                        </div>
                      )}
                      {loading && <LoadingGif />}
                    </div>
                  </div>
                )}
                {searchActive === 'package' && (
                  <div className='package-page'>
                    <div className='row results packages'>
                      {searchPackages.length !== 0 &&
                        searchPackages.map((packageItem, i) => (
                          <div className='col-lg-4 col-md-6 item' key={i}>
                            <Package packageItem={packageItem} cartPageData={this.props.location} />
                          </div>
                        ))}
                      {packageCount === 0 && (
                        <div className='col-12'>
                          <p className='mb-3'>Sorry, no package found.</p>
                        </div>
                      )}
                      {loading && <LoadingGif />}
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}
        </div>

        <HomeDiagnostic
          diagModal={this.state.diagModal}
          setDiagModal={this.setDiagModal}
          testDetails={this.state.testDetails}
          patientModal={this.state.patientModal}
          setPatientModal={this.setPatientModal}
          goToFirstModal={this.goToFirstModal}
          goToSecondModal={this.goToSecondModal}
        />
      </>
    );
  }
}

export default Search;
Search.contextType = AuthContext;
