import { useContext, useState, useEffect } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import Hospital from './Hospital';
import PopularLogo from '../../assets/img/popular.png';
import PatientModal from './PatientModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

const HomeDiagnostic = ({
  diagModal,
  setDiagModal,
  testDetails,
  patientModal,
  setPatientModal,
  goToFirstModal,
  goToSecondModal,
}) => {
  const {
    isLocalLab,
    suMyself,
    suFamily,
    addUserFamily,
    setUserMyself,
    setCartsItemAdd,
    setIsLocalLab,
    userDetails,
    navAreaName,
    lastCartPatient,
    setLastCartPatient,
  } = useContext(AuthContext);
  const [defaultHospitalList, setDefaultHospitalList] = useState(null);
  const [hospitalList, setHospitalList] = useState(null);
  const [dhakaHospitalList, setDhakaHospitalList] = useState(null);
  const [savarHospitalList, setSavarHospitalList] = useState(null);
  const [ctgHospitalList, setCtgHospitalList] = useState(null);

  // const [userDetails, setUserDetails] = useState(null);

  const [testItem, setTestItem] = useState(null);

  //patient modal
  const [personDetails, setPersonDetails] = useState([]);
  const [orderForRadio, setOrderForRadio] = useState('Myself');
  const [showFamily, setShowFamily] = useState(false);
  const [showMyself, setShowMyself] = useState(true);

  const [patientName, setPatientName] = useState('');
  const [patientDob, setPatientDob] = useState('');
  const [patientGender, setPatientGender] = useState('');

  const [myName, setMyName] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
  );
  const [myDob, setMyDob] = useState(
    (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
  );
  const [myGender, setMyGender] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].sex) || ''
  );

  useEffect(() => {
    setMyName(
      (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
    );
    setMyDob(
      (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
    );
    setMyGender((suMyself && suMyself.length !== 0 && suMyself[0].sex) || '');
  }, [suMyself]);

  const [showAddPatientForm, setShowAddPatientForm] = useState(false);
  const [showAddAnotherBtn, setShowAddAnotherBtn] = useState(true);

  useEffect(() => {
    // console.log(testDetails);

    if (testDetails) {
      const dhakaArray = testDetails[0].testitem
        .filter((location) => {
          return location.location.name === 'Dhaka';
        })
        .filter((lab) => lab.branch.lab.is_active);
      const dhakaHospital = [
        {
          description: testDetails[0].description,
          name: testDetails[0].name,
          slug: testDetails[0].slug,
          testitem: [...dhakaArray],
          category: testDetails[0].category,
        },
      ];

      const savarArray = testDetails[0].testitem
        .filter((location) => {
          return location.location.name === 'Savar';
        })
        .filter((lab) => lab.branch.lab.is_active);
      const savarHospital = [
        {
          description: testDetails[0].description,
          name: testDetails[0].name,
          slug: testDetails[0].slug,
          testitem: [...savarArray],
          category: testDetails[0].category,
        },
      ];

      const ctgArray = testDetails[0].testitem
        .filter((location) => {
          return location.location.name === 'Chattogram';
        })
        .filter((lab) => lab.branch.lab.is_active);
      const ctgHospital = [
        {
          description: testDetails[0].description,
          name: testDetails[0].name,
          slug: testDetails[0].slug,
          testitem: [...ctgArray],
          category: testDetails[0].category,
        },
      ];

      // console.log(dhakaHospital);
      // console.log(ctgHospital);

      const labName = localStorage.getItem('lab_name');
      const labLocation = localStorage.getItem('lab_location');
      if (isLocalLab) {
        if (labName && labLocation) {
          const all = testDetails[0].testitem
            .filter((lab) => {
              return lab.branch !== null;
            })
            .filter((lab) => {
              return lab.branch.lab.name === JSON.parse(labName);
            })
            .filter((location) => {
              return location.branch.name === JSON.parse(labLocation);
            })
            .filter((lab) => lab.branch.lab.is_active);

          const allLabs = [
            {
              description: testDetails[0].description,
              name: testDetails[0].name,
              slug: testDetails[0].slug,
              testitem: [...all],
              category: testDetails[0].category,
            },
          ];

          // console.log(allLabs);
          setDefaultHospitalList(allLabs);
        }
      } else {
        setDefaultHospitalList(testDetails);
        setDhakaHospitalList(dhakaHospital);
        setSavarHospitalList(savarHospital);
        setCtgHospitalList(ctgHospital);
        const activeLabs = testDetails[0].testitem.filter(
          (lab) => lab.branch.lab.is_active
        );
        const mainItem = testDetails.map((test) => {
          return {
            ...test,
            testitem: activeLabs,
          };
        });
        // console.log(mainItem);
        setHospitalList(mainItem);

        if (selectedAreaLab === 'Dhaka' || selectedAreaLab === 'Savar') {
          setDefaultHospitalList(dhakaHospital);
        } else if (selectedAreaLab === 'Chattogram') {
          setDefaultHospitalList(ctgHospital);
        }
      }
    }
  }, [testDetails]);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    if (getToken) {
      axios.interceptors.request.use((config) => {
        const token = 'Token ' + JSON.parse(getToken);
        config.headers.Authorization = token;
        return config;
      });

      // axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user/`).then((resp) => {
      //   setUserDetails(resp.data);
      //   // //console.log(resp.data);
      // });
    }
  }, []);

  const [selectedAreaLab, setSelectedAreaLab] = useState(navAreaName);

  useEffect(() => {
    setSelectedAreaLab(navAreaName);
  }, [navAreaName]);

  const subStringModal = (string) => {
    if (string) {
      if (string.length > 100) {
        return string.substring(0, 100) + '.....';
      } else {
        return string;
      }
    } else {
      return '';
    }
  };

  const handleLabDistrict = (e) => {
    //console.log(e.target.value);
    setSelectedAreaLab(e.target.value);
    if (e.target.value === 'all') {
      setDefaultHospitalList(hospitalList);
    } else if (e.target.value === 'Dhaka' || e.target.value === 'Savar') {
      setDefaultHospitalList(dhakaHospitalList);
    } else if (e.target.value === 'Chattogram') {
      setDefaultHospitalList(ctgHospitalList);
    }
  };

  const handleOrderFor = (e) => {
    // console.log(e.target.value);
    setPersonDetails([]);
    setOrderForRadio(e.target.value);

    if (e.target.value === 'Myself') {
      setShowFamily(false);

      // //console.log(userSelf);
      // setPersonDetails(userSelf);

      setPersonDetails(suMyself);
      setShowMyself(true);
    } else if (e.target.value === 'Family') {
      setShowFamily(true);
      setShowMyself(false);
    }

    const user = localStorage.getItem('user_id');
    // //console.log(user);
    if (user === null) {
      //console.log('user not logged in');
      toast.error('Please login and then try to order.', {
        autoClose: 3000,
      });
      setDiagModal(false);
      // history.push('/');
      // window.location.href = '/login';
    } else {
      //console.log('user logged in');
    }
  };

  const handleFamilyMember = (e, family) => {
    // //console.log(e);
    if (e.target.checked) {
      // //console.log('checkbox checked');
      setPersonDetails([...personDetails, family]);
      //console.log([...personDetails, family]);
    } else {
      // //console.log('checkbox unchecked');
      setPersonDetails(
        personDetails.filter((person) => person.id !== family.id)
      );
    }
  };

  const handleAddFamilyMember = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (patientDob === '' || patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const checkToken = localStorage.getItem('token');
      const user = localStorage.getItem('user_id');

      if (user) {
        axios.interceptors.request.use((config) => {
          const token = 'Token ' + JSON.parse(checkToken);
          config.headers.Authorization = token;
          return config;
        });

        const patientData = {
          full_name: patientName,
          dob: moment(patientDob).format('YYYY-MM-DD'),
          sex: patientGender,
          user: user,
        };

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
            patientData
          )
          .then((resp) => {
            //console.log(resp.data);
            setShowAddPatientForm(false);
            setPatientName('');
            setPatientDob('');
            setPatientGender('');
            setShowAddAnotherBtn(true);
            addUserFamily(resp.data);
          })
          .catch((error) => {
            //console.log(error.response.status);
            //console.log(error.response.data);
          });
      }
    }
  };

  const handleAddAnother = () => {
    setShowAddPatientForm(true);
    setShowAddAnotherBtn(false);
  };

  const handleEditMyself = () => {
    // e.preventDefault();

    if (myName.trim() === '') {
      toast.error(`Please enter your name.`, {
        autoClose: 3000,
      });
    } else if (myName.length < 4) {
      toast.error(`Your name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (myGender === '') {
      toast.error(`Please enter your gender.`, {
        autoClose: 3000,
      });
    } else if (myDob === '' || myDob === null) {
      toast.error(`Please enter your date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const putMyself = {
        full_name: myName,
        dob: moment(myDob).format('YYYY-MM-DD'),
        sex: myGender,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/user_management/patient/${suMyself[0].id}/`,
          putMyself
        )
        .then((resp) => {
          // console.log(resp.data);
          setUserMyself([resp.data]);
          // toast.success(`Profile updated successfully.`, {
          //   autoClose: 3000,
          // });
          setPersonDetails([resp.data]);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  const handleOrderNow = () => {
    if (!showFamily) {
      // console.log('update profile');
      handleEditMyself();
    }

    let carts = personDetails.map((person, i) => {
      return {
        order_type: 'diagnostic',
        patient: person,
        test_item: testItem,
      };
    });
    // //console.log(carts);
    const checkCrossSell = carts.find(
      (item) =>
        item.test_item.diagnostic_test.cross_sell &&
        item.test_item.diagnostic_test.cross_sell !== null
    );

    if (checkCrossSell) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?slug=${checkCrossSell.test_item.diagnostic_test.cross_sell.slug}`
        )
        .then((resp) => {
          const branch = carts[0].test_item.branch.name;
          const lab = carts[0].test_item.branch.lab.name;
          const filtered = resp.data.results[0].testitem
            .filter((item) => item.branch.name === branch)
            .filter((item) => item.branch.lab.name === lab);
          if (filtered.length !== 0) {
            const cressCellArray = personDetails.map((person, i) => {
              return {
                order_type: 'diagnostic',
                patient: person,
                test_item: filtered[0],
              };
            });
            // console.log(cressCellArray);
            carts = [...carts, ...cressCellArray];
          }

          const order = {
            user: userDetails,
            status: '',
            order_item: carts,
          };

          //console.log(order);
          setCartsItemAdd(carts);

          localStorage.setItem(
            'lab_name',
            JSON.stringify(order.order_item[0].test_item.branch.lab.name)
          );
          localStorage.setItem(
            'lab_location',
            JSON.stringify(order.order_item[0].test_item.branch.name)
          );
          setIsLocalLab(true);

          const getOrder = localStorage.getItem('order');
          const userId = localStorage.getItem('user_id');

          if (getOrder) {
            const userdsdsad = JSON.parse(getOrder);
            if (userdsdsad.user.pk === JSON.parse(userId)) {
              localStorage.removeItem('order');
              const allOrders = JSON.parse(getOrder);
              const newItem = [...order.order_item, ...allOrders.order_item];
              //console.log(allOrders);

              const newData = {
                user: allOrders.user,
                status: '',
                order_item: newItem,
              };
              //console.log(newData);

              localStorage.setItem('order', JSON.stringify(newData));
            } else {
              localStorage.removeItem('order');
              localStorage.setItem('order', JSON.stringify(order));
            }
          } else {
            localStorage.setItem('order', JSON.stringify(order));
          }

          toast.success('Test added successfully.', {
            autoClose: 3000,
          });

          setPatientModal(false);
          setPersonDetails([]);
          setTestItem(null);
          setShowFamily(false);
          sessionStorage.removeItem('loginAfterData');
          // setHospitalDetails(null);
          if (lastCartPatient.length === 0) {
            const lastCartPat = personDetails.at(-1);
            localStorage.setItem(
              'lastCartPatient',
              JSON.stringify([lastCartPat])
            );
            setLastCartPatient([lastCartPat]);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      const order = {
        user: userDetails,
        status: '',
        order_item: carts,
      };

      //console.log(order);
      setCartsItemAdd(carts);

      localStorage.setItem(
        'lab_name',
        JSON.stringify(order.order_item[0].test_item.branch.lab.name)
      );
      localStorage.setItem(
        'lab_location',
        JSON.stringify(order.order_item[0].test_item.branch.name)
      );
      setIsLocalLab(true);

      const getOrder = localStorage.getItem('order');
      const userId = localStorage.getItem('user_id');

      if (getOrder) {
        const userdsdsad = JSON.parse(getOrder);
        if (userdsdsad.user.pk === JSON.parse(userId)) {
          localStorage.removeItem('order');
          const allOrders = JSON.parse(getOrder);
          const newItem = [...order.order_item, ...allOrders.order_item];
          //console.log(allOrders);

          const newData = {
            user: allOrders.user,
            status: '',
            order_item: newItem,
          };
          //console.log(newData);

          localStorage.setItem('order', JSON.stringify(newData));
        } else {
          localStorage.removeItem('order');
          localStorage.setItem('order', JSON.stringify(order));
        }
      } else {
        localStorage.setItem('order', JSON.stringify(order));
      }

      toast.success('Test added successfully.', {
        autoClose: 3000,
      });

      setPatientModal(false);
      setPersonDetails([]);
      setTestItem(null);
      setShowFamily(false);
      sessionStorage.removeItem('loginAfterData');
      // setHospitalDetails(null);

      if (lastCartPatient.length === 0) {
        const lastCartPat = personDetails.at(-1);
        localStorage.setItem('lastCartPatient', JSON.stringify([lastCartPat]));
        setLastCartPatient([lastCartPat]);
      }
    }
  };

  const directAddToCart = () => {
    if (lastCartPatient.length !== 0) {
      let carts = lastCartPatient.map((person, i) => {
        return {
          order_type: 'diagnostic',
          patient: person,
          test_item: testItem,
        };
      });
      // //console.log(carts);
      const checkCrossSell = carts.find(
        (item) =>
          item.test_item.diagnostic_test.cross_sell &&
          item.test_item.diagnostic_test.cross_sell !== null
      );
  
      if (checkCrossSell) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?slug=${checkCrossSell.test_item.diagnostic_test.cross_sell.slug}`
          )
          .then((resp) => {
            const branch = carts[0].test_item.branch.name;
            const lab = carts[0].test_item.branch.lab.name;
            const filtered = resp.data.results[0].testitem
              .filter((item) => item.branch.name === branch)
              .filter((item) => item.branch.lab.name === lab);
            if (filtered.length !== 0) {
              const cressCellArray = lastCartPatient.map((person, i) => {
                return {
                  order_type: 'diagnostic',
                  patient: person,
                  test_item: filtered[0],
                };
              });
              // console.log(cressCellArray);
              carts = [...carts, ...cressCellArray];
            }
  
            const order = {
              user: userDetails,
              status: '',
              order_item: carts,
            };
  
            //console.log(order);
            setCartsItemAdd(carts);
  
            localStorage.setItem(
              'lab_name',
              JSON.stringify(order.order_item[0].test_item.branch.lab.name)
            );
            localStorage.setItem(
              'lab_location',
              JSON.stringify(order.order_item[0].test_item.branch.name)
            );
            setIsLocalLab(true);
  
            const getOrder = localStorage.getItem('order');
            const userId = localStorage.getItem('user_id');
  
            if (getOrder) {
              const userdsdsad = JSON.parse(getOrder);
              if (userdsdsad.user.pk === JSON.parse(userId)) {
                localStorage.removeItem('order');
                const allOrders = JSON.parse(getOrder);
                const newItem = [...order.order_item, ...allOrders.order_item];
                //console.log(allOrders);
  
                const newData = {
                  user: allOrders.user,
                  status: '',
                  order_item: newItem,
                };
                //console.log(newData);
  
                localStorage.setItem('order', JSON.stringify(newData));
              } else {
                localStorage.removeItem('order');
                localStorage.setItem('order', JSON.stringify(order));
              }
            } else {
              localStorage.setItem('order', JSON.stringify(order));
            }
  
            toast.success('Test added successfully.', {
              autoClose: 3000,
            });
  
            setPatientModal(false);
            setPersonDetails([]);
            setTestItem(null);
            setShowFamily(false);
            sessionStorage.removeItem('loginAfterData');
            // setHospitalDetails(null);
            setDiagModal(false);
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        const order = {
          user: userDetails,
          status: '',
          order_item: carts,
        };
  
        //console.log(order);
        setCartsItemAdd(carts);
  
        localStorage.setItem(
          'lab_name',
          JSON.stringify(order.order_item[0].test_item.branch.lab.name)
        );
        localStorage.setItem(
          'lab_location',
          JSON.stringify(order.order_item[0].test_item.branch.name)
        );
        setIsLocalLab(true);
  
        const getOrder = localStorage.getItem('order');
        const userId = localStorage.getItem('user_id');
  
        if (getOrder) {
          const userdsdsad = JSON.parse(getOrder);
          if (userdsdsad.user.pk === JSON.parse(userId)) {
            localStorage.removeItem('order');
            const allOrders = JSON.parse(getOrder);
            const newItem = [...order.order_item, ...allOrders.order_item];
            //console.log(allOrders);
  
            const newData = {
              user: allOrders.user,
              status: '',
              order_item: newItem,
            };
            //console.log(newData);
  
            localStorage.setItem('order', JSON.stringify(newData));
          } else {
            localStorage.removeItem('order');
            localStorage.setItem('order', JSON.stringify(order));
          }
        } else {
          localStorage.setItem('order', JSON.stringify(order));
        }
  
        toast.success('Test added successfully.', {
          autoClose: 3000,
        });
  
        setPatientModal(false);
        setPersonDetails([]);
        setTestItem(null);
        setShowFamily(false);
        sessionStorage.removeItem('loginAfterData');
        setDiagModal(false);
        // setHospitalDetails(null);
      }
    }
  }

  return (
    <>
      <Modal
        className='main-modl'
        show={diagModal}
        onHide={() => {
          sessionStorage.removeItem('loginAfterData');
          setDiagModal(false);
        }}
        animation={false}
      >
        <ModalBody className='diagnostic-select'>
          <div className='head'>
            <div>
              <h5>Select Labs</h5>
              <p className='mb-0'>Testing will be done from this lab</p>
            </div>
            <button
              className='btn btn-light'
              onClick={() => {
                sessionStorage.removeItem('loginAfterData');
                setDiagModal(false);
              }}
            >
              Close
            </button>
          </div>
          {defaultHospitalList && (
            <div className='bodies'>
              <div className='details'>
                <div className='left'>
                  <h5>{defaultHospitalList[0].name}</h5>
                  <p className='mb-0'>
                    {defaultHospitalList[0].description
                      ? subStringModal(defaultHospitalList[0].description)
                      : ''}
                  </p>
                </div>
                {!isLocalLab && (
                  <div className='right'>
                    <select
                      onChange={handleLabDistrict}
                      value={selectedAreaLab}
                    >
                      <option value='all'>All Lab...</option>
                      <option value='Dhaka'>DHAKA</option>
                      {/* <option value='Savar'>SAVAR</option>
                      <option value='Chattogram'>CTG</option> */}
                    </select>
                  </div>
                )}
              </div>
              <div className='lists'>
                {defaultHospitalList[0].testitem.map((hospital, i) => (
                  <Hospital
                    Logo={PopularLogo}
                    key={i}
                    hospital={hospital}
                    // setHospitalDetails={setHospitalDetails}
                    setTestItem={setTestItem}
                    testItem={testItem}
                  />
                ))}
              </div>
              <div className='lists'>
                {defaultHospitalList &&
                  defaultHospitalList[0].testitem.length === 0 && (
                    <p className='text-failed not-found'>
                      Sorry, no test available in selected area for your
                      selected lab.
                    </p>
                  )}
              </div>
              {lastCartPatient.length === 0 ? (
                <button
                  className='btn btn-primary btn-block next-step'
                  onClick={() => {
                    goToSecondModal();
                    setPersonDetails(suMyself);
                  }}
                  disabled={!testItem}
                >
                  Select & Next
                </button>
              ) : (
                <button
                  className='btn btn-primary btn-block next-step'
                  onClick={() => {
                    directAddToCart();
                    setPersonDetails(suMyself);
                  }}
                  disabled={!testItem}
                >
                  Add To Cart
                </button>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>

      <PatientModal
        showModal={patientModal}
        setShowModal={setPatientModal}
        goToFirstModal={goToFirstModal}
        personDetails={personDetails}
        handleOrderNow={handleOrderNow}
        handleOrderFor={handleOrderFor}
        orderForRadio={orderForRadio}
        showMyself={showMyself}
        myName={myName}
        setMyName={setMyName}
        myDob={myDob}
        setMyDob={setMyDob}
        myGender={myGender}
        setMyGender={setMyGender}
        showFamily={showFamily}
        suFamily={suFamily}
        patientName={patientName}
        setPatientName={setPatientName}
        patientDob={patientDob}
        setPatientDob={setPatientDob}
        patientGender={patientGender}
        setPatientGender={setPatientGender}
        handleFamilyMember={handleFamilyMember}
        showAddPatientForm={showAddPatientForm}
        handleAddFamilyMember={handleAddFamilyMember}
        showAddAnotherBtn={showAddAnotherBtn}
        handleAddAnother={handleAddAnother}
      />
    </>
  );
};

export default HomeDiagnostic;
