import { Spinner } from 'react-bootstrap';

const LoadingBtn = ({ btnText }) => {
  return (
    <div className='loading-button'>
      <button className='btn btn-primary btn-block' disabled>
        <Spinner animation='border' variant='light' />
        {btnText || 'Loading'}
      </button>
    </div>
  );
};

export default LoadingBtn;
