import React, { Component, useEffect, useState, useContext, } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import AuthContextProvider, { AuthContext } from './context/AuthContext';
import Checkout from './pages/Checkout';
import DiagnosticDetails from './pages/DiagnosticDetails';
// import Diagnostic from './pages/Diagnostic';
import HomePage from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import OrderDetails from './pages/OrderDetails';
import OrderHistory from './pages/OrderHistory';
import Packages from './pages/Packages';
import Package from './pages/PackageDetails';

import PaymentSuccess from './pages/PaymentSuccess';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import LoginResetPass from './pages/LoginResetPass';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Search from './pages/Search';
import Faqs from './pages/Faqs';
import About from './pages/About';
// import AboutTheTeam from './pages/AboutTheTeam';
import PressRelease from './pages/PressRelease';
import PressDetails from './pages/PressDetails';
import Category from './pages/Category';
import TicketDownload from './pages/TicketDownload';
import PaymentTerms from './pages/PaymentTerms';
import CompanyDoc from './pages/CompanyDoc';
import PartnerPackages from './pages/PartnerPackages';
import Cart from './pages/Cart';
import { isMobile } from 'react-device-detect';
import CheckoutMbl from './pages/CheckoutMbl';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoggedInL: false,
    };
    // this.loginHandler = this.loginHandler.bind(this);
  }

  // componentDidMount() {
  //   const token = localStorage.getItem('token');
  //   token
  //     ? this.setState({ isLoggedInL: true })
  //     : this.setState({ isLoggedInL: false });
  // }

  // loginHandler(param) {
  //   // alert(param);
  //   this.setState({
  //     isLoggedInL: param,
  //   });
  // }

  render() {
    return (
      <Router>
        <AuthContextProvider>
          <AppSwitch {...this.props} />
          {/* <Switch>
            <Route
              path='/order-details/:id/ticket/:algorithm'
              component={TicketDownload}
            />
            <>
              <Navbar
                loginHandler={this.loginHandler}
                isLoggedIn={this.state.isLoggedInL}
              />
              <Route path='/diagnostic' component={Category} />
              <Route path='/packages' component={Packages} />
              <Route path='/checkout' component={Checkout} />
              <Route path='/reset-password' component={ResetPassword} />
              <Route path='/change-password' component={LoginResetPass} />
              <Route path='/profile' component={Profile} />
              <Route path='/search' component={Search} />
              <Route
                path='/login'
                render={({ history }) => (
                  <Login loginHandler={this.loginHandler} history={history} />
                )}
              />
              <Route exact path='/' component={HomePage} />
              <Route
                path='/signup'
                render={({ history }) => (
                  <SignUp loginHandler={this.loginHandler} history={history} />
                )}
              />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              <Route path='/terms-of-service' component={TermsOfService} />
              <Route path='/payment-terms' component={PaymentTerms} />
              <Route path='/company-doc' component={CompanyDoc} />
              <Route path='/payment-success' component={PaymentSuccess} />
              <Route path='/payment-status/:id' component={PaymentSuccess} />
              <Route path='/order-history' component={OrderHistory} />
              <Route path='/order-details/:id' component={OrderDetails} />
              <Route path='/order-placed/:id' component={OrderDetails} />
              <Route path='/faqs' component={Faqs} />
              <Route path='/about' component={About} />
              <Route path='/press' component={PressRelease} />
              <Route path='/press-release/:slug' component={PressDetails} />
              <Footer />
            </>
            <Route path='*' component={NotFound} />
          </Switch> */}
        </AuthContextProvider>
      </Router>
    );
  }
}

// export default App;


const AppSwitch = (props) => {
  const { postActivity, isLoggedIn } = useContext(AuthContext);

  const [isLoggedInL, setIsLoggedInL] = useState(false);

  let location = useLocation();
  const processPageName = (text = '') => {
    return text.split(/[ -]/)
      .map(word => { return word.length ? (word.length > 1 ? (word[0].toUpperCase() + word.substr(1).toLowerCase()) : word.toUpperCase()) : ' ' }).join(' ');
  }

  useEffect(() => {
    if (location.pathname === '/') {
      postActivity('page', 'Home', window.location.href);
    } else if (location.pathname.startsWith('/') && location.pathname.length > 1) {
      postActivity('page', processPageName(location.pathname.slice(1)), window.location.href);
    } else {
      postActivity('page', processPageName(location.pathname), window.location.href);
    }


  }, [location]);


  useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem('token');
      token ? setIsLoggedInL(true) : setIsLoggedInL(false);
    }, 500);
  }, [isLoggedIn])


  const loginHandler = (param) => {
    // alert(param);
    setIsLoggedInL(param);
  }



  return (
    <Switch>
      <Route
        path='/order-details/:id/ticket/:algorithm'
        component={TicketDownload}
      />
      <>
        <Navbar
          loginHandler={loginHandler}
          isLoggedIn={isLoggedInL}
        />
        <Route path='/diagnostic' component={Category} /> 
        <Route path='/diagnostic-test' component={DiagnosticDetails} />
        <Route exact path='/packages/:a(\w+)-offers' component={PartnerPackages} />
        <Route exact path='/packages' component={Packages} />
        <Route exact path='/package' component={Package} />
        <Route path='/checkout' component={isMobile ? CheckoutMbl : Checkout} />
        <Route path='/reset-password' component={ResetPassword} />
        <Route path='/change-password' component={LoginResetPass} />
        <Route path='/profile' component={Profile} />
        <Route path='/search' component={Search} />
        <Route path='/cart' component={Cart} />
        <Route
          path='/login'
          render={({ history }) => (
            <Login loginHandler={loginHandler} history={history} />
          )}
        />
        <Route exact path='/' component={HomePage} />
        <Route
          path='/signup'
          render={({ history }) => (
            <SignUp loginHandler={loginHandler} history={history} />
          )}
        />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        <Route path='/terms-of-service' component={TermsOfService} />
        <Route path='/payment-terms' component={PaymentTerms} />
        <Route path='/company-doc' component={CompanyDoc} />
        <Route path='/payment-success' component={PaymentSuccess} />
        <Route path='/payment-status/:id' component={PaymentSuccess} />
        <Route path='/order-history' component={OrderHistory} />
        <Route path='/order-details/:id' component={OrderDetails} />
        <Route path='/order-placed/:id' component={OrderDetails} />
        <Route path='/faqs' component={Faqs} />
        <Route path='/about' component={About} />

        <Route path='/press' component={PressRelease} />
        <Route path='/press-release/:slug' component={PressDetails} />
        <Footer />
      </>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}

export default App;