import axios from 'axios';
import React, { Component } from 'react';
import StepOne from '../assets/img/hero-step-1.png';
import StepTwo from '../assets/img/hero-step-2.png';
import StepThree from '../assets/img/hero-step-3.png';
// import SortImg from '../assets/img/sort.png';
// import FilterImg from '../assets/img/filter.png';
import Package from '../components/homepage/Package';
import Sponsor from '../components/Sponsor';
import SemiHeaderMbl from '../components/SemiHeaderMbl';
import HomePackage from '../components/modal/HomePackage';
import swal from 'sweetalert';
import { AuthContext } from '../context/AuthContext';
import CallForOrder from '../components/CallForOrder';
import { capitalize } from '../helpers/UtilityFuncs';

class PartnerPackages extends Component {
  state = {
    packageItems: null,
    userSelf: [],
    userFamily: [],
    previous: null,
    next: null,
    offset: 6,
    limit: 6,
    packageLoading: true,

    packModal: false,
    packDetails: null,
    patientModal: false,

    partner: this.props.match.params.a,
  };

  setPackModal = (value) => {
    this.setState({ packModal: value });
  };

  setPatientModal = (value) => {
    this.setState({ patientModal: value });
  };

  handleFetchPackageDetails = (slug) => {
    const partnerName = this.props.match.params.a;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&package_source=${partnerName}&slug=${slug}`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        // setPackDetails(resp.data.results);
        this.setState({ packDetails: resp.data.results });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  componentDidMount() {
    const partnerName = this.props.match.params.a;

    this.context.setPageTitle(`${capitalize(partnerName)} Packages`);
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&package_source=${partnerName}&page=1&limit=6&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ previous: resp.data.previous });
        this.setState({ next: resp.data.next });
        // setPackageItems(resp.data.results);
        this.setState({ packageItems: resp.data.results });
        this.setState({ packageLoading: false });
      })
      .catch((error) => {
        this.setState({ packageLoading: false });
        //console.log(error.data.response);
      });

    const userId = localStorage.getItem('user_id');
    if (userId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL
          }/user_management/patient/?user=${JSON.parse(userId)}&limit=300`
        )
        .then((resp) => {
          const results = resp.data.results;
          const myself = results.filter((item) => {
            return item.is_account;
          });
          const family = results.filter((item) => {
            return !item.is_account;
          });
          this.setState({ userSelf: myself });
          this.setState({ userFamily: family });
        })
        .catch((error) => {
          //console.log(error.response.data);
        });
    }
  }

  componentDidUpdate() {
    if (this.context.isLoggedIn) {
      const getLoginAfter = sessionStorage.getItem('loginAfterData');
      const getLoginAfterData = JSON.parse(getLoginAfter);
      if (getLoginAfter) {
        if (getLoginAfterData.testType === 'package') {
          if (window.innerWidth < 768) {
            window.scrollTo(0, 700);
          } else {
            window.scrollTo(0, 1200);
          }
          if (this.state.packModal === false) {
            // console.log('component');
            sessionStorage.clear();
            this.setPackModal(true);
            this.handleFetchPackageDetails(getLoginAfterData.testSlug);
          }
        }
        // console.log(getLoginAfterData);
      }
    }
  }

  setUserFamily = (array) => {
    this.setState({ userFamily: array });
  };

  handlePrevious = () => {
    axios
      .get(this.state.previous)
      .then((resp) => {
        // //console.log(resp.data);
        this.setState({ packageItems: resp.data.results });
        this.setState({ previous: resp.data.previous });
        this.setState({ next: resp.data.next });
        // setPrevious(resp.data.previous);
        // setNext(resp.data.next);
      })
      .catch((error) => {
        //console.log(error.data);
      });
  };

  handleNext = () => {
    axios
      .get(this.state.next)
      .then((resp) => {
        // //console.log(resp.data);
        this.setState({ packageItems: resp.data.results });
        this.setState({ previous: resp.data.previous });
        this.setState({ next: resp.data.next });
        // setPrevious(resp.data.previous);
        // setNext(resp.data.next);
      })
      .catch((error) => {
        //console.log(error.data);
      });
  };

  loadMore = () => {
    this.context.postActivity('button', 'Load More', window.location.href);
    this.setState({ offset: this.state.offset + 6 });
    const partnerName = this.props.match.params.a;

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&package_source=${partnerName}&limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          packageItems: [...this.state.packageItems, ...resp.data.results],
        });

        this.setState({ next: resp.data.next });
      });
  };

  goToFirstModalPack = () => {
    this.setPackModal(true);
    this.setPatientModal(false);
  };

  goToSecondModal = () => {
    if (!this.context.isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          this.setPackModal(false);
          this.setPatientModal(true);
        }
      });
    } else {
      this.setPackModal(false);
      this.setPatientModal(true);
      // setPersonDetails(suMyself);
    }
  };

  render() {
    const { packageItems, userSelf, userFamily, next, partner, packageLoading } = this.state;
    const { postActivity } = this.context;

    return (
      <div className='package-page partner-package'>
        {/* <SemiHeaderMbl />
        <section className='showcase'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 details'>
                <h5 className='title-web'>
                  Get Lab Tests And Full Body <br /> Checkups From The Comfort{' '}
                  <br /> Of Your Home.
                </h5>
                <h5 className='title-mobile'>
                  Diagnostics <br /> At Your Doorstep
                </h5>
                <p className='main-subheading web'>
                  Order Now & Get Up To 10% Instant Discount
                </p>
                <p className='main-subheading mobile'>
                  Making Healthcare Easily Accessible <br /> to Everyone,
                  Everywhere.
                </p>
                <a
                  href='#browse'
                  onClick={() =>
                    postActivity('button', 'Browse Now', window.location.href)
                  }
                  className='btn btn-primary'
                >
                  Browse Now
                </a>
              </div>
            </div>
            <p className='mb-0' id='browse'></p>
          </div>
        </section> */}
        <section className='packages'>
          <div className='container'>
            <div className='row'>
              <CallForOrder />
              <div className='col-12 descrip'>
                <h5 className='main-heading'>Health Checkup Packages {partner ? `(${partner})`.toUpperCase() : ''}</h5>
                <p className='main-subheading mb-0'>
                  Order Now & Get Instant Discount
                </p>
              </div>
              {packageItems &&
                packageItems.map((packageItem, i) => (
                  <div className='col-lg-4 col-md-6 item' key={i}>
                    <Package
                      userSelf={userSelf}
                      userFamily={userFamily}
                      setUserFamily={this.setUserFamily}
                      packageItem={packageItem}
                      package_source={partner}
                      cartPageData={this.props.location}
                    />
                  </div>
                ))}
              {packageItems && packageItems.length && next && <div className='col-12 btns'>
                <p className='mb-0 text-center'>
                  <button
                    className='btn btn-light'
                    onClick={this.loadMore}
                    disabled={next ? '' : 'disabled'}
                  >
                    LOAD MORE
                  </button>
                </p>
              </div>}
              {(!packageItems || !packageItems.length) && !packageLoading && <div className='col-12 btns'>
                <p className='mb-0 text-center'>
                  No offer available now.
                </p>
              </div>

              }
            </div>
          </div>
        </section>
        <section className='services'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h5 className='section-heading text-center'>
                  How Our Service Works
                </h5>
              </div>
              <div className='col-4 col-first'>
                <div className='d-flex justify-content-center'>
                  <div className='icon'>
                    <img src={StepOne} alt='' width='30' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='text-center'>Step 01: Order Tests</h5>
                  <p className='text-center'>Book tests & checkups</p>
                </div>
              </div>
              <div className='col-4 second'>
                <div className='d-flex justify-content-center'>
                  <div className='icon'>
                    <img src={StepTwo} alt='' width='35' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='text-center'>Step 02: Sample Collection</h5>
                  <p className='text-center'>From your doorstep</p>
                </div>
              </div>
              <div className='col-4 mb-0 col-last'>
                <div className='d-flex justify-content-center'>
                  <div className='icon'>
                    <img src={StepThree} alt='' width='30' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='text-center'>Step 03: Get Report</h5>
                  <p className='text-center'>Your report delivered</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Sponsor />

        <HomePackage
          packModal={this.state.packModal}
          setPackModal={this.setPackModal}
          packDetails={this.state.packDetails}
          patientModalPack={this.state.patientModal}
          setPatientModalPack={this.setPatientModal}
          goToFirstModalPack={this.goToFirstModalPack}
          goToSecondModalPack={this.goToSecondModal}
        />
      </div>
    );
  }
}

export default PartnerPackages;
PartnerPackages.contextType = AuthContext;
