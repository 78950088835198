import TestImage from '../../assets/img/package.jpg';
// import Popular from '../../assets/img/popular.png';
// import IbnSina from '../../assets/img/ibnsina.png';
import Carousel from 'react-multi-carousel';
import { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import LeftArrow from '../../assets/img/left-arrow.png';
import axios from 'axios';
import PatientDob from '../PatientDob';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
// import PackageHospital from '../modal/PackageHospital';
import swal from 'sweetalert';
import { useHistory } from 'react-router';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 991,
    },
    items: 3.2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 575,
      min: 0,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 991,
      min: 575,
    },
    items: 4,
    partialVisibilityGutter: 30,
  },
};

const Package = ({ packageItem, package_source, cartPageData }) => {
  const {
    carts,
    setCartsItemAdd,
    addUserFamily,
    suMyself,
    suFamily,
    isLocalLab,
    setIsLocalLab,
    setUserMyself,
    userDetails,
    setShowLoginModal,
    navAreaName,
    postActivity,
    lastCartPatient,
    setLastCartPatient,
  } = useContext(AuthContext);
  const history = useHistory();
  const [showLabModal, setShowLabModal] = useState(false);
  const [showFamilyModal, setShowFamilyModal] = useState(false);
  const [showFamily, setShowFamily] = useState(false);
  const [showMyself, setShowMyself] = useState(true);
  const [orderForRadio, setOrderForRadio] = useState('Myself');

  const [defaultHospitalList, setDefaultHospitalList] = useState(null);
  const [hospitalList, setHospitalList] = useState(null);
  const [dhakaHospitalList, setDhakaHospitalList] = useState(null);
  const [ctgHospitalList, setCtgHospitalList] = useState(null);
  const [testItem, setTestItem] = useState(null);
  const [personDetails, setPersonDetails] = useState([]);
  const [showAddPatientForm, setShowAddPatientForm] = useState(false);

  const [myName, setMyName] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
  );
  const [myDob, setMyDob] = useState(
    (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
  );
  const [myGender, setMyGender] = useState(
    (suMyself && suMyself.length !== 0 && suMyself[0].sex) || ''
  );

  useEffect(() => {
    setMyName(
      (suMyself && suMyself.length !== 0 && suMyself[0].full_name) || ''
    );
    setMyDob(
      (suMyself && suMyself.length !== 0 && new Date(suMyself[0].dob)) || ''
    );
    setMyGender((suMyself && suMyself.length !== 0 && suMyself[0].sex) || '');
  }, [suMyself]);

  const [patientName, setPatientName] = useState('');
  const [patientDob, setPatientDob] = useState('');
  const [patientGender, setPatientGender] = useState('');
  // const [userDetails, setUserDetails] = useState(null);
  const [showAddAnotherBtn, setShowAddAnotherBtn] = useState(true);

  useEffect(() => {
    const getToken = localStorage.getItem('token');

    if (getToken) {
      axios.interceptors.request.use((config) => {
        const token = 'Token ' + JSON.parse(getToken);
        config.headers.Authorization = token;
        return config;
      });

      // axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user/`).then((resp) => {
      //   setUserDetails(resp.data);
      //   // //console.log(resp.data);
      // });
    }
  }, []);

  const [cPAllPat, setCPAllPat] = useState(false);
  const [fCartPage, setFCartPage] = useState(false);
  const [cPPatient, setCPPatient] = useState([]);

  useEffect(() => {
    if (cartPageData.params) {
      // console.log(cartPageData.params.formCartPage);
      // console.log(cartPageData.params.showPatient);
      if (
        cartPageData.params.showPatient &&
        cartPageData.params.showPatient.length === 0
      ) {
        setCPAllPat(true);
      } else {
        setCPPatient(cartPageData.params.showPatient);
      }

      if (cartPageData.params.formCartPage) {
        setFCartPage(true);
      }
    }
  }, []);

  const [selectedAreaLab, setSelectedAreaLab] = useState(navAreaName);

  useEffect(() => {
    setSelectedAreaLab(navAreaName);
  }, [navAreaName]);

  // //console.log(packageItem);

  const subStr = (string, maxLen = 70) => {
    if (string) {
      const commaToPlus = string.replace(/,/g, '+');
      // return commaToPlus;

      if (commaToPlus.length >= maxLen) {
        return commaToPlus.substring(0, maxLen) + '...';
      } else {
        return commaToPlus;
      }
    }
  };

  const smallDescription = (string) => {
    if (string) {
      if (string.length > 100) {
        return string.substring(0, 100) + '.....';
      } else {
        return string;
      }
    } else {
      return '';
    }
  };

  const calculatePercentage = (object) => {
    const value =
      (parseInt(object.test_item.purchasable_order_item.sell_price) /
        parseInt(object.test_item.purchasable_order_item.price)) *
      100;

    const percentage = (100 - value).toString();
    return parseInt(percentage);
  };

  const minPrice = (object) => {
    var myArray = object.packageitem,
      min = Math.min.apply(
        null,
        myArray.map((item) => {
          if (item && item.test_item && item.test_item.purchasable_order_item) {
            return item.test_item.purchasable_order_item.sell_price;
          } else {
            return 0;
          }
        })
      );

    // //console.log(min);
    return min;
  };

  const maxPrice = (object) => {
    var myArray = object.packageitem,
      min = Math.max.apply(
        null,
        myArray.map((item) => {
          if (item && item.test_item && item.test_item.purchasable_order_item) {
            return item.test_item.purchasable_order_item.price;
          } else {
            return 0;
          }
        })
      );

    // //console.log(min);
    return min;
  };

  const goToFamilyModal = () => {
    setPersonDetails(suMyself);
    if (!isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          setShowLabModal(false);
          setShowFamilyModal(true);
        }
      });
    } else {
      setShowLabModal(false);
      setShowFamilyModal(true);
    }
  };

  const goToLabModal = () => {
    setShowFamilyModal(false);
    setShowLabModal(true);
  };

  const handleOrderNow = (packageItem) => {
    // console.log(packageItem);
    postActivity('package', packageItem.name, window.location.href);
    if (localStorage.getItem('token')) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&slug=${packageItem.slug}`
        )
        .then((resp) => {
          if (carts.length !== 0) {
            // console.log(carts);
            if (carts[0].order_type === 'diagnostic') {
              toast.error(
                'Sorry, You cannot order packages and tests at the same time.'
              );
              return;
            }
          }

          // //console.log(resp.data.results[0].packageitem);
          const dhakaArray = resp.data.results[0].packageitem.filter(
            (location) => {
              return location.test_item.location.name === 'Dhaka';
            }
          );
          const dhakaHospital = [
            {
              description: resp.data.results[0].description,
              name: resp.data.results[0].name,
              packageitem: [...dhakaArray],
              purchasable_order_item:
                resp.data.results[0].purchasable_order_item,
              slug: resp.data.results[0].slug,
            },
          ];

          const ctgArray = resp.data.results[0].packageitem.filter(
            (location) => {
              return location.test_item.location.name === 'Chattogram';
            }
          );
          const ctgHospital = [
            {
              description: resp.data.results[0].description,
              name: resp.data.results[0].name,
              packageitem: [...ctgArray],
              purchasable_order_item:
                resp.data.results[0].purchasable_order_item,
              slug: resp.data.results[0].slug,
            },
          ];

          const labName = localStorage.getItem('lab_name');
          const labLocation = localStorage.getItem('lab_location');
          if (isLocalLab) {
            if (labName && labLocation) {
              const all = resp.data.results[0].packageitem
                .filter((lab) => lab.test_item.branch.lab !== null)
                .filter(
                  (lab) => lab.test_item.branch.lab.name === JSON.parse(labName)
                )
                .filter(
                  (lab) => lab.test_item.branch.name === JSON.parse(labLocation)
                );

              const allLabs = [
                {
                  description: resp.data.results[0].description,
                  name: resp.data.results[0].name,
                  packageitem: [...all],
                  purchasable_order_item:
                    resp.data.results[0].purchasable_order_item,
                  slug: resp.data.results[0].slug,
                },
              ];

              // console.log(allLabs);
              setDefaultHospitalList(allLabs);
            }
          } else {
            //console.log(dhakaHospital);
            //console.log(ctgHospital);
            // console.log(resp.data.results);
            setDefaultHospitalList(resp.data.results);
            setDhakaHospitalList(dhakaHospital);
            setCtgHospitalList(ctgHospital);
            setHospitalList(resp.data.results);

            if (selectedAreaLab === 'Dhaka') {
              setDefaultHospitalList(dhakaHospital);
            } else if (selectedAreaLab === 'Chattogram') {
              setDefaultHospitalList(ctgHospital);
            }
          }

          setShowLabModal(true);
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      // console.log(packageItem);
      // toast.error('Please login first.');
      const unknownTestdata = {
        testType: 'package',
        testSlug: packageItem.slug,
        location: history.location.pathname,
      };

      sessionStorage.setItem('loginAfterData', JSON.stringify(unknownTestdata));
      // history.push('/login');
      if (window.innerWidth < 768) {
        setShowLoginModal(true);
      } else {
        history.push('/login');
      }
    }
  };

  const handleOrderFor = (e) => {
    //console.log(e.target.value);
    setPersonDetails([]);
    setOrderForRadio(e.target.value);

    if (e.target.value === 'Myself') {
      setShowFamily(false);
      // setPersonDetails(userSelf);
      setShowMyself(true);
      setPersonDetails(suMyself);
      // //console.log(userSelf);
    } else if (e.target.value === 'Family') {
      setShowFamily(true);
      setShowMyself(false);
      setPersonDetails([]);
    }
  };

  const handleLabRadio = (lab) => {
    //console.log(lab);
    setTestItem(lab);
  };

  const handleFamilyMember = (e, family) => {
    if (e.target.checked) {
      // //console.log('checkbox checked');
      setPersonDetails([...personDetails, family]);
      //console.log([...personDetails, family]);
    } else {
      // //console.log('checkbox unchecked');
      setPersonDetails(
        personDetails.filter((person) => person.id !== family.id)
      );
    }
  };

  const handleAddAnother = () => {
    setShowAddPatientForm(true);
    setShowAddAnotherBtn(false);
  };

  const handleAddFamilyMember = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (patientDob === '' || patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const allPatients = [...suFamily, ...suMyself];
      // console.log(allPatients);
      const check = allPatients.find(
        (item) => item.full_name.toLowerCase() === patientName.toLowerCase()
      );
      if (check) {
        toast.error(
          `A patient already exists with this name in your family, please try another name.`
        );
      } else {
        const checkToken = localStorage.getItem('token');
        const user = localStorage.getItem('user_id');

        if (user) {
          axios.interceptors.request.use((config) => {
            const token = 'Token ' + JSON.parse(checkToken);
            config.headers.Authorization = token;
            return config;
          });

          const patientData = {
            full_name: patientName,
            dob: moment(patientDob).format('YYYY-MM-DD'),
            sex: patientGender,
            user: user,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
              patientData
            )
            .then((resp) => {
              //console.log(resp.data);
              setShowAddPatientForm(false);
              setPatientName('');
              setPatientDob('');
              setPatientGender('');
              setShowAddAnotherBtn(true);
              // //console.log(newFamily);
              addUserFamily(resp.data);
            })
            .catch((error) => {
              //console.log(error.response.status);
              //console.log(error.response.data);
            });
        }
      }
    }
  };

  const directAddToCart = (array, newLastCartPtn) => {
    if (array.length !== 0) {
      const carts = array.map((person, i) => {
        return {
          order_type: 'package',
          patient: person,
          package: testItem,
        };
      });

      // //console.log(carts);

      const order = {
        user: userDetails,
        status: '',
        order_item: carts,
      };

      // //console.log(order);
      setCartsItemAdd(carts);

      localStorage.setItem(
        'lab_name',
        JSON.stringify(order.order_item[0].package.test_item.branch.lab.name)
      );
      localStorage.setItem(
        'lab_location',
        JSON.stringify(order.order_item[0].package.test_item.branch.name)
      );
      setIsLocalLab(true);

      const getOrder = localStorage.getItem('order');
      const userId = localStorage.getItem('user_id');

      if (getOrder) {
        const userdsdsad = JSON.parse(getOrder);
        if (userdsdsad.user.pk === JSON.parse(userId)) {
          localStorage.removeItem('order');
          const allOrders = JSON.parse(getOrder);
          const newItem = [...order.order_item, ...allOrders.order_item];
          //console.log(allOrders);
          const newData = {
            user: allOrders.user,
            status: '',
            order_item: newItem,
          };
          //console.log(newData);
          localStorage.setItem('order', JSON.stringify(newData));
        } else {
          localStorage.removeItem('order');
          localStorage.setItem('order', JSON.stringify(order));
        }
      } else {
        localStorage.setItem('order', JSON.stringify(order));
      }

      toast.success('Test added successfully.', {
        autoClose: 3000,
      });

      setShowFamilyModal(false);
      setPersonDetails([]);
      setTestItem(null);
      setShowFamily(false);
      setShowLabModal(false);
      sessionStorage.removeItem('loginAfterData');

      if (fCartPage) {
        history.push('/cart');
      }

      if (newLastCartPtn && newLastCartPtn.length !== 0) {
        localStorage.setItem(
          'lastCartPatient',
          JSON.stringify(newLastCartPtn)
        );
        setLastCartPatient(newLastCartPtn);
      }
    }
  };

  const handleAddToCart = () => {
    if (!showFamily) {
      // console.log('update profile');
      handleEditMyself();
    }

    const carts = personDetails.map((person, i) => {
      return {
        order_type: 'package',
        patient: person,
        package: testItem,
      };
    });

    // //console.log(carts);

    const order = {
      user: userDetails,
      status: '',
      order_item: carts,
    };

    // //console.log(order);
    setCartsItemAdd(carts);

    localStorage.setItem(
      'lab_name',
      JSON.stringify(order.order_item[0].package.test_item.branch.lab.name)
    );
    localStorage.setItem(
      'lab_location',
      JSON.stringify(order.order_item[0].package.test_item.branch.name)
    );
    setIsLocalLab(true);

    const getOrder = localStorage.getItem('order');
    const userId = localStorage.getItem('user_id');

    if (getOrder) {
      const userdsdsad = JSON.parse(getOrder);
      if (userdsdsad.user.pk === JSON.parse(userId)) {
        localStorage.removeItem('order');
        const allOrders = JSON.parse(getOrder);
        const newItem = [...order.order_item, ...allOrders.order_item];
        //console.log(allOrders);
        const newData = {
          user: allOrders.user,
          status: '',
          order_item: newItem,
        };
        //console.log(newData);
        localStorage.setItem('order', JSON.stringify(newData));
      } else {
        localStorage.removeItem('order');
        localStorage.setItem('order', JSON.stringify(order));
      }
    } else {
      localStorage.setItem('order', JSON.stringify(order));
    }

    toast.success('Test added successfully.', {
      autoClose: 3000,
    });

    setShowFamilyModal(false);
    setPersonDetails([]);
    setTestItem(null);
    setShowFamily(false);
    sessionStorage.removeItem('loginAfterData');

    if (fCartPage) {
      history.push('/cart');
    }

    if (lastCartPatient.length === 0 || fCartPage) {
      const lastCartPat = personDetails.at(-1);
      localStorage.setItem('lastCartPatient', JSON.stringify([lastCartPat]));
      setLastCartPatient([lastCartPat]);
    }
  };

  const handleLabDistrict = (e) => {
    // //console.log(e.target.value);
    setSelectedAreaLab(e.target.value);
    if (e.target.value === 'all') {
      setDefaultHospitalList(hospitalList);
    } else if (e.target.value === 'Dhaka' || e.target.value === 'Savar') {
      setDefaultHospitalList(dhakaHospitalList);
    } else if (e.target.value === 'Chattogram') {
      setDefaultHospitalList(ctgHospitalList);
    }
  };

  const handleEditMyself = () => {
    if (myName.trim() === '') {
      toast.error(`Please enter your name.`, {
        autoClose: 3000,
      });
    } else if (myName.length < 4) {
      toast.error(`Your name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (myGender === '') {
      toast.error(`Please enter your gender.`, {
        autoClose: 3000,
      });
    } else if (myDob === '' || myDob === null) {
      toast.error(`Please enter your date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const putMyself = {
        full_name: myName,
        dob: moment(myDob).format('YYYY-MM-DD'),
        sex: myGender,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/user_management/patient/${suMyself[0].id}/`,
          putMyself
        )
        .then((resp) => {
          // console.log(resp.data);
          setUserMyself([resp.data]);
          // toast.success(`Profile updated successfully.`, {
          //   autoClose: 3000,
          // });
          setPersonDetails([resp.data]);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    }
  };

  return (
    <>
      <a
        className='btn btn-primary dtl-cart'
        onClick={() => handleOrderNow(packageItem)}
      >
        Add To Cart
      </a>



      <Modal
        show={showLabModal}
        onHide={() => setShowLabModal(false)}
        animation={false}
        className='main-modl'
      >
        <ModalBody className='diagnostic-select'>
          <div className='head'>
            <div>
              <h5>Select Labs</h5>
              <p className='mb-0'>Testing will be done from this lab</p>
            </div>
            <button
              className='btn btn-light'
              onClick={() => setShowLabModal(false)}
            >
              Close
            </button>
          </div>
          {defaultHospitalList && (
            <div className='bodies'>
              <div className='details'>
                <div className='left'>
                  <h5>{defaultHospitalList[0].name}</h5>
                  <p className='mb-0'>
                    {smallDescription(defaultHospitalList[0].description)}
                  </p>
                </div>
                {!isLocalLab && (
                  <div className='right'>
                    <select
                      value={selectedAreaLab}
                      onChange={handleLabDistrict}
                    >
                      <option value='all'>All Loc...</option>
                      <option value='Dhaka'>DHAKA</option>
                      {/* <option value='Savar'>SAVAR</option>
                      <option value='Chattogram'>CTG</option> */}
                    </select>
                  </div>
                )}
              </div>
              <div className='lists'>
                {defaultHospitalList[0].packageitem.map((hospital, i) => (
                  <div key={i}>
                    {hospital &&
                      hospital.test_item &&
                      hospital.test_item.branch &&
                      hospital.test_item.branch.lab && 
                      hospital.test_item.branch.lab.is_active &&(
                        <div className='single'>
                          <label className='radio'>
                            <div className='all'>
                              <div className='hospital'>
                                <div className='logo'>
                                  <img
                                    src={hospital.test_item.branch.lab.logo}
                                    alt=''
                                  />
                                </div>
                                <div className='name'>
                                  <h5>{hospital.test_item.branch.lab.name}</h5>
                                  <p>
                                    Branch: {hospital.test_item.location.name}
                                  </p>
                                </div>
                              </div>
                              {parseInt(
                                hospital.test_item.purchasable_order_item.price
                              ) >
                                parseInt(
                                  hospital.test_item.purchasable_order_item
                                    .sell_price
                                ) && (
                                <div className='wever'>
                                  <span className='btn btn-success'>
                                    {`- ${calculatePercentage(hospital)}%`}
                                  </span>
                                </div>
                              )}
                              <div className='price'>
                                {parseInt(
                                  hospital.test_item.purchasable_order_item
                                    .price
                                ) >
                                  parseInt(
                                    hospital.test_item.purchasable_order_item
                                      .sell_price
                                  ) && (
                                  <div className='old'>
                                    ৳{' '}
                                    {hospital &&
                                    hospital.test_item &&
                                    hospital.test_item.purchasable_order_item
                                      ? hospital.test_item
                                          .purchasable_order_item.price
                                      : ''}
                                  </div>
                                )}
                                <div className='new'>
                                  ৳{' '}
                                  {hospital &&
                                  hospital.test_item &&
                                  hospital.test_item.purchasable_order_item
                                    ? hospital.test_item.purchasable_order_item
                                        .sell_price
                                    : ''}
                                </div>
                              </div>
                            </div>
                            <input
                              type='radio'
                              name='radio'
                              onChange={() => handleLabRadio(hospital)}
                              checked={hospital === testItem}
                            />
                            <span className='checkmark' />
                          </label>
                        </div>
                      )}
                  </div>
                ))}
              </div>
              <div className='lists'>
                {defaultHospitalList &&
                  defaultHospitalList[0].packageitem.length === 0 && (
                    <p className='text-failed not-found'>
                      Sorry, no test available in selected area for your
                      selected lab.
                    </p>
                  )}
              </div>
              {lastCartPatient.length === 0 || cPAllPat ? (
                <button
                  className='btn btn-primary btn-block next-step'
                  onClick={goToFamilyModal}
                  disabled={!testItem}
                >
                  Select & Next
                </button>
              ) : (
                <>
                  {cPPatient.length === 0 ? (
                    <button
                      className='btn btn-primary btn-block next-step'
                      onClick={() => directAddToCart(lastCartPatient)}
                      disabled={!testItem}
                    >
                      Add To Cart
                    </button>
                  ) : (
                    <button
                      className='btn btn-primary btn-block next-step'
                      onClick={() => directAddToCart(cPPatient, cPPatient)}
                      disabled={!testItem}
                    >
                      Add To Cart
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        show={showFamilyModal}
        className='main-modl'
        onHide={() => setShowFamilyModal(false)}
        animation={false}
      >
        <ModalBody className='diagnostic-select patient family'>
          <div className='head'>
            <div className='descrip'>
              <img src={LeftArrow} alt='' onClick={goToLabModal} />
              <div className='left'>
                <h5>Patient Info</h5>
                <p className='mb-0'>Report will be generated with this name</p>
              </div>
            </div>
            <button
              className='btn btn-light'
              onClick={() => setShowFamilyModal(false)}
            >
              Close
            </button>
          </div>
          <div className='bodies'>
            <div className='details'>
              <div className='left'>
                <h5>I’m ordering for</h5>
              </div>
              <div className='right'>
                <label className='person first'>
                  Myself
                  <input
                    type='radio'
                    name='person'
                    value='Myself'
                    onChange={handleOrderFor}
                    checked={orderForRadio === 'Myself'}
                  />
                  <span className='checkmark' />
                </label>
                <label className='person'>
                  Other
                  <input
                    type='radio'
                    name='person'
                    value='Family'
                    onChange={handleOrderFor}
                    checked={orderForRadio === 'Family'}
                  />
                  <span className='checkmark' />
                </label>
              </div>
            </div>
            {showMyself && (
              <form>
                <div className='name-gender d-flex justify-content-between'>
                  <div className='name'>
                    <input
                      type='text'
                      className='nainp'
                      placeholder='Your Name'
                      value={myName}
                      onChange={(e) => setMyName(e.target.value)}
                    />
                  </div>
                  {/* <div className='gender'>
                    <h5>Your Gender*</h5>
                    <div className='male-female'>
                      <label className='gender'>
                        Male
                        <input
                          type='radio'
                          value='male'
                          onChange={(e) => setMyGender(e.target.value)}
                          name='my-gender'
                          checked={myGender === 'male'}
                        />
                        <span className='checkmark' />
                      </label>
                      <label className='gender'>
                        Female
                        <input
                          type='radio'
                          value='female'
                          onChange={(e) => setMyGender(e.target.value)}
                          name='my-gender'
                          checked={myGender === 'female'}
                        />
                        <span className='checkmark' />
                      </label>
                    </div>
                  </div> */}
                  <div className='gender'>
                    <select
                      className='nainp form-select form-select-sm'
                      value={myGender}
                      onChange={(e) => setMyGender(e.target.value)}
                    >
                      <option disabled value=''>
                        Select gender
                      </option>
                      <option value='male'>M</option>
                      <option value='female'>F</option>
                      <option value='common'>T</option>
                    </select>
                  </div>
                </div>
                <div className='age-rx'>
                  <PatientDob setPatientDob={setMyDob} patientDob={myDob} />
                </div>
              </form>
            )}
            {showFamily && (
              <>
                <div className='members'>
                  {suFamily.length === 0 ? (
                    <p>No family member found. Please add some.</p>
                  ) : (
                    <>
                      {suFamily.map((family, i) => (
                        <div key={i}>
                          <label className='individual'>
                            <span className='font-semibold'>
                              {family.full_name}
                            </span>
                            <input
                              type='checkbox'
                              value='Father'
                              onChange={(e) => handleFamilyMember(e, family)}
                              // onChange={(e) => //console.log(family)}
                            />
                            <span className='checkmark'></span>
                          </label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {showAddPatientForm && (
                  <form>
                    <div className='name-gender'>
                      <div className='name'>
                        <input
                          type='text'
                          className='nainp'
                          placeholder='Person Name'
                          value={patientName}
                          onChange={(e) => setPatientName(e.target.value)}
                        />
                      </div>
                      {/* <div className='gender'>
                        <h5>Gender*</h5>
                        <div className='male-female'>
                          <label className='gender'>
                            Male
                            <input
                              type='radio'
                              value='male'
                              onChange={(e) => setPatientGender(e.target.value)}
                              name='gender'
                            />
                            <span className='checkmark' />
                          </label>
                          <label className='gender'>
                            Female
                            <input
                              type='radio'
                              value='female'
                              onChange={(e) => setPatientGender(e.target.value)}
                              name='gender'
                            />
                            <span className='checkmark' />
                          </label>
                        </div>
                      </div> */}
                      <div className='gender'>
                        <select
                          className='nainp form-select form-select-sm'
                          value={patientGender}
                          onChange={(e) => setPatientGender(e.target.value)}
                        >
                          <option disabled value=''>
                            Select gender
                          </option>
                          <option value='male'>M</option>
                          <option value='female'>F</option>
                          <option value='common'>T</option>
                        </select>
                      </div>
                    </div>
                    <div className='age-rx'>
                      <PatientDob
                        setPatientDob={setPatientDob}
                        patientDob={patientDob}
                      />
                    </div>
                    <button
                      className='btn-btn-light'
                      onClick={handleAddFamilyMember}
                    >
                      Add Member
                    </button>
                  </form>
                )}
                {showAddAnotherBtn && (
                  <div className='add-another'>
                    <button
                      className='btn-btn-light'
                      onClick={handleAddAnother}
                    >
                      Add Another
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='foot'>
            <button
              className='btn btn-primary btn-block'
              onClick={handleAddToCart}
              disabled={!personDetails.length ? 'disabled' : ''}
            >
              Add To Cart
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Package;
