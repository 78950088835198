// import HeroOne from '../../assets/img/hero-caro-1.png';
// import HeroTwo from '../../assets/img/hero-caro-2.png';
// import HeroEight from '../../assets/img/hero-caro-8.jpg';
import HeroOne from '../../assets/img/hero-caro-4.jpg';
// import HeroTwo from '../../assets/img/hero-caro-2.jpg';
import HeroFour from '../../assets/img/hero-caro-5.jpg';
import HeroFive from '../../assets/img/hero-caro-6.jpg';
// import HeroSix from '../../assets/img/hero-caro-7.png';
// import HeroNine from '../../assets/img/hero-caro-9.jpg';
import Carousel from 'react-multi-carousel';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const HeroCarousel = () => {
  const { postActivity } = useContext(AuthContext);

  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={5000}
      centerMode={false}
      className=''
      containerClass='container carousel-container'
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass='test'
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      showDots={true}
      sliderClass=''
      slidesToSlide={1}
      swipeable
    >
      {/* <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Health Check Balance', window.location.href)
        }
      >
        <img src={HeroEight} alt='' className='img-fluid' />
      </div> */}
      {/* <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Ramadan 2022 Cover', window.location.href)
        }
      >
        <img src={HeroSix} alt='' className='img-fluid' />
      </div> */}
      <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Covid Cover', window.location.href)
        }
      >
        <img src={HeroOne} alt='' className='img-fluid' />
      </div>
      <div
        className='imagel'
        onClick={() =>
          postActivity('banner', '10% discount', window.location.href)
        }
      >
        <img src={HeroFive} alt='' className='img-fluid' />
      </div>
      {/* <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Full Health Checkup', window.location.href)
        }
      >
        <img src={HeroTwo} alt='' className='img-fluid' />
      </div> */}
      <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Youtube Video', window.location.href)
        }
      >
        <div
          className=''
          style={{
            overflow: 'hidden',
            borderTopLeftRadius: '30px',
            borderBottomRightRadius: '30px',
          }}
        >
          <iframe
            width={560}
            height={315}
            src='https://www.youtube.com/embed/Gv4zcpaTTvY'
            title='YouTube video player'
            frameBorder={0}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            loading='lazy'
          />
        </div>
      </div>
      <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Equipment Banner', window.location.href)
        }
      >
        <img src={HeroFour} alt='' className='img-fluid' />
      </div>
    </Carousel>
  );
};

export default HeroCarousel;
