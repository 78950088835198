import { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import CloseIcon from '../../assets/img/x-black.png';

const MblFilterModal = ({
  showFilterModal,
  setFilterModal,
  filterType,
  oldNewSlug,
  handleOldNew,
  fetchInitialTests,
  handleReset,
  categories,
  labs,
  handleCategory,
  categorySlug,
  locationSlug,
  handleCity,
  labSlug,
  handleLabs,
  applyMobileFilter,
}) => {
  const [category, setCategories] = useState('');
  const [area, setArea] = useState('');
  const [lab, setLab] = useState('');
  const [sortBy, setSortBy] = useState('id');

  const handleFilter = () => {
    setFilterModal(false);
    applyMobileFilter(category, area, lab, sortBy);
  };

  const handleResett = (e) => {
    // setOldNewSlug('');
    handleReset(e);
    setFilterModal(false);
    setCategories('');
    setArea('');
    setLab('');
    setSortBy('id');
  };

  return (
    <Modal
      show={showFilterModal}
      onHide={() => setFilterModal(false)}
      animation={false}
      centered
    >
      <ModalBody>
        <div className='filter-Modal'>
          <div className='head'>
            <p className='left'>
              {filterType === 'filter' ? 'Filter' : 'Sort'}
            </p>
            <div className='right'>
              <p onClick={handleResett}>RESET</p>
              <img
                onClick={() => setFilterModal(false)}
                src={CloseIcon}
                alt=''
              />
            </div>
          </div>
          <div className='dropdowns'>
            {filterType === 'filter' && (
              <>
                <select
                  className='cat'
                  onChange={(e) => setCategories(e.target.value)}
                  value={category || ''}
                >
                  <option value='all'>Category</option>
                  {categories &&
                    categories.map((category, i) => (
                      <option key={i} value={category.slug}>
                        {category.name}
                      </option>
                    ))}
                </select>
                <select
                  className='city'
                  value={area || ''}
                  onChange={(e) => setArea(e.target.value)}
                >
                  <option value='Dhaka'>Dhaka</option>
                  {/* <option value='Chattogram'>Chattogram</option> */}
                </select>
                <select
                  className='lab'
                  value={lab || ''}
                  onChange={(e) => setLab(e.target.value)}
                >
                  <option value='all'>All Labs</option>
                  {labs &&
                    labs.map((lab, i) => (
                      <option key={i} value={lab.name}>
                        {lab.name}
                      </option>
                    ))}
                </select>
              </>
            )}
            {filterType === 'sort' && (
              <>
                <select
                  className='sort'
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value='old'>Oldest</option>
                  <option value='new'>Newest</option>
                </select>
              </>
            )}
          </div>
          <div className='button'>
            <button
              className='btn btn-primary btn-block'
              onClick={handleFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MblFilterModal;
