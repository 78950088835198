import React, { Component } from 'react';
import XLogo from '../assets/img/x-black.png';
import UserLogo from '../assets/img/user.png';
import DateSelect from '../components/DateSelect';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { dhakaArea, savarArea, ctgArea } from '../helpers/Areas';
import LoadingBtn from '../components/LoadingBtn';
import { Helmet } from 'react-helmet';
import { Spinner } from 'react-bootstrap';
import SearchIcon from '../assets/img/search.png';

import Package from '../components/homepage/PackageDetailModal';

import { Modal, ModalBody } from 'react-bootstrap';
import CheckupPackages from '../components/CheckupTestModal';

import { Accordion } from 'react-bootstrap';
import AccArrow from '../assets/img/right-arrow-link.png';
import { faqDetails } from '../helpers/FaqsDetails';

import homeImage from '../assets/img/blood-test.svg';
import getImage from '../assets/img/board.svg';
import affordableImage from '../assets/img/discount.svg';
import packageImage from '../assets/img/package.png';



import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers'

class PackageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosticTests: {},
      totalPriceTests: [],
      userSelf: [],
      userFamily: [],
      minPrice: 0,
      searchText: '',
      showLabModal: false,
      isLocalLab: false,
      selectedAreaLab: '',

      selectedLabs: [],
      searchArea: '',

    };
  }



  setUserFamily = (array) => {
    this.setState({ userFamily: array });
  };

  minTotalByName = (data) => {
      console.log("Hi this is my bad bro .... ", data)
      //alert(data[0].purchasable_order_item.price)
      //if(data && data.length){
      //alert(Math.min(data.map(x => x.purchasable_order_item.price)));
      const totals = data.map(x => x.test_item.purchasable_order_item.sell_price);
      return Math.min(...totals);
      //}
  }


  handleSearch = (e) => {
    e.preventDefault();
    //window.scrollTo(0, 1000);

    if (this.state.searchText !== '') {
      this.props.history.push(`/diagnostic?search=${this.state.searchText}`);
      //this.fetchInitialTests();
    }
  };




  handleAddToCart = (test) => {
    // console.log(test);
    // postActivity('test', test.name, window.location.href);
    // if (localStorage.getItem('token')) {
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&slug=${test.slug}`
    //     )
    //     .then((resp) => {
    //       // //console.log(resp.data.results);

    //       const dhakaArray = resp.data.results[0].testitem.filter(
    //         (location) => {
    //           return location.location.name === 'Dhaka';
    //         }
    //       );
    //       const dhakaHospital = [
    //         {
    //           description: resp.data.results[0].description,
    //           name: resp.data.results[0].name,
    //           slug: resp.data.results[0].slug,
    //           testitem: [...dhakaArray],
    //           category: resp.data.results[0].category,
    //         },
    //       ];

    //       const ctgArray = resp.data.results[0].testitem.filter((location) => {
    //         return location.location.name === 'Chattogram';
    //       });
    //       const ctgHospital = [
    //         {
    //           description: resp.data.results[0].description,
    //           name: resp.data.results[0].name,
    //           slug: resp.data.results[0].slug,
    //           testitem: [...ctgArray],
    //           category: resp.data.results[0].category,
    //         },
    //       ];

    //       const labName = localStorage.getItem('lab_name');
    //       const labLocation = localStorage.getItem('lab_location');
    //       if (isLocalLab) {
    //         if (labName && labLocation) {
    //           const all = resp.data.results[0].testitem
    //             .filter((lab) => {
    //               return lab.branch !== null;
    //             })
    //             .filter((lab) => {
    //               return lab.branch.lab.name === JSON.parse(labName);
    //             })
    //             .filter((location) => {
    //               return location.branch.name === JSON.parse(labLocation);
    //             });

    //           const allLabs = [
    //             {
    //               description: resp.data.results[0].description,
    //               name: resp.data.results[0].name,
    //               slug: resp.data.results[0].slug,
    //               testitem: [...all],
    //               category: resp.data.results[0].category,
    //             },
    //           ];

    //           // console.log(allLabs);
    //           setDefaultHospitalList(allLabs);
    //           setHospitalModal(true);
    //         }
    //       } else {
    //         setDefaultHospitalList(resp.data.results);
    //         setDhakaHospitalList(dhakaHospital);
    //         setCtgHospitalList(ctgHospital);
    //         setHospitalList(resp.data.results);
    //         setHospitalModal(true);

    //         if (selectedAreaLab === 'Dhaka') {
    //           setDefaultHospitalList(dhakaHospital);
    //         } else if (selectedAreaLab === 'Chattogram') {
    //           setDefaultHospitalList(ctgHospital);
    //         }
    //       }

    //       //console.log(dhakaHospital);
    //       //console.log(ctgHospital);
    //       // console.log(resp.data.results);

    //       // resp.data.results[0].testitem.forEach((hospital) => {
    //       //   // //console.log(hospital.branch.name);
    //       //   //console.log(hospital);
    //       // });
    //     })
    //     .catch((error) => {
    //       //console.log(error);
    //     });
    //   // setShowModal(true);
    // } else {
    //   // toast.error('Please login first.');
    //   const unknownTestdata = {
    //     testType: 'diagnostic',
    //     testSlug: test.slug,
    //     location: history.location.pathname,
    //   };

    //   sessionStorage.setItem('loginAfterData', JSON.stringify(unknownTestdata));
    //   // console.log(test);
    //   if (window.innerWidth < 768) {
    //     setShowLoginModal(true);
    //   } else {
    //     history.push('/login');
    //   }
    // }
  };




  fetchCoupon = (slug) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?is_active=true&slug=${slug}`)
      .then((resp) => {
        // console.log(resp.data.results);
        if (resp.data.results.length !== 0) {
            //alert("hi")
          this.setState({ diagnosticTests: resp.data.results[0] });
          this.setState({ minPrice: this.minTotalByName(resp.data.results[0].packageitem) });
          //const min = minTotalByName(data);
        }


        console.log("The data is = ", this.state.diagnosticTests);

      })
      .catch((error) => {
        console.log(error.response);
      });




    const userId = localStorage.getItem('user_id');
    if (userId) {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }/user_management/patient/?user=${JSON.parse(userId)}&limit=300`
        )
        .then((resp) => {
          const results = resp.data.results;
          const myself = results.filter((item) => {
            return item.is_account;
          });
          const family = results.filter((item) => {
            return !item.is_account;
          });
          this.setState({ userSelf: myself });
          this.setState({ userFamily: family });
        })
        .catch((error) => {
          //console.log(error.response.data);
        });
    }




  };

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let slug = params.get('slug');
    this.fetchCoupon(slug);
    //alert(this.state.totalPriceTests.length)

  }



  render() {
    const {
      diagnosticTests,
      totalPriceTests,
      minPrice,
    } = this.state;
    // //console.log(carts);
    return (
      <>
        <section className='checkout package'>
          <div className='container'>

            <div className='row dt-page'>
              <div className='col-lg-8 row'>

              <div className="col-md-12 description search">
                
                <img src={diagnosticTests && diagnosticTests.image ? diagnosticTests.image : packageImage} />
              </div>

                <form className='details'>
                <div className="row">
                  <h1 className=' col-md-8 main-heading diagnostic-title'>{diagnosticTests && diagnosticTests.name}</h1>




                  <div className="dtl-top-cart desktop">
                    <Package
                      userSelf={this.state.userSelf}
                      userFamily={this.state.userFamily}
                      setUserFamily={this.setUserFamily}
                      packageItem={this.state.diagnosticTests}
                      package_source={`amarlab`}
                      cartPageData={this.props.location}
                    />

                  </div>
                  {/* 
                    <button
                      className=' col-md-2 btn btn-primary'
                      onClick={this.handleAddToCart(diagnosticTests)}
                    >
                      Add To Cart
                    </button>
                  */} 


                  </div>
                  <span className='main-sub-title'>Price Starting From <strong>&#2547;{minPrice && minPrice}</strong></span>
                  <h1 className='main-heading mobile'>
                    Order Details <span> (Fill-Up Booking Info.)</span>
                  </h1>


                  <div className='row items time col-md-12 note package-top-des'>
                    <div>
                      <h5>Who should book this checkup?</h5>
                      <p>
                        {diagnosticTests && diagnosticTests.why_should}
                      </p>
                    </div>
                  </div>




                  <div className="lab-details">
                    <span>Lab Partners</span>
                    <div className="lab-image">
                      {diagnosticTests && diagnosticTests.packageitem &&
                        diagnosticTests.packageitem.map((item, index) => {
                        return (
                          <>
                            <img key={index} src={item.test_item.branch.lab.logo} />
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className="test-details-faq-title">
                  Test Included: <span>{diagnosticTests && diagnosticTests.package_package && diagnosticTests.package_package.length } Tests</span> 
                  </div>



                  {diagnosticTests && diagnosticTests.package_package &&
                    diagnosticTests.package_package.map((item, index) => {
                    return (
                      <>
                        <div className='row items time col-md-12 note'>
                          <div className='left note-image'>
                            <img src={homeImage} />
                          </div>
                          <div className="pckg-desc-list">
                            <h5>{item && item.diagnostic_test && item.diagnostic_test.name}</h5>
                            <p >
                              {item && item.diagnostic_test && item.diagnostic_test.description.substring(0, 150) }
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}




                </form>
              </div>



              <div className='col-lg-4'>
                <h5 className='mbl-diag-title d-none'>Diagnostics Details</h5>
                <div className='calculation diag-list'>
                  <div className='order-summery web d-details'>
                    <h5>Need Help with booking your test?</h5>
                    <span className="title-gray">Our expert are here to help you</span>
                    <div className="phone-details"><i className="fa fa-phone" aria-hidden="true"></i> 096 43445566</div>
                  </div>

                </div>
              </div>
              <div className='d-none form-group form-check mbl-terms'>

              </div>



              <div
                className='mbl-sticky shadow-sm test-dtls-stcky'
              >
                    <Package
                      userSelf={this.state.userSelf}
                      userFamily={this.state.userFamily}
                      setUserFamily={this.setUserFamily}
                      packageItem={this.state.diagnosticTests}
                      package_source={`amarlab`}
                      cartPageData={this.props.location}
                    />
              </div>










            </div>
          </div>
        </section>

        <Helmet>
          <script>
            {`
            gtag('event', 'conversion', {'send_to': 'AW-10778978942/sA9mCNrP070DEP7U6JMo'});
          `}
          </script>
        </Helmet>
      </>
    );
  }
}

export default PackageDetails;

PackageDetails.contextType = AuthContext;
