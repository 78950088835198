import axios from 'axios';
import React, { Component } from 'react';
import Achievements from '../components/Achievements';
import Faq from '../components/homepage/Faq';
import ReqOrderCall from '../components/ReqOrderCall';
import SearchIcon from '../assets/img/search.png';
import LoadingGif from '../components/LoadingGif';
import CheckupPackages from '../components/CheckupPackages';
import Pagination from '../components/Pagination';
import SemiHeaderMbl from '../components/SemiHeaderMbl';
import SortImg from '../assets/img/sort.png';
import FilterImg from '../assets/img/filter.png';
import MblFilterModal from '../components/modal/MblFilterModal';
import HeroCarouselMbl from '../components/homepage/HeroCarouselMbl';
import { AuthContext } from '../context/AuthContext';
import HomeDiagnostic from '../components/modal/HomeDiagnostic';
import swal from 'sweetalert';

class Category extends Component {
  state = {
    categories: null,
    locations: null,
    labs: null,

    tests: [],
    filteredTest: [],
    testCount: null,
    limit: 6,
    pagination: 1,
    previous: null,
    next: null,
    loading: false,
    loadingPagi: false,

    searchCategory: '',
    searchText: '',
    searchArea: '',
    searchLabs: '',
    searchBySort: 'id',
    searchByType: '',

    urlParamObj: {},
    fetchParams: {
      is_active: true,
      category_test__category__slug: '',
      search: '',
      ordering: 'id',
      testitem__branch__location__name: '',
      testitem__branch__lab__name: '',
    },

    showFilterModal: false,
    filterType: 'filter',

    diagModal: false,
    testDetails: null,
    patientModal: false,

    selectedLabs: [],
  };

  fetchCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/category/?page=1&limit=800&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ categories: resp.data.results });
      });
  };

  fetchLabs = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?page=1&limit=800&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        const activeLabs = resp.data.results.filter((lab) => lab.is_active);
        this.setState({ labs: null });
        this.setState({ labs: activeLabs });
      });
  };

  // fetchInitialTests = () => {
  //   this.setState({ loading: true });
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&page=1&limit=${this.state.limit}&ofset=0`
  //       // `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&page=1&limit=${this.state.limit}&offset=0&is_covid=false&category_test__category__slug=&ordering=id&testitem__branch__location__name=&testitem__branch__lab__name=`
  //     )
  //     .then((resp) => {
  //       // console.log(resp.data);
  //       this.setState({ tests: resp.data.results });
  //       this.setState({ filteredTest: resp.data.results });
  //       this.setState({ previous: resp.data.previous });
  //       this.setState({ next: resp.data.next });
  //       this.setState({ testCount: resp.data.count });
  //       this.setState({ loading: false });
  //     });
  // };

  fetchInitialTests = (limit, pagination) => {
    let url = `${
      process.env.REACT_APP_BASE_URL
    }/diagnostic/diagnostic-test-item/?is_active=true&page=1&limit=${limit}&offset=${
      (pagination - 1) * limit
    }`;

    const searchArea =
      this.state.searchArea === 'Savar' ? 'Dhaka' : this.state.searchArea;

    if (this.state.urlParamObj) {
      url = `${
        process.env.REACT_APP_BASE_URL
      }/diagnostic/diagnostic-test-item/?is_active=true&page=1&limit=${limit}&offset=${
        (pagination - 1) * limit
      }&search=${this.state.searchText}&category_test__category__slug=${
        this.state.searchCategory
      }&ordering=${
        this.state.searchBySort
      }&testitem__branch__name=${searchArea}&testitem__branch__lab__name=${
        this.state.searchLabs
      }`;

      // &is_covid=${this.state.searchByType === 'covid' ? true : false}
    }

    this.setState({ loading: true });
    axios
      .get(url)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ filteredTest: resp.data.results });
        this.setState({ next: resp.data.next, testCount: resp.data.count });
        this.setState({ testCount: resp.data.count });
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    let param_collection = {};
    let params = new URLSearchParams(this.props.location.search);
    let searchObj = {};

    for (const param of params.entries()) {
      param_collection[param[0]] = param[1];
      if (param[0] === 'category') {
        this.setState({ searchCategory: param[1] });
      } else if (param[0] === 'search') {
        this.setState({ searchText: param[1] });
      } else if (param[0] === 'ordering') {
        this.setState({ searchBySort: param[1] });
      } else if (param[0] === 'location') {
        this.setState({ searchArea: param[1] });
      }
    }

    this.setState({ urlParamObj: param_collection });
    // console.log(param_collection);

    setTimeout(() => {
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 200);

    this.context.setPageTitle('Diagnostic');
    window.scrollTo(0, 0);
    this.fetchCategories();
    this.fetchLabs();
    return;

    // this.fetchInitialTests();

    let category = params.get('category');
    let search = params.get('search');
    let ordering = params.get('ordering');
    let location = params.get('location');
    let lab = params.get('lab');

    if (category) {
      this.setState({ searchCategory: category });
      setTimeout(() => {
        this.fetchInitialTests(this.state.limit, this.state.pagination);
      }, 100);
    } else {
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }
  }

  goToFirstModal = () => {
    this.setDiagModal(true);
    this.setPatientModal(false);
  };

  goToSecondModal = () => {
    if (!this.context.isLocalLab) {
      swal({
        // title: 'Are you sure?',
        text: 'Once you select a lab or area/district, you will not be able to change it later to this order.',
        icon: 'warning',
        buttons: {
          cancel: true,
          text: 'I Understand',
        },
      }).then((willDelete) => {
        if (willDelete) {
          this.setDiagModal(false);
          this.setPatientModal(true);
        }
      });
    } else {
      this.setDiagModal(false);
      this.setPatientModal(true);
      // setPersonDetails(suMyself);
    }
  };

  setPatientModal = (value) => {
    this.setState({ patientModal: value });
  };

  setDiagModal = (value) => {
    this.setState({ diagModal: value });
  };

  setTestDetails = (value) => {
    this.setState({ testDetails: value });
  };

  handleFetchTestDetails = (slug) => {
    // console.log(test);
    if (localStorage.getItem('token')) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?is_active=true&slug=${slug}`
        )
        .then((resp) => {
          // console.log(resp.data.results);
          this.setTestDetails(resp.data.results);
        })
        .catch((error) => {
          //console.log(error);
        });
      // setShowModal(true);
    }
  };

  componentDidUpdate() {
    if (this.context.isLoggedIn) {
      const getLoginAfter = sessionStorage.getItem('loginAfterData');
      const getLoginAfterData = JSON.parse(getLoginAfter);
      if (getLoginAfter) {
        if (getLoginAfterData.testType === 'diagnostic') {
          if (window.innerWidth < 768) {
            window.scrollTo(0, 300);
          } else {
            window.scrollTo(0, 250);
          }
          if (this.state.diagModal === false) {
            sessionStorage.clear();
            this.setDiagModal(true);
            // console.log('component');
            this.handleFetchTestDetails(getLoginAfterData.testSlug);
          }
        }
        // console.log(getLoginAfterData);
      }
    }
  }

  paramsMod = (key, value, ispush = true) => {
    let newUrlParam = { ...this.state.urlParamObj };
    newUrlParam[key] = value;
    this.setState({ urlParamObj: newUrlParam });

    let paramstring = '';
    for (const key in newUrlParam) {
      if (newUrlParam[key]) paramstring += `&${key}=${newUrlParam[key]}`;
    }

    if (ispush) this.props.history.push(`/diagnostic?${paramstring.slice(1)}`);
  };

  filterData = (term, value) => {
    let passsing_data = { ...this.state.fetchParams };
    passsing_data[term] = value;

    this.setState({ fetchParams: passsing_data });

    setTimeout(() => {
      const url = `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item/?search=${this.state.fetchParams}&is_active=${this.state.fetchParams.is_active}&category_test__category__slug=${this.state.fetchParams.category_test__category__slug}&page=1&limit=6&ofset=0`;
      console.log(url);
    }, 200);
  };

  fetchFilterData = () => {
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/diagnostic/diagnostic-test-item/?is_active=true&page=1&limit=${
      this.state.limit
    }&ofset=0&is_covid=${
      this.state.searchByType === 'covid' ? true : false
    }&search=${this.state.searchText}&category_test__category__slug=${
      this.state.searchCategory
    }&ordering=${this.state.searchBySort}&testitem__branch__location__name=${
      this.state.searchArea
    }&testitem__branch__lab__name=${this.state.searchLabs}`;
    // console.log(url);

    this.setState({ loading: true });
    axios.get(url).then((resp) => {
      console.log(resp.data);
      this.setState({ filteredTest: resp.data.results });
      this.setState({ previous: resp.data.previous });
      this.setState({ next: resp.data.next });
      this.setState({ testCount: resp.data.count });
      this.setState({ loading: false });
    });
  };

  handleCategory = (name) => {
    if (name === '') {
      this.context.postActivity('category', 'All', window.location.href);
    } else {
      this.context.postActivity('category', name, window.location.href);
    }
    // console.log(data);
    this.setState({ searchCategory: name, pagination: 1 });

    this.paramsMod('category', name);
    // this.filterData('category_test__category__slug', name);
    setTimeout(() => {
      // this.fetchFilterData();
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handleCategoryMbl = (e) => {
    // console.log(data);
    this.setState({ searchCategory: e.target.value, pagination: 1 });

    this.paramsMod('category', e.target.value);
    // this.filterData('category_test__category__slug', name);
    setTimeout(() => {
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handleSearch = (e) => {
    e.preventDefault();
    // console.log(this.state.searchText);
    this.paramsMod('search', this.state.searchText);
    this.setState({ pagination: 1 });
    this.context.postActivity(
      'search',
      this.state.searchText,
      window.location.href
    );
    setTimeout(() => {
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handleArea = (e) => {
    const value = e.target.value;
    // console.log(value);

    // console.log(this.context);
    this.context.postActivity('filter', value, window.location.href);
    if (e.target.checked) {
      this.setState({ searchArea: value });
      this.paramsMod('location', value);
    } else {
      this.setState({ searchArea: '' });
      this.paramsMod('location', '');
    }
    this.setState({ pagination: 1 });
    setTimeout(() => {
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handleAreaMbl = (e) => {
    console.log(e.target.value);
    this.setState({ searchArea: e.target.value });
    this.paramsMod('location', e.target.value);
    this.setState({ pagination: 1 });
    setTimeout(() => {
      // this.fetchFilterData();
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handleLab = (e) => {
    // console.log(e.target.value);
    // if (e.target.checked) {
    //   this.setState({ searchLabs: e.target.value });
    //   this.paramsMod('lab', e.target.value);
    // } else {
    //   this.setState({ searchLabs: '' });
    //   this.paramsMod('lab', '');
    // }
    // this.setState({ pagination: 1 });
    // setTimeout(() => {
    //   // this.fetchFilterData();
    //   this.fetchInitialTests(this.state.limit, this.state.pagination);
    // }, 100);

    if (e.target.checked) {
      this.setState({
        selectedLabs: [...this.state.selectedLabs, e.target.value],
      });
    } else {
      this.setState({
        selectedLabs: this.state.selectedLabs.filter((lab) => {
          return lab !== e.target.value;
        }),
      });
    }

    this.context.postActivity('filter', e.target.value, window.location.href);

    // setTimeout(() => {
    //   console.log(this.state.selectedLabs);
    // }, 1000);
  };

  handleLabMbl = (e) => {
    // // console.log(e.target.value);
    // this.setState({ searchLabs: e.target.value, pagination: 1 });
    // this.paramsMod('lab', e.target.value);
    // setTimeout(() => {
    //   // this.fetchFilterData();
    //   this.fetchInitialTests(this.state.limit, this.state.pagination);
    // }, 100);

    this.setState({ searchLabs: e.target.value });
    console.log(e.target.value);

    this.setState({
      selectedLabs: [e.target.value],
    });
  };

  handleTestType = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked) {
      this.setState({ searchByType: e.target.value });
      this.paramsMod('type', e.target.value);
    } else {
      this.setState({ searchByType: '' });
      this.paramsMod('type', '');
    }
    this.setState({ pagination: 1 });
    setTimeout(() => {
      // this.fetchFilterData();
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handleOldNew = (e) => {
    this.setState({ searchBySort: e.target.value, pagination: 1 });
    this.paramsMod('ordering', e.target.value);
    if (e.target.value === 'id') {
      this.context.postActivity('filter', 'Oldest', window.location.href);
    } else {
      this.context.postActivity('filter', 'Newest', window.location.href);
    }
    setTimeout(() => {
      // this.fetchFilterData();
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    this.fetchInitialTests(this.state.limit, pageno);

    if (window.innerWidth < 768) {
      window.scrollTo(0, 300);
    } else {
      window.scrollTo(0, 250);
    }
  };

  handleReset = () => {
    this.props.history.push('/diagnostic');
    this.setState({
      searchCategory: '',
      searchText: '',
      searchArea: '',
      searchBySort: 'id',
      searchByType: '',
      pagination: 1,
      selectedLabs: [],
    });
    setTimeout(() => {
      this.fetchLabs();
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  setFilterModal = (value) => {
    this.setState({ showFilterModal: value });
  };

  handleFilterModal = (value) => {
    this.setFilterModal(true);
    this.setState({ filterType: value });
  };

  applyMobileFilter = (category, area, lab, sortBy) => {
    this.context.postActivity('filter', sortBy, window.location.href);
    if (category !== '') {
      this.context.postActivity('category', category, window.location.href);
    }
    if (area !== '') {
      this.context.postActivity('filter', area, window.location.href);
    }
    if (lab !== '') {
      this.context.postActivity('filter', lab, window.location.href);
    }
    this.setState({
      searchCategory: category,
      searchArea: area,
      selectedLabs: [lab],
      searchBySort: sortBy,
      pagination: 1,
    });

    setTimeout(() => {
      // this.fetchFilterData();
      this.fetchInitialTests(this.state.limit, this.state.pagination);
    }, 100);
  };

  render() {
    const {
      categories,
      labs,
      testCount,
      filteredTest,
      searchCategory,
      showFilterModal,
      filterType,
    } = this.state;

    return (
      <div className='cate-page'>
        <SemiHeaderMbl />
        {/* <section className='title'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='name text-capitalize'>
                  <i className='fas fa-home'></i> /{' '}
                  {searchCategory === '' ? 'All' : searchCategory}
                </div>
                <div className='lists'>
                  <span
                    className={searchCategory === '' ? 'active' : ''}
                    onClick={() => this.handleCategory('')}
                  >
                    All Tests
                  </span>
                  {categories &&
                    categories.map((category, i) => (
                      <span
                        key={i}
                        onClick={() => this.handleCategory(category.slug)}
                        className={
                          searchCategory === category.slug ? 'active' : ''
                        }
                      >
                        {category.name}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className='hero-mobile'>
          <HeroCarouselMbl />
        </section>
        <section className='all-labs'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='lists'>
                  {/* <div className='card single'>
                    <div className='body'>
                      <h5>Service Area</h5>
                      <div className='form-group form-check'>
                        <input
                          value='Dhaka'
                          type='checkbox'
                          className='form-check-input'
                          onChange={this.handleArea}
                          id='Dhaka'
                          checked={this.state.searchArea === 'Dhaka'}
                        />
                        <label className='form-check-label' htmlFor='Dhaka'>
                          Dhaka
                        </label>
                      </div>
                      <div className='form-group form-check'>
                        <input
                          value='Savar'
                          type='checkbox'
                          className='form-check-input'
                          onChange={this.handleArea}
                          id='Savar'
                          checked={this.state.searchArea === 'Savar'}
                        />
                        <label className='form-check-label' htmlFor='Savar'>
                          Savar
                        </label>
                      </div>
                      <div className='form-group form-check'>
                        <input
                          value='Chattogram'
                          type='checkbox'
                          className='form-check-input'
                          onChange={this.handleArea}
                          id='Chattogram'
                          checked={this.state.searchArea === 'Chattogram'}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Chattogram'
                        >
                          Chattogram
                        </label>
                      </div>
                    </div>
                  </div> */}
                  <div className='card single'>
                    <div className='body'>
                      <h5>Labs</h5>
                      {labs &&
                        labs.map((lab, i) => (
                          <div className='form-group form-check' key={i}>
                            <input
                              onChange={this.handleLab}
                              type='checkbox'
                              className='form-check-input'
                              value={lab.name}
                              id={lab.name}
                              // checked={this.state.selectedLabs.map(
                              //   (lab) => lab === lab.name
                              // )}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={lab.name}
                            >
                              {lab.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <div className='card single'>
                    <div className='body'>
                      <h5>Type</h5>
                      <div className='form-group form-check'>
                        <input
                          onChange={this.handleTestType}
                          type='checkbox'
                          className='form-check-input'
                          id='covid'
                          value='covid'
                          checked={this.state.searchByType === 'covid'}
                        />
                        <label htmlFor='covid' className='form-check-label'>
                          COVID
                        </label>
                      </div>
                      <div className='form-group form-check'>
                        <input
                          onChange={this.handleTestType}
                          type='checkbox'
                          className='form-check-input'
                          id='non_covid'
                          value='non_covid'
                          checked={this.state.searchByType === 'non_covid'}
                        />
                        <label htmlFor='non_covid' className='form-check-label'>
                          Non COVID
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='col-md-9 diag-list'>
                <h5 className='main-heading text-capitalize'>
                  {searchCategory === '' ? 'All' : searchCategory} Diagnostics
                  Tests
                </h5>
                <p className='main-subheading mb-0'>
                  {testCount || 0}+ Tests Available
                </p>
                <div className='search'>
                  <form onSubmit={this.handleSearch}>
                    <div className='input-group mb-0'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Search for test'
                        onChange={(e) =>
                          this.setState({ searchText: e.target.value })
                        }
                        value={this.state.searchText}
                      />
                      <div className='input-group-append'>
                        <button className=''>
                          <img src={SearchIcon} alt='' width='20' />
                        </button>
                      </div>
                    </div>
                  </form>
                  <select
                    onChange={this.handleOldNew}
                    value={this.state.searchBySort}
                  >
                    <option value='id'>Oldest</option>
                    <option value='-id'>Newest</option>
                  </select>
                  {/* <button
                    className='btn btn-primary reset'
                    onClick={this.handleReset}
                  >
                    Reset
                  </button> */}
                </div>
                <div className='filter-mobile'>
                  <div className='buttons'>
                    <div className='row'>
                      <div className='col-6'>
                        <p
                          className='filte'
                          onClick={() => this.handleFilterModal('filter')}
                        >
                          <img src={FilterImg} alt='' /> Filter
                        </p>
                      </div>
                      <div className='col-6'>
                        <p
                          className='sort'
                          onClick={() => this.handleFilterModal('sort')}
                        >
                          <img src={SortImg} alt='' /> Sort
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.loading ? (
                  <LoadingGif />
                ) : (
                  <div className='checkups'>
                    <div className='row items'>
                      {filteredTest.map((test, i) => (
                        <div
                          className='single-item col-xl-4 col-lg-4 col-sm-6 col-6'
                          key={i}
                        >
                          <CheckupPackages
                            test={test}
                            selectedLabs={this.state.selectedLabs}
                            searchArea={this.state.searchArea}
                            cartPageData={this.props.location}
                          />
                        </div>
                      ))}
                      {filteredTest.length === 0 && (
                        <div className='col-12 main-heading'>
                          Sorry, no tests found.
                        </div>
                      )}
                    </div>
                    {testCount > 6 && (
                      <div className='pagi'>
                        <Pagination
                          onPageChange={(pageno) =>
                            this.handlePaginationPress(pageno)
                          }
                          totalCount={this.state.testCount}
                          pageSize={this.state.limit}
                          currentPage={this.state.pagination}
                          siblingCount={1}
                          loadingPagi={this.state.loadingPagi}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <ReqOrderCall />
        <Achievements />
        <Faq />

        <MblFilterModal
          showFilterModal={showFilterModal}
          setFilterModal={this.setFilterModal}
          filterType={filterType}
          oldNewSlug={this.state.searchBySort}
          handleOldNew={this.handleOldNew}
          fetchInitialTests={this.fetchInitialTests}
          handleReset={this.handleReset}
          categories={categories}
          labs={labs}
          handleCategory={this.handleCategoryMbl}
          categorySlug={this.state.searchCategory}
          locationSlug={this.state.searchArea}
          handleCity={this.handleAreaMbl}
          labSlug={this.state.searchLabs}
          handleLabs={this.handleLabMbl}
          applyMobileFilter={this.applyMobileFilter}
        />

        <HomeDiagnostic
          diagModal={this.state.diagModal}
          setDiagModal={this.setDiagModal}
          testDetails={this.state.testDetails}
          patientModal={this.state.patientModal}
          setPatientModal={this.setPatientModal}
          goToFirstModal={this.goToFirstModal}
          goToSecondModal={this.goToSecondModal}
        />
      </div>
    );
  }
}

export default Category;
Category.contextType = AuthContext;
