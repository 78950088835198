const Hospital = ({ hospital, setTestItem, testItem }) => {
  const handleRadio = (e) => {
    // //console.log(e);
    setTestItem(e);
  };

  const calculatePercentage = (object) => {
    const value =
      (parseInt(object.purchasable_order_item.sell_price) /
        parseInt(object.purchasable_order_item.price)) *
      100;

    const percentage = (100 - value).toString();
    return parseInt(percentage);
  };

  return (
    <>
      {hospital && hospital.branch && hospital.branch.lab && (
        <div className='single'>
          <label className='radio'>
            <div className='all'>
              <div className='hospital'>
                <div className='logo'>
                  {hospital && hospital.branch && hospital.branch.lab && (
                    <img src={hospital.branch.lab.logo || ''} alt='' />
                  )}
                </div>
                <div className='name'>
                  {hospital && hospital.branch && hospital.branch.lab && (
                    <h5>{hospital.branch.lab.name || ''}</h5>
                  )}
                  <p>Branch: {hospital.location.name || ''}</p>
                </div>
              </div>
              {parseInt(hospital.purchasable_order_item.price) >
                parseInt(hospital.purchasable_order_item.sell_price) && (
                <div className='wever'>
                  <span className='btn btn-success'>
                    {`- ${calculatePercentage(hospital)}%`}
                  </span>
                </div>
              )}
              <div className='price'>
                {parseInt(hospital.purchasable_order_item.price) >
                  parseInt(hospital.purchasable_order_item.sell_price) && (
                  <div className='old'>
                    ৳{' '}
                    {hospital && hospital.purchasable_order_item
                      ? hospital.purchasable_order_item.price
                      : ''}
                  </div>
                )}
                <div className='new'>
                  ৳{' '}
                  {hospital &&
                  hospital.purchasable_order_item &&
                  hospital.purchasable_order_item.sell_price
                    ? hospital.purchasable_order_item.sell_price
                    : ''}
                </div>
              </div>
            </div>
            <input
              type='radio'
              name='radio'
              onChange={() => handleRadio(hospital)}
              checked={hospital === testItem}
            />
            <span className='checkmark' />
          </label>
        </div>
      )}
    </>
  );
};

export default Hospital;
