import axios from 'axios';
import moment from 'moment';
import { createContext, Component } from 'react';
import { isAndroid, isDesktop } from 'react-device-detect';

export const AuthContext = createContext();

class AuthContextProvider extends Component {
  state = {
    isLoggedIn: false,
    userId: null,
    apiKey: null,
    carts: [],
    suMyself: [],
    suFamily: [],
    pSTotal: null,
    isLocalLab: false,

    showLoginModal: false,
    showSignupModal: false,
    userDetails: null,
    userDetailsBroad: null,
    userAddress: null,

    navAreaName: 'Dhaka',
    deviceType: 'desktop',
    showAppRecomendation: false,
    labDiscountArray: null,

    lastCartPatient: [],
  };

  checkDeviceType = () => {
    const shownApp = localStorage.getItem('show-app');
    if (shownApp) {
      const data = JSON.parse(shownApp);
      console.log(data);
    } else {
      if (isAndroid) {
        this.setState({ showAppRecomendation: true });
      }
    }
  };

  getLabDiscount = (callback) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab-discount-get/`)
      .then((resp) => {
        // console.log(resp.data.results);
        // this.calculateLabDiscount(resp.data.results);
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length !== 0) {
            // console.log(data);
            this.setState({ labDiscountArray: data });
            // this.calculateLabDiscount(data);
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log('come here 4');
      });
  };

  setPageTitle = (name) => {
    document.title = `${name} | Amarlab`;
  };

  setIsLoggedIn = (val) => {
    this.setState({ isLoggedIn: val });
  }

  postActivity = (type, query, url) => {
    // console.log(type, query, url);
    const visitor = localStorage.getItem('id_v');
    const user_id = localStorage.getItem('user_id');
    const data = {
      user: user_id ? user_id : null,
      visitor: visitor ? visitor : null,
      query_type: type,
      query: query,
      url: url,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user_management/activity/`, data)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  postVisitor = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user_management/visitor/`)
      .then((resp) => {
        if (!resp.data.id) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/user_management/visitor/?ip_address=${resp.data.ip_address}`
            )
            .then((resp) => {
              if (resp.data.results.length !== 0) {
                // console.log('here', resp.data.results[0]);
                localStorage.setItem(
                  'id_v',
                  JSON.parse(resp.data.results[0].id)
                );
              }
            })
            .catch((error) => {
              // console.log(error.response);
            });
        } else {
          // console.log(resp.data);
          localStorage.setItem('id_v', JSON.parse(resp.data.id));
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  fetchUserDetails = () => {
    const userId = localStorage.getItem('user_id');
    const apiKey = localStorage.getItem('token');
    axios.interceptors.request.use((config) => {
      const token = 'Token ' + JSON.parse(apiKey);
      config.headers.Authorization = token;
      return config;
    });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
      .then((resp) => {
        this.setUserDetails(resp.data);
        
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/user_management/user/${resp.data.pk}/?id=${resp.data.pk}`
          )
          .then((userData) => {
            this.userDetailsBroad(userData.data);
            this.fetchPatients(userId);
          })
          .catch((err) => {
            console.log(1)});
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          this.unauthorized();
        }
      });
  };

  fetchPatients = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL
        }/user_management/patient/?user=${JSON.parse(id)}&limit=300`
      )
      .then((resp) => {
        const results = resp.data.results;
        const myself = results.filter((item) => {
          return item.is_account;
        });
        const family = results
          .filter((item) => {
            return item.is_account === false;
          })
          .filter((patient) => {
            return patient.is_active;
          });
        // console.log(family);
        this.setState({ suMyself: myself });
        this.setState({ suFamily: family });

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${myself[0].id}&user=${myself[0].id}`
          )
          .then((resp) => {
            this.setUserAddress(resp.data.results);
          });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.unauthorized();
        }
      });
  };

  setUserAddress = (value) => {
    this.setState({ userAddress: value });
  };

  setNavAreaName = (value) => {
    this.setState({ navAreaName: value });
  };

  forLogout = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 401) {
          // localStorage.clear();
          // sessionStorage.clear();
          // // this.props.history.push('/login');
          // window.location.href = '/login';
          // // window.location.href = '/';
          this.unauthorized();
        }
      });
  };

  componentDidMount() {
    this.postVisitor();
    const userId = localStorage.getItem('user_id');
    const apiKey = localStorage.getItem('token');
    const getCarts = localStorage.getItem('order');
    const labName = localStorage.getItem('lab_name');
    const navAreaName = localStorage.getItem('navAreaName');
    const lastCartPat = localStorage.getItem('lastCartPatient');

    this.checkDeviceType();

    if (navAreaName) {
      this.setNavAreaName(JSON.parse(navAreaName));
    }

    if (getCarts && userId) {
      const allCarts = JSON.parse(getCarts);
      if (allCarts.user.pk === JSON.parse(userId)) {
        this.setState({ carts: allCarts.order_item });

        if (labName) {
          this.setState({ isLocalLab: true });
        }
        if (lastCartPat) {
          this.setLastCartPatient(JSON.parse(lastCartPat));
        }
      }
    }

    if (apiKey) {
      axios.interceptors.request.use((config) => {
        const token = 'Token ' + JSON.parse(apiKey);
        config.headers.Authorization = token;
        return config;
      });
      this.forLogout();
      this.setState({ apiKey: JSON.parse(apiKey) });
      this.getLabDiscount();
    }

    if (userId && apiKey) {
      this.setState({ userId: JSON.parse(userId) });
      this.setState({ isLoggedIn: true });

      // this.fetchPatients(userId);

      // axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user/`).then((resp) => {
      //   this.setUserDetails(resp.data);
      //   // //console.log(resp.data);
      // });
      this.fetchUserDetails();
    }

    // apiKey
    //   ? this.setState({ apiKey: JSON.parse(apiKey) })
    //   : //console.log('no api key');
  }

  setUserDetails = (value) => {
    this.setState({ userDetails: value });
  };

  setLastCartPatient = (array) => this.setState({ lastCartPatient: array });

  userDetailsBroad = (value) => {
    this.setState({ userDetailsBroad: value });
  };

  setShowLoginModal = (value) => {
    this.setState({ showLoginModal: value });
  };

  setShowSignupModal = (value) => {
    this.setState({ showSignupModal: value });
  };

  setIsLocalLab = (d) => {
    this.setState({ isLocalLab: d });
  };

  setUserMyself = (array) => {
    this.setState({ suMyself: array });
  };

  setUserFamily = (array) => {
    this.setState({ suFamily: array });
  };

  setUserNewFamily = (array) => {
    this.setState({ suFamily: [array, ...this.state.suFamily] });
  };

  addUserFamily = (array) => {
    this.setState({ suFamily: [array, ...this.state.suFamily] });
  };

  toggleLoggedIn = () => {
    this.setState({ isLoggedIn: !this.state.isLoggedIn });
  };

  setCartsItem = (array) => {
    this.setState({ carts: [...array] });
  };

  setCartsItemAdd = (array) => {
    this.setState({ carts: [...array, ...this.state.carts] });
  };

  clearCart = () => {
    this.setState({ carts: [] });
  };

  setUserId = (id) => {
    this.setState({ userId: id });
  };

  setPaymentSuccessTotal = (data) => {
    this.setState({ pSTotal: data });
  };

  setInterceptors = (key) => {
    axios.interceptors.request.use((config) => {
      const token = 'Token ' + key;
      config.headers.Authorization = token;
      return config;
    });
  };

  unauthorized = () => {
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.removeItem('user_id');
    this.setState({ isLoggedIn: false });
    window.location.href = '/';
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          toggleLoggedIn: this.toggleLoggedIn,
          setInterceptors: this.setInterceptors,
          setUserId: this.setUserId,
          setCartsItem: this.setCartsItem,
          clearCart: this.clearCart,
          setCartsItemAdd: this.setCartsItemAdd,
          setUserMyself: this.setUserMyself,
          setUserFamily: this.setUserFamily,
          addUserFamily: this.addUserFamily,
          setPaymentSuccessTotal: this.setPaymentSuccessTotal,
          setUserNewFamily: this.setUserNewFamily,
          unauthorized: this.unauthorized,
          setIsLocalLab: this.setIsLocalLab,
          setShowLoginModal: this.setShowLoginModal,
          setShowSignupModal: this.setShowSignupModal,
          setUserDetails: this.setUserDetails,
          setUserAddress: this.setUserAddress,
          fetchPatients: this.fetchPatients,
          fetchUserDetails: this.fetchUserDetails,
          setNavAreaName: this.setNavAreaName,
          postActivity: this.postActivity,
          setPageTitle: this.setPageTitle,
          setLastCartPatient: this.setLastCartPatient,
          setIsLoggedIn: this.setIsLoggedIn,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
