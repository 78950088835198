const Contact = () => {
  return (
    <div className='address'>
      <h1 className='main-heading'>Address</h1>
      <div className='row'>
        <div className='col-md-6 details'>
          <div className='location'>
            <p>
              <span>Office:</span> Level 3, House-15, Road-105, Gulshan-2, Dhaka-1212
              {/*Level # 6, House # 35, Road # 07,8*/}
            </p>
            {/*<p>Block # G, Banani, Dhaka - 1213.</p>*/}
          </div>
          <div className='phone'>
            <p>
              <span>Phone:</span> <a href='tel:09643445566'>09643445566</a>
            </p>
          </div>
          <div className='email'>
            <p>
              <span>Email:</span> info@amarlab.com
            </p>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='embed-responsive embed-responsive-16by9'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d912.7055277678799!2d90.40361072923601!3d23.789348126193048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7bddc6822bf%3A0xabd542a7a6dd770b!2sAmarLab!5e0!3m2!1sen!2sbd!4v1644836127274!5m2!1sen!2sbd'
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading='lazy'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
