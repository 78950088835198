import axios from 'axios';
import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import Blog from './Blog';

const BlogCarousel = () => {
  const [Blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://amarlab.com/eng/blog/wp-json/wp/v2/posts?_fields[]=title&_fields[]=excerpt&_fields[]=featured_media&_fields[]=author&_fields[]=link&_fields[]=categories&per_page=2`
      )
      .then((resp) => {
        // console.log(resp.data);
        setBlogs(resp.data);
      });
  }, []);

  return (
    <>
      {Blogs && (
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={false}
          autoPlaySpeed={3000}
          centerMode={false}
          className=''
          containerClass='container carousel-container'
          dotListClass=''
          draggable
          focusOnSelect={false}
          infinite
          itemClass='test'
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 991,
              },
              items: 2,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 575,
                min: 0,
              },
              items: 1.6,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 991,
                min: 575,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=''
          slidesToSlide={1}
          swipeable
        >
          {Blogs.map((blog, i) => (
            <Blog key={i} blog={blog} />
          ))}
        </Carousel>
      )}
    </>
  );
};

export default BlogCarousel;
