import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

class PrivacyPolicy extends Component {
  state = {};

  componentDidMount() {
    this.context.setPageTitle('Privacy Policy');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <section className='privacy-policy'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <p>
                  This policy will help you understand how we use and protect
                  your data. If you have any questions, feel free to contact us
                  at{' '}
                  <a className='text-primary' href='mailto:info@amarlab.com'>
                    info@amarlab.com
                  </a>
                  . Thank you so much for choosing AmarLab.
                  <br />
                  <br />
                  AmarLab Ltd. (AmarLab) has a website and a patient portal
                  application, which is web and mobile-enabled (available on
                  Android and iOS) (sometimes collectively referred to as the
                  “Services”). <br />
                  <br />
                  The website provides information related to AmarLab, its
                  services, and health in general. <br />
                  <br />
                  The patient portal allows users to book appointments, receive
                  diagnostic test results, and view medical records, among other
                  features. <br />
                  <br />
                  The privacy notice applies to the website and the application,
                  which are owned and operated by AmarLab. The privacy notice
                  describes how AmarLab collects and uses personal information
                  provided by those who visit the website and the application.
                  It also describes the options available to users and visitors
                  regarding AmarLab’s use of personal information. <br />
                  <br />
                  These policies may be changed at any given time at the
                  management’s discretion, in which case the revised privacy
                  notice will only apply to information collected after the date
                  the revised policy is posted. <br />
                  <br />
                  The effective date of this privacy notice is set forth at the
                  top of this webpage. We will notify you of any material
                  changes by posting a notice on this webpage. Your continued
                  use of the Services after the effective date constitutes your
                  acceptance of the amended privacy notice. We encourage you to
                  periodically review this page for the latest information on
                  our privacy practices. Any amended privacy notice supersedes
                  all previous versions. <br />
                  <br />
                  IF YOU DO NOT AGREE TO FUTURE CHANGES TO THIS PRIVACY NOTICE,
                  YOU MUST STOP USING THE SERVICES AFTER THE EFFECTIVE DATE OF
                  SUCH CHANGES. <br /> <br />
                  By accessing the website and application, you agree that you
                  have read and understood and agree to the terms and conditions
                  of this privacy notice.
                </p>
                <h5 className='main-heading'>AmarLab Policy</h5>
                <p>
                  We highly value your privacy and share your concerns regarding
                  any information you provide to us when you visit us online. We
                  recommend that you read this entire privacy notice as you
                  choose to navigate through our website and patient portal.
                </p>
                <h5 className='main-heading'>Personal Information</h5>
                <p>
                  “Personal Information” refers to information that allows us to
                  identify you, which may include, without limitation, your full
                  name, contact number, e-mail address, gender, date of birth,
                  zip code, insurance data, medical data, and other identifying
                  information you voluntarily choose to provide to us (e.g.,
                  passwords, emails, letters, etc.). <br />
                  <br /> If you choose to make a payment to AmarLab either via a
                  mobile payment gateway or through the patient portal
                  integrated on the website, the required financial information
                  will be collected by a third-party service provider. Such
                  partners’ use and storage of information are governed by its
                  applicable terms of service and privacy notice. The
                  information we store includes your payment card type and the
                  last four digits of the payment card. We understand the degree
                  of confidentiality associated with financial information and
                  our policy is geared towards assuring it.
                  <br /> <br />
                  Some third parties, such as our business partners and service
                  providers, provide us with Personal Information about you,
                  such as the following: <br />
                  <br />
                  <ul>
                    <li>
                      <b>Account information for third party services:</b> our
                      Services, such as if you use third-party service to log in
                      to our Services (e.g., Facebook or Google), or if you
                      share content from our Services through a third party
                      social media service, the third party service will send us
                      certain information about you if the third-party service
                      and your account settings allow such sharing. The
                      information we receive will depend on the policies and
                      your account settings with the third-party service. You
                      understand the information transmitted to us is covered by
                      this privacy notice; for example, the basic information we
                      receive from Facebook about you.
                    </li>
                  </ul>{' '}
                  <br />
                  Some information, which may include Personal Information, is
                  automatically collected when you use our Services, such as the
                  following: <br />
                  <br />
                  <ul>
                    <li>
                      <b>Traffic Data:</b> We also may automatically collect
                      certain data when you use the Services, such as (1) IP
                      address; (2) domain server; (3) type of device(s) used to
                      access the Services; (4) web browser(s) used to access the
                      Services; (5) referring webpage or other sources through
                      which you accessed the Services; (6) geolocation
                      information; and (7) other statistics and information
                      associated with the interaction between your browser or
                      device and the Services (collectively “Traffic Data”).
                      Depending on applicable law, some Traffic Data may be
                      Personal Information.
                    </li>
                  </ul>{' '}
                  <br />
                  You may also browse our website without disclosing any
                  personal information. However, your authentication information
                  will be required to register or log in to use the patient
                  portal services. <br /> <br />
                  Online payments will be processed by a third-party service
                  provider and you may read the section below entitled “Links
                  and Third Party Websites”and familiarize yourself with the
                  privacy terms of any non-AmarLab websites before using them.
                </p>
                <h5 className='main-heading'>How We Collect Information</h5>
                <p>
                  We collect information (including Personal Information and
                  Traffic Data) when you use and interact with the Services, and
                  in some cases from third-party sources. Such means of the
                  collection include:
                  <br />
                  <br />
                  <ul>
                    <li>
                      When you use the Services’ interactive tools and services,
                      such as searching for doctors or other healthcare
                      providers (“Healthcare Providers”), searching for
                      available appointments with Healthcare Providers,
                      participating in live chats, sending queries or requests,
                      or subscribing to AmarLab newsletter;
                    </li>
                    <li>
                      When you voluntarily provide information in free-form text
                      boxes through the Services or through responses to
                      surveys, questionnaires and the like;
                    </li>
                    <li>
                      If you download and install certain applications and
                      software we make available, we may receive and collect
                      information transmitted from your computing device for the
                      purpose of providing you the relevant Services, such as
                      information regarding when you are logged on and available
                      to receive updates or alert notices;
                    </li>
                    <li>
                      If you download our mobile application or use a
                      location-enabled browser, we may receive information about
                      your location and mobile device, as applicable;
                    </li>
                    <li>
                      Through cookies, web beacons, analytics services ,and
                      other tracking technology; and
                    </li>
                    <li>
                      When you use the “Contact Us” function on the website,
                      send us an email or otherwise contact us.
                    </li>
                  </ul>
                </p>
                <h5 className='main-heading'>How We Use Your Information</h5>
                <p>
                  We may use information that is not Personal Information to
                  better understand who uses AmarLab and how we can deliver a
                  better healthcare experience (for example, testing different
                  kinds of emails helps us understand when and how patients
                  prefer to get reminders for preventive care), or otherwise at
                  our discretion. <br />
                  <br />
                  We use information, including Personal Information, to provide
                  the Services and to help improve the Services, to develop new
                  services, and to advertise (for example, to display AmarLab
                  ads on other web services). Specifically, such use may
                  include:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Providing you with the products, services, and information
                      you request;
                    </li>
                    <li>
                      Responding to correspondence that we receive from you;
                    </li>
                    <li>
                      Contacting you when necessary or requested, including to
                      remind you of an upcoming appointment;
                    </li>
                    <li>
                      Providing, maintaining, administering, or expanding the
                      Services, performing business analyses, or for other
                      internal purposes to support, improve or enhance our
                      business, the Services, and other products and services we
                      offer;
                    </li>
                    <li>
                      Customizing or tailoring your experience of the Services;
                    </li>
                    <li>
                      Notifying you about certain resources, Healthcare
                      Providers ,or services we think you may be interested in
                      learning more about;
                    </li>
                    <li>
                      Sending you information about AmarLab or our products or
                      Services;
                    </li>
                    <li>
                      Sending emails and other communications that display
                      content that we think will interest you and according to
                      your preferences;
                    </li>
                    <li>
                      Combining information received from third parties with
                      information that we have from or about you and using the
                      combined information for any of the purposes described in
                      this privacy notice;
                    </li>
                    <li>
                      Showing you advertisements, including interest-based or
                      online behavioral advertising;
                    </li>
                    <li>
                      Using statistical information that we collect in any way
                      permitted by law, including from third parties in
                      connection with their commercial and marketing efforts;
                    </li>
                    <li>
                      Fulfilling our legally required obligations, such as
                      preventing, detecting, and investigating security
                      incidents and potentially illegal or prohibited
                      activities;
                    </li>
                    <li>Resolving disputes;</li>
                    <li>
                      Protecting against or deterring fraudulent, illegal, or
                      harmful actions; and
                    </li>
                    <li>Enforcing our Terms of Use and other agreements.</li>
                  </ul>
                </p>
                <h5 className='main-heading'>How We Share Your Information</h5>
                <p>
                  In certain circumstances, and in order to perform the
                  Services, we may share certain information that we collect
                  from you, as described in this section: <br /> <br />
                  <ul>
                    <li>
                      <b>Representatives:</b> We may share your Personal
                      Information and/or Traffic Data with agents, contractors
                      or affiliates of ours who help us operate this site (e.g.,
                      hosting, billing, fulfillment, data storage, security,
                      insurance verification, web service analytics, or ad
                      serving), conduct our business or help us complete a
                      service you requested.
                    </li>
                    <li>
                      <b>Healthcare Providers:</b> We may share your Personal
                      Information with healthcare providers with whom you choose
                      to schedule through the Services. We may share your
                      Personal Information with healthcare providers in the
                      event of an emergency.
                    </li>
                    <li>
                      <b>Cross-Device Matchin:</b> To determine if users have
                      interacted with content across multiple devices and to
                      match such devices, we may work with partners who analyze
                      device activity data and/or rely on your information
                      (including demographic, geographic, and interest-based
                      data). To supplement this analysis, we may also provide
                      de-identified data to these partners. Based on this data,
                      we may then display targeted advertisements across devices
                      that we believe are associated or use this data to further
                      analyze usage of Services across devices.
                    </li>
                    <li>
                      We may share your Personal Information with the insurance
                      provider you identify with us (and do so via our partners)
                      to determine eligibility and cost-sharing obligations, or
                      otherwise, obtain a benefit plan and other
                      insurance-related information on your behalf.
                    </li>
                    <li>
                      <b>Business Transfers:</b> We may transfer your Personal
                      Information to another company in connection with a
                      proposed merger, sale, acquisition, or other change of
                      ownership or control by or of AmarLab (whether in whole or
                      in part). Should one of these events occur, we will make
                      reasonable efforts to notify you before your information
                      becomes subject to different privacy and security policies
                      and practices.
                    </li>
                    <li>
                      <b>Public Information and Submissions:</b> You agree that
                      any information that you may reveal in a review posting,
                      online discussion, or forum is intended for the public and
                      is not in any way private. Carefully consider whether to
                      disclose any Personal Information in any public posting or
                      forum. Your submissions may be seen and/or collected by
                      third parties and may be used by others in ways we are
                      unable to control or predict.
                    </li>
                    <li>
                      <b>Protection of AmarLab and Others:</b> We also may need
                      to disclose your Personal Information or any other
                      information we collect about you if we determine in good
                      faith that such disclosure is needed to: (1) comply with
                      or fulfill our obligations under applicable law,
                      regulation, court order or other legal processes; (2)
                      protect the rights, property or safety of you, AmarLab or
                      another party; (3) enforce any agreement with you; or (4)
                      respond to claims that any posting or other content
                      violates third-party rights.
                    </li>
                    <li>
                      <b>Other Information:</b> We may disclose information that
                      is not Personal Information (including information that
                      has been de-identified and/or aggregated) at our
                      discretion.
                    </li>
                  </ul>
                </p>
                <h5 className='main-heading'>Links and Third-Party Websites</h5>
                <p>
                  This policy strictly applies to information collected by
                  AmarLab. AmarLab’s website contains hyperlinks or links to
                  other websites, including access to content, products, and
                  services of our affiliated and non-affiliated websites. We
                  urge you to familiarize yourself with the individual privacy
                  policy and other terms for each third-party website prior to
                  submitting any personal information. We cannot be held liable
                  for data collection, privacy, and information sharing policies
                  and procedures, or the content of any non-AmarLab websites.
                </p>
                <h5 className='main-heading'>Data Storage and Retention</h5>
                <p>
                  AmarLab maintains strict data protection standards and has
                  three-layered security protection in place. Your information
                  is stored in secure operating environments that are not
                  available to the public or all AmarLab staff. All of AmarLab
                  staff and affiliates are dedicated to maintaining and
                  upholding your privacy and security and are fully aware of our
                  privacy and security policies. AmarLab will retain your
                  information for as long as your account is active or as needed
                  to provide you services. We may retain and use your
                  information if necessary to comply with legal obligations,
                  resolve dispute resolutions, prevent fraud, enforce
                  agreements, or as otherwise permitted by applicable law.{' '}
                  <br />
                  <br />
                  Your account is protected by a password for your privacy and
                  security. If you access your account via a third-party site or
                  service, you may have additional or different sign-on
                  protections via that third-party site or service. You must
                  prevent unauthorized access to your account and Personal
                  Information by selecting and protecting your password and/or
                  other sign-on mechanisms appropriately, and limiting access to
                  your computer or device and browser by signing off after you
                  have finished accessing your account. <br /> <br />
                  Although we make good faith efforts to store Personal
                  Information in a secure operating environment that is not open
                  to the public, we do not and cannot guarantee the security of
                  your Personal Information. If at any time during or after our
                  relationship we believe that the security of your Personal
                  Information may have been compromised, we may seek to notify
                  you of that development. If a notification is appropriate, we
                  will endeavor to notify you as promptly as possible under the
                  circumstances. If we have your e-mail address, we may notify
                  you by e-mail to the most recent e-mail address you have
                  provided us in your account profile. Please keep your e-mail
                  address in your account up to date. You can update that e-mail
                  address anytime in your account profile. If you receive a
                  notice from us, you can print it to retain a copy of it. To
                  receive these notices, you must check your e-mail account
                  using your computer or mobile device and email application
                  software.
                </p>
                <h5 className='main-heading'>
                  Information Provided on Behalf of Children and Others
                </h5>
                <p>
                  The Services are not intended for use by children and children
                  under the age of 13 are prohibited from using the Services.
                  AmarLab does not knowingly collect any information from
                  children, nor are the Services directed to children. If you
                  are under 13, please do not attempt to register for the
                  Services or send any Personal Information about yourself to
                  us. <br />
                  <br /> By accessing, using, and/or submitting information to
                  or through the Services, you represent that you are not
                  younger than age 13. If we learn that we have received any
                  information directly from a child under age 13 without his/her
                  parent’s written consent, we will use that information only to
                  respond directly to that child (or his/her parent or legal
                  guardian) to inform the child that he/she cannot use the
                  Services, and we will subsequently delete that information. If
                  you believe that a child under 13 may have provided us with
                  Personal Information, please contact us at{' '}
                  <a className='text-primary' href='mailto:info@amarlab.com'>
                    info@amarlab.com
                  </a>
                  .
                  <br /> <br />
                  If you are between age 13 and the age of majority in your
                  place of residence, you may use the Services only with the
                  consent of or under the supervision of your parent or legal
                  guardian. If you are a parent or legal guardian of a minor
                  child, you may, in compliance with the Agreement, use the
                  Services on behalf of such minor child. Any information that
                  you provide us while using the Services on behalf of your
                  minor child will be treated as Personal Information as
                  otherwise provided herein. <br /> <br />
                  If you use the Services on behalf of another person,
                  regardless of age, you agree that AmarLab may contact you for
                  any communication made in connection with providing the
                  Services or any legally required communications. You further
                  agree to forward or share any such communication with any
                  person on whose behalf you are using the Services.
                </p>
                <h5 className='main-heading'>Consent to Processing</h5>
                <p>
                  Subject to your opt-out choices and all of the provisions
                  contained in this notice, you hereby consent to the
                  collection, processing, disclosure, and transfer by AmarLab of
                  your personally identifiable information for the limited
                  purposes described under this notice. Your consent relating to
                  a right to transfer information referred to in this paragraph
                  shall be deemed to include your consent to the transfer of
                  your personally identifiable information to jurisdictions that
                  may have different levels of privacy protection than that
                  available to you under your local law.
                </p>
                <h5 className='main-heading'>Cookies</h5>
                <p>
                  Our website may use cookies to track information and usage
                  behavior to better understand our user base. This helps us to
                  deliver better information and services, improve the content
                  and administration of our website, and generally enhance your
                  online experience when visiting our website. Cookies also help
                  us to diagnose problems with our server. We only use cookie
                  technology to compile aggregated data about your visits to our
                  website and not to obtain personal information.
                </p>
                <h5 className='main-heading'>Opt-Out or Unsubscribe</h5>
                <p>
                  If you are receiving regular emails or messages (such as a
                  subscription to our online newsletter) through our website or
                  application, you may request to discontinue receiving them.
                  All communications sent to you will have an opt-out or
                  unsubscribe option. Please note that you cannot opt-out or
                  unsubscribe from certain messages such as an email letting you
                  know that a doctor has sent you a secure message, or reports
                  of your diagnostic tests.
                </p>
                <h5 className='main-heading'>
                  Intellectual Property and Copyright
                </h5>
                <p>
                  All the information displayed, transmitted, or carried by
                  AmarLab and its related websites are copyright protected. The
                  content is owned by AmarLab, its affiliates, its vendors, and
                  third-party licensors, and may not be used as a substitute for
                  professional medical advice or for solicitation of business.
                  By reading this notice you agree to abide by all copyright
                  notices and restrictions attached to any content accessed
                  through the website.
                </p>
                <h5 className='main-heading'>
                  Evaluation and Quality Improvement
                </h5>
                <p>
                  AmarLab believes in improving service quality through the
                  collective effort of all stakeholders. We may periodically ask
                  users to complete surveys asking about their experience using
                  our website and application. The surveys may ask for
                  demographic information (such as age, gender, and education),
                  but will not request to provide specific information about any
                  medical condition. Information collected through the survey
                  will be strictly used for the purposes of evaluation and
                  quality improvement with an aim to provide a better user
                  experience. In addition, users who provide feedback may be
                  individually contacted for follow-ups on instances of concerns
                  raised.
                </p>
                <h5 className='main-heading'>Other Terms</h5>
                <p>
                  Your use of our website is subject to and contingent upon your
                  agreement with all other terms and conditions. Aside from as
                  described in this notice, AmarLab will not use your
                  information for any other purposes without your explicit
                  authorization. If you have any questions regarding this
                  notice, please email us at{' '}
                  <a className='text-primary' href='mailto:info@amarlab.com'>
                    info@amarlab.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PrivacyPolicy;
PrivacyPolicy.contextType = AuthContext;
