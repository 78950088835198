import React, { Component } from 'react';
// import GLogo from '../assets/img/google-logo.png';
// import FLogo from '../assets/img/facebook-logo.png';
import Eye from '../assets/img/eye.svg';
import EyeOff from '../assets/img/eye-off.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import PatientDob from '../components/PatientDob';
import moment from 'moment';
import LoadingBtn from '../components/LoadingBtn';

class SignUp extends Component {
  state = {
    number: '',
    password: '',
    cPassword: '',
    name: '',
    dob: null,
    gender: '',
    showStep: 'first',
    showPassword: false,
    showPasswordC: false,
    otp: '',
    disableOtp: false,
    loadingFirst: false,
    loadingSecond1: false,
    loadingSecond2: false,
    loadingThird: false,
  };

  getLastName = (fullName) => {
    let parts = fullName.split(' ');
    // let firstName = parts.shift();
    // let lastName = parts.join(' ');

    parts.shift();
    return parts.join(' ');
  };

  updateUserProfile = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/${id}/?id=${id}`
      )
      .then((resp) => {
        // console.log(resp.data);
        const putUser = {
          username: resp.data.username,
          password: resp.data.password,
          first_name: this.state.name.split(' ').shift(),
          last_name: this.getLastName(this.state.name),
        };
        // console.log(putUser);

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/user_management/user/${id}/?id=${id}`,
            putUser
          )
          .then((resp) => {
            // console.log(resp.data);
          })
          .catch((err) => {
            // console.log(err.response);
          });
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  calculateMinutes = (oldTime) => {
    const now = new Date();
    const difference = now.getTime() - oldTime.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
  };

  componentDidMount() {
    this.context.setPageTitle('Signup');
    window.scrollTo(0, 0);
    const existing = localStorage.getItem('otp-details');
    const token = localStorage.getItem('token');
    token ? this.props.history.push('/') : console.log('Not logged in');

    if (existing) {
      const data = JSON.parse(existing);
      // //console.log(data);
      const minutes = this.calculateMinutes(new Date(data.created_at));
      // //console.log(minutes);

      if (1440 < minutes) {
        //console.log('clear localstorage');
        localStorage.clear();
        this.setState({ disableOtp: false });
      } else if (1440 > minutes && data.counter < 3) {
        // //console.log('Continue');
        this.setState({ disableOtp: false });
      } else {
        this.setState({ disableOtp: true });
      }
    } else {
      this.setState({ disableOtp: false });
    }
  }

  otpDisableFunction = () => {
    setTimeout(() => {
      this.setState({ disableOtp: false });
    }, 30000);
  };

  handleFirstStep = (e) => {
    e.preventDefault();
    // let numberPattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(this.state.number)) {
      toast.error('Please enter a valid Bangladeshi number!', {
        autoClose: 3000,
      });
    } else {
      this.setState({ loadingFirst: true });
      this.setState({ disableOtp: true });
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/send-otp/${this.state.number}`
        )
        .then((resp) => {
          // //console.log(resp.data);
          this.setState({ showStep: 'second' });
          toast.success(`OTP has been sent to your mobile, please check.`, {
            autoClose: 3000,
          });
          this.otpDisableFunction();
          this.setState({ loadingFirst: false });
        })
        .catch((error) => {
          if (error.response.data && error.response.data.sms) {
            toast.error(
              'Sorry, user already exists, please try again with another phone number.'
            );
            this.setState({ disableOtp: false });
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
            this.setState({ disableOtp: false });
          }
          // console.log(error.response);
          this.setState({ loadingFirst: false });
        });
    }
  };

  handleSecondStep = (e) => {
    e.preventDefault();
    if (this.state.otp === '') {
      toast.error(`Please enter OTP.`, {
        autoClose: 3000,
      });
    } else {
      this.setState({ loadingSecond2: true });
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/match-otp/?mobile=${this.state.number}&page=1&limit=1&ofset=0`
        )
        .then((resp) => {
          const dbCode = resp.data.results[0].code;
          // //console.log(dbCode);
          this.setState({ loadingSecond2: false });
          if (dbCode === parseInt(this.state.otp)) {
            this.setState({ showStep: 'third' });
          } else if (dbCode !== parseInt(this.state.otp)) {
            toast.error(`OTP doesn't matched, please try again.`, {
              autoClose: 3000,
            });
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          //console.log(error.response.status);
          //console.log(error.response.data);
          this.setState({ loadingSecond2: false });
        });
    }
  };

  handleThirdStep = (e, toggleLoggedIn, setUserMyself) => {
    e.preventDefault();

    if (this.state.name.trim() === '') {
      toast.error('Please enter your name!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.name.trim().length < 4) {
      toast.error('Name must be at least 4 character.', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.dob === '' || this.state.dob === null) {
      toast.error('Please select your date of birth.', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.gender === '') {
      toast.error('Please select your gender.', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.password === '') {
      toast.error('Please enter a password!', {
        autoClose: 3000,
      });
    } else if (this.state.cPassword === '') {
      toast.error('Please enter confirm password!', {
        autoClose: 3000,
      });
    } else if (this.state.cPassword !== this.state.password) {
      toast.error('Password and confirm password not same!', {
        autoClose: 3000,
      });
    } else {
      this.setState({ loadingThird: true });
      const postData = {
        username: this.state.number,
        password1: this.state.password,
        password2: this.state.password,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/registration`, postData)
        .then((resp) => {
          // //console.log(resp.data);
          this.setState({ loadingThird: false });
          axios.interceptors.request.use((config) => {
            const token = 'Token ' + resp.data.key;
            config.headers.Authorization = token;
            return config;
          });
          localStorage.clear();
          localStorage.setItem('token', JSON.stringify(resp.data.key));

          axios
            .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
            .then((resp) => {
              // //console.log(resp.data);
              this.context.setUserDetails(resp.data);

              this.updateUserProfile(resp.data.pk);
              this.context.setUserId(resp.data.pk);
              this.context.setIsLoggedIn(true);

              const patientData = {
                is_account: true,
                user: resp.data.pk,
                full_name: this.state.name,
                sex: this.state.gender,
                dob: moment(this.state.dob).format('YYYY-MM-DD'),
                mobile: this.state.number,
              };
              localStorage.removeItem('user_id');
              localStorage.setItem('user_id', JSON.stringify(resp.data.pk));

              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
                  patientData
                )
                .then((resp) => {
                  // console.log(resp.data);
                  this.props.history.push('/');
                  this.props.loginHandler(true);
                  toggleLoggedIn();
                  const mySelf = [resp.data];
                  setUserMyself(mySelf);
                  localStorage.setItem('userPatients', JSON.stringify(mySelf));

                  const getLoginAfterData =
                    sessionStorage.getItem('loginAfterData');
                  if (getLoginAfterData) {
                    const details = JSON.parse(getLoginAfterData);
                    this.props.history.push(details.location);
                  } else {
                    this.props.history.push('/');
                  }
                })
                .catch((error) => {
                  //console.log(error.response.status);
                  //console.log(error.response.data);
                });
            });
        })
        .catch((error) => {
          this.setState({ loadingThird: false });
          if (error.response.status === 400) {
            if (error.response.data.username) {
              toast.error(`${error.response.data.username}`, {
                autoClose: 3000,
              });
            } else if (error.response.data.password1) {
              if (error.response.data.password1[2]) {
                toast.error(`${error.response.data.password1[2]}`, {
                  autoClose: 3000,
                });
              } else if (error.response.data.password1[1]) {
                toast.error(`${error.response.data.password1[1]}`, {
                  autoClose: 3000,
                });
              } else if (error.response.data.password1[0]) {
                toast.error(`${error.response.data.password1[0]}`, {
                  autoClose: 3000,
                });
              }
            }
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
        });
    }
  };

  handleOTP = () => {
    this.setState({ disableOtp: true });
    this.setState({ loadingSecond1: true });
    const existing = localStorage.getItem('otp-details');
    if (existing) {
      const data = JSON.parse(existing);
      if (data.counter < 3) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/user_management/send-otp/${this.state.number}`
          )
          .then((resp) => {
            // //console.log(resp.data);
            toast.success(`OTP has been sent to your mobile, please check.`, {
              autoClose: 3000,
            });
            data.counter++;
            data.created_at = new Date();
            localStorage.setItem('otp-details', JSON.stringify(data));
            this.otpDisableFunction();
            this.setState({ loadingSecond1: false });
          })
          .catch((error) => {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
            this.setState({ loadingSecond1: false });
          });
      } else {
        // //console.log('disable');
        this.setState({ disableOtp: true });
        toast.error('Please try again 24 hours later later.', {
          autoClose: 3000,
        });
        this.setState({ loadingSecond1: false });
      }
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/send-otp/${this.state.number}`
        )
        .then((resp) => {
          toast.success(
            `We will send an OTP to ${this.state.number}, please check.`,
            {
              autoClose: 3000,
            }
          );
          const data = {
            counter: 1,
            created_at: new Date(),
          };
          localStorage.setItem('otp-details', JSON.stringify(data));
          this.otpDisableFunction();
          this.setState({ loadingSecond1: false });
        })
        .catch((error) => {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 3000,
          });
          this.setState({ loadingSecond1: false });
        });
    }
  };

  setDefaultDate = (value) => {
    this.setState({ dob: value });
  };

  render() {
    const { toggleLoggedIn, setUserMyself } = this.context;
    const {
      name,
      dob,
      gender,
      cPassword,
      showPasswordC,
      loadingFirst,
      loadingSecond1,
      loadingSecond2,
      loadingThird,
    } = this.state;

    return (
      <div>
        <section className='losign signup'>
          <div className='container'>
            <div className='row justify-content-center'>
              {this.state.showStep === 'first' && (
                <div className='col-md-6 first'>
                  <h2 className='title'>Sign Up</h2>
                  {/* <div className='socials'>
                    <button className='google'>
                      <img src={GLogo} alt='' />
                      <p>Continue with Google</p>
                    </button>
                    <button className='facebook'>
                      <img src={FLogo} alt='' />
                      <p>Continue with Facebook</p>
                    </button>
                  </div> */}
                  <form onSubmit={this.handleFirstStep}>
                    <div className='form-group for-otp'>
                      <input
                        className={!loadingFirst ? '' : 'loading'}
                        type='number'
                        placeholder='Mobile Number'
                        value={this.state.number}
                        onChange={(e) =>
                          this.setState({ number: e.target.value })
                        }
                      />
                      {!loadingFirst ? (
                        <button
                          className='btn btn-primary btn-sm'
                          type='submit'
                          disabled={
                            this.state.disableOtp ? 'disabled' : ''
                          } /*disabled */
                        >
                          Send OTP
                        </button>
                      ) : (
                        <LoadingBtn btnText={'Sending..'} />
                      )}
                    </div>
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='Enter OTP'
                        className='opt'
                        disabled
                      />
                    </div>
                    <div className='form-group'>
                      <button
                        type='submit'
                        className='submit-button disabled'
                        disabled
                      >
                        Sign Up Now
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {this.state.showStep === 'second' && (
                <div className='col-md-6 second'>
                  <h2 className='title'>Sign Up</h2>
                  {/* <div className='socials'>
                    <button className='google'>
                      <img src={GLogo} alt='' />
                      <p>Continue with Google</p>
                    </button>
                    <button className='facebook'>
                      <img src={FLogo} alt='' />
                      <p>Continue with Facebook</p>
                    </button>
                  </div> */}
                  <form onSubmit={this.handleSecondStep}>
                    <div className='form-group for-otp'>
                      <input
                        type='number'
                        placeholder='+88 01765113131'
                        value={this.state.number}
                        disabled
                        onChange={(e) =>
                          this.setState({ number: e.target.value })
                        }
                      />
                      {!loadingSecond2 ? (
                        <button
                          className={`btn btn-primary btn-sm ${
                            this.state.disableOtp ? 'disabled' : ''
                          }`}
                          disabled={this.state.disableOtp ? 'disabled' : ''}
                          type='button'
                          onClick={this.handleOTP}
                        >
                          Send OTP
                        </button>
                      ) : (
                        <LoadingBtn btnText={'Sending..'} />
                      )}
                    </div>
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='Enter OTP'
                        value={this.state.otp}
                        onChange={(e) => this.setState({ otp: e.target.value })}
                      />
                    </div>
                    <div className='form-group'>
                      {!loadingSecond1 ? (
                        <button type='submit' className='submit-button'>
                          Match OTP
                        </button>
                      ) : (
                        <LoadingBtn btnText={'Matching..'} />
                      )}
                    </div>
                  </form>
                  <div className='bottom'>
                    An OTP has been sent to your mobile. It will expire within
                    next
                    <span> 3 minutes</span>
                  </div>
                </div>
              )}
              {this.state.showStep === 'third' && (
                <div className='col-md-6 third'>
                  <h2 className='title'>Sign Up</h2>
                  {/* <div className='socials'>
                    <button className='google'>
                      <img src={GLogo} alt='' />
                      <p>Continue with Google</p>
                    </button>
                    <button className='facebook'>
                      <img src={FLogo} alt='' />
                      <p>Continue with Facebook</p>
                    </button>
                  </div> */}
                  <form
                    onSubmit={(e) =>
                      this.handleThirdStep(e, toggleLoggedIn, setUserMyself)
                    }
                  >
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='+8801765113131'
                        value={this.state.number}
                        disabled
                        onChange={(e) =>
                          this.setState({ number: e.target.value })
                        }
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='text'
                        placeholder='Enter your name'
                        value={name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                    <div className='form-group'>
                      <PatientDob
                        patientDob={dob}
                        setPatientDob={this.setDefaultDate}
                        placeholder='Enter your date of birth'
                      />
                    </div>
                    <div className='form-group'>
                      <select
                        className='sex'
                        value={gender}
                        onChange={(e) =>
                          this.setState({ gender: e.target.value })
                        }
                      >
                        <option value=''>Select your gender</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </select>
                    </div>
                    <div className='form-group'>
                      <div className='show-hide'>
                        <input
                          type={this.state.showPassword ? 'text' : 'password'}
                          placeholder='Enter new password'
                          value={this.state.password}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                        <img
                          src={this.state.showPassword ? EyeOff : Eye}
                          alt=''
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='show-hide'>
                        <input
                          type={showPasswordC ? 'text' : 'password'}
                          placeholder='Enter confirm password'
                          value={cPassword}
                          onChange={(e) =>
                            this.setState({ cPassword: e.target.value })
                          }
                        />
                        <img
                          src={showPasswordC ? EyeOff : Eye}
                          alt=''
                          onClick={() =>
                            this.setState({
                              showPasswordC: !this.state.showPasswordC,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='form-group submit-button'>
                      {!loadingThird ? (
                        <button type='submit' className='submit-button'>
                          Sign Up Now
                        </button>
                      ) : (
                        <LoadingBtn btnText={'Sign Up..'} />
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SignUp;

SignUp.contextType = AuthContext;
