import Logo from '../assets/img/logo.png';
import LogoMbl from '../assets/img/logo.png';
import Ssl from '../assets/img/ssl.png';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const Footer = () => {
  const { postActivity } = useContext(AuthContext);

  const now = new Date();

  return (
    <footer>
      <div className='details'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 footer-logo'>
              <img src={Logo} alt='AmarLab Logo' width='80' className='web' />
              <img
                src={LogoMbl}
                alt='AmarLab Logo'
                width='80'
                className='mobile'
              />
              <p className='logo-name'>AMARLAB</p>
            </div>
            <div className='col-md-5 company-details'>
              <h5>Making Healthcare Easily</h5>
              <h5 className='mb-0'>Accessible to Everyone, Everywhere.</h5>
              <p className='email'>Email: info@amarlab.com</p>
              <p>Phone: 09643445566</p>
              <p>Office: Level 3, House-15, Road-105, Gulshan-2, Dhaka-1212</p>
               {/*<p>Office: 9th Floor, Grameen Telecom Bhaban,<br/> Zoo Road, Dhaka 1216
              
              </p>*/}
              {/* <p>Block # G, Banani, Dhaka - 1213</p> */}
              <ul className='social-icons'>
                <li>
                  <a
                    href='https://www.facebook.com/amarlab.bd'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      postActivity(
                        'footer_menu',
                        'Facebook',
                        window.location.href
                      )
                    }
                  >
                    <i className='fab fa-facebook-f facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/company/amarlab/'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      postActivity(
                        'footer_menu',
                        'Linkedin',
                        window.location.href
                      )
                    }
                  >
                    <i className='fab fa-linkedin-in linkedin'></i>
                  </a>
                </li>
                {/* <li>
                  <a href='?#' rel='noopener noreferrer' target='_blank'>
                    <i className='fab fa-twitter twitter'></i>
                  </a>
                </li> */}
                <li>
                  <a
                    href='https://www.instagram.com/amarlab.bd'
                    rel='noopener noreferrer'
                    target='_blank'
                    onClick={() =>
                      postActivity(
                        'footer_menu',
                        'Instagram',
                        window.location.href
                      )
                    }
                  >
                    <i className='fab fa-instagram instagram'></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-md-7 useful-links'>
              <div className='row'>
                <div className='col-md-4'>
                  <h4>AmarLab</h4>
                  <ul className='text-dark'>
                    <li>
                      <Link
                        to='/about'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'About',
                            window.location.href
                          )
                        }
                      >
                        About
                      </Link>
                    </li>
                    {/*
                    <li>
                      <Link
                        to='/about-the-team'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'About',
                            window.location.href
                          )
                        }
                      >
                        About the Team
                      </Link>
                    </li>*/}
                    <li>
                      <a
                        href='https://amarlab.com/eng/blog/'
                        rel='noopener noreferrer'
                        target='_blank'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Blog',
                            window.location.href
                          )
                        }
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <Link
                        to='/press'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Press',
                            window.location.href
                          )
                        }
                      >
                        Press
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/faqs'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'FAQs',
                            window.location.href
                          )
                        }
                      >
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/terms-of-service'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Terms of Service',
                            window.location.href
                          )
                        }
                      >
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/payment-terms'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Payment Terms',
                            window.location.href
                          )
                        }
                      >
                        Payment Terms
                      </Link>
                    </li>
                    {/*
                    <li>
                      <Link
                        to='/company-doc'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Company DOC',
                            window.location.href
                          )
                        }
                      >
                        Company DOC
                      </Link>
                    </li>
                    */}
                  </ul>
                </div>
                <div className='col-md-4 account'>
                  <h4>Services</h4>
                  <ul className='text-dark'>
                    <li>
                      <Link
                        to='/diagnostic'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Diagnostic',
                            window.location.href
                          )
                        }
                      >
                        Diagnostic
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/packages'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Packages',
                            window.location.href
                          )
                        }
                      >
                        Health Packages
                      </Link>
                    </li>
                    {/* <li>
                      <a href='?#' className='text-white'>
                        Medicines
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className='col-md-4'>
                  <h4>For Patients</h4>
                  <ul className='text-dark'>
                    <li className='mb-0'>
                      <Link
                        to='/signup'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Signup',
                            window.location.href
                          )
                        }
                      >
                        Create Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/login'
                        onClick={() =>
                          postActivity(
                            'footer_menu',
                            'Login',
                            window.location.href
                          )
                        }
                      >
                        Login
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <div className='col-md-6'>
                  <h4>Get In Touch</h4>
                  <ul className='text-white'>
                    <li>
                      <a href='?#' className='text-white'>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href='?#' className='text-white'>
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href='?#' className='text-white'>
                        Help
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <img
                    src={Ssl}
                    alt=''
                    className='img-fluid'
                    onClick={() =>
                      postActivity(
                        'footer_menu',
                        'Ssl Logo',
                        window.location.href
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='links'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 parent'>
              {/* <div className='left'>
                <ul>
                  <li>
                    <a href='?#'>Features</a>
                  </li>
                  <li>
                    <a href='?#'>Use Cases</a>
                  </li>
                  <li>
                    <a href='?#'>Pricing</a>
                  </li>
                  <li>
                    <a href='?#'>Contacts</a>
                  </li>
                </ul>
              </div> */}
              <div className='right'>
                <p className='mb-0'>
                  AmarLab Ltd. &copy; {now.getFullYear()}.{' '}
                  <Link
                    to='/privacy-policy'
                    onClick={() =>
                      postActivity(
                        'footer_menu',
                        'Privacy Policy',
                        window.location.href
                      )
                    }
                  >
                    {' '}
                    Privacy & Terms
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
