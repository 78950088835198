// import HeroOne from '../../assets/img/hero-caro-mbl-1.png';
// import HeroTwo from '../../assets/img/hero-caro-mbl-2.png';
// import HeroThree from '../../assets/img/hero-caro-mbl-3.png';
import HeroOne from '../../assets/img/hero-caro-mbl-4.png';
// import HeroTwo from '../../assets/img/hero-caro-mbl-2.jpg';
import HeroFour from '../../assets/img/hero-caro-mbl-5.jpg';
// import HeroSix from '../../assets/img/hero-caro-mbl-6.jpg';
// import HeroNine from '../../assets/img/hero-caro-mbl-9.jpg';
import Carousel from 'react-multi-carousel';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';

const HeroCarouselMbl = () => {
  const { postActivity } = useContext(AuthContext);

  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay={true}
      autoPlaySpeed={5000}
      centerMode={false}
      className=''
      containerClass='container carousel-container'
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass='test'
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      showDots={true}
      sliderClass=''
      slidesToSlide={1}
      swipeable
    >
      {/* <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Bosonto Cover', window.location.href)
        }
      >
        <img src={HeroSix} alt='' className='img-fluid' />
      </div> */}
      <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Covid Cover', window.location.href)
        }
      >
        <img src={HeroOne} alt='' className='img-fluid' />
      </div>
      <div
        className='imagel'
        onClick={() =>
          postActivity('banner', '10% discount', window.location.href)
        }
      >
        <img src={HeroFour} alt='' className='img-fluid' />
      </div>
      {/* <div
        className='imagel'
        onClick={() =>
          postActivity('banner', 'Full Health Checkup', window.location.href)
        }
      >
        <img src={HeroTwo} alt='' className='img-fluid' />
      </div> */}
      <div
        className='imagel w-100 h-100'
        onClick={() =>
          postActivity('banner', 'Youtube Video', window.location.href)
        }
      >
        <div className='w-100 h-100' style={{ overflow: 'hidden' }}>
          <iframe
            width='100%'
            height='100%'
            src='https://www.youtube.com/embed/Gv4zcpaTTvY'
            title='YouTube video player'
            frameBorder={0}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            loading='lazy'
          />
        </div>
      </div>
    </Carousel>
  );
};

export default HeroCarouselMbl;
