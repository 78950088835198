import { Modal, ModalBody } from 'react-bootstrap';
import LeftArrow from '../../assets/img/left-arrow.png';
import PatientDob from '../PatientDob';

const PatientModal = ({
  showModal,
  setShowModal,
  goToFirstModal,
  personDetails,
  handleOrderNow,
  handleOrderFor,
  orderForRadio,
  showMyself,
  myName,
  setMyName,
  myDob,
  setMyDob,
  myGender,
  setMyGender,
  showFamily,
  suFamily,
  patientName,
  setPatientName,
  patientDob,
  setPatientDob,
  patientGender,
  setPatientGender,
  handleFamilyMember,
  showAddPatientForm,
  handleAddFamilyMember,
  showAddAnotherBtn,
  handleAddAnother,
}) => {
  return (
    <Modal
      className='main-modl'
      show={showModal}
      onHide={() => setShowModal(false)}
      animation={false}
    >
      <ModalBody className='diagnostic-select patient family'>
        <div className='head'>
          <div className='descrip'>
            <img src={LeftArrow} alt='' onClick={goToFirstModal} />
            <div className='left'>
              <h5>Patient Info</h5>
              <p className='mb-0'>Report will be generated with this name</p>
            </div>
          </div>
          <button className='btn btn-light' onClick={() => setShowModal(false)}>
            Close
          </button>
        </div>
        <div className='bodies'>
          <div className='details'>
            <div className='left'>
              <h5>I’m ordering for</h5>
            </div>
            <div className='right'>
              <label className='person first'>
                Myself
                <input
                  type='radio'
                  name='family'
                  value='Myself'
                  onChange={handleOrderFor}
                  checked={orderForRadio === 'Myself'}
                />
                <span className='checkmark' />
              </label>
              <label className='person'>
                Family
                <input
                  type='radio'
                  name='family'
                  value='Family'
                  onChange={handleOrderFor}
                  checked={orderForRadio === 'Family'}
                />
                <span className='checkmark' />
              </label>
            </div>
          </div>
          {showMyself && (
            <form>
              <div className='name-gender d-flex justify-content-between'>
                <div className='name'>
                  <input
                    type='text'
                    className='nainp'
                    placeholder='Your Name'
                    value={myName}
                    onChange={(e) => setMyName(e.target.value)}
                  />
                </div>
                {/* <div className='gender'>
                  <h5>Your Gender*</h5>
                  <div className='male-female'>
                    <label className='gender'>
                      Male
                      <input
                        type='radio'
                        value='male'
                        onChange={(e) => setMyGender(e.target.value)}
                        name='my-gender'
                        checked={myGender === 'male'}
                      />
                      <span className='checkmark' />
                    </label>
                    <label className='gender'>
                      Female
                      <input
                        type='radio'
                        value='female'
                        onChange={(e) => setMyGender(e.target.value)}
                        name='my-gender'
                        checked={myGender === 'female'}
                      />
                      <span className='checkmark' />
                    </label>
                  </div>
                </div> */}
                <div className='gender'>
                  <select
                    className='nainp form-select form-select-sm'
                    value={myGender}
                    onChange={(e) => setMyGender(e.target.value)}
                  >
                    <option disabled value=''>Select gender</option>
                    <option value='male'>M</option>
                    <option value='female'>F</option>
                    <option value='trans'>T</option>
                  </select>

                </div>
              </div>
              <div className='age-rx'>
                <PatientDob setPatientDob={setMyDob} patientDob={myDob} />
              </div>
              {/* <button className='btn-btn-light' onClick={handleEditMyself}>
                  Update Details
                </button> */}
            </form>
          )}
          {showFamily && (
            <>
              <div className='members'>
                {suFamily.length === 0 ? (
                  // 'Please Add member'
                  <p>No family member found. Please add some.</p>
                ) : (
                  <>
                    {suFamily.map((family, i) => (
                      <div key={i}>
                        <label className='individual'>
                          <span className='font-semibold'>
                            {family.full_name}
                          </span>
                          <input
                            type='checkbox'
                            value='Father'
                            onChange={(e) => handleFamilyMember(e, family)}
                          // onChange={(e) => //console.log(family)}
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {showAddPatientForm && (
                <form>
                  <div className='name-gender d-flex justify-content-between'>
                    <div className='name'>
                      <input
                        type='text'
                        className='nainp'
                        placeholder='Person Name'
                        value={patientName}
                        onChange={(e) => setPatientName(e.target.value)}
                      />
                    </div>
                    {/* <div className='gender'>
                      <h5>Gender*</h5>
                      <div className='male-female'>
                        <label className='gender'>
                          Male
                          <input
                            type='radio'
                            value='male'
                            onChange={(e) => setPatientGender(e.target.value)}
                            name='gender'
                          />
                          <span className='checkmark' />
                        </label>
                        <label className='gender'>
                          Female
                          <input
                            type='radio'
                            value='female'
                            onChange={(e) => setPatientGender(e.target.value)}
                            name='gender'
                          />
                          <span className='checkmark' />
                        </label>
                      </div>
                    </div> */}
                    <div className='gender'>
                      <select
                        className='nainp form-select form-select-sm'
                        value={patientGender}
                        onChange={(e) => setPatientGender(e.target.value)}
                      >
                        <option disabled value=''>Select gender</option>
                        <option value='male'>M</option>
                        <option value='female'>F</option>
                        <option value='trans'>T</option>
                      </select>

                    </div>
                  </div>
                  <div className='age-rx'>
                    <PatientDob
                      setPatientDob={setPatientDob}
                      patientDob={patientDob}
                    />
                  </div>
                  <button
                    className='btn-btn-light'
                    onClick={handleAddFamilyMember}
                  >
                    Add Member
                  </button>
                </form>
              )}
              {showAddAnotherBtn && (
                <div className='add-another'>
                  <button className='btn-btn-light' onClick={handleAddAnother}>
                    Add Another
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className='foot'>
          <button
            className='btn btn-primary btn-block'
            disabled={!personDetails.length ? 'disabled' : ''}
            onClick={handleOrderNow}
          >
            Add To Cart
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PatientModal;
