export const faqDetails = [
  // {
  //   title: 'What is Amarlab?',
  //   description:
  //     'Amarlab, your partner in health, provides diagnostic services from the comfort of your home. We go to your house, collect sample, transport them to lab of your choice for testing and then bring the report to you. ',
  // },
  {
    title: 'How to Order Tests?',
    description:
      'Clients can use Facebook Messenger, AmarLab website or call directly to our hotline number 09643445566 or 01879443333 to place an order. Once they provide the name of their preferred lab, details of the tests they want to do, our agents will inform them with the test price, discounts if available and also the schedule of AmarLab’s home sample collection service. ',
  },
  {
    title: 'What is the cost?',
    description:
      'The cost of AmarLab tests is what the chosen lab of a client charges. If there is any discount, we offer it to clients. AmarLab charges 50 taka for non-covid tests as material cost per patient and 200-taka fixed service charge. Clients can pay cash on delivery, through our website or  make Bkash payment. ',
  },
  {
    title: 'How Sample Collection is Done? ',
    description:
      'Our trained medical technicians will visit the household as per schedule. They will collect the sample (blood, urine, stool etc.) following standard medical practice. We have dedicated collection and transportation policy developed with the help of PUM Netherland. After collection the sample shall be transported directly to lab by our custom designed temperature-controlled carrier system.',
  },
  {
    title: 'How do I get report?',
    description:
      'After lab publishes the test results, we collect the report and deliver to clients. For softcopy we deliver it as soon as they are published by email. If client requests hard copy of reports, we deliver it to the home of patients within 48 hours. There are no extra charges associated with hardcopy delivery.',
  },
  {
    title: 'How do I know who is doing my lab tests? ',
    description: `We are in official partnership with following premium diagnostic centers:`,
    points: [
      '1. Dr. Lal Path Labs',
      '2. Popular Diagnostic',
      '3. Thyrocare',
      '4. IBN SINA',
      '5. Islami Bank Hospital',
      '6. United Hospital',
      '7. Sigma Lab Chattogram and Others',
    ],
    description2:
      'Amarlab will provide you with reports directly from the lab and you can contact the lab to get status of your tests. ',
  },
  {
    title: 'What are the working time for Amarlab?',
    description:
      'Amarlab is open 365 days a year to serve your heath care need. From 8 am to 10 pm Amarlab customer service accepts order. You can directly order from the website anytime or leave a message in Facebook page. ',
    description2:
      'Sample collections are done from 8 am to 6 pm right now. But we are working to make the hours longer.',
  },
  {
    title: 'What are our service areas? ',
    description:
      'Amarlab currently operates in Dhaka metropolitan areas and Chattogram city area. You can call the customer support unit to check whether your area is within coverage. ',
  },
  {
    title: 'Do you do covid service?',
    description:
      'We can provide at home RT-PCR and antigen service through our partner labs. However as per government policy, for travel purpose covid tests can not be done from home. ',
  },
  {
    title: 'Do you do ECG x-ray, ultra-sonography etc?',
    description:
      'Sorry sir, x-rays at home could bring health hazards as walls in resident style buildings are not built heavily to endure this. Also, the environment at home could not be controlled during x-rays.',
    description3: 'However, we can do at home ECG at Dhaka city. ',
    description2:
      'Ultrasonography and similar imaging tests are not possible to do from home. ',
  },
  {
    title: 'What is Amarlab’s legal standing?',
    description:
      'Amarlab is registered under both RJSC Bangladesh and ACRA Singapore. It has license from DGHS to collect samples for lab tests. It is also member of BASIS and E-CAB.  ',
  },
  // {
  //   title: '',
  //   description: '',
  // },
];
