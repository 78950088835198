import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CartIcon from '../assets/img/MblEmptyCart.png';
import CrossImg from '../assets/img/x-black.png';
import { Modal, ModalBody } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const Cart = () => {
  const {
    carts,
    setCartsItem,
    setIsLocalLab,
    labDiscountArray,
    setLastCartPatient,
  } = useContext(AuthContext);

  const [cartCheckout, setCartCheckout] = useState([]);
  const [patinetOrders, setPatinetOrders] = useState([]);
  const [showCouponSection, setShowCouponSection] = useState(false);
  const [showPaymentViewMore, setShowPaymentViewMore] = useState(false);

  const [cartOrderType, setCartOrderType] = useState('diagnostic');

  const [materialPerson, setMaterialPerson] = useState(null);
  const [fees, setFees] = useState({
    meterial_fee: 50,
    collection_fee: 200,
    delivery_fee: 100,
  });
  const [feesApi, setFeesApi] = useState({
    meterial_fee: 50,
    collection_fee: 200,
    delivery_fee: 100,
  });
  const [deliveryCopy, setDeliveryCopy] = useState('both');
  const [labDiscount, setLabDiscount] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(null);
  const [coupon, setCoupon] = useState('');
  const [showDiscount, setShowDiscount] = useState(false);
  const [cuponId, setcuponId] = useState(null);

  useEffect(() => {
    // console.log(carts);
    handlePatientOrders(carts);
    handleMeterialPerson(carts);
    setCartCheckout(carts);
    if (labDiscountArray) {
      calculateLabDiscount(labDiscountArray);
    }

    if (carts && carts.length !== 0) {
      if (carts[0].order_type === 'diagnostic') {
        setCartOrderType('diagnostic');
      }

      if (carts[0].order_type === 'package') {
        setCartOrderType('package');
      }
    }
  }, [carts]);

  useEffect(() => {
    // console.log(labDiscountArray);
    if (labDiscountArray) {
      setTimeout(() => {
        calculateLabDiscount(labDiscountArray);
      }, 500);
    }
  }, [labDiscountArray]);

  useEffect(() => {
    getFees();
    window.scrollTo(0, 0);
  }, []);

  const calculateLabDiscount = (array) => {
    // console.log(array);
    // console.log(carts);

    let labMatched = false;

    carts.forEach((test) => {
      let matchedLabs = [];
      if (test.order_type === 'diagnostic') {
        const matched = array.find((coupon) => {
          return (
            test.test_item.branch.lab.name.toLowerCase() ===
            coupon.lab.name.toLowerCase()
          );
        });
        if (matched) {
          matchedLabs.push(matched);
        }
      } else if (test.order_type === 'package') {
        const matched = array.find((coupon) => {
          return (
            test.package.test_item.branch.lab.name.toLowerCase() ===
            coupon.lab.name.toLowerCase()
          );
        });
        if (matched) {
          matchedLabs.push(matched);
        }
      }

      if (matchedLabs.length !== 0) {
        labMatched = true;
      } else {
        labMatched = false;
      }
    });

    if (labMatched) {
      // console.log('continue for next step');

      let matchedTests = [];
      let matchedPackages = [];

      array.forEach((labTest) => {
        let single = [];
        labTest.cct_lab.forEach((test) => {
          let existing = carts
            .filter((cart) => cart.order_type === 'diagnostic')
            .filter(
              (cart) =>
                cart.test_item.diagnostic_test.id === test.test.id &&
                cart.test_item.branch.lab.id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedTests.push(...single);
      });
      array.forEach((labTest) => {
        let single = [];
        labTest.ccp_lab.forEach((test) => {
          let existing = carts
            .filter((cart) => cart.order_type === 'package')
            .filter(
              (cart) =>
                cart.package.package.id === test.package.id &&
                cart.package.test_item.branch.lab.id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedPackages.push(...single);
      });

      // const testIds = matchedTests.map((o) => o.test_item.id);
      // matchedTests = matchedTests.filter(
      //   (item, index) => !testIds.includes(item.test_item.id, index + 1)
      // );

      matchedTests = matchedTests.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.test_item.id === tag.test_item.id &&
              t.patient.id === tag.patient.id
          ) === index
      );

      // const packageIds = matchedPackages.map((o) => o.package.test_item.id);
      // matchedPackages = matchedPackages.filter(
      //   (item, index) =>
      //     !packageIds.includes(item.package.test_item.id, index + 1)
      // );

      matchedPackages = matchedPackages.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.package.test_item.id === tag.package.test_item.id &&
              t.patient.id === tag.patient.id
          ) === index
      );

      const allItems = [...matchedTests, ...matchedPackages];
      // console.log(allItems);

      let itemMaterial = 0,
        itemColletion = 0;

      const checkCollection = allItems.map((item) => item.is_collection_fee);
      checkCollection.includes(true)
        ? (itemColletion = 0)
        : (itemColletion =
            deliveryCopy === 'soft'
              ? feesApi.collection_fee
              : feesApi.collection_fee + feesApi.delivery_fee);

      const checkMaterial = allItems
        .filter((item) => {
          return item.is_material_fee;
        })
        .sort(
          (a, b) =>
            parseFloat(b.material_rate_percentage) -
            parseFloat(a.material_rate_percentage)
        );
      if (checkMaterial.length !== 0) {
        // console.log(checkMaterial[0]);
        itemMaterial =
          fees.meterial_fee -
          (fees.meterial_fee * checkMaterial[0].material_rate_percentage) / 100;
      } else {
        itemMaterial = fees.meterial_fee;
      }

      // console.log(itemColletion, itemMaterial);
      setFees({ meterial_fee: itemMaterial, collection_fee: itemColletion });
      setTimeout(() => {
        serviceFeesRemove(carts);
      }, 300);

      // console.log(cartCheckout);
      const newCart = carts.map(
        (cart) =>
          matchedTests.find(
            (test) =>
              test.patient === cart.patient && test.test_item === cart.test_item
          ) || cart
      );
      setCartCheckout(newCart);
      // console.log(newCart);
      // this.setState({ carts: newCart });
      setTimeout(() => {
        serviceFeesRemove(carts);
      }, 300);

      const newCart1 = newCart.map(
        (cart) =>
          matchedPackages.find(
            (test) =>
              test.patient === cart.patient && test.package === cart.package
          ) || cart
      );
      // this.setState({ carts: newCart1 });
      setCartCheckout(newCart1);
      setTimeout(() => {
        serviceFeesRemove(carts);
      }, 300);

      const discountTestPrice = matchedTests.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.test_item.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
              100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPackagePrice = matchedPackages.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.package.package.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
              100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPrice = discountTestPrice + discountPackagePrice;

      if (discountPrice && 0 < discountPrice) {
        setLabDiscount(discountPrice);
        // console.log(discountTestPrice);
      }
    }
  };

  const serviceFeesRemove = (array) => {
    const testItems = array
      .filter((item) => {
        return item.order_type === 'diagnostic';
      })
      .map((item) => {
        return item.test_item.diagnostic_test.name;
      });

    const checkOne = testItems.includes('Covid RT PCR');
    const checkTwo = testItems.includes('Covid Antigen');

    // console.log(checkTwo);
    if (checkOne || checkTwo) {
      setFees({ meterial_fee: 0, collection_fee: 0, deliveryCopy: 0 });
    } else if (carts && carts.length && carts[0].order_type === 'package') {
      setFees({ meterial_fee: 0, collection_fee: 0, deliveryCopy: 0 });
    }
  };

  const getFees = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => {
        setFeesApi(resp.data.results[0]);
        if (deliveryCopy === 'soft') {
          setFees(resp.data.results[0]);
        } else {
          setFees({
            collection_fee:
              resp.data.results[0].collection_fee +
              resp.data.results[0].delivery_fee,
            meterial_fee: resp.data.results[0].meterial_fee,
            delivery_fee: resp.data.results[0].delivery_fee,
          });
        }
        setTimeout(() => {
          serviceFeesRemove(carts);
        }, 300);
        // console.log('fees = ', resp.data.results[0]);
      })
      .catch((error) => {});
  };

  const handleMeterialPerson = (array) => {
    const patients = array
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);
    setMaterialPerson(patients.length);
  };

  const handlePatientOrders = (order) => {
    const itemArray = order;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    // console.log(itemArray);
    //   console.log(filteredPatient);
    let patientOrders = [];

    filteredPatient.forEach((patient) => {
      const result = itemArray.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        testItem: result,
      };
      patientOrders.push(newArray);
      // console.log(newArray);
    });
    patientOrders.sort((p) => (p.patient.is_account ? -1 : 1));
    // setCtCPatient(patientOrders);
    setPatinetOrders(patientOrders);
    // console.log(patientOrders);
  };

  const getPatTestPrice = (testItem) => {
    if (testItem.length !== 0) {
      if (testItem[0].order_type === 'package') {
        const price = testItem.reduce((total, item) => {
          return (
            total +
            parseInt(item.package.test_item.purchasable_order_item.sell_price)
          );
        }, 0);
        return price;
      } else {
        const price = testItem.reduce((total, item) => {
          return (
            total + parseInt(item.test_item.purchasable_order_item.sell_price)
          );
        }, 0);
        return price;
      }
    } else {
      return '00';
    }
  };

  const getTotalPrice = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );

    return diagprice + packprice;
  };

  const deleteCartItem = (cart) => {
    // console.log(cart);

    const newCarts = [...carts];
    const filterCart = newCarts.filter((single) => single !== cart);
    setCartsItem(filterCart);
    // console.log(filterCart);
    if (filterCart.length === 0) {
      localStorage.removeItem('lab_name');
      localStorage.removeItem('lab_location');
      setIsLocalLab(false);
      setLastCartPatient([]);
      localStorage.removeItem('lastCartPatient');
    } else {
      // setTimeout(() => {
      //   if (labDiscountArray) {
      //     calculateLabDiscount(labDiscountArray);
      //   }
      //   console.log(carts);
      // }, 1000);
    }

    const localCarts = localStorage.getItem('order');
    if (localCarts) {
      const allCarts = JSON.parse(localCarts);

      const newData = {
        user: allCarts.user,
        status: '',
        order_item: filterCart,
      };

      localStorage.setItem('order', JSON.stringify(newData));
    }
  };

  const getTotalPriceWithFees = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );
    const totalPrice =
      diagprice +
      packprice +
      fees.collection_fee +
      materialPerson * fees.meterial_fee;
    return totalPrice;
  };

  const getTotalPriceWithFeesWithCoupon = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );
    const totalPrice =
      diagprice +
      packprice +
      fees.collection_fee +
      materialPerson * fees.meterial_fee;
    if (discountAmount && labDiscount) {
      return totalPrice - discountAmount - labDiscount;
    } else if (labDiscount) {
      return totalPrice - labDiscount;
    } else if (discountAmount) {
      return totalPrice - discountAmount;
    } else {
      return totalPrice;
    }
  };

  const couponRangeCheck = () => {
    const letter = this.state.coupon.match(/[a-zA-Z]+/g);
    const number = this.state.coupon.match(/\d+/g);

    // console.log(letter);
    // console.log(number);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon/?code=${letter}`)
      .then((resp) => {
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length === 0) {
            toast.error('Sorry, this coupon is not valid.');
          } else {
            // console.log(data);
            const cartLab = this.state.carts.map((cart) =>
              cart.order_type === 'diagnostic'
                ? cart.test_item.branch.lab.id
                : cart.package.test_item.branch.lab.id
            );
            const cartLabId = cartLab[0];

            if (1 < data.length) {
              const getCouponLab = data.filter((labs) =>
                labs.ccl.some((lab) => lab.lab === cartLabId)
              );

              if (getCouponLab.length !== 0) {
                // this.cuponAndCartMatch(getCouponLab.at(-1));
                const couponDet = getCouponLab.at(-1);
                if (couponDet.is_code_range) {
                  // console.log(couponDet);

                  const array = this.coupontRange(
                    couponDet.code_start,
                    couponDet.code_end,
                    couponDet.code
                  );
                  // console.log(array.includes(this.state.coupon));
                  if (array.includes(this.state.coupon)) {
                    this.cuponAndCartMatch(couponDet);
                  } else {
                    toast.error('Sorry, this coupon is not valid.');
                  }
                  // array.includes('amarlab10000');
                } else {
                  toast.error('Sorry, this coupon is not valid.');
                }
              } else {
                toast.error('Sorry, this coupon is not valid.');
              }
            } else {
              if (data[0].is_code_range) {
                const couponDet = data[0];
                // console.log(couponDet);
                if (couponDet.is_code_range) {
                  // console.log(couponDet);

                  const array = this.coupontRange(
                    couponDet.code_start,
                    couponDet.code_end,
                    couponDet.code
                  );
                  // console.log(array.includes(this.state.coupon));
                  if (array.includes(this.state.coupon)) {
                    this.cuponAndCartMatch(couponDet);
                  } else {
                    toast.error('Sorry, this coupon is not valid.');
                  }
                  // array.includes('amarlab10000');
                } else {
                  toast.error('Sorry, this coupon is not valid.');
                }
              } else {
                toast.error('Sorry, this coupon is not valid.');
              }
            }
          }
        } else {
          toast.error('Sorry, this coupon is not valid.');
        }
      });
  };

  const cuponAndCartMatch = (data) => {
    // console.log(data);

    let newCollectionFees, newMaterialFees;
    data.is_collection_fee
      ? (newCollectionFees = 0)
      : (newCollectionFees = fees.collection_fee);

    data.is_material_fee
      ? (newMaterialFees =
          fees.meterial_fee -
          (fees.meterial_fee * data.material_rate_percentage) / 100)
      : (newMaterialFees = fees.meterial_fee);

    setFees({
      meterial_fee: newMaterialFees,
      collection_fee: newCollectionFees,
    });

    const diagnostics = cartCheckout.filter((diagnostic) => {
      return diagnostic.order_type === 'diagnostic';
    });
    const packages = cartCheckout.filter((name) => {
      return name.order_type === 'package';
    });

    let cartLabs = [];

    if (diagnostics.length !== 0) {
      const labDetails = diagnostics.map((lab) => {
        return {
          labName: lab.test_item.branch.lab.name,
          id: lab.test_item.branch.lab.id,
        };
      });
      // console.log(labDetails);
      cartLabs.push(...labDetails);
    } else {
      const labDetails = packages.map((lab) => {
        return {
          labName: lab.package.test_item.branch.lab.name,
          id: lab.package.test_item.branch.lab.id,
        };
      });
      // console.log(labDetails);
      cartLabs.push(...labDetails);
    }

    let cartBranches = [];

    if (diagnostics.length !== 0) {
      const labDetails = diagnostics.map((lab) => {
        return {
          branchName: lab.test_item.branch.name,
          id: lab.test_item.branch.id,
        };
      });
      // console.log(labDetails);
      cartBranches.push(...labDetails);
    } else {
      const labDetails = packages.map((lab) => {
        return {
          branchName: lab.package.test_item.branch.name,
          id: lab.package.test_item.branch.id,
        };
      });
      // console.log(labDetails);
      cartBranches.push(...labDetails);
    }

    const cuponTests = data.cct;
    const cuponPackages = data.ccp;
    const cuponLabs = data.ccl;
    const cuponBranches = data.ccb;

    let matchedTests = [];
    cuponTests.forEach((cupId) => {
      let existing = diagnostics.filter(
        (diagId) => diagId.test_item.diagnostic_test.id === cupId.test
      );

      if (existing) {
        matchedTests.push(...existing);
      }
    });

    let matchedPackages = [];
    cuponPackages.forEach((cupId) => {
      let existing = packages.filter(
        (packId) => packId.package.package.id === cupId.package
      );

      if (existing) {
        matchedPackages.push(...existing);
      }
    });

    let matchedLabs = [];
    cuponLabs.forEach((cupId) => {
      let existing = cartLabs.find((packId) => packId.id === cupId.lab);

      if (existing) {
        matchedLabs.push(existing);
      }
    });

    let matchedBranches = [];
    cuponBranches.forEach((cupId) => {
      let existing = cartBranches.find((packId) => packId.id === cupId.branch);

      if (existing) {
        matchedBranches.push(existing);
      }
    });

    // console.log('Tests: ', matchedTests);
    // console.log('Packages: ', matchedPackages);
    // console.log('Labs: ', matchedLabs);

    if (data.is_branch) {
      // console.log('Calculate Branch');
      // console.log(data);
      // console.log('Tests: ', matchedTests);
      // console.log('Packages: ', matchedPackages);
      // console.log('Branches: ', matchedBranches);

      if (matchedBranches.length !== 0) {
        const priceArray = [...matchedTests, ...matchedPackages];
        const percentAmount = getTotalPrice(priceArray) * (data.rate / 100);
        setDiscountAmount(parseInt(percentAmount));

        const newTestCart = [...matchedTests].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newPackageCart = [...matchedPackages].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newCart = cartCheckout.map(
          (cart) =>
            newTestCart.find(
              (test) =>
                test.patient === cart.patient &&
                test.test_item === cart.test_item
            ) || cart
        );
        setCartCheckout(newCart);
        setTimeout(() => {
          serviceFeesRemove(cartCheckout);
        }, 300);

        const newCart1 = cartCheckout.map(
          (cart) =>
            newPackageCart.find(
              (test) =>
                test.patient === cart.patient && test.package === cart.package
            ) || cart
        );
        setCartCheckout(newCart1);
        setTimeout(() => {
          serviceFeesRemove(cartCheckout);
        }, 300);

        setShowDiscount(true);
        toast.success('Congratulation, coupon matched.', {
          autoClose: 3000,
        });
        setcuponId(data.id);
      } else {
        toast.error('Sorry, coupon is not valid.');
      }
    } else {
      if (
        data.cca.length === 0 &&
        // data.ccl.length === 0 &&
        data.ccp.length === 0 &&
        data.cct.length === 0
      ) {
        if (data.criteria === 'static') {
          setDiscountAmount(data.rate);
        } else {
          const percentAmount = getTotalPrice(cartCheckout) * (data.rate / 100);
          // console.log(parseInt(percentAmount));
          setDiscountAmount(parseInt(percentAmount));
        }

        const newCart = cartCheckout.map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });
        setCartCheckout(newCart);
        setTimeout(() => {
          serviceFeesRemove(cartCheckout);
        }, 300);

        toast.success('Congratulation, coupon matched.', {
          autoClose: 3000,
        });
        setShowDiscount(true);
        setcuponId(data.id);
      } else if (matchedTests.length !== 0 || matchedPackages.length !== 0) {
        if (data.criteria === 'static') {
          setDiscountAmount(data.rate);
        } else {
          const priceArray = [...matchedTests, ...matchedPackages];
          // console.log(priceArray);
          const percentAmount = getTotalPrice(priceArray) * (data.rate / 100);
          // console.log(percentAmount);
          setDiscountAmount(parseInt(percentAmount));
        }

        const newTestCart = [...matchedTests].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newPackageCart = [...matchedPackages].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newCart = cartCheckout.map(
          (cart) =>
            newTestCart.find(
              (test) =>
                test.patient === cart.patient &&
                test.test_item === cart.test_item
            ) || cart
        );
        setCartCheckout(newCart);
        setTimeout(() => {
          serviceFeesRemove(cartCheckout);
        }, 300);

        const newCart1 = cartCheckout.map(
          (cart) =>
            newPackageCart.find(
              (test) =>
                test.patient === cart.patient && test.package === cart.package
            ) || cart
        );

        setTimeout(() => {
          setCartCheckout(newCart1);
          serviceFeesRemove(cartCheckout);
        }, 300);

        setShowDiscount(true);
        toast.success('Congratulation, coupon matched.', {
          autoClose: 3000,
        });
        setcuponId(data.id);
      } else {
        toast.error('Sorry, coupon is not valid.', {
          autoClose: 3000,
        });
      }
    }
  };

  const fetchCoupon = (code) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon/?code=${code}`)
      .then((resp) => {
        // console.log(resp.data.results);
        const today = moment().format('YYYY-MM-DD');
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(today);

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length === 0) {
            // toast.error('Sorry, this coupon is not valid.');
            couponRangeCheck();
          } else {
            // console.log('do something with coupon');
            // this.cuponAndCartMatch(data);
            const cartLab = cartCheckout.map((cart) =>
              cart.order_type === 'diagnostic'
                ? cart.test_item.branch.lab.id
                : cart.package.test_item.branch.lab.id
            );
            const cartLabId = cartLab[0];
            // console.log(data);
            // console.log(cartLabId);

            if (1 < data.length) {
              console.log(3);
              const getCouponLab = data.filter((labs) =>
                labs.ccl.some((lab) => lab.lab === cartLabId)
              );
              // console.log(getCouponLab);
              if (getCouponLab.length !== 0) {
                // console.log(getCouponLab.at(-1).is_code_range);
                if (!getCouponLab.at(-1).is_code_range) {
                  cuponAndCartMatch(getCouponLab.at(-1));
                } else {
                  // toast.error('Sorry, this coupon is not valid.');
                  couponRangeCheck();
                }
              } else {
                // toast.error('Sorry, this coupon is not valid.');
                couponRangeCheck();
              }
            } else {
              if (!data[0].is_code_range) {
                cuponAndCartMatch(data[0]);
              } else {
                // toast.error('Sorry, this coupon is not valid.');
                couponRangeCheck();
              }
            }
          }
        } else {
          // toast.error('Sorry, this coupon is not valid.', {
          //   autoClose: 3000,
          // });
          couponRangeCheck();
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleCoupon = (e) => {
    e.preventDefault();
    if (coupon.trim() === '') {
      toast.error('Please enter coupon code.', {
        autoClose: 3000,
      });
    } else {
      fetchCoupon(coupon);
    }
  };

  return (
    <div className='cart-p-mbl'>
      {patinetOrders && patinetOrders.length !== 0 ? (
        <>
          {patinetOrders.map((item, i) => (
            <div className='single' key={i}>
              <div className='patient'>
                <div>
                  <span className='font-semibold'>Patient: </span>{' '}
                  {item.patient.full_name}, {item.patient.sex},{' '}
                  {item.patient.dob
                    ? moment().diff(`${item.patient.dob}`, 'years') + 'y'
                    : ''}
                </div>
                <div className='price font-semibold'>
                  ৳ {getPatTestPrice(item.testItem)}
                </div>
              </div>
              <div className='tests'>
                <ul>
                  {item.testItem.length !== 0 &&
                    item.testItem.map((test, i) => (
                      <li key={i}>
                        <span className='font-semibold'>
                          {test && test.order_type === 'diagnostic'
                            ? test.test_item.diagnostic_test && test.test_item.diagnostic_test.name
                            : test.package.package.name}
                        </span>
                        <div className='las'>
                          <span>
                            ৳{' '}
                            {test.order_type === 'diagnostic'
                              ? parseInt(
                                  test.test_item.purchasable_order_item
                                    .sell_price
                                )
                              : parseInt(
                                  test.package.test_item.purchasable_order_item
                                    .sell_price
                                )}
                          </span>
                          <i
                            className='far fa-trash-alt'
                            onClick={() => deleteCartItem(test)}
                          ></i>
                        </div>
                      </li>
                    ))}
                </ul>
                <p className='text-right' style={{ marginBottom: '24px' }}>
                  <Link
                    to={{
                      pathname:
                        cartOrderType === 'diagnostic'
                          ? '/diagnostic'
                          : '/packages',
                      params: {
                        formCartPage: true,
                        showPatient: [item.patient],
                      },
                    }}
                    className='add-an-btn'
                  >
                    Add Another
                  </Link>
                </p>
              </div>
            </div>
          ))}

          <div className='another-pat'>
            <Link
              to={{
                pathname:
                  cartOrderType === 'diagnostic' ? '/diagnostic' : '/packages',
                params: {
                  formCartPage: true,
                  showPatient: [],
                },
              }}
            >
              Add Tests For Another Patient
              <i className='fas fa-arrow-right'></i>
            </Link>
          </div>

          <div className='have-code'>
            <p
              className='text-center m-0'
              onClick={() => setShowCouponSection(!showCouponSection)}
            >
              {showCouponSection ? (
                <i className='fas fa-chevron-up'></i>
              ) : (
                <i className='fas fa-chevron-down'></i>
              )}
              Have A Special Code?
            </p>
            {showCouponSection && (
              <form className='input-code' onSubmit={handleCoupon}>
                <input
                  type='text'
                  placeholder='Special Code'
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <button disabled={showDiscount ? 'disabled' : ''}>
                  {showDiscount ? 'Applied' : 'Apply'}
                </button>
              </form>
            )}
          </div>

          <div className='checkout-btn'>
            <div className='left'>
              <p className='tp'>Total Payable</p>
              <div className='bd'>
                <p className='tk'>৳ {getTotalPriceWithFeesWithCoupon(carts)}</p>
                <p className='vd' onClick={() => setShowPaymentViewMore(true)}>
                  View Details
                </p>
              </div>
            </div>
            <div className='right'>
              <Link
                className='btn btn-primary'
                to={{
                  pathname: '/checkout',
                  params: {
                    carts: cartCheckout,
                    fees: fees,
                    feesApi: feesApi,
                    labDiscount: labDiscount,
                    discountAmount: discountAmount,
                    coupon: coupon,
                    cuponId: cuponId,
                    showDiscount: showDiscount,
                  },
                }}
              >
                Checkout
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className='empty'>
          <div className='details'>
            <img src={CartIcon} alt='' className='cart-icon' />
            <p className='ce'>Your cart is empty</p>
            {/* <p className='dt'>
              A medical technologist will be assigned to your order.
            </p> */}
            <p className='dt'>
              Please add tests or packages to your cart.
            </p>
            <p className='text-center m-0'>
              <Link to='/diagnostic' className='btn btn-primary'>
                Start Ordering
              </Link>
            </p>
          </div>
        </div>
      )}

      <Modal
        show={showPaymentViewMore}
        onHide={() => setShowPaymentViewMore(false)}
        animation={false}
        centered
        dialogClassName='app-modal'
      >
        <ModalBody>
          <div className='cart-payment-modal'>
            <div className='body'>
              <div className='header'>
                <p className='title'>Payment Details</p>
                <img
                  src={CrossImg}
                  alt=''
                  onClick={() => setShowPaymentViewMore(false)}
                />
              </div>
              <ul className='mai-p'>
                <li>
                  <span>Cost of Diagnostics</span>
                  <span>৳ {getTotalPrice(carts)}</span>
                </li>
                {carts &&
                  carts.length !== 0 &&
                  carts[0].order_type !== 'package' && (
                    <>
                      <li>
                        <span>Material Fee</span>
                        <span>৳ {materialPerson * fees.meterial_fee}</span>
                      </li>
                      <li>
                        <span>Collection Fee</span>
                        <span>৳ {fees.collection_fee}</span>
                      </li>
                    </>
                  )}
                <li>
                  <span className='font-semibold'>Sub Total</span>
                  <span className='font-semibold'>
                    ৳ {getTotalPriceWithFees(carts)}
                  </span>
                </li>
              </ul>
              <ul className='sub-p'>
                {labDiscount && (
                  <li>
                    <span>Discount on Diagnostics</span>
                    <span>- ৳ {labDiscount || '00'}</span>
                  </li>
                )}
                {showDiscount && (
                  <li>
                    <span>Coupon Code</span>
                    <span>- ৳ {discountAmount || '00'}</span>
                  </li>
                )}
                <li>
                  <span className='font-semibold'>Total Payable</span>
                  <span className='font-semibold'>
                    ৳ {getTotalPriceWithFeesWithCoupon(carts)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Cart;
