import { useContext } from 'react';
import { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import AccArrow from '../assets/img/right-arrow-link.png';
import { AuthContext } from '../context/AuthContext';
import { faqDetails } from '../helpers/FaqsDetails';

const Faqs = () => {
  const { postActivity, setPageTitle } = useContext(AuthContext);
  const [idx, setIdx] = useState([111]);

  useEffect(() => {
    setPageTitle('FAQs');
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='faqs'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='title main-heading'>Frequently asked questions</h2>

            <div className='details'>
              <Accordion defaultActiveKey='111'>
                <div className='card'>
                  <div className='card-header'>
                    <Accordion.Toggle
                      eventKey='111'
                      onClick={() => {
                        postActivity(
                          'faq',
                          'What is Amarlab?',
                          window.location.href
                        );
                        if (idx.includes(111))
                          setIdx(idx.filter((idx) => idx !== 111));
                        else setIdx([111]);
                      }}
                    >
                      <p>What is Amarlab?</p>
                      <img
                        src={AccArrow}
                        alt=''
                        className={idx.includes(111) ? 'active' : ''}
                      />
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='111'>
                    <div className='card-body'>
                      <p>
                        Amarlab is an at-home-at-work pathology test service
                        that provides diagnostic services at home for your own
                        comfort. We go to your house, collect sample, transport
                        them to the lab of your choice for testing and then
                        deliver the report at your doorsteps.
                      </p>
                      <br />
                      <p>
                        Amarlab service is beneficial to the patients who face
                        difficulty in travelling to receive healthcare
                        facilities for various reasons including inaccessible
                        transport, disability, old age or busy schedules. With
                        the first of its kind initiative in Dhaka, people do not
                        need to travel to and wait at diagnostic centers for
                        medical tests. Enjoy diagnostic services from the
                        comfort of your own home with Amarlab!{' '}
                      </p>
                    </div>
                  </Accordion.Collapse>
                </div>
                {faqDetails.map((faq, i) => (
                  <div className='card' key={i}>
                    <div className='card-header'>
                      <Accordion.Toggle
                        eventKey={i + 1}
                        onClick={() => {
                          postActivity('faq', faq.title, window.location.href);
                          if (idx.includes(i))
                            setIdx(idx.filter((idx) => idx !== i));
                          else setIdx([i]);
                        }}
                      >
                        <p>{faq.title}</p>
                        <img
                          src={AccArrow}
                          alt=''
                          className={idx.includes(i) ? 'active' : ''}
                        />
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={i + 1}>
                      <div className='card-body'>
                        <p>{faq.description}</p>
                        {faq.points && <br />}
                        {faq.points && (
                          <ul>
                            {faq.points.map((point, i) => (
                              <li key={i}>{point}</li>
                            ))}
                          </ul>
                        )}
                        {faq.description2 && <br />}
                        <p>{faq.description2}</p>
                        {faq.description3 && <br />}
                        <p>{faq.description3}</p>
                      </div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
