import axios from 'axios';
import moment from 'moment';
import React, { useState, useContext, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import PatientDob from './PatientDob';

export const ProfileInfo = ({ fetchUserPatients, patients }) => {
  const [username, setUsername] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userDob, setUserDob] = useState('');
  const [updating, setUpdating] = useState(false);

  const [userDet, setUserDet] = useState(null);

  const { suMyself, fetchUserDetails, userDetails, postActivity } =
    useContext(AuthContext);

  useEffect(() => {
    if (suMyself.length) {
      setUsername(suMyself[0].full_name);
      setUserGender(suMyself[0].sex);
      setUserDob(new Date(suMyself[0].dob));
    }
    // console.log(suMyself)
  }, [suMyself]);

  useEffect(() => {
    if (userDetails) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/user/${userDetails.pk}/?id=${userDetails.pk}`
        )
        .then((resp) => {
          // console.log(resp.data);
          setUserDet(resp.data);
        });
    }
  }, [userDetails]);

  const getLastName = (fullName) => {
    let parts = fullName.split(' ');
    parts.shift();
    return parts.join(' ');
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    if (username.trim() === '') {
      toast.error('Please enter your name!', { autoClose: 3000 });
      return;
    }
    if (username.trim().length < 4) {
      toast.error('Name must be at least 4 character.', { autoClose: 3000 });
      return;
    }
    if (userDob === '' || userDob === null) {
      toast.error('Please select your date of birth.', { autoClose: 3000 });
      return;
    }
    if (userGender === '') {
      toast.error('Please select your gender.', { autoClose: 3000 });
      return;
    }

    const check = patients.find((item) => item.full_name === username);
    if (check) {
      toast.error(
        `A patient already exists with this name in your family, please try another name.`
      );
    } else {
      setUpdating(true);
      const userId = JSON.parse(localStorage.getItem('user_id'));
      const putUser = {
        username: userDet.username,
        password: userDet.password,
        first_name: username.split(' ').shift(),
        last_name: getLastName(username),
        dob: moment(userDob).format('YYYY-MM-DD'),
        sex: userGender,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/user_management/user/${userId}/?id=${userId}`,
          putUser
        )
        .then((resp) => {
          // console.log('put: ', resp)
          const putPtn = {
            full_name: username,
            dob: moment(userDob).format('YYYY-MM-DD'),
            sex: userGender,
          };

          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient/${suMyself[0].id}/`,
              putPtn
            )
            .then((resp) => {
              // console.log(resp);
              fetchUserPatients(userId);
              fetchUserDetails();
              toast.success(`Myself updated successfully.`, {
                autoClose: 3000,
              });
              setUpdating(false);
            })
            .catch((error) => {
              // console.log(error.response);
              setUpdating(false);
              toast.error(`Myself update failed.`, {
                autoClose: 3000,
              });
            });
        })
        .catch((err) => {
          setUpdating(false);
          // console.log('puterr: ', err)
        });
    }
  };

  return (
    <div className='profileInfo' style={{ marginTop: -10 }}>
      <form onSubmit={(e) => handleUpdateUser(e)}>
        <div className='name-gender'>
          <div className='name'>
            <input
              type='text'
              className='nainp'
              placeholder='Person Name'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='gender'>
            {/* <h5>Gender*</h5>
            <div className='male-female'>
              <label className='gender'>
                Male
                <input
                  type='radio'
                  value='male'
                  onChange={(e) => setUserGender(e.target.value)}
                  name='gender'
                  checked={userGender === 'male'}
                />
                <span className='checkmark' />
              </label>
              <label className='gender'>
                Female
                <input
                  type='radio'
                  value='female'
                  onChange={(e) => setUserGender(e.target.value)}
                  name='gender'
                  checked={userGender === 'female'}
                />
                <span className='checkmark' />
              </label>
            </div> */}
            <select
              className='nainp form-select form-select-sm'
              value={userGender}
              onChange={(e) => setUserGender(e.target.value)}
            >
              <option disabled value=''>
                Select gender
              </option>
              <option value='male'>M</option>
              <option value='female'>F</option>
              <option value='common'>T</option>
            </select>
          </div>
        </div>
        <div className='age-rx'>
          <PatientDob setPatientDob={setUserDob} patientDob={userDob} />
        </div>
        <button
          className='btn btn-info'
          disabled={updating}
          onClick={() =>
            postActivity('button', 'Update Myself', window.location.href)
          }
        >
          Update Myself
          {updating && (
            <Spinner size='sm' animation='border' className='ml-2' />
          )}
        </button>
      </form>
    </div>
  );
};

export default ProfileInfo;
