import Img from '../assets/img/loading.gif';

const LoadingGif = () => {
  return (
    <div className='loading-gif'>
      <img src={Img} alt='' />
      {/* <p>Loading...</p> */}
    </div>
  );
};

export default LoadingGif;
