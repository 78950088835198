import SearchIcon from '../assets/img/search-white.png';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const SemiHeaderMbl = () => {
  const { postActivity } = useContext(AuthContext);
  const [search, setSearch] = useState('');
  const history = useHistory();

  const handleSearch = (e) => {
    e.preventDefault();
    history.push('/search');
    postActivity('search', 'Click on search box', window.location.href);

    // console.log(search);

    // if (search.trim() === '') {
    //   toast.error(`Type something and then search`, {
    //     autoClose: 3000,
    //   });
    // } else {
    //   // console.log(search);
    //   history.push(`/search?term=${search.trim()}`);
    // }
  };

  return (
    <>
      <div className='mobile-semi-nav'>
        {/* <section className='bg-primary text-white'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <p className='offer mb-0'>
                  Offering 15% discount on all diagnostics.{' '}
                  <Link to='/diagnostic'>
                    GET OFFER <img src={OfferIcon} alt='' />
                  </Link>
                </p>
                <p className='number'>
                  Call for order: <a href='tel:09643445566'>09643445566</a>
                </p>
              </div>
            </div>
          </div>
        </section> */}
        <section className='search'>
          <div className='container'>
            <div className='row'>
              <form className='col-12' onFocus={handleSearch}>
                <div className='input-group'>
                  <input
                    type='text'
                    placeholder='Search for diagnostics tests, labs etc.'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <button className='img'>
                      <img src={SearchIcon} alt='' />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SemiHeaderMbl;
