import { useContext } from 'react';
import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import ImgMinus from '../../assets/img/faq-minus.svg';
import ImgPlus from '../../assets/img/faq-plus.svg';
import { AuthContext } from '../../context/AuthContext';
import { faqDetails } from '../../helpers/FaqsDetails';

const Faq = () => {
  const { postActivity } = useContext(AuthContext);
  const [idx, setIdx] = useState([111]);

  return (
    <section className='global faques'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>
              Frequently Asked Questions
            </h5>
            <p className='text-center main-desc mx-auto'>
              {/* There is no greater benchmark for success than customer
              satisfaction. Over the years. */}
            </p>
          </div>
          <div className='col-12'>
            <Accordion defaultActiveKey='111'>
              <div className={`${idx.includes(111) ? 'active' : ''} single`}>
                <Accordion.Toggle
                  eventKey='111'
                  onClick={() => {
                    postActivity(
                      'faq',
                      'What is Amarlab?',
                      window.location.href
                    );
                    if (idx.includes(111))
                      setIdx(idx.filter((idx) => idx !== 111));
                    else setIdx([111]);
                  }}
                >
                  {idx.includes(111) ? (
                    <img src={ImgMinus} alt='' />
                  ) : (
                    <img src={ImgPlus} alt='' />
                  )}
                  <p className='head'>What is Amarlab?</p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='111'>
                  <div className='desc'>
                    <p className='mb-0'>
                      Amarlab is an at-home-at-work pathology test service that
                      provides diagnostic services at home for your own comfort.
                      We go to your house, collect sample, transport them to the
                      lab of your choice for testing and then deliver the report
                      at your doorsteps.
                    </p>
                    <p className='brek'></p>
                    <p className='mb-0'>
                      Amarlab service is beneficial to the patients who face
                      difficulty in travelling to receive healthcare facilities
                      for various reasons including inaccessible transport,
                      disability, old age or busy schedules. With the first of
                      its kind initiative in Dhaka, people do not need to travel
                      to and wait at diagnostic centers for medical tests. Enjoy
                      diagnostic services from the comfort of your own home with
                      Amarlab!{' '}
                    </p>
                  </div>
                </Accordion.Collapse>
              </div>

              {faqDetails.map((faq, i) => (
                <div
                  key={i}
                  className={`${idx.includes(i) ? 'active' : ''} single`}
                >
                  <Accordion.Toggle
                    eventKey={i + 1}
                    onClick={() => {
                      postActivity('faq', faq.title, window.location.href);
                      if (idx.includes(i))
                        setIdx(idx.filter((idx) => idx !== i));
                      else setIdx([i]);
                    }}
                  >
                    {idx.includes(i) ? (
                      <img src={ImgMinus} alt='' />
                    ) : (
                      <img src={ImgPlus} alt='' />
                    )}
                    <p className='head'>{faq.title}</p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={i + 1}>
                    <div className='desc'>
                      <p className='mb-0'>{faq.description}</p>
                      {faq.points && <p className='brek'></p>}
                      {faq.points && (
                        <ul>
                          {faq.points.map((point, i) => (
                            <li key={i}>{point}</li>
                          ))}
                        </ul>
                      )}
                      {faq.description2 && <p className='brek'></p>}
                      <p className='mb-0'>{faq.description2}</p>
                      {faq.description3 && <p className='brek'></p>}
                      <p className='mb-0'>{faq.description3}</p>
                    </div>
                  </Accordion.Collapse>
                </div>
              ))}

              {/* <div className={`${idx.includes(2) ? 'active' : ''} single`}>
                <Accordion.Toggle
                  eventKey='2'
                  onClick={() => {
                    if (idx.includes(2)) setIdx(idx.filter((idx) => idx !== 2));
                    else setIdx([2]);
                  }}
                >
                  {idx.includes(2) ? (
                    <img src={ImgMinus} alt='' />
                  ) : (
                    <img src={ImgPlus} alt='' />
                  )}
                  <p className='head'>Do I need to sign any agreements?</p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <div className='desc'>
                    <p className='mb-0'>
                      Transportation in our cities are hectic, time consuming
                      and expensive. For individuals who are not fit to walk,
                      handicap accessibility in the overall city is almost
                      non-existent. For families without private cars, visiting
                      a diagnostic center/hospital can be very stressful.
                    </p>
                  </div>
                </Accordion.Collapse>
              </div>
              <div className={`${idx.includes(3) ? 'active' : ''} single`}>
                <Accordion.Toggle
                  eventKey='3'
                  onClick={() => {
                    if (idx.includes(3)) setIdx(idx.filter((idx) => idx !== 3));
                    else setIdx([3]);
                  }}
                >
                  {idx.includes(3) ? (
                    <img src={ImgMinus} alt='' />
                  ) : (
                    <img src={ImgPlus} alt='' />
                  )}
                  <p className='head'>
                    What is the process of selling/renting my property?
                  </p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <p className='desc'>
                    Transportation in our cities are hectic, time consuming and
                    expensive. For individuals who are not fit to walk, handicap
                    accessibility in the overall city is almost non-existent.
                    For families without private cars, visiting a diagnostic
                    center/hospital can be very stressful.
                  </p>
                </Accordion.Collapse>
              </div>
              <div className={`${idx.includes(4) ? 'active' : ''} single`}>
                <Accordion.Toggle
                  eventKey='4'
                  onClick={() => {
                    if (idx.includes(4)) setIdx(idx.filter((idx) => idx !== 4));
                    else setIdx([4]);
                  }}
                >
                  {idx.includes(4) ? (
                    <img src={ImgMinus} alt='' />
                  ) : (
                    <img src={ImgPlus} alt='' />
                  )}
                  <p className='head'>
                    How long will it take to find buyers/tenants?
                  </p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <p className='desc'>
                    Transportation in our cities are hectic, time consuming and
                    expensive. For individuals who are not fit to walk, handicap
                    accessibility in the overall city is almost non-existent.
                    For families without private cars, visiting a diagnostic
                    center/hospital can be very stressful.
                  </p>
                </Accordion.Collapse>
              </div>
              <div className={`${idx.includes(5) ? 'active' : ''} single`}>
                <Accordion.Toggle
                  eventKey='5'
                  onClick={() => {
                    if (idx.includes(5)) setIdx(idx.filter((idx) => idx !== 5));
                    else setIdx([5]);
                  }}
                >
                  {idx.includes(5) ? (
                    <img src={ImgMinus} alt='' />
                  ) : (
                    <img src={ImgPlus} alt='' />
                  )}
                  <p className='head'>Are there any charges?</p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <p className='desc'>
                    Transportation in our cities are hectic, time consuming and
                    expensive. For individuals who are not fit to walk, handicap
                    accessibility in the overall city is almost non-existent.
                    For families without private cars, visiting a diagnostic
                    center/hospital can be very stressful.
                  </p>
                </Accordion.Collapse>
              </div> */}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
