import { useContext } from 'react';
import Support from '../assets/img/Support.svg';
import { AuthContext } from '../context/AuthContext';

const CallForOrder = () => {
  const { postActivity } = useContext(AuthContext);
  return (
    <div className='col-md-12'>
      <div className=' call-for-order'>
        <div className='left'>
          <div className='icon'>
            {/* <i className='fas fa-headset'></i> */}
            <img src={Support} alt='' />
          </div>
          <div className='text'>
            <h5 className=''>Need help with booking your test?</h5>
            <p className=''>Our experts are here to help you</p>
          </div>
        </div>
        <div className='right'>
          <a
            href='tel:09643445566'
            className='text-white'
            onClick={() =>
              postActivity('button', 'Call to Order', window.location.href)
            }
          >
            <i className='fas fa-phone-alt'></i>09643445566
          </a>
        </div>
      </div>
    </div>
  );
};

export default CallForOrder;
