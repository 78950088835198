import CloseIcon from '../assets/img/x-black.png';
import CartIcon from '../assets/img/MblEmptyCart.png';
import CartImg from '../assets/img/white-cart.png';
import AccArrow from '../assets/img/right-arrow-link.png';
import User from '../assets/img/user.png';
import { Link } from 'react-router-dom';
import { Accordion, AccordionCollapse, AccordionToggle } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';
import { useContext, useState } from 'react';
import moment from 'moment';

const CartMobile = ({ showCartMobile, setShowCartMobile }) => {
  const { carts, setCartsItem, setIsLocalLab } = useContext(AuthContext);

  const [idx, setIdx] = useState([]);

  const getTotalPrice = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );

    return diagprice + packprice;
  };

  // const getFirstName = (name) => {
  //   const nameArray = name.split(' ');
  //   if (nameArray.length < 2) {
  //     return name;
  //   } else {
  //     const firstname = nameArray.slice(0, -1).join(' ');
  //     return firstname;
  //   }
  // };

  const deleteCartItem = (cart) => {
    // console.log(cart);

    const newCarts = [...carts];
    const filterCart = newCarts.filter((single) => single !== cart);
    setCartsItem(filterCart);
    // console.log(filterCart.length);
    if (filterCart.length === 0) {
      localStorage.removeItem('lab_name');
      localStorage.removeItem('lab_location');
      setIsLocalLab(false);
    }

    const localCarts = localStorage.getItem('order');
    if (localCarts) {
      const allCarts = JSON.parse(localCarts);

      const newData = {
        user: allCarts.user,
        status: '',
        order_item: filterCart,
      };

      localStorage.setItem('order', JSON.stringify(newData));
    }
  };

  const hideCart = () => {
    setShowCartMobile(false);
    const body = document.body;
    body.style.height = 'auto';
    body.style.overflowY = 'auto';
  };

  return (
    <div className='cart-mobile'>
      <div className={`${showCartMobile ? 'cart-mobile-overlay' : ''}`}></div>
      <div className={`main-cart ${showCartMobile ? 'active' : ''}`}>
        <div className='main-cart-diaglog'>
          {carts.length !== 0 ? (
            <div className='not-empty'>
              <div className='headd'>
                <p className='text-center'>Your Cart</p>
                <img src={CloseIcon} alt='' onClick={hideCart} />
              </div>
              <div className='details hei'>
                <Accordion>
                  {carts && carts.map((cart, i) => (
                    <div className='single' key={i}>
                      <AccordionToggle
                        className='head'
                        eventKey={i + 1}
                        onClick={() => {
                          if (idx.includes(i))
                            setIdx(idx.filter((idx) => idx !== i));
                          else setIdx([i]);
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <img src={User} alt='' />
                          <div className='names'>
                            <h5 className='name'>
                              {cart.patient.full_name.startsWith('0')
                                ? 'Myself'
                                : cart.patient.full_name}
                            </h5>
                            ,
                            <p className='age'>
                              {cart.patient.dob === null
                                ? ''
                                : moment().diff(
                                    `${cart.patient.dob}`,
                                    'years'
                                  ) + ' Years'}
                            </p>
                          </div>
                        </div>
                        <div className='cross'>
                          <img
                            src={AccArrow}
                            alt=''
                            className={idx.includes(i) ? 'active' : ''}
                          />
                        </div>
                      </AccordionToggle>
                      <AccordionCollapse eventKey={i + 1}>
                        <div className='body'>
                          <h5>
                            {cart && cart.order_type === 'diagnostic'
                              ? cart && cart.test_item && cart.test_item.diagnostic_test && cart.test_item.diagnostic_test.name
                              : cart.package.package.name}
                            <img
                              src={CloseIcon}
                              alt=''
                              onClick={() => deleteCartItem(cart)}
                            />
                          </h5>
                          <p className='pres'>Preparations:</p>
                          <p>
                            {cart && cart.order_type === 'diagnostic' &&
                              ( cart && cart.test_item &&  cart.test_item.diagnostic_test && cart.test_item.diagnostic_test.description
                                ? cart.test_item.diagnostic_test.description.substring(
                                    0,
                                    60
                                  )
                                : '')}

                            {cart.order_type === 'package' &&
                              (cart.package.package.description
                                ? cart.package.package.description.substring(
                                    0,
                                    60
                                  )
                                : '')}
                          </p>
                          <div className='cash'>
                            ৳{' '}
                            {cart.order_type === 'diagnostic'
                              ? cart.test_item.purchasable_order_item.sell_price
                              : cart.package.test_item.purchasable_order_item
                                  .sell_price}
                          </div>
                          <p className='type'>
                            {/* Type:{' '} */}
                            <span
                              className={
                                cart.order_type === 'diagnostic'
                                  ? 'diagnostic'
                                  : 'package'
                              }
                            >
                              {cart.order_type}
                            </span>
                          </p>
                          <p className='ordered'>Ordered From:</p>
                          <img
                            src={
                              cart.order_type === 'diagnostic'
                                ? cart.test_item.branch.lab.logo
                                : cart.package.test_item.branch.lab.logo
                            }
                            alt=''
                          />
                        </div>
                      </AccordionCollapse>
                    </div>
                  ))}
                </Accordion>
              </div>
              <div className='foot'>
                <div className='count'>
                  <div className='bg-primary'>
                    <div className='left'>
                      <img src={CartImg} alt='' width='16' />
                      <p className='mb-0'>
                        <span className='font-weight-bold'>{carts.length}</span>{' '}
                        Tests Added
                      </p>
                    </div>
                    <div className='right'>{getTotalPrice(carts)} ৳</div>
                  </div>
                </div>
                <div className='button'>
                  <Link
                    to='/checkout'
                    onClick={hideCart}
                    className='btn btn-block btn-golden'
                  >
                    Proceed To Checkout
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className='empty'>
              <div className='close-icon'>
                <img src={CloseIcon} alt='' onClick={hideCart} />
              </div>
              <div className='details'>
                <img src={CartIcon} alt='' />
                <p className='m-0'>Your cart is empty</p>
                <Link
                  to='/diagnostic'
                  onClick={hideCart}
                  className='btn btn-primary'
                >
                  Start Ordering
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartMobile;
