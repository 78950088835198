export const dhakaArea = [
  "Adabor",
  "Airport",
  "Ashulia",
  "Badda",
  "Banani",
  "Bangshal",
  "Cantonment",
  "Chawkbazar",
  "Dakshinkhan",
  "Darus Salam",
  "Demra",
  "Dhamrai",
  "Dhanmondi",
  "Dohar",
  "Gendaria",
  "Gulshan",
  "Hatirjheel",
  "Hazaribagh",
  "JAtrabari",
  "Kadomtoli",
  "KAfrul",
  "Kalabagan",
  "Kamrangirchar",
  "Keraniganj Model",
  "Khilgaon",
  "Khilkhet",
  "Kotwali",
  "Lalbagh",
  "Mirpur",
  "Mohammadpur",
  "Motijheel",
  "Mugda",
  "Nawabgonj",
  "New Mrket",
  "Pallabi",
  "Paltan Model",
  "Ramna Model",
  "Rampura",
  "Rupnagar",
  "Sabujbagh",
  "Savar",
  "Shah Ali",
  "Shahbagh",
  "Shahjahanpur",
  "Shampur",
  "Sher-E-Bangla Nagar",
  "South Keraniganj",
  "Sutrapur",
  "Tejgaon",
  "Tejgaon Industrial Area",
  "Turag",
  "Uttara East",
  "Uttara West",
  "Uttarkhan",
  "Vashantek",
  "Vatara",
  "Wari",
];

export const savarArea = [
  "Sreepur",
  "Bolibordho Bazar",
  "EPZ Area",
  "Baipayl Area",
  "Dendabor",
  "Nobinagar",
  "Bish mail",
  "C&B",
  "Redio Colony",
  "CRP",
  "Savar Bus stand Area",
  "Savar Thana Stand area",
  "Bank Town",
  "Fulbaria",
  "Hemayetpur",
];

export const ctgArea = [
  "Bayazid",
  "Chandgaon",
  "Panchlaish",
  "Akbarsha",
  "Pahartali",
  "Khulshi",
  "Bakoliya",
  "Kotwali",
  "Halishahar",
  "Double Mooring",
  "Bandar",
  "Patenga",
  "Bhatiari",
];
