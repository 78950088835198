import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

class PaymentTerms extends Component {
  state = {};

  componentDidMount() {
    this.context.setPageTitle('Terms Of Service');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <section className='privacy-policy'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h5 className='main-heading'>REFUND AND CANCELLATION</h5>
                <p>
                  <ul>
                    <li>
                      If a Patient cancels a Pathology test prior to the call of
                      the Patient’s request before the expiry of 60 minutes from
                      the Initiation Request, a 5% fee will be deducted and the
                      remaining fee will be refunded to the Patient. There may
                      be additional charges to make the refund. For example, i
                    </li>
                    <li>
                      For any cancellation and refund please email:{' '}
                      <a
                        href='mailto:info@amarlab.com'
                        className='text-primary'
                      >
                        info@amarlab.com
                      </a>
                    </li>
                    <li>The refund process may take up to 10 working days.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PaymentTerms;
PaymentTerms.contextType = AuthContext;
