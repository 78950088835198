import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { AuthContext } from '../context/AuthContext';

class TicketDownload extends Component {
  state = {
    order: null,
    CtCPatient: [],
    discountPercentClipboard: null,
  };

  asciiCode = (user) => {
    const userPhone = user.username.slice(2, 4); // phone number 2 digit
    const phnLst = user.username.slice(-1); // last character of phone number
    const firstNameLast = user.first_name.slice(-1); // last character of firstname
    const firstNameFirst = user.first_name.charAt(0); // first character of firstname
    return (
      userPhone +
      '0zV1' +
      phnLst.charCodeAt(0).toString(16) +
      '2Qj27' +
      firstNameLast.charCodeAt(0).toString(16) +
      '90pK4' +
      firstNameFirst.charCodeAt(0)
    );
  };

  downloadAsTxt = (mainOrder) => {
    const tableRow = document.querySelectorAll('#copy-table tbody tr td');
    let retContent = [];
    let retString = '';
    let elemText = [];

    tableRow.forEach((elem) => {
      elemText.push(elem.innerText);
    });

    retContent.push(elemText.join('\r\n'));
    retString = retContent.join(' ');

    const element = document.createElement('a');
    const file = new Blob([retString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `order_${mainOrder.order_id}.txt`;
    document.body.appendChild(element);
    element.click();
    // window.close();
    this.props.history.push('/');
  };

  calculateDiscountForClipboard = (order) => {
    if (order.orderdiscount) {
      const discountPercentage =
        (parseInt(order.orderdiscount.discount_price) /
          parseInt(order.total_price)) *
        100;
      //   console.log(discountPercentage);
      this.setState({ discountPercentClipboard: discountPercentage });
    }

    setTimeout(() => {
      this.downloadAsTxt(order);
    }, 100);
  };

  patientOrderItems = (mainOrder) => {
    const itemArray = mainOrder.orderitem;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    let patientOrders = [];

    filteredPatient.forEach((patient) => {
      const result = itemArray.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        testItem: result,
      };
      patientOrders.push(newArray);
    });
    patientOrders.sort((p) => (p.patient.is_account ? -1 : 1));
    this.setState({ CtCPatient: patientOrders });
    // console.log(patientOrders);

    this.calculateDiscountForClipboard(mainOrder);
  };

  fetchSingleOrder = (id, algorithm) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-tree/${id}/?ticket=${algorithm}&order=${id}`
      )
      .then((resp) => {
        // console.log(resp.data);
        const expire_day = moment(resp.data.date)
          .add(15, 'days')
          .format('YYYY-MM-DD')
          .toString();
        const isSameOrAfter = moment(expire_day).isSameOrAfter(
          moment().format('YYYY-MM-DD')
        );

        if (!isSameOrAfter) {
          this.props.history.push('/');
          this.context.postActivity(
            'user_ticket',
            'Link Expired',
            window.location.href
          );
        } else {
          //   console.log(isSameOrAfter);
          //   console.log(this.props.match.params.algorithm);
          //   console.log(this.asciiCode(resp.data.user));

          if (
            this.props.match.params.algorithm === this.asciiCode(resp.data.user)
          ) {
            this.setState({ order: resp.data });
            // console.log('algorithm match');
            this.context.postActivity(
              'user_ticket',
              'Download successfully',
              window.location.href
            );
            this.patientOrderItems(resp.data);
          } else {
            this.props.history.push('/');
            // console.log("algorithm don't match");
            this.context.postActivity(
              'user_ticket',
              'Algorithm not matched',
              window.location.href
            );
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
        // this.setState({ loading: false });
      });
  };

  componentDidMount() {
    // console.log(this.props.match.params.id);
    // console.log(this.props.match.params.algorithm);

    this.context.postActivity(
      'user_ticket',
      'Entered in page',
      window.location.href
    );

    this.fetchSingleOrder(
      this.props.match.params.id,
      this.props.match.params.algorithm
    );
  }

  _toSpace = (string) => {
    let newStr = string.replace(/_/g, ' ');
    return newStr;
  };

  getAge = (dob) => {
    const year = moment().diff(`${dob}`, 'years');

    if (0 < year) {
      return year + ' Years';
    } else {
      const month = moment().diff(`${dob}`, 'months');
      return month + ' Months';
    }
  };

  getTestItemsPrice = (array) => {
    const price = array.reduce(
      (total, item) => total + parseInt(item.purchasable_order_item.sell_price),
      0
    );

    return parseInt(price);
  };

  render() {
    const { order, CtCPatient, discountPercentClipboard } = this.state;

    return (
      <>
        {order && (
          <table
            id='copy-table'
            className='d-flex flex-column'
            style={{
              height: '0',
              overflow: 'hidden',
            }}
          >
            {order.orderitem.length !== 0 &&
              CtCPatient.map((item, i) => (
                <tbody
                  key={i}
                  className='d-flex flex-column'
                  style={{ flexGrow: 1 }}
                >
                  <tr className='d-flex justify-content-center'>
                    <td>AmarLab Limited</td>
                  </tr>
                  <tr className='d-flex justify-content-center'>
                    <td>www.amarlab.com</td>
                  </tr>
                  <tr className='d-flex justify-content-center'>
                    <td>www.facebook.com/amarlab.bd</td>
                  </tr>
                  <tr className='d-flex justify-content-center'>
                    <td>Hotline: 09643445566</td>
                  </tr>
                  {/* <tr className='d-flex justify-content-center'>
                      <td>
                        Making Healthcare Easily Accessible to Everyone,
                        Everywhere
                      </td>
                    </tr> */}
                  <tr className='d-flex justify-content-center text-muted'>
                    <td>&nbsp;</td>
                  </tr>
                  {CtCPatient.length > 1 && (
                    <tr>
                      <td>{i + 1}.</td>
                    </tr>
                  )}
                  <tr>
                    <td>#{order.order_id}</td>
                  </tr>
                  <tr>
                    <td>
                      Ticket Generated On:{' '}
                      {moment().format('Do MMMM YYYY; hh:mm A')}
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Date: {moment(order.date).format('Do MMMM YYYY')}</td>
                  </tr>
                  {order.time && (
                    <tr>
                      <td>
                        Time:{' '}
                        {moment(order.time, ['HH:mm:ss']).format('hh:mm A')} -{' '}
                        {moment(order.time, 'HH:mm:ss')
                          .add(60, 'minutes')
                          .format('hh:mm A')}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      Address:{' '}
                      {order.orderdetail ? (
                        <>
                          {order.orderdetail && order.orderdetail.address
                            ? `${order.orderdetail.address.address}, ${order.orderdetail.address.district}.` //${order.orderdetail.address.thana},
                            : `${order.orderitem[0].address.address}, ${order.orderitem[0].address.district}.`}
                          {/* ${order.orderitem[0].address.thana}, */}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contact no:{' '}
                      {order.orderdetail ? (
                        <>
                          {order.orderdetail && order.orderdetail.address
                            ? order.orderdetail.address.mobile
                            : order.orderitem[0].address.mobile}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Email:{' '}
                      {order.orderdetail ? (
                        <>
                          {order.orderdetail && order.orderdetail.address
                            ? order.orderdetail.address.email
                            : order.orderitem[0].address.email}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  {order.orderdetail && order.orderdetail.references && (
                    <tr>
                      <td>References: {order.orderdetail.references}</td>
                    </tr>
                  )}
                  {order.orderdetail && order.orderdetail.user_type && (
                    <tr className='text-capitalize'>
                      <td>User Type: {order.orderdetail.user_type}</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      Lab:{' '}
                      {order.orderitem.length !== 0
                        ? order.orderitem[0] &&
                          order.orderitem[0].purchasable_order_item &&
                          order.orderitem[0].purchasable_order_item
                            .testitem_purchasable_oi &&
                          order.orderitem[0].purchasable_order_item
                            .testitem_purchasable_oi.branch &&
                          order.orderitem[0].purchasable_order_item
                            .testitem_purchasable_oi.branch.lab &&
                          order.orderitem[0].purchasable_order_item
                            .testitem_purchasable_oi.branch.lab.name
                        : 'No lab'}
                      {/* {order &&
                      order.purchasable_order_item &&
                      order.purchasable_order_item
                        .testitem_purchasable_oi &&
                      order.purchasable_order_item.testitem_purchasable_oi
                        .branch &&
                      order.purchasable_order_item.testitem_purchasable_oi
                        .branch.lab.name} */}
                    </td>
                  </tr>
                  <tr>
                    <td className='text-capitalize'>
                      Payment Status:{' '}
                      {order.orderdetail && order.orderdetail.payment_status}
                    </td>
                  </tr>
                  {order.orderdetail && (
                    <tr>
                      <td className='text-capitalize'>
                        Order Category: {this._toSpace(order.order_type)}
                      </td>
                    </tr>
                  )}
                  {order.orderdetail && order.orderdetail.mt && (
                    <tr>
                      <td>
                        MT:{' '}
                        {order.orderdetail && order.orderdetail.mt.first_name
                          ? order.orderdetail.mt.first_name +
                            ' ' +
                            order.orderdetail.mt.last_name
                          : order.orderdetail.mt.username}
                      </td>
                    </tr>
                  )}
                  {order.orderdetail && order.orderdetail.cs_agent && (
                    <tr>
                      <td>
                        CA:{' '}
                        {order.orderdetail &&
                        order.orderdetail.cs_agent.first_name
                          ? order.orderdetail.cs_agent.first_name +
                            ' ' +
                            order.orderdetail.cs_agent.last_name
                          : order.orderdetail.cs_agent.username}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      Report Required:{' '}
                      <span className='text-capitalize'>
                        {order &&
                          order.orderdetail &&
                          order.orderdetail.delivery_copy_status}{' '}
                      </span>
                      Copy
                    </td>
                  </tr>
                  {/* {order.orderdetail && order.orderdetail.order_note && (
                    <tr>
                      <td>
                        Order Note:{' '}
                        {order.orderdetail && order.orderdetail.order_note}
                      </td>
                    </tr>
                  )} */}
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td> ——————————————————————————————————————</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Name: {item.patient.full_name}</td>
                  </tr>
                  {item.patient.dob && (
                    <tr>
                      <td>
                        Age: {this.getAge(item.patient.dob)}
                        {/* {moment().diff(`${item.patient.dob}`, 'years') +
                              ' Years'} */}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className='text-capitalize'>Sex: {item.patient.sex}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  {item.testItem.map((item, i) => (
                    <>
                      <tr>
                        <td>
                          {i + 1}.{' '}
                          {item &&
                          item.purchasable_order_item &&
                          item.purchasable_order_item.testitem_purchasable_oi &&
                          item.purchasable_order_item.testitem_purchasable_oi
                            .diagnostic_test
                            ? item.purchasable_order_item
                                .testitem_purchasable_oi.diagnostic_test.name
                            : 'No Name found'}
                          :{' '}
                          {
                            item &&
                              item.purchasable_order_item &&
                              parseInt(item.purchasable_order_item.sell_price)
                            // - parseInt(item.discount_price)
                          }{' '}
                          tk
                        </td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td> ——————————————————————————————————————</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      Total Test Price ={' '}
                      {order.orderitem.length !== 0 &&
                        this.getTestItemsPrice(item.testItem)}{' '}
                      tk
                      {/* {order.orderdiscount ? '=' : ''}{' '} */}
                      {/* {order.orderitem.length !== 0 && (
                          <>
                            {order && order.orderdiscount && (
                              <>
                                {parseInt(order.total_price) -
                                  parseInt(
                                    order.orderdiscount.discount_price
                                  ) +
                                  'TK'}
                              </>
                            )}
                          </>
                        )} */}
                    </td>
                  </tr>
                  {i + 1 === 1 ? (
                    <tr>
                      <td>
                        Service Charge: {parseInt(order.collection_fee)} tk
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr>
                    <td>
                      Material Charge:{' '}
                      {order.orderitem.length !== 0 &&
                        order.orderitem[0].meterial_fee}{' '}
                      tk
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {i + 1 === 1 ? (
                        <>
                          Total:{' '}
                          {this.getTestItemsPrice(item.testItem) +
                            order.orderitem[0].meterial_fee +
                            parseInt(order.collection_fee)}{' '}
                          tk
                        </>
                      ) : (
                        <>
                          Total:{' '}
                          {this.getTestItemsPrice(item.testItem) +
                            order.orderitem[0].meterial_fee}{' '}
                          tk
                        </>
                      )}
                    </td>
                  </tr>
                  {order.orderdiscount ? (
                    <>
                      <tr>
                        <td>
                          Discount:{' '}
                          {discountPercentClipboard
                            ? (
                                (discountPercentClipboard / 100) *
                                this.getTestItemsPrice(item.testItem)
                              ).toFixed(2)
                            : '00'}{' '}
                          tk{' '}
                          {order.orderdiscount.discount_note
                            ? `(${order.orderdiscount.discount_note})`
                            : ''}
                        </td>
                      </tr>
                      {/* <tr>
                          <td>
                            Discount Note:{' '}
                            {order.orderdiscount.discount_note}
                          </td>
                        </tr> */}
                    </>
                  ) : (
                    ''
                  )}
                  <tr>
                    <td>
                      Total Payable:{' '}
                      {i + 1 === 1 ? (
                        <>
                          {order.orderdiscount
                            ? (
                                this.getTestItemsPrice(item.testItem) +
                                parseInt(order.orderitem[0].meterial_fee) +
                                parseInt(order.collection_fee) -
                                (discountPercentClipboard / 100) *
                                  this.getTestItemsPrice(item.testItem)
                              ).toFixed(2)
                            : (
                                this.getTestItemsPrice(item.testItem) +
                                parseInt(order.orderitem[0].meterial_fee) +
                                parseInt(order.collection_fee)
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {order.orderdiscount
                            ? (
                                this.getTestItemsPrice(item.testItem) +
                                order.orderitem[0].meterial_fee -
                                (discountPercentClipboard / 100) *
                                  this.getTestItemsPrice(item.testItem)
                              ).toFixed(2)
                            : (
                                this.getTestItemsPrice(item.testItem) +
                                order.orderitem[0].meterial_fee
                              ).toFixed(2)}
                        </>
                      )}{' '}
                      tk
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  {/* <tr>
                      <td>&nbsp;</td>
                    </tr> */}
                </tbody>
              ))}
          </table>
        )}
      </>
    );
  }
}

export default TicketDownload;
TicketDownload.contextType = AuthContext;
