import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

export const OrderCardSec = (props) => {
  const { postActivity } = useContext(AuthContext);
  const { isMobileView } = props;

  const [isMobile, setIsMobile] = useState(isMobileView ? true : false);

  return (
    !isMobile ? (
      <section className='order-sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='first'>
                <h5>Order Diagnostics Service From Top Labs</h5>
                <p>Order Diagnostic Tests from Preferred Labs.</p>
                <Link
                  to='/diagnostic'
                  onClick={() =>
                    postActivity(
                      'button',
                      'Order Now (Diagnostic)',
                      window.location.href
                    )
                  }
                  className='btn btn-primary'
                >
                  Order Now
                </Link>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='second'>
                <h5>Order Packages From Top Labs</h5>
                <p>Order Heavily Discounted packages.</p>
                <Link
                  onClick={() =>
                    postActivity(
                      'button',
                      'Order Now (Package)',
                      window.location.href
                    )
                  }
                  to='/packages'
                  className='btn btn-primary'
                >
                  Order Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    ) : (

      <section className='order-sec'>
        <div className='container order-card-container'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='first mb-3 d-flex flex-row'>
                <div className='order-card-left'>
                  <Link
                    to='/diagnostic'
                    onClick={() =>
                      postActivity(
                        'button',
                        'Order Now (Diagnostic)',
                        window.location.href
                      )
                    }
                    className='btn btn-primary'
                  >
                    Order Now
                  </Link>
                </div>
                <div className='order-card-right'>
                  <h5>Order Diagnostics Service From Top Labs</h5>
                  <p>Order Diagnostic Tests from Preferred Labs.</p>
                </div>

              </div>
            </div>
            <div className='col-sm-6'>
              <div className='second d-flex flex-row'>
                <div className='order-card-left'>
                  <Link
                    onClick={() =>
                      postActivity(
                        'button',
                        'Order Now (Package)',
                        window.location.href
                      )
                    }
                    to='/packages'
                    className='btn btn-primary'
                  >
                    Order Now
                  </Link>
                </div>
                <div className='order-card-right'>
                  <h5>Order Packages From Top Labs</h5>
                  <p>Order Heavily Discounted packages.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  )
}

export default OrderCardSec