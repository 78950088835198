import Quote from '../assets/img/inverted-commas.svg';
import Carousel from 'react-multi-carousel';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const Achievements = () => {
  const { postActivity } = useContext(AuthContext);
  const [achievements, setAchievements] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/press/achievement-get/?ordering=id&limit=100`
      )
      .then((resp) => {
        // console.log(resp.data);
        setAchievements(resp.data.results);
      });
  }, []);

  return (
    <section className='global achievement'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>Our Achievements</h5>
            <p className='text-center main-desc mx-auto'>
              {/* There is no greater benchmark for success than customer */}
              {/* satisfaction. Over the years. */}
            </p>
          </div>
          {achievements && (
            <Carousel
              autoPlay={true}
              additionalTransfrom={0}
              arrows={false}
              autoPlaySpeed={5000}
              centerMode={false}
              className=''
              containerClass='container carousel-container'
              dotListClass=''
              draggable
              focusOnSelect={false}
              infinite={true}
              itemClass='test'
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
              }}
              showDots={true}
              sliderClass=''
              slidesToSlide={1}
              swipeable
            >
              {achievements.map((achievement, i) => (
                <div
                  className='col-12 h-100'
                  key={i}
                  onClick={() =>
                    postActivity('box', 'Achievements', window.location.href)
                  }
                >
                  <div className='row h-100 justify-content-center'>
                    {achievement.image || achievement.embeded ? (
                      <div className='col-md-6 video'>
                        {achievement.image ? (
                          <img
                            src={achievement.image}
                            alt=''
                            className='img-fluid'
                          />
                        ) : (
                          <div className='embed-responsive embed-responsive-16by9'>
                            <iframe
                              src={achievement.embeded}
                              title='YouTube video player'
                              frameBorder={0}
                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                              allowFullScreen
                              loading='lazy'
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`${
                          achievement.image || achievement.embeded
                            ? 'col-md-6'
                            : 'col-md-12 only-content'
                        } details`}
                      >
                        <div className=''>
                          <div className='icon'>
                            <img src={Quote} alt='' />
                          </div>
                          <p className='content'>{achievement.comment}</p>
                          <div className='by'>
                            <p className='name'>{achievement.name}</p>
                            <p className='title'>{achievement.subname}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {/* <div className='col-12'>
              <div className='row'>
                <div className='col-md-6 video'>
                  <div className='embed-responsive embed-responsive-16by9'>
                    <iframe
                      src='https://www.youtube.com/embed/MFMbs6lMrYw'
                      title='YouTube video player'
                      frameBorder={0}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className='col-md-6 details'>
                  <div className=''>
                    <div className='icon'>
                      <img src={Quote} alt='' />
                    </div>
                    <p className='content'>
                      Transportation in our cities are hectic, time consuming
                      and expensive. For individuals who are not fit to walk,
                      handicap accessibility in the overall city is almost
                      non-existent. For families without private cars, visiting
                      a diagnostic center/hospital can be very stressful.
                    </p>
                    <div className='by'>
                      <p className='name'>Mr. Haque</p>
                      <p className='title'>
                        Development Specialist | Grameenphone
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              {/* <div className='col-12'>
              <div className='row'>
                <div className='col-md-6 video'>
                  <div className='embed-responsive embed-responsive-16by9'>
                    <iframe
                      src='https://www.youtube.com/embed/CM0w7Vfp5mY'
                      title='YouTube video player'
                      frameBorder={0}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className='col-md-6 details'>
                  <div className=''>
                    <div className='icon'>
                      <img src={Quote} alt='' />
                    </div>
                    <p className='content'>
                      Transportation in our cities are hectic, time consuming
                      and expensive. For individuals who are not fit to walk,
                      handicap accessibility in the overall city is almost
                      non-existent. For families without private cars, visiting
                      a diagnostic center/hospital can be very stressful.
                    </p>
                    <div className='by'>
                      <p className='name'>Mr. Haque</p>
                      <p className='title'>
                        Development Specialist | Grameenphone
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </Carousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default Achievements;
