import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CloseIcon from '../assets/img/x-black.png';
import AmarlabLogo from '../assets/img/logo.png';
import RightArrow from '../assets/img/right-arrow-link.png';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const MblHamburg = ({ showNavMobile, setShowNavMobile }) => {
  const { postActivity, isLoggedIn, unauthorized, setIsLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  const now = new Date();

  const goToUrl = (url) => {
    history.push(url);
    setShowNavMobile(false);
    const body = document.body;
    body.style.height = 'auto';
    body.style.overflowY = 'auto';
  };

  const handleLogout = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/logout/`)
      .then((resp) => {
        ////console.log(resp.data);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('userPatients');
        // loginHandler(false);
        window.location.href = '/';
        setIsLoggedIn(false);
      })
      .catch((error) => {
        // console.log(error.response.status);
        if (error.response.status === 401) {
          unauthorized();
          //   loginHandler(false);
        }
      });
  };

  return (
    <div className='cart-mobile mbl-hamburg'>
      <div className={`${showNavMobile ? 'cart-mobile-overlay' : ''}`}></div>
      <div className={`main-cart ${showNavMobile ? 'active' : ''}`}>
        <div className='main-cart-diaglog'>
          <div className='headd'>
            <div
              className='logo'
              onClick={() => postActivity('menu', 'Home', window.location.href)}
            >
              <img
                src={AmarlabLogo}
                alt=''
                width='25'
                onClick={() => goToUrl('/')}
              />
              <span>Amarlab</span>
            </div>
            <img
              src={CloseIcon}
              alt=''
              onClick={() => {
                const body = document.body;
                body.style.height = 'auto';
                body.style.overflowY = 'auto';
                setShowNavMobile(false);
                postActivity(
                  'menu',
                  'Mobile Navigation Close',
                  window.location.href
                );
              }}
              className='close'
            />
          </div>
          <div className='body'>
            <ul>
              <li
                onClick={() => {
                  goToUrl('/');
                  postActivity('menu', 'Home', window.location.href);
                }}
              >
                <p className='m-0'>Home</p>
                <img src={RightArrow} alt='' />
              </li>
              <li
                onClick={() => {
                  goToUrl('/diagnostic');
                  postActivity('menu', 'Diagnostic', window.location.href);
                }}
              >
                <p className='m-0'>Diagnostic</p>
                <img src={RightArrow} alt='' />
              </li>
              <li
                onClick={() => {
                  goToUrl('/packages');
                  postActivity('menu', 'Packages', window.location.href);
                }}
              >
                <p className='m-0'>Packages</p>
                <img src={RightArrow} alt='' />
              </li>
              {isLoggedIn ? (
                <>
                  <li
                    onClick={(e) => {
                      handleLogout(e);
                      postActivity('menu', 'Logout', window.location.href);
                    }}
                  >
                    <p className='m-0'>Logout</p>
                    <img src={RightArrow} alt='' />
                  </li>
                </>
              ) : (
                <>
                  <li
                    onClick={() => {
                      goToUrl('/login');
                      postActivity('menu', 'Login', window.location.href);
                    }}
                  >
                    <p className='m-0'>Login</p>
                    <img src={RightArrow} alt='' />
                  </li>
                  <li
                    onClick={() => {
                      goToUrl('/signup');
                      postActivity('menu', 'Signup', window.location.href);
                    }}
                  >
                    <p className='m-0'>Signup</p>
                    <img src={RightArrow} alt='' />
                  </li>
                </>
              )}
            </ul>

            <div className='footer'>
              <p className='call'>
                Call for order:
                <a href='tel:09643445566'> 09643445566</a>
              </p>
              <p className='copy'>
                AmarLab Ltd. &copy; {now.getFullYear()}.{' '}
                <span
                  onClick={() => {
                    goToUrl('/privacy-policy');
                    postActivity(
                      'menu',
                      'Privacy Policy',
                      window.location.href
                    );
                  }}
                >
                  {' '}
                  Privacy & Terms
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MblHamburg;
