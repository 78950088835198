import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/404.svg';
import Icon from '../assets/img/right.png';
import { AuthContext } from '../context/AuthContext';

class NotFound extends Component {
  state = {};

  componentDidMount() {
    this.context.setPageTitle('404 not found');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <section className='not-found'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <img src={Logo} alt='' className='img-fluid main' />
                <h1 className='text-center'>Oops! You have been lost.</h1>
                <p className='mb-0 text-center'>
                  <Link to='/' className='btn btn-primary'>
                    <img src={Icon} alt='' className='icon' /> Back To Homepage
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NotFound;
NotFound.contextType = AuthContext;
