import { useContext } from 'react';
import FI1 from '../../assets/img/fi1.png';
import FI2 from '../../assets/img/fi2.svg';
import FI3 from '../../assets/img/fi3.png';
import FI4 from '../../assets/img/fi4.svg';
import FI5 from '../../assets/img/fi5.png';
import FI6 from '../../assets/img/fi6.png';
import { AuthContext } from '../../context/AuthContext';

const FeaturedIn = () => {
  const { postActivity } = useContext(AuthContext);
  return (
    <section className='sponsor featured'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>We Are Featured In</h5>
          </div>
          {/* {[...Array(10)].map((d, i) => (
            <marquee className='images' key={i}>
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
            </marquee>
          ))} */}
          <div
            className='col-12'
            onClick={() =>
              postActivity('box', 'Featured In Logo', window.location.href)
            }
          >
            <marquee className='images'>
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              {/* {' '} */}
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
              <img src={FI1} alt='' />
              <img src={FI2} alt='' className='daily-star' />
              <img src={FI3} alt='' />
              <img src={FI4} alt='' className='dhaka-tribun' />
              <img src={FI5} alt='' />
              <img src={FI6} alt='' className='new-adge' />
            </marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedIn;
