import axios from 'axios';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import Logo1 from '../../assets/img/partners/logo1.png';
import Logo2 from '../../assets/img/partners/logo2.png';
import Logo3 from '../../assets/img/partners/logo3.png';
import Logo4 from '../../assets/img/partners/lal.jpg';
import Logo5 from '../../assets/img/partners/logo5.jpg';
import Logo6 from '../../assets/img/partners/logo6.png';
import Logobirdem from '../../assets/img/partners/birdem.jpeg';
import Logoprava from '../../assets/img/partners/prava.png'
// import Logo7 from '../../assets/img/partners/logo7.png';
// import Logo8 from '../../assets/img/partners/chevronlab.jpg';
// import Logo9 from '../../assets/img/partners/logo9.png';medinova.jpeg
import medinova from '../../assets/img/partners/medinova.jpeg';
import Logo10 from '../../assets/img/partners/logo11.png';
import { AuthContext } from '../../context/AuthContext';

const Partners = () => {
  const { postActivity } = useContext(AuthContext);
  // const [allLabs, setAllLabs] = useState(null);

  useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=50`)
    //   .then((resp) => {
    //     setAllLabs(resp.data.results);
    //   });
  }, []);

  return (
    <section className='partners'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>Our Lab Partners</h5>
            <p className='text-center main-desc mx-auto'>
              Your tests will be done from our partner labs where quality is
              uncompromised.
            </p>
          </div>
          <div className='col-12'>
            <div className='row logo-sec justify-content-center'>
              <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo1} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo2} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo3} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo4} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo5} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo6} alt='' />
                </div>
              </div>
              {/* <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo7} alt='' />
                </div>
              </div> */}
              {/* <div className='col-4 col-md-3 single'>
                <div
                  className='main'
                  onClick={() =>
                    postActivity('banner', 'Lab Partner', window.location.href)
                  }
                >
                  <img src={Logo9} alt='' />
                </div>
              </div> */}
              {/* <div className='col-4 col-md-3 single'>
                <div className='main'>
                  <img src={Logo8} alt='' />
                </div>
              </div> */}
              <div className='col-4 col-md-3 single'>
                <div className='main'>
                  <img src={Logo10} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div className='main'>
                  <img src={medinova} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div className='main'>
                  <img src={Logobirdem} alt='' />
                </div>
              </div>
              <div className='col-4 col-md-3 single'>
                <div className='main'>
                  <img src={Logoprava} alt='' />
                </div>
              </div>


            </div>
          </div>
          {/* {allLabs &&
            allLabs.map((lab, i) => (
              <div className='col-4 col-md-3 single' key={i}>
                <div className='main'>
                  <img src={lab.logo} alt='' />
                </div>
              </div>
            ))} */}
        </div>
      </div>
    </section>
  );
};

export default Partners;
