import axios from 'axios';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RightArrow from '../assets/img/right-arrow-link.png';
import { AuthContext } from '../context/AuthContext';
import { Modal, ModalBody } from 'react-bootstrap';
import CloseBtn from '../assets/img/x-black.png';
import Eye from '../assets/img/eye.svg';
import EyeOff from '../assets/img/eye-off.svg';
import { toast } from 'react-toastify';
import PatientDob from './PatientDob';
import moment from 'moment';
import LoadingBtn from './LoadingBtn';

const UserTabMobile = ({ showUserMobile, setShowUserMobile }) => {
  const {
    isLoggedIn,
    unauthorized,
    toggleLoggedIn,
    setUserMyself,
    setUserFamily,
    setCartsItem,
    showLoginModal,
    setShowLoginModal,
    showSignupModal,
    setShowSignupModal,
    setUserDetails,
    postActivity,
    setLastCartPatient,
    setIsLocalLab,
    setIsLoggedIn,
    setUserId,
  } = useContext(AuthContext);
  const history = useHistory();
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [showSignupModal, setShowSignupModal] = useState(false);

  // login
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  //signup
  const [signupStep, setSignupStep] = useState('first');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState(null);
  const [signupNumber, setSignupNumber] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [showPasswordSign, setShowPasswordSign] = useState(false);
  const [disableOtp, setDisableOtp] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [LoadingFirst, setLoadingFirst] = useState(false);
  const [LoadingSecond, setLoadingSecond] = useState(false);
  const [LoadingThird, setLoadingThird] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/logout/`)
      .then((resp) => {
        ////console.log(resp.data);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('userPatients');
        // loginHandler(false);
        window.location.href = '/';
        
        setIsLoggedIn(false);
      })
      .catch((error) => {
        // console.log(error.response.status);
        if (error.response.status === 401) {
          unauthorized();
          //   loginHandler(false);
        }
      });
  };

  // Login ==============================//
  const handleLogin = (
    e,
    toggleLoggedIn,
    setUserMyself,
    setUserFamily,
    setCartsItem
  ) => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(number)) {
      toast.error('Please enter a valid mobile number!', {
        autoClose: 3000,
      });
    } else if (password === '') {
      toast.error('Please enter a password', {
        autoClose: 3000,
      });
    } else {
      const loginData = {
        username: number,
        password: password,
      };

      setLoading(true);

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/login/`, loginData)
        .then((resp) => {
          // //console.log(resp.data.key);
          axios.interceptors.request.use((config) => {
            const token = 'Token ' + resp.data.key;
            config.headers.Authorization = token;
            return config;
          });
          localStorage.removeItem('token');
          localStorage.setItem('token', JSON.stringify(resp.data.key));

          axios
            .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
            .then((resp) => {
              setUserDetails(resp.data);
              localStorage.setItem('user_id', JSON.stringify(resp.data.pk));
              // setIsLoggedIn(true);

              const this_user_id = resp.data.pk;

              const lastCartPat = localStorage.getItem('lastCartPatient');
              if (lastCartPat) {
                const lastCartPatStr = JSON.parse(lastCartPat);
                if (lastCartPatStr[0].user === resp.data.pk) {
                  setLastCartPatient(lastCartPatStr);
                }
              }

              setUserId(resp.data.pk);

              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/user_management/patient/?user=${resp.data.pk}&limit=300`
                )
                .then((resp) => {
                  const results = resp.data.results;
                  // //console.log(results);
                  const myself = results.filter((item) => {
                    return item.is_account;
                  });
                  const family = results
                    .filter((item) => {
                      return item.is_account === false;
                    })
                    .filter((patient) => {
                      return patient.is_active;
                    });
                  setUserMyself(myself);
                  setUserFamily(family);
                  localStorage.setItem('userPatients', JSON.stringify(myself));

                  const getCarts = localStorage.getItem('order');
                  const labName = localStorage.getItem('lab_name');
                  if (getCarts) {
                    const orderdfdfdsf = JSON.parse(getCarts);
                    // //console.log(orderdfdfdsf.user.pk);
                    // //console.log(this_user_id);
                    if (orderdfdfdsf.user.pk === this_user_id) {
                      // //console.log('cart for this user');
                      // //console.log(true);
                      const forStateChange = orderdfdfdsf.order_item;
                      setCartsItem(forStateChange);
                      if (labName) {
                        setIsLocalLab(true);
                      }
                    } else {
                      // //console.log('cart for other user');
                      //console.log(false);
                    }
                  }

                  // this.props.loginHandler(true);
                  toggleLoggedIn();
                  setShowLoginModal(false);
                  setLoading(false);
                  // window.location.reload();

                  // const getLoginAfterData =
                  //   sessionStorage.getItem('loginAfterData');

                  // if (getLoginAfterData) {
                  //   const details = JSON.parse(getLoginAfterData);
                  //   // this.props.history.push(details.location);
                  //   // console.log(details);
                  // }
                });
            });
        })
        .catch((error) => {
          //console.log(error.response.data.non_field_errors);
          setLoading(false);
          if (error.response.status === 400) {
            toast.error(`${error.response.data.non_field_errors}`, {
              autoClose: 3000,
            });
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
        });
    }
  };

  const handleLink = (url) => {
    // console.log(url);
    setShowUserMobile(false);
    if (isLoggedIn) {
      history.push(url);
    } else {
      setShowLoginModal(true);
    }
  };

  // Signup //
  const hideSignupModal = () => {
    setShowSignupModal(false);
    setSignupStep('first');
    setName('');
    setGender('');
    setDob(null);
    setSignupNumber('');
    setSignupPassword('');
    setOtp('');
  };

  const getLastName = (fullName) => {
    let parts = fullName.split(' ');
    parts.shift();
    return parts.join(' ');
  };

  const updateUserProfile = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/${id}/?id=${id}`
      )
      .then((resp) => {
        // console.log(resp.data);
        const putUser = {
          username: resp.data.username,
          password: resp.data.password,
          first_name: name.split(' ').shift(),
          last_name: getLastName(name),
        };
        // console.log(putUser);

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/user_management/user/${id}/?id=${id}`,
            putUser
          )
          .then((resp) => {
            // console.log(resp.data);
          })
          .catch((err) => {
            // console.log(err.response);
          });
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  const otpDisableFunction = () => {
    setTimeout(() => {
      setDisableOtp(false);
    }, 30000);
  };

  const handleFirstStep = (e) => {
    e.preventDefault();

    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(signupNumber)) {
      toast.error('Please enter a valid Bangladeshi number!', {
        autoClose: 3000,
      });
    } else {
      setDisableOtp(true);
      setLoadingFirst(true);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/send-otp/${signupNumber}`
        )
        .then((resp) => {
          // //console.log(resp.data);
          setSignupStep('second');
          toast.success(`OTP has been sent to your mobile, please check.`, {
            autoClose: 3000,
          });
          otpDisableFunction();
          setLoadingFirst(false);
        })
        .catch((error) => {
          if (error.response.data && error.response.data.sms) {
            toast.error(
              'Sorry, user already exists, please try again with another phone number.'
            );
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
          setLoadingFirst(false);
          setDisableOtp(false);
          // //console.log(error.response.status);
          // //console.log(error.response.data);
        });
    }
  };

  const handleSecondStep = (e) => {
    e.preventDefault();

    if (otp === '') {
      toast.error(`Please enter OTP.`, {
        autoClose: 3000,
      });
    } else {
      setLoadingSecond(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/match-otp/?mobile=${signupNumber}&page=1&limit=1&ofset=0`
        )
        .then((resp) => {
          const dbCode = resp.data.results[0].code;
          // //console.log(dbCode);
          if (dbCode === parseInt(otp)) {
            setSignupStep('third');
          } else if (dbCode !== parseInt(otp)) {
            toast.error(`OTP doesn't matched, please try again.`, {
              autoClose: 3000,
            });
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
          setLoadingSecond(false);
        })
        .catch((error) => {
          //console.log(error.response.status);
          //console.log(error.response.data);
          setLoadingSecond(false);
        });
    }
  };

  const handleThirdStep = (e) => {
    e.preventDefault();

    if (name.trim() === '') {
      toast.error('Please enter your name!', {
        autoClose: 3000,
      });
      return;
    }

    if (name.trim().length < 4) {
      toast.error('Name must be at least 4 character.', {
        autoClose: 3000,
      });
      return;
    }

    if (dob === '' || dob === null) {
      toast.error('Please select your date of birth.', {
        autoClose: 3000,
      });
      return;
    }

    if (gender === '') {
      toast.error('Please select your gender.', {
        autoClose: 3000,
      });
      return;
    }

    if (signupPassword === '') {
      toast.error('Please enter a password.', {
        autoClose: 3000,
      });
      return;
    }

    const postData = {
      username: signupNumber,
      password1: signupPassword,
      password2: signupPassword,
    };

    setLoadingThird(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/registration`, postData)
      .then((resp) => {
        axios.interceptors.request.use((config) => {
          const token = 'Token ' + resp.data.key;
          config.headers.Authorization = token;
          return config;
        });
        localStorage.clear();
        localStorage.setItem('token', JSON.stringify(resp.data.key));

        axios
          .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
          .then((resp) => {
            updateUserProfile(resp.data.pk);
            setUserDetails(resp.data);
            setIsLoggedIn(true);

            const patientData = {
              is_account: true,
              user: resp.data.pk,
              full_name: name,
              sex: gender,
              dob: moment(dob).format('YYYY-MM-DD'),
              mobile: signupNumber,
            };

            localStorage.removeItem('user_id');
            localStorage.setItem('user_id', JSON.stringify(resp.data.pk));

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
                patientData
              )
              .then((resp) => {
                toggleLoggedIn();
                const mySelf = [resp.data];
                setUserMyself(mySelf);
                hideSignupModal();
                toast.success(`Account created successfully!`, {
                  autoClose: 2000,
                });
                localStorage.setItem('userPatients', JSON.stringify(mySelf));
                // setTimeout(() => {
                //   window.location.reload();
                // }, 2000);
                setLoadingThird(false);
              });
          });
      })
      .catch((error) => {
        setLoadingThird(false);
        if (error.response.status === 400) {
          if (error.response.data.username) {
            toast.error(`${error.response.data.username}`, {
              autoClose: 3000,
            });
          } else if (error.response.data.password1) {
            if (error.response.data.password1[2]) {
              toast.error(`${error.response.data.password1[2]}`, {
                autoClose: 3000,
              });
            } else if (error.response.data.password1[1]) {
              toast.error(`${error.response.data.password1[1]}`, {
                autoClose: 3000,
              });
            } else if (error.response.data.password1[0]) {
              toast.error(`${error.response.data.password1[0]}`, {
                autoClose: 3000,
              });
            }
          }
        } else {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 3000,
          });
        }
      });
  };

  return (
    <>
      <section className={`user-tab-mbl ${showUserMobile ? 'active' : ''}`}>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <div className='details'>
                {isLoggedIn && (
                  <ul>
                    <li
                      onClick={() => {
                        handleLink('/profile');
                        postActivity('menu', 'Profile', window.location.href);
                      }}
                    >
                      <p>My Profile</p>
                      <img src={RightArrow} alt='' />
                    </li>
                    <li
                      onClick={() => {
                        handleLink('/order-history');
                        postActivity(
                          'menu',
                          'Order History',
                          window.location.href
                        );
                      }}
                    >
                      <p>My Orders & Reports</p>
                      <img src={RightArrow} alt='' />
                    </li>
                    {/* <li onClick={() => handleLink('/change-password')}>
                      <p>Change Password</p>
                      <img src={RightArrow} alt='' />
                    </li> */}
                  </ul>
                )}
                <div className='buttons'>
                  <>
                    {isLoggedIn ? (
                      <a
                        href='?#'
                        onClick={(e) => {
                          handleLogout(e);
                          postActivity('menu', 'Logout', window.location.href);
                        }}
                        className='btn btn-primary btn-block signup'
                      >
                        Logout
                      </a>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setShowSignupModal(true);
                            postActivity(
                              'menu',
                              'Signup',
                              window.location.href
                            );
                          }}
                          className='btn btn-primary btn-block signup'
                        >
                          Signup
                        </button>
                        <button
                          onClick={() => {
                            setShowLoginModal(true);
                            postActivity('menu', 'Login', window.location.href);
                          }}
                          className='btn btn-primary btn-block login'
                        >
                          Login
                        </button>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        animation={false}
        centered
      >
        <ModalBody>
          <div className='mbl-logsign-modal lgin'>
            <div className='head'>
              <h1>Login</h1>
              <div className='right'>
                <img
                  src={CloseBtn}
                  alt=''
                  onClick={() => setShowLoginModal(false)}
                />
              </div>
            </div>
            <div className='body'>
              <form
                onSubmit={(e) =>
                  handleLogin(
                    e,
                    toggleLoggedIn,
                    setUserMyself,
                    setUserFamily,
                    setCartsItem
                  )
                }
              >
                <div className='form-group'>
                  <input
                    type='number'
                    placeholder='Mobile Number'
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <div className='show-hide'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Account Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img
                      src={showPassword ? EyeOff : Eye}
                      alt=''
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
                <div className='form-group submittt'>
                  {!Loading ? (
                    <button
                      type='submit'
                      className='submit-button btn btn-primary btn-block'
                    >
                      Login
                    </button>
                  ) : (
                    <LoadingBtn btnText={'Login...'} />
                  )}
                </div>
              </form>
              <div className='bottom'>
                <p>
                  Forgot Password?{' '}
                  <span
                    onClick={() => {
                      setShowLoginModal(false);
                      history.push('/reset-password');
                      postActivity(
                        'button',
                        'Reset Password',
                        window.location.href
                      );
                    }}
                  >
                    {' '}
                    Recover
                  </span>
                </p>
                <p className='mb-0'>
                  New to AmarLab?{' '}
                  <span
                    onClick={() => {
                      setShowLoginModal(false);
                      setShowSignupModal(true);
                      postActivity('button', 'Signup', window.location.href);
                    }}
                  >
                    {' '}
                    Signup
                  </span>
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={showSignupModal}
        onHide={hideSignupModal}
        animation={false}
        centered
      >
        <ModalBody>
          <div className='mbl-logsign-modal sgnp'>
            <div className='head'>
              <h1>Signup</h1>
              <div className='right'>
                {signupStep === 'first' && (
                  <span className='reset' onClick={() => setSignupNumber('')}>
                    Reset
                  </span>
                )}
                <img src={CloseBtn} alt='' onClick={hideSignupModal} />
              </div>
            </div>
            <div className='body'>
              {signupStep === 'first' && (
                <>
                  <form onSubmit={handleFirstStep}>
                    <div className='form-group'>
                      <input
                        type='number'
                        placeholder='Mobile Number'
                        value={signupNumber}
                        onChange={(e) => setSignupNumber(e.target.value)}
                      />
                    </div>
                    {/* <p className='para'>
                      Note: Making Healthcare Easily Accessible to Everyone,
                      Everywhere.
                    </p> */}
                    <div className='form-group submittt'>
                      {!LoadingFirst ? (
                        <button
                          type='submit'
                          className='submit-button btn btn-primary btn-block'
                          disabled={disableOtp ? 'disabled' : ''}
                        >
                          Send OTP
                        </button>
                      ) : (
                        <LoadingBtn btnText='Sending...' />
                      )}
                    </div>
                  </form>
                  <div className='bottom'>
                    <p className='mb-0'>
                      Already have an account?{' '}
                      <span
                        onClick={() => {
                          setShowSignupModal(false);
                          setShowLoginModal(true);
                          postActivity('button', 'Login', window.location.href);
                        }}
                      >
                        {' '}
                        Login
                      </span>
                    </p>
                  </div>
                </>
              )}
              {signupStep === 'second' && (
                <form onSubmit={handleSecondStep} className='second'>
                  <div className='form-group'>
                    <input
                      type='number'
                      placeholder='Mobile Number'
                      value={signupNumber}
                      onChange={(e) => setSignupNumber(e.target.value)}
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='number'
                      placeholder='Enter OTP'
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <div className='form-group submittt'>
                    {!LoadingSecond ? (
                      <button
                        type='submit'
                        className='submit-button btn btn-primary btn-block'
                      >
                        Validate & Next
                      </button>
                    ) : (
                      <LoadingBtn btnText='Validate & Next...' />
                    )}
                  </div>
                </form>
              )}
              {signupStep === 'third' && (
                <form onSubmit={handleThirdStep} className='third'>
                  <div className='form-group'>
                    <input
                      type='number'
                      placeholder='Mobile Number'
                      value={signupNumber}
                      onChange={(e) => setSignupNumber(e.target.value)}
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      placeholder='Enter your name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className='form-group'>
                    <PatientDob
                      placeholder='Date of Birth'
                      patientDob={dob}
                      setPatientDob={setDob}
                    />
                  </div>
                  <div className='form-group'>
                    <select
                      className='sex'
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option disabled value=''>
                        Select gender
                      </option>
                      <option value='male'>M</option>
                      <option value='female'>F</option>
                      <option value='common'>T</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <div className='show-hide'>
                      <input
                        type={showPasswordSign ? 'text' : 'password'}
                        placeholder='Enter Password'
                        value={signupPassword}
                        onChange={(e) => setSignupPassword(e.target.value)}
                      />
                      <img
                        src={showPasswordSign ? EyeOff : Eye}
                        alt=''
                        onClick={() => setShowPasswordSign(!showPasswordSign)}
                      />
                    </div>
                  </div>
                  <div className='form-group submittt'>
                    {!LoadingThird ? (
                      <button
                        type='submit'
                        className='submit-button btn btn-primary btn-block'
                      >
                        Create Account
                      </button>
                    ) : (
                      <LoadingBtn btnText='Creating...' />
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UserTabMobile;
