import axios from 'axios';
import { useState, useEffect } from 'react';
import CountUp from 'react-countup';

const Counters = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-summery-home`)
      .then((resp) => {
        // console.log(resp.data);
        setData(resp.data);
      });
  }, []);

  return (
    <section className='counter'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-8 content'>
            <div className='first'>
              <h5>
                <CountUp end={5} duration={1} /> Years+
              </h5>
              <p>Services</p>
            </div>
            <div className='div'></div>
            <div className='second'>
              <h5>
{/*                <CountUp
                  end={data ? data.today_order_confirm + 50000 : 0}
                  duration={2}
                />{' '}*/}
                100,000
                +
              </h5>
              <p>Tests done</p>
            </div>
            {/* <div className='div'></div>
            <div className='third'>
              <h5>
                <CountUp end={data ? data.patient + 15000 : 0} duration={2} /> +
              </h5>
              <p>Happy Users</p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counters;
