import Carousel from 'react-multi-carousel';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const BusinessPartner = () => {
  const { postActivity } = useContext(AuthContext);
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/press/banner-get/?banner_type=business&limit=100`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        setPartners(resp.data.results);
      });
  }, []);

  return (
    <section className='business-partner'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='section-heading text-center'>
              Trusted By Over 80+ Businesses
            </h5>
          </div>
          {partners && (
            <div className='col-12'>
              <Carousel
                autoPlay={true}
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=''
                containerClass='container carousel-container'
                dotListClass=''
                draggable
                focusOnSelect={false}
                infinite={true}
                itemClass='test'
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 4,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 3,
                    partialVisibilityGutter: 30,
                  },
                }}
                showDots={false}
                sliderClass=''
                slidesToSlide={1}
                swipeable
              >
                {partners &&
                  partners.map((partner, i) => (
                    <div
                      className='img'
                      key={i}
                      onClick={() =>
                        postActivity(
                          'box',
                          'Business Partner',
                          window.location.href
                        )
                      }
                    >
                      <img src={partner.image} alt='' />
                    </div>
                  ))}
              </Carousel>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BusinessPartner;
