import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

class TermsOfService extends Component {
  state = {};

  componentDidMount() {
    this.context.setPageTitle('Terms Of Service');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <section className='privacy-policy'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <p>
                  Welcome to{' '}
                  <Link to='/' className='text-primary'>
                    {' '}
                    www.amarlab.com!
                  </Link>{' '}
                  <br /> <br />
                  These terms and conditions outline the rules and regulations
                  for the use of AmarLab Limited’s Website, located at Office: Level 3, House-15, Road-105, Gulshan-2, Dhaka-1212
                  <br />
                  <br />
                  By accessing this website, we assume you accept these terms
                  and conditions. Do not continue to use{' '}
                  <Link to='/' className='text-primary'>
                    www.amarlab.com
                  </Link>{' '}
                  if you do not agree to take all of the terms and conditions
                  stated on this page.
                  <br />
                  <br />
                  The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice and all
                  Agreements: “Client”, “You” and “Your” refers to you, the
                  person log on this website and compliant to the Company’s
                  terms and conditions. “The Company”, “Ourselves”, “We”, “Our”
                  and “Us”, refers to our Company. “Party”, “Parties”, or “Us”,
                  refers to both the Client and ourselves. All terms refer to
                  the offer, acceptance, and consideration of payment necessary
                  to undertake the process of our assistance to the Client in
                  the most appropriate manner for the express purpose of meeting
                  the Client’s needs in respect of provision of the Company’s
                  stated services, in accordance with and subject to, prevailing
                  law of Netherlands. Any use of the above terminology or other
                  words in the singular, plural, capitalization and/or he/she or
                  they, are taken as interchangeable and therefore as referring
                  to same.
                </p>
                <h5 className='main-heading'>Basic Terms</h5>
                <p>
                  By law you will be responsible for any or all activity that
                  take place and content that is submitted in{' '}
                  <Link to='/' className='text-primary'>
                    {' '}
                    www.amarlab.com
                  </Link>
                  (or other owned and operated properties) under your registered
                  username or display name with the site.
                  <br />
                  <br />
                  <ol>
                    <li>
                      it’s your responsibility to keep your password safe and
                      sound.
                    </li>
                    <li>
                      In no condition or situation you can discriminate, abuse,
                      harass, threaten, impersonate, or intimidate others in the
                      user community, public figures, or companies.
                    </li>
                    <li>
                      No services of AmarLab is to use for any unlawful or
                      unconstitutional purpose & should guarantee that the
                      service does not go against national security and/or
                      national significance of Bangladesh and if you do then you
                      shall solely accept the charges arising out of this end if
                      any.
                    </li>
                    <li>
                      You are the only one to be held responsible for your
                      behavior and any data, text, information, screen names,
                      graphics, photos, profiles, audio and video clips,
                      copyright of the content, links, that you present, post,
                      and display on these services.
                    </li>
                    <li>
                      You will not give out or include spam, chain letters, or
                      pyramid schemes, or any code of a destructive nature like
                      viruses or any other technologies that may harm AmarLab or
                      the interests or property of AmarLab users.
                    </li>
                    <li>
                      You must not compel an irrational load on our
                      infrastructure or server with the proper working of
                      AmarLab.
                    </li>
                    <li>
                      You must not copy, modify, or distribute information
                      gathered through AmarLab services to generate or submit
                      redundant email to anyone.
                    </li>
                    <li>
                      In using of AmarLab services you must not violate any laws
                      in your authority (including but not limited to copyright
                      laws). Violating of any of the above agreements may cause
                      for subject to termination of AmarLab user account or
                      legal action accordance on the sternness of violation.
                      while AmarLab forbids intolerable demeanor and content on
                      the site, you recognize and agree that AmarLab cannot be
                      and will not be responsible or liable for the user
                      generated content posted on its web site and you
                      nevertheless may be bare to such materials and that you
                      use these services at your own risk and with full consent.
                    </li>
                  </ol>
                </p>
                <h5 className='main-heading'>General Conditions</h5>
                <p>
                  <ol>
                    <li>
                      AmarLab holds the right to alter or terminate any of its
                      service at any time for any reason without any prior
                      notice.
                    </li>
                    <li>
                      All AmarLab services are generally free, but we may charge
                      a fee for certain services. If any service you use is
                      charged with a fee, you’ll be able to review and accept
                      terms that will be clearly disclosed at that time.
                    </li>
                    <li>
                      If AmarLab alter these terms of service and if the
                      modifications comprise a material change to the terms of
                      use, AmarLab will inform you via email which is given
                      preference expressed on your account at the site. What
                      constitutes a “material change” will be resolute at Amar
                      Lab’s sole discretion, with good faith and using common
                      sense and reasonable findings.
                    </li>
                    <li>
                      AmarLab holds the right to decline service to anyone for
                      any reason at any time.
                    </li>
                    <li>
                      AmarLab may but have no obligation to remove content and
                      accounts containing content that we decide in our sole
                      judgment are illegal, odious, intimidating, defamatory,
                      obscene or otherwise objectionable or violates any other
                      user’s or any third party’s intellectual property or these
                      terms of service.
                    </li>
                    <li>
                      AmarLab admits and supports posting of content from
                      AmarLab services to be displayed on external websites.
                      However, pages on other websites which display content
                      hosted on AmarLab server must quote an appropriate source
                      and provide a link to it.
                    </li>
                  </ol>
                </p>
                <h5 className='main-heading'>You must not:</h5>
                <p>
                  Republish material from{' '}
                  <Link to='/' className='text-primary'>
                    {' '}
                    www.amarlab.com
                  </Link>
                  <br />
                  Sell, rent or sub-license material from{' '}
                  <Link to='/' className='text-primary'>
                    www.amarlab.com
                  </Link>{' '}
                  <br /> Reproduce, duplicate or copy material from{' '}
                  <Link to='/' className='text-primary'>
                    www.amarlab.com
                  </Link>{' '}
                  <br /> Redistribute content from{' '}
                  <Link to='/' className='text-primary'>
                    {' '}
                    www.amarlab.com
                  </Link>
                  <br />
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. AmarLab Limited does not filter, edit, publish or
                  review Comments prior to their presence on the website.
                  Comments do not reflect the views and opinions of AmarLab
                  Limited,its agents and/or affiliates. Comments reflect the
                  views and opinions of the person who post their views and
                  opinions. To the extent permitted by applicable laws, AmarLab
                  Limited shall not be liable for the Comments or for any
                  liability, damages or expenses caused and/or suffered as a
                  result of any use of and/or posting of and/or appearance of
                  the Comments on this website. <br />
                  <br /> AmarLab Limited reserves the right to monitor all
                  Comments and to remove any Comments which can be considered
                  inappropriate, offensive or causes breach of these Terms and
                  Conditions.
                </p>
                <h5 className='main-heading'>
                  You warrant and represent that:
                </h5>
                <p>
                  You are entitled to post the Comments on our website and have
                  all necessary licenses and consents to do so; <br />
                  The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party;
                  <br />
                  The Comments do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy <br />
                  The Comments will not be used to solicit or promote business
                  or custom or present commercial activities or unlawful
                  activity. <br />
                  You hereby grant AmarLab Limited a non-exclusive license to
                  use, reproduce, edit and authorize others to use, reproduce
                  and edit any of your Comments in any and all forms, formats or
                  media.
                </p>
                <h5 className='main-heading'>Copyright:</h5>
                <p>
                  AmarLab holds or claims no intellectual property rights or
                  copyrights over the material you provide to any of Amarlab’s
                  service. Your registered profile and materials uploaded in
                  your profile remain yours. You have the right to remove your
                  profile at any time by deleting or disabling your account on
                  the site. This will also eliminate any text and images you
                  have stored in the system of AmarLab. AmarLab heartens users
                  to contribute and share their creations, news, views, and
                  opinions with the AmarLab user community through its services.
                  AmarLab embark on to obey all appropriate copyright laws.
                  AmarLab will review all claims of copyright breach received
                  and remove the content deemed to have been posted or
                  distributed in violation of any such laws if it finds the
                  objection valid. To make a claim, please provide us with the
                  following:1. a physical or electronic signature of the
                  copyright owner or the person authorized to act on the owner’s
                  behalf with the proper consent of the owner. <br /> <br />
                  <ol>
                    <li>
                      a narrative of the copyrighted work claimed to have been
                      infringed.
                    </li>
                    <li>
                      a description of the infringing material and information
                      logically sufficient to permit AmarLab to trace the
                      material.
                    </li>
                    <li>
                      your full contact information, including your present
                      address, telephone number, and a valid email address.
                    </li>
                    <li>
                      a testimonial by you that you, to the best of your
                      awareness, believe that use of the material in the manner
                      complained of is not certified by the copyright owner, its
                      agent, or the law; and
                    </li>
                    <li>
                      a statement that the information in the notice is
                      accurate, and, under the pains and penalties of false
                      swearing, that you are sanctioned to act on behalf of the
                      copyright owner.
                    </li>
                  </ol>
                </p>
                <h5 className='main-heading'>RESOLUTION OF DISPUTES</h5>
                <p>
                  If a disagreement occurs between you and AmarLab, we strongly
                  recommend you to first contact us directly to seek a
                  resolution by going to the AmarLab contact page. We will
                  consider rational requests to resolve the dispute through
                  alternative dispute resolution procedures, such as mediation
                  or arbitration, as alternatives to litigation. AmarLab
                  encourages amicable settlement of any conflict/s that might
                  arise among you, AmarLab or any other user including the Lab
                  Partners. Any such conflict shall be resolved with the
                  assistance or involvement of AmarLab. For addressing any
                  grievances, or any other issues, transaction disputes you are
                  encouraged to report the same to Customer Support Center
                  (“Dispute Notice”). On service of the Dispute Notice, AmarLab
                  and you will attempt in good faith to resolve the dispute. If
                  the dispute cannot be resolved by consultation within 28
                  (twenty-eight) days from the date of commencement of such
                  consultation, then AmarLab and you will jointly engage a
                  mediator. The procedure for the mediation shall be in
                  accordance with section 89A of the Code of Civil Procedure,
                  1908. If either AmarLab or you fail or refuse to agree to or
                  participation in the mediation procedure or if in any event
                  the dispute or difference is not resolved to satisfaction of
                  both parties within 30 (thirty) days after it has been
                  referred, the matter shall be referred for arbitration and
                  settled in accordance with the Arbitration Act, 2001. The
                  decision of the arbitrator of the arbitral tribunal will be
                  final and binding upon the Parties. Each Party shall bear
                  their costs of arbitration. The venue for arbitration shall be
                  Dhaka and the proceedings of arbitration shall be held in the
                  English language.
                </p>
                <h5 className='main-heading'>GOVERNING LAW AND JURISDICTION</h5>
                <p>
                  This Terms & Condition shall be governed by and construed in
                  accordance with the laws of Bangladesh. The courts of
                  Bangladesh shall have exclusive jurisdiction in relation to
                  all disputes or matters arising hereunder, and the parties
                  irrevocably submit themselves to the exclusive jurisdiction of
                  the courts in Bangladesh, for the resolution of any disputes
                  arising under or in connection with this Agreement.
                </p>
                <h5 className='main-heading'>INDEMNITY</h5>
                <p>
                  ou agree to release, defend, indemnify, and hold the PLATFORM
                  or any person associated with the PLATFORM including its
                  respective affiliates, service providers, owners, partners,
                  directors, officers, agents and employees (collectively as
                  “Indemnified Parties”) free and harmless from and against any
                  claims, liens, demands, causes of action and all losses to the
                  extent arising from or in connection with any or all of: (a)
                  your breach or violation of any of these T&C; (b) any
                  intellectual property rights infringement including but not
                  limited to copyrights, trademarks or any other rights; loss,
                  damages, expenses, fines, fees or liability brought or
                  instituted by a third party against the PLATFORM which is
                  caused or contributed to by you through your use of the
                  PLATFORM or the negligence or willful misconduct of yourself;
                  (c) any violation or alleged violation of any law, including
                  breach of Digital Security Act, 2018; (d) your violation of
                  the rights of any third party, including third party partners.{' '}
                  <br />
                  <br />
                  The PLATFORM or any person associated with the PLATFORM, is
                  not liable for any personal injury, including death,
                  attributable to or caused by your use or misuse of the
                  PLATFORM, to the fullest extent permissible by law.
                </p>
                <h5 className='main-heading'>LIMITATION OF LIABILITY</h5>
                <p>
                  In no event shall AmarLab or its affiliates be liable for any
                  indirect or consequential damages, punitive or consequential
                  damages, including lost profits, lost data, personal injury or
                  property damage related to, in connection with, or otherwise
                  resulting from any use of the services including loss of
                  profits, incurred by the other party, any end-user, of the
                  platform or any other third party, whether in an action in
                  contract or tort or otherwise, in connection with or under
                  these terms & conditions, even if such party has been advised
                  of the possibility of such damages. <br />
                  <br />
                  AmarLab shall not be liable for any damages, liability or
                  losses arising out of: (i)your use of or reliance on the
                  services or your inability to access or use the services, or
                  (ii) any transaction or relationship between you and any third
                  party. In no event shall AmarLab total liability to you in
                  connection with the services for all damages, losses and cause
                  of action exceed one thousand bangladeshi taka.
                </p>
                <h5 className='main-heading'>DISCLAIMER</h5>
                <p>
                  Use of the platform is at your own risk. the app/website is
                  provided on an “as is” basis. to the maximum extent permitted
                  by law: (a) AmarLab disclaims all liability whatsoever,
                  whether arising in contract, tort (including negligence) or
                  otherwise in relation to the app/website, and (b) all implied
                  warranties, terms and conditions relating to the platform,
                  including without limitation any warranty, term or condition
                  as to accuracy, completeness, satisfactory quality,
                  performance, fitness for purpose or any special purpose,
                  availability, non-infringement, information accuracy,
                  interoperability, title are, as between AmarLab and you,
                  hereby excluded, in particular, but without prejudice to the
                  foregoing, AmarLab accepts no responsibility for any technical
                  failure of the internet and/or the platform, or any damage or
                  injury to users or their equipment as a result of or relating
                  to their use of the platform. your statutory rights are not
                  affected.
                </p>
                <h5 className='main-heading'>MODIFICATION OF T&C</h5>
                <p>
                  <ol>
                    <li>
                      The PLATFORM reserves the right, at its sole discretion,
                      to change, modify or otherwise alter these T & C at any
                      time, by posting the updated T&C on the PLATFORM.
                    </li>
                    <li>
                      It is your sole responsibility to check for any such
                      changes, modification or alteration made to the T&C and to
                      familiarize yourself with it. Continued use of the
                      PLATFORM shall be deemed as your assent to be bound by the
                      T&C. If you do not wish to be bound by the updated T&C,
                      you shall log out and discontinue the use of PLATFORM.
                    </li>
                  </ol>
                </p>
                <h5 className='main-heading'>WAIVER</h5>
                <p>
                  Non-exercise or failure to exercise or delay in exercising any
                  right or provision of the T&C, shall not constitute a waiver
                  of such right or provision by AmarLab.
                </p>
                <h5 className='main-heading'>SEVERABILITY</h5>
                <p>
                  If any part of these T&C is determined to be illegal, invalid,
                  frustrated, or unenforceable, for any reason, then, insofar as
                  is practical and feasible, the remaining portions of the T&C
                  shall be deemed to be in full force and effect as if such
                  invalid portions were not contained herein.
                </p>
                <h5 className='main-heading'>TERMINATION</h5>
                <p>
                  You may end your legal agreement with AmarLab at any time by
                  deactivating your accounts and discontinuing your use of the
                  PLATFORM.
                  <br />
                  <br />
                  AmarLab may suspend or terminate your account or cease
                  providing you with all or part of the Services at any time for
                  reasons including but not limited to <br />
                  <br />
                  <ol>
                    <li>your violation of these Terms & Conditions.</li>
                    <li>
                      you create risk of possible legal exposure for AmarLab by
                      your use of the Services.
                    </li>
                    <li>Prolonged inactivity on your account; or</li>
                    <li>
                      AmarLab provision of the Services to you is no longer
                      commercially viable. AmarLab will make reasonable efforts
                      to notify you by the email address associated with your
                      account or the next time you attempt to access your
                      account. Depending on the circumstances, in all such
                      cases, the relationship shall terminate, including without
                      limitation your license to use the Services, except that
                      the accrued rights of AmarLab, and the Clients, until the
                      termination of the Terms & Conditions, in respect of the
                      Services, shall continue to remain valid.
                    </li>
                  </ol>
                </p>
                <h5 className='main-heading'>GOVERNMENT AGENCIES</h5>
                <p>
                  Search engines; <br /> <br />
                  News organizations; <br />
                  <br />
                  Online directory distributors may link to our Website in the
                  same manner as they hyperlink to the Websites of other listed
                  businesses; and <br />
                  <br />
                  System wide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site. <br />
                  <br />
                  These organizations may link to our home page, to publications
                  or to other Website information so long as the link: (a) is
                  not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products and/or services; and (c) fits within the context
                  of the linking party’s site. We may consider and approve other
                  link requests from the following types of organizations:
                  commonly-known consumer and/or business information sources;
                  dot.com community sites; associations or other groups
                  representing charities; online directory distributors;
                  internet portals; accounting, law and consulting firms; and
                  educational institutions and trade associations. We will
                  approve link requests from these organizations if we decide
                  that: (a) the link would not make us look unfavorably to
                  ourselves or to our accredited businesses; (b) the
                  organization does not have any negative records with us; (c)
                  the benefit to us from the visibility of the hyperlink
                  compensates the absence of AmarLab Limited; and (d) the link
                  is in the context of general resource information. These
                  organizations may link to our home page so long as the link:
                  (a) is not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products or services; and (c) fits within the context of
                  the linking party’s site. If you are one of the organizations
                  listed in paragraph 2 above and are interested in linking to
                  our website, you must inform us by sending an e-mail to
                  AmarLab Limited. Please include your name, your organization
                  name, contact information as well as the URL of your site, a
                  list of any URLs from which you intend to link to our Website,
                  and a list of the URLs on our site to which you would like to
                  link. Wait 2-3 weeks for a response. Approved organizations
                  may hyperlink to our Website as follows: By use of our
                  corporate name; or By use of the uniform resource locator
                  being linked to; or By use of any other description of our
                  Website being linked to that makes sense within the context
                  and format of content on the linking party’s site. No use of
                  AmarLab Limited's logo or other artwork will be allowed for
                  linking absent a trademark license agreement. Without prior
                  approval and written permission, you may not create frames
                  around our WebPages that alter in any way the visual
                  presentation or appearance of our Website. Content Liability
                  We shall not be hold responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims that is rising on your Website. No link(s) should
                  appear on any Website that may be interpreted as libelous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any third
                  party rights. Your Privacy Please read Privacy Policy
                  Reservation of Rights We reserve the right to request that you
                  remove all links or any particular link to our Website. You
                  approve to immediately remove all links to our Website upon
                  request. We also reserve the right to amen these terms and
                  conditions and it’s linking policy at any time. By
                  continuously linking to our Website, you agree to be bound to
                  and follow these linking terms and conditions. Removal of
                  links from our website If you find any link on our Website
                  that is offensive for any reason, you are free to contact and
                  inform us any moment. We will consider requests to remove
                  links but we are not obligated to or so or to respond to you
                  directly. We do not ensure that the information on this
                  website is correct, we do not warrant its completeness or
                  accuracy; nor do we promise to ensure that the website remains
                  available or that the material on the website is kept up to
                  date. Disclaimer To the maximum extent permitted by applicable
                  law, we exclude all representations, warranties and conditions
                  relating to our website and the use of this website. Nothing
                  in this disclaimer will: limit or exclude our or your
                  liability for death or personal injury; limit or exclude our
                  or your liability for fraud or fraudulent misrepresentation;
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or exclude any of our or your
                  liabilities that may not be excluded under applicable law. The
                  limitations and prohibitions of liability set in this Section
                  and elsewhere in this disclaimer: (a) are subject to the
                  preceding paragraph; and (b) govern all liabilities arising
                  under the disclaimer, including liabilities arising in
                  contract, in tort and for breach of statutory duty. As long as
                  the website and the information and services on the website
                  are provided free of charge, we will not be liable for any
                  loss or damage of any nature.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TermsOfService;
TermsOfService.contextType = AuthContext;
