import Logo from '../assets/img/logo.png';
import Logo2 from '../assets/img/AmarLab_logo_new.png';
import Promotion from '../assets/img/promotion.png';
import CartLogo from '../assets/img/cart.png';
import OrderIcon from '../assets/img/right.png';
import PolygonIcon from '../assets/img/polygon.png';
import Maps from '../assets/img/maps.png';
import CartMobileImg from '../assets/img/CartMobile.png';
import UserMobileImg from '../assets/img/UserMobile.png';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import Cart from './Cart';
import { Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import CartMobile from './CartMobile';
import UserTabMobile from './UserTabMobile';
import MblHamburg from './MblHamburg';

const Navbarr = ({ isLoggedIn, loginHandler }) => {
  const {
    suMyself,
    carts,
    unauthorized,
    navAreaName,
    setNavAreaName,
    postActivity,
    setIsLoggedIn,
  } = useContext(AuthContext);

  const history = useHistory();

  const [isVisible, setIsVisible] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showCartMobile, setShowCartMobile] = useState(false);
  const [showUserMobile, setShowUserMobile] = useState(false);
  const [showNavMobile, setShowNavMobile] = useState(false);

  // const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  // const [userDefaultSelect, setUserDefaultSelect] = useState('Myself');

  const [locationDropdownVisible, setLocationDropdownVisible] = useState(false);
  const [locationDefaultSelect, setLocationDefaultSelect] =
    useState(navAreaName);
  const [loginDropVisible, setLoginDropVisible] = useState(false);

  const [showHeaderSecondPart, setShowHeaderSecondPart] = useState(true);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname.match(/\w+-offers/g)) setShowHeaderSecondPart(false);
    else setShowHeaderSecondPart(true);
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsVisible(window.scrollY > 500);
    });
  }, []);

  useEffect(() => {
    setLocationDefaultSelect(navAreaName);
  }, [navAreaName]);

  const backToTop = () => {
    window.scrollTo(0, 0);
    postActivity('button', 'Back to Top', window.location.href);
  };

  const handleCart = () => {
    setShowCart(!showCart);
    postActivity('menu', 'Cart', window.location.href);
  };

  const handleCartMobile = () => {
    // setShowCart(!showCart);
    postActivity('menu', 'Cart', window.location.href);
    history.push(`/cart`);
    // setShowCartMobile(true);

    // const body = document.body;
    // body.style.height = '100vh';
    // body.style.overflowY = 'hidden';
  };

  const handleUserMobile = () => {
    setShowUserMobile(true);
  };

  // const handleUserSelect = (name) => {
  //   setUserDropdownVisible(false);
  //   ////console.log(name);
  //   setUserDefaultSelect(name);
  // };

  const handleLocationSelect = (name) => {
    postActivity('menu', `Location (${name})`, window.location.href);
    setLocationDropdownVisible(false);
    ////console.log(name);
    setLocationDefaultSelect(name);
    setNavAreaName(name);
    localStorage.setItem('navAreaName', JSON.stringify(name));
  };

  const handleLogout = (e) => {
    postActivity('menu', 'Logout', window.location.href);
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/logout/`)
      .then((resp) => {
        ////console.log(resp.data);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('userPatients');
        // localStorage.clear();
        loginHandler(false);
        window.location.href = '/';
        setIsLoggedIn(false);
      })
      .catch((error) => {
        // console.log(error.response.status);
        if (error.response.status === 401) {
          unauthorized();
          loginHandler(false);
        }
      });
  };

  // const getFirstName = (string) => {
  //   return string.split(' ').slice(0, 1).join(' ');
  // };

  const handleHamburgMenu = () => {
    setShowNavMobile(true);
    const body = document.body;
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
    postActivity('menu', 'Mobile Navigation Open', window.location.href);
  };

  return (
    <div className="sticky-top">
      <section className="semi-nav">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between align-items-center content">
              <div className="left">
                {/* <img src={Promotion} alt='' className='promotion' /> */}
                {/* Offering up to 15% discount on all health package.{' '} */}
                {/* <Link to='/diagnostic' className='text-white'>
                  ORDER NOW <img src={OrderIcon} alt='' />
                </Link> */}
                <div
                  className="call"
                  onClick={() =>
                    postActivity("menu", "Call Number", window.location.href)
                  }
                >
                  Call For Order:{" "}
                  <a href="tel:09643445566" className="text-darkk">
                    09643445566
                  </a>
                </div>
              </div>
              <div className="right d-flex">
                <div className="header">
                  <ul className="social-icons mt-0">
                    <li>
                      <a
                        href="https://www.facebook.com/amarlab.bd"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          postActivity("menu", "Facebook", window.location.href)
                        }
                      >
                        <i className="fab fa-facebook facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/amarlab/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          postActivity("menu", "Linkedin", window.location.href)
                        }
                      >
                        <i className="fab fa-linkedin linkedin"></i>
                      </a>
                    </li>
                    {/* <li>
                    <a href='?#' rel='noopener noreferrer' target='_blank'>
                      <i className='fab fa-twitter twitter'></i>
                    </a>
                  </li> */}
                    <li className="mr-0">
                      <a
                        href="https://www.instagram.com/amarlab.bd"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() =>
                          postActivity(
                            "menu",
                            "Instagram",
                            window.location.href
                          )
                        }
                      >
                        <i className="fab fa-instagram-square instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* <div className='language ml-4'>
                  <i className='fas fa-globe-asia mr-1'></i> <span>ENG</span>
                  <i className='fas fa-angle-down ml-1'></i>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Navbar expand="lg" className="shadow-sm">
        <div className="container main-con">
          <NavbarBrand>
            <Link
              to="/"
              onClick={() => postActivity("menu", "Home", window.location.href)}
              className="d-flex align-items-center"
            >
              <img src={Logo} alt="" />{" "}
              <img src={Logo2} alt="" className="mobile-logo" />
              {/* <p className="mb-0 text-primary">AmarLab</p> */}
            </Link>
          </NavbarBrand>
          <div className="cart mobile">
            {!isLoggedIn
              ? <img
                src={UserMobileImg}
                alt=""
                onMouseEnter={handleUserMobile}
                onMouseLeave={() => setShowUserMobile(false)}
                onClick={() => setShowUserMobile(!showUserMobile)}
              />
              : <div className="user-det user-det-mobile">
                <div
                  className="pointer mr-2 ml-1 px-3 py-1 d-flex flex-row align-items-center justify-content-center  rounded after-login"
                  onClick={(e) => {
                    setShowUserMobile(!showUserMobile);
                  }}
                >
                  <div className='d-flex flex-row mr-2' >Welcome </div>
                  <i className="fas fa-angle-down drop-icon"></i>
                </div>
              </div>
            }
            <div className='pr-1 pl-1'>
              <img src={CartMobileImg} alt="" onClick={handleCartMobile} className='mr-0' />
              {carts && carts.length ? (
                <span className="badge badge-danger">{carts.length}</span>
              ) : (
                ""
              )}
            </div>

          </div>
          <NavbarToggle onClick={handleHamburgMenu} />
          <NavbarCollapse>
            <Nav className="mr-auto left">
              <NavLink
                activeClassName="active"
                to="/diagnostic"
                className="nav-link first"
                onClick={() =>
                  postActivity("menu", "Diagnostic", window.location.href)
                }
              >
                Diagnostic
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/packages"
                className="nav-link"
                onClick={() =>
                  postActivity("menu", "Packages", window.location.href)
                }
              >
                Packages
              </NavLink>
            </Nav>
            <Nav className="ml-auto right">
              {/* {isLoggedIn && (
                <div className='location user'>
                  <div className='icon'>
                    <img src={UserLogo} alt='' height='18' />
                  </div>
                  <div className='dropdown'>
                    <h5>Switch User</h5>
                    <div
                      className='options-select'
                      onClick={() =>
                        setUserDropdownVisible(!userDropdownVisible)
                      }
                    >
                      <p className='mb-0'>{userDefaultSelect}</p>
                      <i className='fas fa-angle-down drop-icon'></i>
                      <ul className={userDropdownVisible ? 'active' : ''}>
                        {suMyself.map((myself, i) => (
                          <li
                            key={i}
                            onClick={() => handleUserSelect('Myself')}
                          >
                            <i className='fas fa-user self'></i> Myself
                          </li>
                        ))}

                        {suFamily.length !== 0 &&
                          suFamily.map((family, i) => (
                            <li
                              key={i}
                              onClick={() =>
                                handleUserSelect(
                                  `${getFirstName(family.full_name)}`
                                )
                              }
                            >
                              <i className='fas fa-users'></i>{' '}
                              {getFirstName(family.full_name)}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )} */}
              <div className="location">
                <div className="icon">
                  <img src={Maps} alt="" height="18" />
                </div>
                <div className="dropdown">
                  <h5>Your Location</h5>
                  <div
                    className="options-select"
                    onClick={() => {
                      setLocationDropdownVisible(!locationDropdownVisible);
                      postActivity("menu", "Location", window.location.href);
                    }}
                  >
                    <p className="mb-0">{locationDefaultSelect}</p>
                    <i className="fas fa-angle-down drop-icon"></i>
                    <ul className={locationDropdownVisible ? "active" : ""}>
                      <li onClick={() => handleLocationSelect("Dhaka")}>
                        Dhaka
                      </li>
                      {/* <li onClick={() => handleLocationSelect("Savar")}>
                        Savar
                      </li>
                      <li onClick={() => handleLocationSelect("Chattogram")}>
                        Chattogram
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="buttons">
                {!isLoggedIn && (
                  <>
                    <Link
                      to="/login"
                      onClick={() =>
                        postActivity("menu", "Login", window.location.href)
                      }
                      className="btn btn-light login mr-2"
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      onClick={() =>
                        postActivity("menu", "Signup", window.location.href)
                      }
                      className="btn btn-primary signup"
                    >
                      Signup
                    </Link>
                  </>
                )}
                {isLoggedIn && (
                  // <button
                  //   className='btn btn-primary signup'
                  //   onClick={handleLogout}
                  // >
                  //   Logout
                  // </button>
                  <div className="user-det">
                    <a
                      href="?#"
                      className="pointer mr-0"
                      onClick={(e) => e.preventDefault()}
                      onMouseEnter={() => setLoginDropVisible(true)}
                      onMouseLeave={() => setLoginDropVisible(false)}
                    >
                      {suMyself.length !== 0 && suMyself[0].full_name}{" "}
                      <i className="fas fa-angle-down drop-icon"></i>
                    </a>
                    <ul
                      className={`${loginDropVisible ? "active" : ""}`}
                      onMouseEnter={() => setLoginDropVisible(true)}
                      onMouseLeave={() => setLoginDropVisible(false)}
                    >
                      <li>
                        <Link
                          to="/profile"
                          onClick={() =>
                            postActivity(
                              "menu",
                              "Profile",
                              window.location.href
                            )
                          }
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/order-history"
                          onClick={() =>
                            postActivity(
                              "menu",
                              "Order History",
                              window.location.href
                            )
                          }
                        >
                          Order History
                        </Link>
                      </li>
                      {/* <li>
                        <Link to='/change-password'>Change Password</Link>
                      </li> */}
                      <li>
                        <a href="?#" onClick={handleLogout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="cart desktop">
                <img src={CartLogo} alt="" onClick={handleCart} />
                {carts && carts.length ? (
                  <span className="badge badge-danger">{carts.length}</span>
                ) : (
                  ""
                )}
              </div>
            </Nav>
          </NavbarCollapse>
          <Cart showCart={showCart} setShowCart={setShowCart} />
          <UserTabMobile showUserMobile={showUserMobile} setShowUserMobile={setShowUserMobile} />
          <img
            src={PolygonIcon}
            alt=""
            className={`polygon-icon ${showUserMobile ? "active" : ""}`}
          />
        </div>
      </Navbar>

      {showHeaderSecondPart && <div className="mobile-semi-nav">
        <section className="bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="offer mb-0"></p>
                <p
                  className="number"
                  onClick={() =>
                    postActivity("menu", "Call Number", window.location.href)
                  }
                >
                  Call for order: <a href="tel:09643445566">09643445566</a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>}

      <div
        className={`back-to-top ${isVisible ? "active" : ""}`}
        onClick={backToTop}
      >
        <i className="fas fa-chevron-up"></i>
      </div>

      <CartMobile
        showCartMobile={showCartMobile}
        setShowCartMobile={setShowCartMobile}
      />
      <MblHamburg
        showNavMobile={showNavMobile}
        setShowNavMobile={setShowNavMobile}
      />
    </div>
  );
};

export default Navbarr;
