import Eye from '../assets/img/eye.svg';
import EyeOff from '../assets/img/eye-off.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const ResetPassword = () => {
  const { postActivity } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');

  const handleThirdStep = (e) => {
    e.preventDefault();
    if (password === '') {
      toast.error('Please enter new password!', {
        autoClose: 3000,
      });
      return;
    }

    if (cpassword === '') {
      toast.error('Please enter confirm password!', {
        autoClose: 3000,
      });
      return;
    }

    if (password.length < 8) {
      toast.error('Password must be at least 8 characters!', {
        autoClose: 3000,
      });
      return;
    }

    if (cpassword.length < 8) {
      toast.error('Confirm Password must be at least 8 characters!', {
        autoClose: 3000,
      });
      return;
    }

    if (cpassword !== password) {
      toast.error('Password and confirm password not same!', {
        autoClose: 3000,
      });
      return;
    }

    const postData = {
      password: password,
      //   user_id: user_id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user_management/reset-password`,
        postData
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.status && resp.data.status === 1) {
          toast.success('Password changed successfully.', {
            autoClose: 3000,
          });
          setPassword('');
          setCPassword('');
          //   this.props.history.push('/');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Sorry something went wrong, please try again later.', {
          autoClose: 3000,
        });
      });
  };

  return (
    <section className='losign signup py-0'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-6 third'>
            <h2 className='title'>Reset Password</h2>
            <form onSubmit={handleThirdStep}>
              <div className='form-group'>
                <div className='show-hide'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter new password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    src={showPassword ? EyeOff : Eye}
                    alt=''
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='show-hide'>
                  <input
                    type={showCPassword ? 'text' : 'password'}
                    placeholder='Enter confirm password'
                    value={cpassword}
                    onChange={(e) => setCPassword(e.target.value)}
                  />
                  <img
                    src={showCPassword ? EyeOff : Eye}
                    alt=''
                    onClick={() => setShowCPassword(!showCPassword)}
                  />
                </div>
              </div>
              <div className='form-group'>
                <button
                  type='submit'
                  className='submit-button'
                  onClick={() =>
                    postActivity(
                      'button',
                      'Reset Password',
                      window.location.href
                    )
                  }
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
