import axios from 'axios';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import BlogPic from '../../assets/img/blog.jpg';
import { AuthContext } from '../../context/AuthContext';

const Blog = ({ blog }) => {
  const { postActivity } = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://amarlab.com/eng/blog/wp-json/wp/v2/media/${blog.featured_media}?_fields[]=guid`
      )
      .then((resp) => {
        // console.log(resp.data);
        setImage(resp.data.guid.rendered);
      });

    axios
      .get(
        `https://amarlab.com/eng/blog/wp-json/wp/v2/users/${blog.author}?&_fields[]=name`
      )
      .then((resp) => {
        // console.log(resp.data);
        setName(resp.data.name);
      });

    axios
      .get(
        `https://amarlab.com/eng/blog/wp-json/wp/v2/categories/${blog.categories[0]}?_fields[]=name`
      )
      .then((resp) => {
        // console.log(resp.data);
        setCategory(resp.data.name);
      });
  }, []);

  return (
    <a
      href={blog.link}
      target='_blank'
      rel='noopener noreferrer'
      className='card'
      onClick={() =>
        postActivity(
          'box',
          `Single Blog (${blog.title.rendered})`,
          window.location.href
        )
      }
    >
      <img className='card-img-top' src={image || BlogPic} alt='blog gffg' />
      <div className='card-body'>
        <h5 className='card-title'>
          <a href={blog.link} target='_blank' rel='noopener noreferrer'>
            {blog.title ? blog.title.rendered : 'This is Blog Title'}
          </a>
        </h5>
        <p className='card-text'>{category && category}</p>
        <p className='author'>
          <span>Written By, </span>
          {name && name}
        </p>
      </div>
    </a>
  );
};

export default Blog;
