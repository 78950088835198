import axios from 'axios';
import moment from 'moment';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import LoadingGif from '../LoadingGif';

const PressR = () => {
  const { postActivity } = useContext(AuthContext);
  const [PressRelease, setPressRelease] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [Next, setNext] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchPressR();
  }, []);

  const fetchPressR = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/press/post-get/?is_linkable=false&is_active=true`
      )
      .then((resp) => {
        // console.log(resp.data);
        setPressRelease(resp.data.results);
        setLoading(false);
        setNext(resp.data.next);
      })
      .catch((error) => {
        // console.log(error.response);
        setLoading(false);
      });
  };

  const handlePagination = () => {
    postActivity('button', `Load More`, window.location.href);
    axios
      .get(Next)
      .then((resp) => {
        // console.log(resp.data);
        setPressRelease([...PressRelease, ...resp.data.results]);
        setNext(resp.data.next);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className='press-r'>
      <h1 className='main-heading'>Press Release</h1>
      {Loading ? (
        <LoadingGif />
      ) : (
        <>
          <div className='cards'>
            {PressRelease &&
              PressRelease.map((press, i) => (
                <div
                  className='single'
                  key={i}
                  onClick={() =>
                    postActivity(
                      'card',
                      `Press Release (${press.name})`,
                      window.location.href
                    )
                  }
                >
                  <div className='rightt'>
                    <img src={press.image} alt='' width='255' height='163' />
                  </div>
                  <div className='leftt'>
                    <div className='date'>
                      <span>{moment(press.date).format('DD MMM YYYY')}</span>
                    </div>
                    <div className='title'>
                      <Link to={`/press-release/${press.slug}`}>
                        {press.name}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            {Next && (
              <div className='pagination-button'>
                <button
                  className='btn btn-light'
                  onClick={handlePagination}
                  disabled={Next ? '' : 'disabled'}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
          {PressRelease && PressRelease.length === 0 ? (
            <p className='not-found'>Sorry, no press release found.</p>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default PressR;

{
  /* <div className='single'>
            <div className='rightt'>
              <img src={CardImg} alt='' width='255' height='163' />
            </div>
            <div className='leftt'>
              <div className='date'>
                <span> 27 Jan 1994</span>
              </div>
              <div className='title'>
                <Link to='/press-release/abc-def-xyz'>
                  Mashrafe Moving Bangladesh!
                </Link>
              </div>
            </div>
          </div> */
}
