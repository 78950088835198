import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import GLogo from '../assets/img/google-logo.png';
// import FLogo from '../assets/img/facebook-logo.png';
import Eye from '../assets/img/eye.svg';
import EyeOff from '../assets/img/eye-off.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import LoadingBtn from '../components/LoadingBtn';

class Login extends Component {
  state = {
    number: '',
    password: '',
    showPassword: false,
    user_id: null,
    loading: false,
  };

  componentDidMount() {
    this.context.setPageTitle('Login');
    window.scrollTo(0, 0);
    const token = localStorage.getItem('token');
    token ? this.props.history.push('/') : console.log('Not logged in');
  }

  handleLogin = (
    e,
    toggleLoggedIn,
    setUserMyself,
    setUserFamily,
    setCartsItem
  ) => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(this.state.number)) {
      toast.error('Please enter a valid mobile number!', {
        autoClose: 3000,
      });
    } else if (this.state.password === '') {
      toast.error('Please enter a password', {
        autoClose: 3000,
      });
    } else {
      this.setState({ loading: true });

      const loginData = {
        username: this.state.number,
        password: this.state.password,
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/login/`, loginData)
        .then((resp) => {
          // //console.log(resp.data.key);
          axios.interceptors.request.use((config) => {
            const token = 'Token ' + resp.data.key;
            config.headers.Authorization = token;
            return config;
          });
          localStorage.removeItem('token');
          localStorage.setItem('token', JSON.stringify(resp.data.key));

          axios
            .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
            .then((resp) => {
              this.context.setUserDetails(resp.data);
              localStorage.removeItem('user_id');
              localStorage.setItem('user_id', JSON.stringify(resp.data.pk));
              this.setState({ user_id: resp.data.pk });
              
              const lastCartPat = localStorage.getItem('lastCartPatient');
              if (lastCartPat) {
                const lastCartPatStr = JSON.parse(lastCartPat);
                if (lastCartPatStr[0].user === resp.data.pk) {
                  this.context.setLastCartPatient(lastCartPatStr);
                }
              }

              const this_user_id = resp.data.pk;
              this.context.setUserId(resp.data.pk);
              // this.context.setIsLoggedIn(true);

              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/user_management/patient/?user=${resp.data.pk}&limit=300`
                )
                .then((resp) => {
                  const results = resp.data.results;
                  // //console.log(results);
                  const myself = results.filter((item) => {
                    return item.is_account;
                  });
                  const family = results
                    .filter((item) => {
                      return item.is_account === false;
                    })
                    .filter((patient) => {
                      return patient.is_active;
                    });
                  setUserMyself(myself);
                  setUserFamily(family);
                  localStorage.setItem('userPatients', JSON.stringify(myself));

                  axios
                    .get(
                      `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${myself[0].id}&user=${this_user_id}`
                    )
                    .then((resp) => {
                      this.context.setUserAddress(resp.data.results);
                    });

                  const getCarts = localStorage.getItem('order');
                  const labName = localStorage.getItem('lab_name');
                  if (getCarts) {
                    const orderdfdfdsf = JSON.parse(getCarts);
                    // //console.log(orderdfdfdsf.user.pk);
                    // //console.log(this_user_id);
                    if (orderdfdfdsf.user.pk === this_user_id) {
                      // //console.log('cart for this user');
                      // //console.log(true);
                      const forStateChange = orderdfdfdsf.order_item;
                      setCartsItem(forStateChange);
                      if (labName) {
                        this.context.setIsLocalLab(true);
                      }
                    } else {
                      // //console.log('cart for other user');
                      //console.log(false);
                    }
                  }

                  this.props.loginHandler(true);
                  toggleLoggedIn();

                  let params = new URLSearchParams(
                    this.props.history.location.search
                  );
                  let redirectUrl = params.get('redirect');

                  const getLoginAfterData =
                    sessionStorage.getItem('loginAfterData');
                  if (getLoginAfterData) {
                    const details = JSON.parse(getLoginAfterData);
                    this.props.history.push(details.location);
                  } else if (redirectUrl) {
                    this.props.history.push(redirectUrl);
                  } else {
                    this.props.history.push('/');
                  }
                });
            });
        })
        .catch((error) => {
          //console.log(error.response.data.non_field_errors);
          this.setState({ loading: false });
          if (error.response.status === 400) {
            toast.error(`${error.response.data.non_field_errors}`, {
              autoClose: 3000,
            });
          } else {
            toast.error('Sorry something went wrong, please try again later.', {
              autoClose: 3000,
            });
          }
        });
    }
  };

  handleAuthChange = (toggleLoggedIn) => {
    toggleLoggedIn();
  };

  render() {
    const { loading } = this.state;
    const { toggleLoggedIn, setUserMyself, setUserFamily, setCartsItem } =
      this.context;

    return (
      <section className='losign login'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <h2 className='title'>Log In to Your AmarLab Account</h2>
              {/* <div className='socials'>
                <button className='google'>
                  <img src={GLogo} alt='' />
                  <p>Continue with Google</p>
                </button>
                <button className='facebook'>
                  <img src={FLogo} alt='' />
                  <p>Continue with Facebook</p>
                </button>
              </div> */}
              <form
                onSubmit={(e) =>
                  this.handleLogin(
                    e,
                    toggleLoggedIn,
                    setUserMyself,
                    setUserFamily,
                    setCartsItem
                  )
                }
              >
                <div className='form-group'>
                  <input
                    type='number'
                    placeholder='Mobile Number'
                    value={this.state.number}
                    onChange={(e) => this.setState({ number: e.target.value })}
                  />
                </div>
                <div className='form-group'>
                  <div className='show-hide'>
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      placeholder='Account Password'
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <img
                      src={this.state.showPassword ? EyeOff : Eye}
                      alt=''
                      onClick={() =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='form-group submit-button'>
                  {!loading ? (
                    <button type='submit' className='submit-button'>
                      Sign In Now
                    </button>
                  ) : (
                    <LoadingBtn btnText={'Sign In..'} />
                  )}
                </div>
              </form>
              <div className='bottom'>
                <p>
                  Forgot your password?{' '}
                  <Link
                    to='/reset-password'
                    onClick={() =>
                      this.context.postActivity(
                        'button',
                        'Reset Password',
                        window.location.href
                      )
                    }
                  >
                    {' '}
                    Click Here
                  </Link>
                </p>
                <p>
                  Want to create an account?{' '}
                  <Link
                    to='/signup'
                    onClick={() =>
                      this.context.postActivity(
                        'button',
                        'Signup',
                        window.location.href
                      )
                    }
                  >
                    {' '}
                    Create Now
                  </Link>
                </p>
              </div>
              {/* <button onClick={() => this.handleAuthChange(toggleLoggedIn)}>
                Click Me
              </button> */}
              {/* <p>{isLoggedIn ? 'true' : 'false'}</p> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;

Login.contextType = AuthContext;
