import React, { Component } from 'react';
import XLogo from '../assets/img/x-black.png';
import UserLogo from '../assets/img/user.png';
import DateSelect from '../components/DateSelect';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { dhakaArea, savarArea, ctgArea } from '../helpers/Areas';
import LoadingBtn from '../components/LoadingBtn';
import { Helmet } from 'react-helmet';
import { Spinner } from 'react-bootstrap';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';

class CheckoutMbl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLoopItem: dhakaArea,
      dhakaAreaName: dhakaArea,
      savarAreaName: savarArea,
      ctgAreaName: ctgArea,
      carts: [],
      subTotal: null,
      user: null,

      district: '',
      thana: '',
      address: `Flat no/Level: \nHouse: \nRoad: \nBlock: \nDetails: `,
      mobile: '',
      email: '',
      addressId: null,

      date: null,
      reference: '',
      deliveryCopy: 'both',
      all_total_price: 0,
      fees: { meterial_fee: 50, collection_fee: 200, delivery_fee: 100 },
      feesApi: { meterial_fee: 50, collection_fee: 200, delivery_fee: 100 },

      coupon: '',

      toady: moment().format('YYYY-MM-DD'),
      showDiscount: false,
      discountAmount: null,
      cuponId: null,
      materialPerson: null,
      ifUserOrder: null,
      labDiscount: null,
      placeOrderLoading: false,
      labDisLoading: false,
      selectTerms: false,
    };
  }

  userOrderCheck = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/user-order-exist/${id}`)
      .then((resp) => {
        this.setState({
          ifUserOrder: resp.data.exist ? 'old' : 'new',
        });
        // console.log(resp.data);
      })
      .catch((error) => {});
  };

  serviceFeesRemove = (array) => {
    const testItems = array
      .filter((item) => {
        return item.order_type === 'diagnostic';
      })
      .map((item) => {
        return item.test_item.diagnostic_test.name;
      });

    const checkOne = testItems.includes('Covid RT PCR');
    const checkTwo = testItems.includes('Covid Antigen');

    // console.log(checkTwo);
    if (checkOne || checkTwo) {
      this.setState({
        fees: { meterial_fee: 0, collection_fee: 0, deliveryCopy: 0 },
      });
    } else if (
      this.state.carts &&
      this.state.carts.length &&
      this.state.carts[0].order_type === 'package'
    ) {
      this.setState({
        fees: { meterial_fee: 0, collection_fee: 0, deliveryCopy: 0 },
      });
    }
  };

  calculateLabDiscount = (array) => {
    // console.log(array);
    // console.log(this.state.carts);

    let labMatched = false;

    this.state.carts.forEach((test) => {
      let matchedLabs = [];
      if (test.order_type === 'diagnostic') {
        const matched = array.find((coupon) => {
          return (
            test.test_item.branch.lab.name.toLowerCase() ===
            coupon.lab.name.toLowerCase()
          );
        });
        if (matched) {
          matchedLabs.push(matched);
        }
      } else if (test.order_type === 'package') {
        const matched = array.find((coupon) => {
          return (
            test.package.test_item.branch.lab.name.toLowerCase() ===
            coupon.lab.name.toLowerCase()
          );
        });
        if (matched) {
          matchedLabs.push(matched);
        }
      }

      if (matchedLabs.length !== 0) {
        labMatched = true;
      } else {
        labMatched = false;
      }
    });

    if (labMatched) {
      // console.log('continue for next step');

      let matchedTests = [];
      let matchedPackages = [];

      array.forEach((labTest) => {
        let single = [];
        labTest.cct_lab.forEach((test) => {
          let existing = this.state.carts
            .filter((cart) => cart.order_type === 'diagnostic')
            .filter(
              (cart) =>
                cart.test_item.diagnostic_test.id === test.test.id &&
                cart.test_item.branch.lab.id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedTests.push(...single);
      });
      array.forEach((labTest) => {
        let single = [];
        labTest.ccp_lab.forEach((test) => {
          let existing = this.state.carts
            .filter((cart) => cart.order_type === 'package')
            .filter(
              (cart) =>
                cart.package.package.id === test.package.id &&
                cart.package.test_item.branch.lab.id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedPackages.push(...single);
      });

      // const testIds = matchedTests.map((o) => o.test_item.id);
      // matchedTests = matchedTests.filter(
      //   (item, index) => !testIds.includes(item.test_item.id, index + 1)
      // );

      matchedTests = matchedTests.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.test_item.id === tag.test_item.id &&
              t.patient.id === tag.patient.id
          ) === index
      );

      // const packageIds = matchedPackages.map((o) => o.package.test_item.id);
      // matchedPackages = matchedPackages.filter(
      //   (item, index) =>
      //     !packageIds.includes(item.package.test_item.id, index + 1)
      // );

      matchedPackages = matchedPackages.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.package.test_item.id === tag.package.test_item.id &&
              t.patient.id === tag.patient.id
          ) === index
      );

      const allItems = [...matchedTests, ...matchedPackages];
      // console.log(allItems);

      let itemMaterial = 0,
        itemColletion = 0;

      const checkCollection = allItems.map((item) => item.is_collection_fee);
      checkCollection.includes(true)
        ? (itemColletion = 0)
        : (itemColletion = this.state.fees.collection_fee);

      const checkMaterial = allItems
        .filter((item) => {
          return item.is_material_fee;
        })
        .sort(
          (a, b) =>
            parseFloat(b.material_rate_percentage) -
            parseFloat(a.material_rate_percentage)
        );
      if (checkMaterial.length !== 0) {
        // console.log(checkMaterial[0]);
        itemMaterial =
          this.state.fees.meterial_fee -
          (this.state.fees.meterial_fee *
            checkMaterial[0].material_rate_percentage) /
            100;
      } else {
        itemMaterial = this.state.fees.meterial_fee;
      }

      // console.log(itemColletion, itemMaterial);
      this.setState({
        fees: { meterial_fee: itemMaterial, collection_fee: itemColletion },
      });
      setTimeout(() => {
        this.serviceFeesRemove(this.state.carts);
      }, 300);

      // console.log(this.state.carts);
      const newCart = this.state.carts.map(
        (cart) =>
          matchedTests.find(
            (test) =>
              test.patient === cart.patient && test.test_item === cart.test_item
          ) || cart
      );
      // console.log(newCart);
      this.setState({ carts: newCart });
      setTimeout(() => {
        this.serviceFeesRemove(this.state.carts);
      }, 300);

      const newCart1 = this.state.carts.map(
        (cart) =>
          matchedPackages.find(
            (test) =>
              test.patient === cart.patient && test.package === cart.package
          ) || cart
      );
      // console.log(newCart1);
      this.setState({ carts: newCart1 });
      setTimeout(() => {
        this.serviceFeesRemove(this.state.carts);
      }, 300);

      const discountTestPrice = matchedTests.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.test_item.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
              100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPackagePrice = matchedPackages.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.package.package.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
              100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPrice = discountTestPrice + discountPackagePrice;

      if (discountPrice && 0 < discountPrice) {
        this.setState({ labDiscount: discountPrice });
        // console.log(discountTestPrice);
      }
    }

    this.setState({ labDisLoading: false });
  };

  getLabDiscount = () => {
    this.setState({ labDisLoading: true });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab-discount-get/`)
      .then((resp) => {
        // console.log(resp.data.results);
        // this.calculateLabDiscount(resp.data.results);
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length !== 0) {
            // console.log(data);
            this.calculateLabDiscount(data);
          }
        }
        this.setState({ labDisLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ labDisLoading: false });
      });
  };

  fetchUserAddress = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${id}&user=${id}`
      )
      .then((resp) => {
        // this.setUserAddress(resp.data.results);
        const address = resp.data.results;
        // console.log(address);
        if (address.length !== 0) {
          // console.log(address);
          this.setState({
            district: address[0].district,
            thana: address[0].thana,
            address: address[0].address,
            mobile: address[0].mobile,
            email: address[0].email || '',
            addressId: address[0].id,
          });

          if (address[0].district === 'Dhaka') {
            this.setState({ locationLoopItem: dhakaArea });
          } else if (address[0].district === 'Savar') {
            this.setState({ locationLoopItem: savarArea });
          } else if (address[0].district === 'Chattogram') {
            this.setState({ locationLoopItem: ctgArea });
          } else {
            this.setState({ locationLoopItem: dhakaArea });
          }
        } else {
          this.setState({ district: this.context.navAreaName });
          if (this.context.navAreaName === 'Dhaka') {
            this.setState({ selectLoopItem: [...this.state.dhakaAreaName] });
          } else if (this.context.navAreaName === 'Chattogram') {
            this.setState({ selectLoopItem: [...this.state.ctgAreaName] });
          } else if (this.context.navAreaName === 'Savar') {
            this.setState({ selectLoopItem: [...this.state.savarAreaName] });
          } else {
            this.setState({ selectLoopItem: [...this.state.dhakaAreaName] });
          }
        }
      });
  };

  componentDidMount() {
    console.log(this.props.location.params);

    this.context.setPageTitle('Checkout');
    this.getFees();
    window.scrollTo(0, 0);
    const getCarts = localStorage.getItem('order');
    const userId = localStorage.getItem('user_id');
    const userPatient = localStorage.getItem('userPatients');

    const token = localStorage.getItem('token');
    !token ? this.props.history.push('/login') : console.log('');

    this.context.setInterceptors(JSON.parse(token));

    if (getCarts && userId) {
      if (
        this.props.location &&
        this.props.location.params &&
        this.props.location.params.carts
      ) {
        const allCarts = JSON.parse(getCarts);
        this.setState({ user: allCarts.user });
        if (allCarts.user.pk === JSON.parse(userId)) {
          if (allCarts.order_item.length === 0) {
            this.props.history.push('/');
          }

          const price = this.getTotalPrice(this.props.location.params.carts);
          // console.log(price);
          this.setState({ subTotal: price });
          this.userOrderCheck(JSON.parse(userId));
          this.setState({ mobile: allCarts.user.username });
          this.handleMeterialPerson(this.props.location.params.carts);

          this.setState({ carts: this.props.location.params.carts });

          if (
            this.props.location &&
            this.props.location.params &&
            this.props.location.params.labDiscount
          ) {
            this.setState({
              labDiscount: this.props.location.params.labDiscount,
            });
          }

          if (
            this.props.location &&
            this.props.location.params &&
            this.props.location.params.discountAmount
          ) {
            this.setState({
              discountAmount: this.props.location.params.discountAmount,
            });
          }

          if (
            this.props.location &&
            this.props.location.params &&
            this.props.location.params.showDiscount
          ) {
            this.setState({
              showDiscount: this.props.location.params.showDiscount,
            });
          }

          if (
            this.props.location &&
            this.props.location.params &&
            this.props.location.params.coupon
          ) {
            this.setState({
              coupon: this.props.location.params.coupon,
            });
          }

          if (
            this.props.location &&
            this.props.location.params &&
            this.props.location.params.fees
          ) {
            this.setState({
              fees: this.props.location.params.fees,
            });
          }

          if (
            this.props.location &&
            this.props.location.params &&
            this.props.location.params.feesApi
          ) {
            this.setState({
              feesApi: this.props.location.params.feesApi,
            });
          }
        }
      } else {
        this.props.history.push('/cart');
      }

      if (userPatient) {
        const parUserPatient = JSON.parse(userPatient);
        // console.log(parUserPatient);
        if (parUserPatient.length !== 0) {
          this.fetchUserAddress(parUserPatient[0].id);
        }
      }
    }
  }

  setDefaultDate = (params) => {
    this.setState({ date: params });
  };

  handleSelect = (e) => {
    this.setState({ district: e.target.value });
    if (e.target.value === 'Dhaka') {
      this.setState({ selectLoopItem: [...this.state.dhakaAreaName] });
    } else if (e.target.value === 'Savar') {
      this.setState({ selectLoopItem: [...this.state.savarAreaName] });
    } else if (e.target.value === 'Chattogram') {
      this.setState({ selectLoopItem: [...this.state.ctgAreaName] });
    }
  };

  getTotalPrice = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );

    return diagprice + packprice;
  };

  getTotalPriceWithFees = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );
    const totalPrice =
      diagprice +
      packprice +
      this.state.fees.collection_fee +
      this.state.materialPerson * this.state.fees.meterial_fee;
    return totalPrice;
  };

  getTotalPriceWithFeesWithCoupon = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.package.test_item.purchasable_order_item.sell_price),
      0
    );
    const totalPrice =
      diagprice +
      packprice +
      this.state.fees.collection_fee +
      this.state.materialPerson * this.state.fees.meterial_fee;
    if (this.state.discountAmount && this.state.labDiscount) {
      return totalPrice - this.state.discountAmount - this.state.labDiscount;
    } else if (this.state.labDiscount) {
      return totalPrice - this.state.labDiscount;
    } else if (this.state.discountAmount) {
      return totalPrice - this.state.discountAmount;
    } else {
      return totalPrice;
    }
  };

  // getTotalPrice = (array) => {
  //   return array.reduce(
  //     (total, item) =>
  //       total + parseInt(item.test_item.purchasable_order_item.sell_price),
  //     0
  //   );
  // };

  deleteItem = (i, item, setCartsItem, setIsLocalLab) => {
    this.context.postActivity(
      'button',
      `Remove Cart (${
        item.order_type === 'diagnostic'
          ? item.test_item.diagnostic_test.name
          : item.package.test_item.diagnostic_test.name
      })`,
      window.location.href
    );
    const filter = this.state.carts.filter((singel) => singel !== item);

    setCartsItem(filter);
    //console.log(filter);
    this.setState({
      carts: filter,
    });
    setTimeout(() => {
      this.serviceFeesRemove(this.state.carts);
    }, 300);

    const price = this.getTotalPriceWithFees(filter);
    this.setState({ subTotal: price });

    const newData = {
      user: this.state.user,
      status: '',
      order_item: filter,
    };

    localStorage.removeItem('order');
    localStorage.setItem('order', JSON.stringify(newData));

    if (this.state.carts.length - 1 === 0) {
      localStorage.removeItem('lab_name');
      localStorage.removeItem('lab_location');
      setIsLocalLab(false);
      // console.log(this.state.carts.length - 1);
    }
    window.location.reload();
  };

  getFees = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => {
        this.setState({
          feesApi: resp.data.results[0],
        });
        if (this.state.deliveryCopy === 'soft') {
          this.setState({ fees: resp.data.results[0] });
        } else {
          this.setState({
            fees: {
              collection_fee:
                resp.data.results[0].collection_fee +
                resp.data.results[0].delivery_fee,
              meterial_fee: resp.data.results[0].meterial_fee,
              delivery_fee: resp.data.results[0].delivery_fee,
            },
          });
        }
        setTimeout(() => {
          this.serviceFeesRemove(this.state.carts);
        }, 300);
        // console.log("fees = ", resp.data.results[0]);
      })
      .catch((error) => {});
  };

  postReference = (postData) => {
    // if (this.state.reference !== '') {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-detail/`, postData)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
    // }
  };

  postDiscount = (postData) => {
    // if (this.state.reference !== '') {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-discount/`, postData)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    // }
  };

  orderItemDiscountPrice = (item) => {
    if (item.rate && item.criteria) {
      if (item.order_type === 'diagnostic') {
        if (item.criteria === 'perchantange') {
          return (
            (parseInt(item.test_item.purchasable_order_item.sell_price) *
              item.rate) /
            100
          );
        } else if (item.criteria === 'static') {
          return (
            parseInt(item.test_item.purchasable_order_item.sell_price) -
            item.rate
          );
        }
      } else if (item.order_type === 'package') {
        if (item.criteria === 'perchantange') {
          return (
            (parseInt(
              item.package.test_item.purchasable_order_item.sell_price
            ) *
              item.rate) /
            100
          );
        } else if (item.criteria === 'static') {
          return (
            parseInt(item.package.test_item.purchasable_order_item.sell_price) -
            item.rate
          );
        }
      }
    } else {
      return 0;
    }
  };

  postDeliverySoft = (id) => {
    const postDate = {
      order: id,
      name: 'soft_copy_due',
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-delivery-status/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  postDeliveryHard = (id) => {
    const postDate = {
      order: id,
      name: 'no_hard_copy',
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-hardcopy-delivery-status/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  smsApiCall = (orderId, status) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/sms-order-status/${orderId}/${status}`
      )
      .then((resp) => {
        // console.log('come success', resp.data);
      })
      .catch((error) => {
        // console.log('come err', error.response);
      });
  };

  postOrderAndDetails = (addressId) => {
    const order_items = this.state.carts.map((cart) => {
      return {
        patient: cart.patient.id,
        address: addressId,
        order_type: cart.order_type,
        meterial_fee: this.state.fees.meterial_fee,
        cupon: this.state.cuponId ? this.state.cuponId : null,
        price:
          cart.order_type === 'diagnostic'
            ? parseInt(cart.test_item.purchasable_order_item.sell_price)
            : parseInt(
                cart.package.test_item.purchasable_order_item.sell_price
              ),
        purchasable_order_item:
          cart.order_type === 'diagnostic'
            ? cart.test_item.purchasable_order_item.id
            : cart.package.test_item.purchasable_order_item.id,
        discount_price: this.orderItemDiscountPrice(cart),
      };
    });

    const covidOrNot = this.state.carts.map((cart) => {
      return cart.order_type === 'diagnostic'
        ? cart.test_item.diagnostic_test.is_covid
        : cart.package.test_item.diagnostic_test.is_covid;
    });

    const postOrder = {
      user: this.state.user.pk,
      // status: 'order_confirmed',
      date: moment(this.state.date).format('YYYY-MM-DD'),
      orderitem: order_items,
      total_price: this.getTotalPrice(this.state.carts),
      collection_fee: this.state.fees.collection_fee,
      material_fee: this.state.fees.meterial_fee * this.state.materialPerson,
      order_type: covidOrNot.includes(true) ? 'covid' : 'non_covid',
    };

    // console.log(postOrder);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order/`, postOrder)
      .then((resp) => {
        this.context.setLastCartPatient([]);
        localStorage.removeItem('lastCartPatient');
        // console.log(resp.data);
        this.postDeliverySoft(resp.data.orderitem[0].order);
        this.postDeliveryHard(resp.data.orderitem[0].order);

        this.smsApiCall(resp.data.orderitem[0].order, 'order_placement');

        this.context.postActivity(
          'order',
          resp.data.orderitem[0].order,
          window.location.href
        );

        const postOrderDetails = {
          references: this.state.reference ? this.state.reference : null,
          order: resp.data.orderitem[0].order,
          address: addressId,
          user_type: this.state.ifUserOrder,
          delivery_copy_status: this.state.deliveryCopy,
        };
        this.postReference(postOrderDetails);

        const disPrice = this.state.discountAmount
          ? this.state.discountAmount
          : 0;

        const labDisPrice = this.state.labDiscount ? this.state.labDiscount : 0;

        const mainCollFee =
          this.state.deliveryCopy === 'soft'
            ? this.state.feesApi.collection_fee
            : this.state.feesApi.collection_fee +
              this.state.feesApi.delivery_fee;

        const postOrderDiscount = {
          order: resp.data.orderitem[0].order,
          cupon: this.state.cuponId,
          discount_note: 'Frontend coupon',
          discount_price: disPrice + labDisPrice,
          discount_source: 'cupon',
          collection_discount_price:
            mainCollFee - this.state.fees.collection_fee,
          material_discount_price:
            50 * this.state.materialPerson -
            this.state.fees.meterial_fee * this.state.materialPerson,
        };

        if (this.state.discountAmount || this.state.labDiscount) {
          this.postDiscount(postOrderDiscount);
        }

        toast.success('Your order has been successfully placed.', {
          autoClose: 3000,
        });
        localStorage.removeItem('order');
        localStorage.removeItem('lab_name');
        localStorage.removeItem('lab_location');
        this.context.setIsLocalLab(false);
        this.props.history.push(
          `/order-placed/${resp.data.orderitem[0].order}`
        );
        this.context.clearCart();
        this.setState({ placeOrderLoading: false });
      })
      .catch((error) => {
        //console.log(error.response.data);
      });
  };

  confirmPayment = (clearCart, setPaymentSuccessTotal, setIsLocalLab) => {
    // //console.log('Confirm Order,');
    let numberPattern = /^01\d{9}$/;
    if (this.state.date === '' || this.state.date === null) {
      toast.error('Please select date.', {
        autoClose: 3000,
      });
    } else if (this.state.district.trim() === '') {
      toast.error('Please select your location district.', {
        autoClose: 3000,
      });
    } else if (this.state.thana.trim() === '') {
      toast.error('Please select your location area.', {
        autoClose: 3000,
      });
    } else if (this.state.address.trim() === '') {
      toast.error('Please enter address.', {
        autoClose: 3000,
      });
    } else if (!numberPattern.test(this.state.mobile)) {
      toast.error('Please enter a valid mobile number.', {
        autoClose: 3000,
      });
    } else if (!this.state.selectTerms) {
      toast.error('Please checked Terms of Service', {
        autoClose: 3000,
      });
      window.scroll({ top: 1200, left: 0, behavior: 'smooth' });
      return;
    } else {
      this.setState({ placeOrderLoading: true });
      const postAddress = {
        district: this.state.district,
        thana: this.state.thana,
        address: this.state.address,
        mobile: this.state.mobile,
        email: this.state.email,
        patient:
          this.context.suMyself.length !== 0
            ? this.context.suMyself[0].id
            : null,
      };

      if (this.state.addressId) {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/user_management/address/${this.state.addressId}/`,
            postAddress
          )
          .then((resp) => {
            // console.log(resp.data);
            this.postOrderAndDetails(resp.data.id);
          })
          .catch((error) => {
            // console.log(error.response);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user_management/address/`,
            postAddress
          )
          .then((resp) => {
            // console.log(resp.data.id);
            // const add_id = resp.data.id;
            this.postOrderAndDetails(resp.data.id);
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response.status === 400) {
              if (error.response.data.address) {
                toast.error(`Address: ${error.response.data.address[0]}`, {
                  autoClose: 3000,
                });
              }
            }
          });
      }
    }
  };

  cuponAndCartMatch = (data) => {
    // console.log(data);

    let newCollectionFees, newMaterialFees;
    data.is_collection_fee
      ? (newCollectionFees = 0)
      : (newCollectionFees = this.state.fees.collection_fee);

    data.is_material_fee
      ? (newMaterialFees =
          this.state.fees.meterial_fee -
          (this.state.fees.meterial_fee * data.material_rate_percentage) / 100)
      : (newMaterialFees = this.state.fees.meterial_fee);

    this.setState({
      fees: {
        meterial_fee: newMaterialFees,
        collection_fee: newCollectionFees,
      },
    });

    const diagnostics = this.state.carts.filter((diagnostic) => {
      return diagnostic.order_type === 'diagnostic';
    });
    const packages = this.state.carts.filter((name) => {
      return name.order_type === 'package';
    });

    let cartLabs = [];

    if (diagnostics.length !== 0) {
      const labDetails = diagnostics.map((lab) => {
        return {
          labName: lab.test_item.branch.lab.name,
          id: lab.test_item.branch.lab.id,
        };
      });
      // console.log(labDetails);
      cartLabs.push(...labDetails);
    } else {
      const labDetails = packages.map((lab) => {
        return {
          labName: lab.package.test_item.branch.lab.name,
          id: lab.package.test_item.branch.lab.id,
        };
      });
      // console.log(labDetails);
      cartLabs.push(...labDetails);
    }

    let cartBranches = [];

    if (diagnostics.length !== 0) {
      const labDetails = diagnostics.map((lab) => {
        return {
          branchName: lab.test_item.branch.name,
          id: lab.test_item.branch.id,
        };
      });
      // console.log(labDetails);
      cartBranches.push(...labDetails);
    } else {
      const labDetails = packages.map((lab) => {
        return {
          branchName: lab.package.test_item.branch.name,
          id: lab.package.test_item.branch.id,
        };
      });
      // console.log(labDetails);
      cartBranches.push(...labDetails);
    }

    const cuponTests = data.cct;
    const cuponPackages = data.ccp;
    const cuponLabs = data.ccl;
    const cuponBranches = data.ccb;

    let matchedTests = [];
    cuponTests.forEach((cupId) => {
      let existing = diagnostics.find(
        (diagId) => diagId.test_item.diagnostic_test.id === cupId.test
      );

      if (existing) {
        matchedTests.push(existing);
      }
    });

    let matchedPackages = [];
    cuponPackages.forEach((cupId) => {
      let existing = packages.find(
        (packId) => packId.package.package.id === cupId.package
      );

      if (existing) {
        matchedPackages.push(existing);
      }
    });

    let matchedLabs = [];
    cuponLabs.forEach((cupId) => {
      let existing = cartLabs.find((packId) => packId.id === cupId.lab);

      if (existing) {
        matchedLabs.push(existing);
      }
    });

    let matchedBranches = [];
    cuponBranches.forEach((cupId) => {
      let existing = cartBranches.find((packId) => packId.id === cupId.branch);

      if (existing) {
        matchedBranches.push(existing);
      }
    });

    // console.log('Tests: ', matchedTests);
    // console.log('Packages: ', matchedPackages);
    // console.log('Labs: ', matchedLabs);

    if (data.is_branch) {
      // console.log('Calculate Branch');
      // console.log(data);
      // console.log('Tests: ', matchedTests);
      // console.log('Packages: ', matchedPackages);
      // console.log('Branches: ', matchedBranches);

      if (matchedBranches.length !== 0) {
        const priceArray = [...matchedTests, ...matchedPackages];
        const percentAmount =
          this.getTotalPrice(priceArray) * (data.rate / 100);
        this.setState({ discountAmount: parseInt(percentAmount) });

        const newTestCart = [...matchedTests].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newPackageCart = [...matchedPackages].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newCart = this.state.carts.map(
          (cart) =>
            newTestCart.find(
              (test) =>
                test.patient === cart.patient &&
                test.test_item === cart.test_item
            ) || cart
        );
        this.setState({ carts: newCart });
        setTimeout(() => {
          this.serviceFeesRemove(this.state.carts);
        }, 300);

        const newCart1 = this.state.carts.map(
          (cart) =>
            newPackageCart.find(
              (test) =>
                test.patient === cart.patient && test.package === cart.package
            ) || cart
        );
        this.setState({ carts: newCart1 });
        setTimeout(() => {
          this.serviceFeesRemove(this.state.carts);
        }, 300);

        this.setState({ showDiscount: true });
        toast.success('Congratulation, coupon matched.', {
          autoClose: 3000,
        });
        this.setState({ cuponId: data.id });
      } else {
        toast.error('Sorry, coupon is not valid.');
      }
    } else {
      if (
        data.cca.length === 0 &&
        // data.ccl.length === 0 &&
        data.ccp.length === 0 &&
        data.cct.length === 0
      ) {
        if (data.criteria === 'static') {
          this.setState({ discountAmount: data.rate });
        } else {
          const percentAmount =
            this.getTotalPrice(this.state.carts) * (data.rate / 100);
          // console.log(parseInt(percentAmount));
          this.setState({ discountAmount: parseInt(percentAmount) });
        }

        const newCart = this.state.carts.map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });
        this.setState({ carts: newCart });
        setTimeout(() => {
          this.serviceFeesRemove(this.state.carts);
        }, 300);

        toast.success('Congratulation, coupon matched.', {
          autoClose: 3000,
        });
        this.setState({ showDiscount: true });
        this.setState({ cuponId: data.id });
      } else if (matchedTests.length !== 0 || matchedPackages.length !== 0) {
        if (data.criteria === 'static') {
          this.setState({ discountAmount: data.rate });
        } else {
          const priceArray = [...matchedTests, ...matchedPackages];
          // console.log(priceArray);
          const percentAmount =
            this.getTotalPrice(priceArray) * (data.rate / 100);
          // console.log(percentAmount);
          this.setState({ discountAmount: parseInt(percentAmount) });
        }

        const newTestCart = [...matchedTests].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newPackageCart = [...matchedPackages].map((cart) => {
          return {
            ...cart,
            criteria: data.criteria,
            rate: cart.rate ? cart.rate + data.rate : data.rate,
            is_collection_fee: cart.is_collection_fee
              ? cart.is_collectio
              : data.is_collection_fee,
            is_material_fee: cart.is_material_fee
              ? cart.is_material_fee
              : data.is_material_fee,
            material_rate_percentage: cart.material_rate_percentage
              ? cart.material_rate_percentage + data.material_rate_percentage
              : data.material_rate_percentage,
          };
        });

        const newCart = this.state.carts.map(
          (cart) =>
            newTestCart.find(
              (test) =>
                test.patient === cart.patient &&
                test.test_item === cart.test_item
            ) || cart
        );
        this.setState({ carts: newCart });
        setTimeout(() => {
          this.serviceFeesRemove(this.state.carts);
        }, 300);

        const newCart1 = this.state.carts.map(
          (cart) =>
            newPackageCart.find(
              (test) =>
                test.patient === cart.patient && test.package === cart.package
            ) || cart
        );
        this.setState({ carts: newCart1 });
        setTimeout(() => {
          this.serviceFeesRemove(this.state.carts);
        }, 300);

        this.setState({ showDiscount: true });
        toast.success('Congratulation, coupon matched.', {
          autoClose: 3000,
        });
        this.setState({ cuponId: data.id });
      } else {
        toast.error('Sorry, coupon is not valid.', {
          autoClose: 3000,
        });
      }
    }
  };

  coupontRange = (x, y, str) => {
    let numbers = [];
    for (let i = x; i <= y; i++) {
      numbers.push(`${str}${i}`);
    }
    return numbers;
  };

  couponRangeCheck = () => {
    const letter = this.state.coupon.match(/[a-zA-Z]+/g);
    const number = this.state.coupon.match(/\d+/g);

    // console.log(letter);
    // console.log(number);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon/?code=${letter}`)
      .then((resp) => {
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length === 0) {
            toast.error('Sorry, this coupon is not valid.');
          } else {
            // console.log(data);
            const cartLab = this.state.carts.map((cart) =>
              cart.order_type === 'diagnostic'
                ? cart.test_item.branch.lab.id
                : cart.package.test_item.branch.lab.id
            );
            const cartLabId = cartLab[0];

            if (1 < data.length) {
              const getCouponLab = data.filter((labs) =>
                labs.ccl.some((lab) => lab.lab === cartLabId)
              );

              if (getCouponLab.length !== 0) {
                // this.cuponAndCartMatch(getCouponLab.at(-1));
                const couponDet = getCouponLab.at(-1);
                if (couponDet.is_code_range) {
                  // console.log(couponDet);

                  const array = this.coupontRange(
                    couponDet.code_start,
                    couponDet.code_end,
                    couponDet.code
                  );
                  // console.log(array.includes(this.state.coupon));
                  if (array.includes(this.state.coupon)) {
                    this.cuponAndCartMatch(couponDet);
                  } else {
                    toast.error('Sorry, this coupon is not valid.');
                  }
                  // array.includes('amarlab10000');
                } else {
                  toast.error('Sorry, this coupon is not valid.');
                }
              } else {
                toast.error('Sorry, this coupon is not valid.');
              }
            } else {
              if (data[0].is_code_range) {
                const couponDet = data[0];
                // console.log(couponDet);
                if (couponDet.is_code_range) {
                  // console.log(couponDet);

                  const array = this.coupontRange(
                    couponDet.code_start,
                    couponDet.code_end,
                    couponDet.code
                  );
                  // console.log(array.includes(this.state.coupon));
                  if (array.includes(this.state.coupon)) {
                    this.cuponAndCartMatch(couponDet);
                  } else {
                    toast.error('Sorry, this coupon is not valid.');
                  }
                  // array.includes('amarlab10000');
                } else {
                  toast.error('Sorry, this coupon is not valid.');
                }
              } else {
                toast.error('Sorry, this coupon is not valid.');
              }
            }
          }
        } else {
          toast.error('Sorry, this coupon is not valid.');
        }
      });
  };

  fetchCoupon = (code) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon/?code=${code}`)
      .then((resp) => {
        // console.log(resp.data.results);
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          // console.log(data);
          if (data.length === 0) {
            // toast.error('Sorry, this coupon is not valid.');
            this.couponRangeCheck();
          } else {
            // console.log('do something with coupon');
            // this.cuponAndCartMatch(data);
            const cartLab = this.state.carts.map((cart) =>
              cart.order_type === 'diagnostic'
                ? cart.test_item.branch.lab.id
                : cart.package.test_item.branch.lab.id
            );
            const cartLabId = cartLab[0];
            // console.log(data);
            // console.log(cartLabId);

            if (1 < data.length) {
              const getCouponLab = data.filter((labs) =>
                labs.ccl.some((lab) => lab.lab === cartLabId)
              );
              // console.log(getCouponLab);
              if (getCouponLab.length !== 0) {
                // console.log(getCouponLab.at(-1).is_code_range);
                if (!getCouponLab.at(-1).is_code_range) {
                  this.cuponAndCartMatch(getCouponLab.at(-1));
                } else {
                  // toast.error('Sorry, this coupon is not valid.');
                  this.couponRangeCheck();
                }
              } else {
                // toast.error('Sorry, this coupon is not valid.');
                this.couponRangeCheck();
              }
            } else {
              if (!data[0].is_code_range) {
                this.cuponAndCartMatch(data[0]);
              } else {
                // toast.error('Sorry, this coupon is not valid.');
                this.couponRangeCheck();
              }
            }
          }
        } else {
          // toast.error('Sorry, this coupon is not valid.', {
          //   autoClose: 3000,
          // });
          this.couponRangeCheck();
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleCoupon = (e) => {
    e.preventDefault();
    if (this.state.coupon.trim() === '') {
      toast.error('Please enter coupon code.', {
        autoClose: 3000,
      });
    } else {
      this.fetchCoupon(this.state.coupon);
    }
  };

  getFirstName = (name) => {
    const nameArray = name.split(' ');
    if (nameArray.length < 2) {
      return name;
    } else {
      const firstname = nameArray.slice(0, -1).join(' ');
      return firstname;
    }
  };

  handleMeterialPerson = (array) => {
    const patients = array
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);
    this.setState({ materialPerson: patients.length });
  };

  handleExmClick = () => {
    const orderType = this.state.carts.map((cart) => {
      return cart.order_type === 'diagnostic'
        ? cart.test_item.diagnostic_test.is_covid
        : cart.package.test_item.diagnostic_test.is_covid;
    });

    console.log(orderType.includes(true) ? 'covid' : 'non_covid');
  };

  render() {
    const {
      coupon,
      showDiscount,
      discountAmount,
      labDiscount,
      placeOrderLoading,
      deliveryCopy,
    } = this.state;
    const { setCartsItem, clearCart, setPaymentSuccessTotal, setIsLocalLab } =
      this.context;
    // //console.log(carts);
    return (
      <>
        <section className='checkout'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 mobile-first'>
                <h5 className='summery'>Order Summary</h5>
                <div className='calculation'>
                  <div className='order-summery'>
                    <div className='list'>
                      <p className='name'>Total Price</p>
                      <p className='price'>
                        ৳ {this.getTotalPrice(this.state.carts)}
                      </p>
                    </div>
                    {this.state.carts &&
                      this.state.carts.length &&
                      this.state.carts[0].order_type !== 'package' && (
                        <>
                          <div className='list'>
                            <p className='name'>
                              Material fee ৳{this.state.fees.meterial_fee} *{' '}
                              {this.state.materialPerson || '0'}
                            </p>
                            <p className='price'>
                              ৳{' '}
                              {this.state.materialPerson *
                                this.state.fees.meterial_fee}
                            </p>
                          </div>
                          <div className='list'>
                            <p className='name'>Collection fee </p>
                            <p className='price'>
                              ৳ {this.state.fees.collection_fee}
                            </p>
                          </div>
                        </>
                      )}

                    <div className='break'></div>
                    <div className='list total'>
                      <p className='name'>Total: </p>
                      <p className='price'>
                        ৳ {this.getTotalPriceWithFees(this.state.carts)}
                      </p>
                    </div>
                    {this.state.labDisLoading && (
                      <div className='list load'>
                        <p className='name'>Loading Lab Discount</p>
                        <p className='price'>
                          <Spinner animation='border' variant='dark' />
                        </p>
                      </div>
                    )}
                    {labDiscount && (
                      <div className='list'>
                        <p className='name'>Discount</p>
                        <p className='price'>- ৳ {labDiscount || '00'}</p>
                      </div>
                    )}
                    {showDiscount && (
                      <div className='list'>
                        <p className='name'>Coupon Applied</p>
                        <p className='price'>- ৳ {discountAmount || '00'}</p>
                      </div>
                    )}
                    <div className='break bottom'></div>
                    <div className='list payable mb-0'>
                      <p className='name'>Payable: </p>
                      <p className='price'>
                        ৳{' '}
                        {this.getTotalPriceWithFeesWithCoupon(this.state.carts)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-8'>
                <form className='details'>
                  <h1 className='main-heading web'>Order Service</h1>
                  <h1 className='main-heading mobile'>
                    Order Details <span> (Fill-Up Booking Info.)</span>
                  </h1>
                  <div className='row items time'>
                    <div className='col-md-5 left'>
                      <h5>Sample Collection Date*</h5>
                      <p>
                        This schedule is not confirmed, wait till our team
                        communicates you.
                      </p>
                    </div>
                    <div className='col-md-7 right'>
                      {/* <DateSelect
                        defaultDate={this.state.date}
                        setDefaultDate={this.setDefaultDate}
                      /> */}
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                      >
                        <DatePicker
                          // disableToolbar
                          disablePast
                          autoOk={true}
                          variant='dialog'
                          format='YYYY-MM-DD'
                          margin='normal'
                          id='date-picker'
                          value={this.state.date}
                          error={false}
                          helperText={null}
                          onChange={this.setDefaultDate}
                          placeholder='YYYY - MM - DD'
                          // KeyboardButtonProps={{
                          //   'aria-label': 'change date'
                          // }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div className='row items location'>
                    <div className='col-md-5 left'>
                      <h5>Location*</h5>
                      <p>
                        Your test sample will be collected from this location
                      </p>
                    </div>
                    <div className='col-md-7 right'>
                      <div className='row'>
                        <div className='col-6 sel-one'>
                          <select
                            className=''
                            onChange={this.handleSelect}
                            value={this.state.district}
                          >
                            <option value=''>Select District</option>
                            <option value='Dhaka'>Dhaka</option>
                            {/* <option value='Savar'>Savar</option>
                            <option value='Chattogram'>Chattogram</option> */}
                          </select>
                        </div>
                        <div className='col-6 sel-two'>
                          <select
                            className=''
                            onChange={(e) =>
                              this.setState({ thana: e.target.value })
                            }
                            value={this.state.thana}
                          >
                            <option value=''>Select Area</option>
                            {this.state.selectLoopItem.map((item, i) => (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-5'></div>
                    <div className='col-md-7 right'>
                      <textarea
                        className='form-control'
                        placeholder='Enter Location Details*'
                        onChange={(e) =>
                          this.setState({ address: e.target.value })
                        }
                        value={this.state.address}
                      ></textarea>
                    </div>
                    {/* <div className='col-md-5'></div>
                    <div className='col-md-7 right'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Nearby Popular Landmark'
                      />
                    </div> */}
                  </div>
                  <div className='row items mobile'>
                    <div className='col-md-5 left'>
                      <h5>Mobile Number*</h5>
                      <p>We will call you in every necessary steps</p>
                    </div>
                    <div className='col-md-7 right'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='01xxxxxxx'
                        value={this.state.mobile}
                        onChange={(e) =>
                          this.setState({ mobile: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='row items email'>
                    <div className='col-md-5 left'>
                      <h5>Email</h5>
                      <p>Your report will be sent on this email address</p>
                    </div>
                    <div className='col-md-7 right'>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Enter Email'
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='row items refrence'>
                    <div className='col-md-5 left'>
                      <h5>Reference (if any)</h5>
                      <p>For references, corporate discounts & special notes</p>
                    </div>
                    <div className='col-md-7 right'>
                      <textarea
                        className='form-control'
                        placeholder='Enter reference'
                        onChange={(e) =>
                          this.setState({ reference: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div className='row items delivery-copy'>
                    <div className='col-md-5 left'>
                      <h5>Report Delivery Mode</h5>
                      <p>Your report will be delivered via email or hand</p>
                    </div>
                    <div className='col-md-7 right'>
                      <select
                        value={deliveryCopy}
                        onChange={(e) => {
                          this.setState({ deliveryCopy: e.target.value });
                          setTimeout(() => {
                            this.getFees();
                          }, 200);
                        }}
                      >
                        <option value='hard'>Hard Copy</option>
                        <option value='soft'>Soft Copy</option>
                        <option value='both'>Both</option>
                      </select>
                    </div>
                  </div>
                  <div className='row items t-and-c'>
                    <div className='col-md-12 left'>
                      <input
                        value='TermsService'
                        type='checkbox'
                        id='TermsService'
                        defaultChecked={this.state.selectTerms}
                        onChange={(e) =>
                          this.setState({
                            selectTerms: e.target.checked,
                          })
                        }
                      />
                      <h5>
                        * By completing your purchase you agree to this{' '}
                        <Link to='/terms-of-service' target="_blank" rel="noopener noreferrer">Terms of Service.</Link>
                      </h5>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 amount'>
                      <div className='bg-primary'>
                        <div className='left'>
                          <h5>Total Payable Amount</h5>
                          <div className='price'>
                            ৳{' '}
                            {this.getTotalPriceWithFeesWithCoupon(
                              this.state.carts
                            )}
                            {/* 00000 */}
                          </div>
                        </div>
                        {/* <div className='right'>
                          <button
                            className='btn btn-warning'
                            onClick={() => this.confirmPayment(clearCart)}
                            type='button'
                          >
                            Pay Now
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-12 terms'>
                      * By completing your purchase you agree to this{' '}
                      <Link to='/terms-of-service' target="_blank" rel="noopener noreferrer">Terms of Service.</Link>
                    </div>
                  </div>
                </form>
              </div>
              <button
                onClick={() =>
                  this.confirmPayment(
                    clearCart,
                    setPaymentSuccessTotal,
                    setIsLocalLab
                  )
                }
                disabled={this.state.carts.length !== 0 ? '' : 'disabled'}
                className='mbl-sticky btn btn-primary btn-block shadow-sm'
              >
                Place Order
              </button>
            </div>
          </div>
        </section>

        <Helmet>
          <script>
            {`
            gtag('event', 'conversion', {'send_to': 'AW-10778978942/sA9mCNrP070DEP7U6JMo'});
          `}
          </script>
        </Helmet>
      </>
    );
  }
}

export default CheckoutMbl;

CheckoutMbl.contextType = AuthContext;
