import axios from 'axios';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Contact from '../components/press/Contact';
import PressC from '../components/press/PressC';
import PressR from '../components/press/PressR';
import { AuthContext } from '../context/AuthContext';

const PressRelease = () => {
  const { setPageTitle, postActivity } = useContext(AuthContext);
  const history = useHistory();
  const [Categoriy, setCategoriy] = useState([]);
  const [ShowStep, setShowStep] = useState('press-release');

  useEffect(() => {
    setPageTitle('Press Release & Coverage');
    window.scrollTo(0, 0);

    let params = new URLSearchParams(history.location.search);
    let term = params.get('term');

    term ? setShowStep(term) : setShowStep('press-release');

    fetchCategory();
  }, []);

  const fetchCategory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/press/category?ordering=sorting`)
      .then((resp) => {
        // console.log(resp.data);
        setCategoriy(resp.data.results);
        if (resp.data.results.length !== 0) {
          setShowStep(resp.data.results[0].slug);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        // setLoading(false);
      });
  };

  const handleLink = (name) => {
    postActivity('button', name, window.location.href);
    setShowStep(name);
    history.push(`/press?term=${name}`);
  };

  return (
    <div className='press-release'>
      <section className='head'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='title main-heading'>AmarLab Press And Media</h2>
              <p className='main-subheading mb-0'>
                Our press release and press coverage
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 left'>
              <ul>
                {Categoriy.length !== 0 &&
                  Categoriy.map((category, i) => (
                    <li
                      key={i}
                      className={`${
                        ShowStep === category.slug ? 'active' : ''
                      }`}
                      onClick={() => handleLink(category.slug)}
                    >
                      {category.name}
                    </li>
                  ))}
                <li
                  className={`${ShowStep === 'contact' ? 'active' : ''}`}
                  onClick={() => handleLink('contact')}
                >
                  Contact
                </li>
              </ul>
            </div>
            <div className='col-md-9 right'>
              {ShowStep === 'press-release' && <PressR />}
              {ShowStep === 'press-coverage' && <PressC />}
              {ShowStep === 'contact' && <Contact />}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PressRelease;
