import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import AccArrow from '../assets/img/right-arrow-link.png';
import { AuthContext } from '../context/AuthContext';

const About = () => {
  const { setPageTitle } = useContext(AuthContext);
  const [idx, setIdx] = useState([111, 222, 333]);

  useEffect(() => {
    setPageTitle('About Us');
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about'>
      <section>
        <div className='container text-justify'>
          <div className='row mb-3'>
            <div className='col-12'>
              <h3 className='title mb-3'>About Us</h3>
              <section>
                <p>
                  The revolution of digital healthcare service has been apparent
                  worldwide over the last two years in the context of the
                  coronavirus pandemic. With the accessibility to health checkup
                  facilities at home, the popularity of at-home pathology
                  service is growing continuously. Bangladesh is no different.
                  There has been the emergence of several healthcare service
                  providers. AmarLab, a digital healthcare startup that provides
                  at-home diagnostic test services is amongst the pioneers of
                  such facilitators in Bangladesh.
                </p>
              </section>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <h4 className='subtitle mb-3'>How We Started</h4>
              <section>
                <p>
                  back in 2007. At that time, his mother was diagnosed with
                  late-stage cancer. He was working at Microsoft back then. His
                  mother survived that time but he did not forget the troubles
                  that he had to face while providing proper medical treatment
                  to his mother then. His direct experience of encountering the
                  healthcare system in Bangladesh inspired him to think further
                  about transforming the overall situation.{' '}
                </p>
                <p>
                  In 2007, Tazin Shadid founded a clinic to provide free medical
                  services such as consultation, tests, etc. Dr. Ishtiaque
                  Zahid, the other co-founder at AmarLab, was the first doctor
                  to join the clinic. Within just 10 years, AmarLab served more
                  than 200,000 patients in their clinic.
                </p>
                <p>
                  In 2016, when Tazin Shadid returned to Bangladesh permanently,
                  he was already aware of the healthcare sector in Bangladesh.
                  Rather than throwing disruptive ideas, comprehensive basic
                  solutions could be the key to overcome the obstacles of this
                  industry to ensure quality healthcare service to all.{' '}
                </p>
                <p>
                  The scarcity of doctors and technologists is acute in
                  Bangladesh. On top of that, doing a diagnostic test in a
                  hospital consumes a lot of time, including the traveling and
                  waiting period which is stressful for a patient indeed. Hence,
                  Tazin Shadid along with Dr. Ishtiaque Zahid and Sabbir Amin, a
                  friend of Shadid and a successful entrepreneur, thought of
                  introducing something innovative to resolve this problem.{' '}
                </p>
                <p>
                  From the experience of running a clinic, the founders had a
                  better understanding of how this sector works. Moreover, they
                  went through the realities and requirements of providing
                  at-home diagnostic services, spending almost a year in R&D to
                  develop AmarLab’s own sample toolkit, processes, and systems,
                  the user experience, etc. Eventually, the founders took almost
                  a year to build an entire service and train the staff.
                </p>
                <p>
                  When Amarlab was finally launched, Tamzid Siddiq Spondon, the
                  Managing Director of NeoFarmers and Zanala Bangladesh, took
                  over the responsibility to run it since Shadid and Zahid were
                  working on other initiatives. Spondon ran Amarlab for a year,
                  managing the entire operation, building the MVP, and finding
                  the initial transaction to the stage where it needed more
                  attention. Later Shadid and Zahid took over the responsibility
                  and now they are operating AmarLab full time. This is the
                  journey of AmarLab, in a nutshell.
                </p>
              </section>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <h4 className='subtitle mb-3'>What We Do</h4>
              <section>
                <p>
                  The operations of AmarLab are completely different from other
                  at-home pathology services as we go to patients’ houses,
                  collect samples, transport them to the lab of their choice for
                  testing and then deliver the report at their doorsteps.
                </p>
                <p>
                  AmarLab is a unique platform where patients can select a test
                  and diagnostic center of their preference. There is no other
                  platform in Bangladesh that gives you the freedom to choose a
                  lab for diagnostic tests from multiple options and compare
                  test prices.
                </p>
                <p>
                  Our service is beneficial to the patients who face difficulty
                  in traveling to receive healthcare facilities for various
                  reasons including inaccessible transport, disability, old age,
                  or busy schedules. With the first of its kind initiative in
                  Dhaka, people do not need to travel to and wait at diagnostic
                  centers for medical tests. As a result, they can enjoy
                  diagnostic services from the comfort of their own home with
                  us.
                </p>
                <p>
                  While collecting samples, our staff may face different types
                  of challenges as the patient has to get ready to give samples
                  at home. There is a high probability of getting interrupted by
                  a family member of the patient while collecting samples as
                  well. Our agents take care of every small issue and focus on
                  accomplishing the given tasks with perfection.{' '}
                </p>
                <p>
                  AmarLab follows a safe and secured sample collection and
                  transportation system. The sample kit that we use is the 4th
                  version of the kit. We are expecting to introduce version 5 of
                  that kit soon which we are developing in collaboration with
                  PUM, a Netherlands-based organization that works with SMEs
                  across the world. We are also following the WHO standard to
                  develop the kits. Additionally, we constantly receive expert
                  feedback to improve the quality of our sample kits.
                </p>
                <p>
                  The importance of having a built-in waste management system is
                  indispensable. AmarLab ensures the safe disposal of medical
                  waste for a healthy environment. We follow the ideal
                  procedures for waste disposal as per medical standard which we
                  regularly maintain. Moreover, we often do testing in different
                  labs at our own cost to inspect if the submitted test reports
                  are being accurate.
                </p>
                <p>
                  Quality is always our first priority. Since healthcare is a
                  sensitive service, we never take our responsibilities for
                  granted. Whenever there is any complaint against a lab, we
                  take prompt steps against them to ensure the best quality for
                  our customers.
                </p>
                <p>
                  AmarLab has worked on four different groups so far:
                  <ul className='my-2'>
                    <li>Elderly patients with chronic diseases</li>
                    <li>Pregnant women</li>
                    <li>Busy professionals</li>
                    <li>Newborn babies</li>
                  </ul>
                </p>
              </section>
            </div>
          </div>

          <div className='row mb-0'>
            <div className='col-12'>
              <section>
                <p>
                  At present, AmarLab is working on a variety of activities:
                </p>
              </section>
            </div>
          </div>

          <div className='mx-0 details mb-4'>
            <div className='row mb-3'>
              <div className='col-12'>
                <h4 className='subtitle mb-3'>Health Data Management</h4>
                <section>
                  <p>
                    The traditional diagnostic test report organization is very
                    much hectic. AmarLab believes in making the lives of
                    patients easier. Hence, we want to provide people with an
                    option to record, preserve and access their health data so
                    that they can keep track of health and do not need to carry
                    the printed copies of their prescriptions and health records
                    with them anymore.
                  </p>
                </section>
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <h3 className='subtitle mb-3'>Our Mission</h3>
              <section>
                <p>
                  AmarLab is determined to provide people healthcare service at
                  home, within their comfort zone. We make no compromise in
                  providing an excellent customer experience. Our founders have
                  vast experience of working with a plethora of clinics and
                  healthcare providers, including Spreeha and Amarlab. That has
                  helped AmarLab to improve our customer experience every single
                  day. We aspire to gain the trust of our users and make them
                  satisfied with our service so that they come back to us again.
                </p>
              </section>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <h3 className='subtitle mb-3'>Our Vision</h3>
              <section>
                <p>
                  Our vision is to make healthcare accessible to everyone
                  everywhere in Bangladesh. Since AmarLab is a passionate
                  value-driven organization, we never took money as our primary
                  driving motivation. Our founders had only one thought in mind
                  - that is to solve the healthcare problems in Bangladesh. We
                  want to bring a revolution to the entire healthcare sector of
                  Bangladesh through our hard work and determination. AmarLab
                  dreams to build a happy and healthy Bangladesh and will always
                  work incessantly for the development of our healthcare sector.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
