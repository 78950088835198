import React, { Component } from 'react';
import Eye from '../assets/img/eye.svg';
import EyeOff from '../assets/img/eye-off.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';

class LoginResetPass extends Component {
  state = {
    password: '',
    cpassword: '',
    showPassword: false,
    token: null,
    user_id: null,
  };

  componentDidMount() {
    this.context.setPageTitle('Change Password');
    window.scrollTo(0, 0);
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    if (!token) {
      this.props.history.push('/');
    } else {
      this.setState({ token: JSON.parse(token) });
      this.setState({ user_id: JSON.parse(user_id) });
    }
  }

  handleThirdStep = (e, toggleLoggedIn, setUserMyself) => {
    e.preventDefault();
    if (this.state.password === '') {
      toast.error('Please enter new password!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.cpassword === '') {
      toast.error('Please enter confirm password!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.password.length < 8) {
      toast.error('Password must be at least 8 characters!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.cpassword.length < 8) {
      toast.error('Confirm Password must be at least 8 characters!', {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.cpassword !== this.state.password) {
      toast.error('Password and confirm password not same!', {
        autoClose: 3000,
      });
      return;
    }

    const postData = {
      password: this.state.password,
      user_id: this.state.user_id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user_management/reset-password`,
        postData
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.status && resp.data.status === 1) {
          toast.success('Password changed successfully.', {
            autoClose: 3000,
          });
          this.props.history.push('/');
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error('Sorry something went wrong, please try again later.', {
          autoClose: 3000,
        });
      });
  };

  render() {
    const { toggleLoggedIn, setUserMyself } = this.context;

    return (
      <div>
        <section className='losign signup'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-6 third'>
                <h2 className='title'>Reset Password</h2>
                <form
                  onSubmit={(e) =>
                    this.handleThirdStep(e, toggleLoggedIn, setUserMyself)
                  }
                >
                  <div className='form-group'>
                    <div className='show-hide'>
                      <input
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder='Enter new password'
                        value={this.state.password}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                      <img
                        src={this.state.showPassword ? EyeOff : Eye}
                        alt=''
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='show-hide'>
                      <input
                        type={this.state.showCPassword ? 'text' : 'password'}
                        placeholder='Enter confirm password'
                        value={this.state.cpassword}
                        onChange={(e) =>
                          this.setState({ cpassword: e.target.value })
                        }
                      />
                      <img
                        src={this.state.showCPassword ? EyeOff : Eye}
                        alt=''
                        onClick={() =>
                          this.setState({
                            showCPassword: !this.state.showCPassword,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <button type='submit' className='submit-button'>
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LoginResetPass;

LoginResetPass.contextType = AuthContext;
