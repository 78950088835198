import moment from 'moment';

const SingleCartSummery = ({ item }) => {
  const getAllTestPrice = (testItems) => {
    if (testItems.length !== 0) {
      const price = testItems.reduce(
        (total, item) =>
          total + parseInt(item.purchasable_order_item.sell_price),
        0
      );

      return price;
    } else {
      return '00';
    }
  };

  return (
    <div className='single'>
      <div className='patient'>
        <div>
          <span className='font-semibold'>Patient: </span>{' '}
          {item.patient.full_name}, {item.patient.sex},{' '}
          {item.patient.dob
            ? moment().diff(`${item.patient.dob}`, 'years') + ' Years'
            : ''}
        </div>
        <div className='price font-semibold'>
          ৳ {getAllTestPrice(item.testItem)}
        </div>
      </div>
      <div className='tests'>
        <ul>
          {item.testItem.length !== 0 &&
            item.testItem.map((test, i) => (
              <li key={i}>
                <span className='font-semibold'>
                  {
                    test.purchasable_order_item.testitem_purchasable_oi
                      .diagnostic_test.name
                  }
                </span>
                <span>
                  ৳ {parseInt(test.purchasable_order_item.sell_price)}
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>

    // <div className='single'>
    //   <div className='person'>
    //     <div className='name'>
    //       <span>Patient: </span> {item.patient.full_name},&nbsp;
    //     </div>
    //     <div className='age'>
    //       {item.patient.dob
    //         ? moment().diff(`${item.patient.dob}`, 'years') + ' Years'
    //         : ''}
    //     </div>
    //   </div>
    //   <div className='details'>
    //     <h5>
    //       {
    //         item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
    //           .name
    //       }
    //     </h5>
    //     {/* <p>
    //       <span className='font-semibold'>
    //         Preparations: <br />
    //       </span>
    //       {
    //         item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
    //           .description
    //       }
    //     </p> */}
    //     <p className='price'>
    //       ৳ {parseInt(item.purchasable_order_item.sell_price)}
    //     </p>
    //   </div>
    //   <div className='branch'>
    //     Branch:{' '}
    //     {item.purchasable_order_item.testitem_purchasable_oi.branch.name}
    //   </div>
    //   <div className='hospital'>
    //     <p className='font-semibold'>Ordered From:</p>
    //     <img
    //       src={
    //         item.purchasable_order_item.testitem_purchasable_oi.branch.lab.logo
    //       }
    //       alt=''
    //     />
    //   </div>
    // </div>
  );
};

export default SingleCartSummery;
