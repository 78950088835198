import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SuccessOne from '../assets/img/payment-success-1.png';
import SuccessTwo from '../assets/img/payment-success-2.png';
import PaymentFailed from '../assets/img/payment-failed.svg';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

class PaymentSuccess extends Component {
  state = {
    payment: {},
    fees: { meterial_fee: 50, collection_fee: 200 },
    discount: { discount_price: 0 },
    userLocalId: null,
    meterial_fee: null,
    collection_fee: null,
    coupon_amount: null,
    mainPrice: null,
  };

  getFees = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => {
        this.setState({
          fees: resp.data.results[0],
        });
        // console.log("fees = ", resp.data.results[0]);
      })
      .catch((error) => {});
  };

  fetchDiscount = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-discount?order=${id}`)
      .then((resp) => {
        this.setState({ discount: resp.data.results[0] });
        console.log('the data is ', resp.data.results[0].discount_price);
      });
  };

  smsApiCall = (orderId, status) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/sms-order-status/${orderId}/${status}`)
      .then((resp) => {
        // console.log('come success', resp.data);
      })
      .catch((error)=> {
        // console.log('come err', error.response);
      })
  }

  fetchPaymentStatus = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/payment-status?payment_id=${id}`
      )
      .then((resp) => {
        if (this.state.userLocalId !== resp.data.results[0].order.user.id) {
          this.props.history.push('/order-history');
        } else {
          this.setState({ payment: resp.data.results[0] });
          //this.fetchDiscount(resp.data.results[0].order.id);
          // console.log('the data is ', resp.data.results[0]);
          if (resp.data.results[0].status === 'VALID') {
            this.smsApiCall(resp.data.results[0].order.id, 'payment_confirmation')
          }
        }
      })
      .catch((error) => {
        this.props.history.push('/order-history');
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Payment Status');
    window.scrollTo(0, 0);
    const price = localStorage.getItem('main_price');
    const meterial_fee = localStorage.getItem('material_fee');
    const collection_fee = localStorage.getItem('collection_fee');
    const coupon_amount = localStorage.getItem('coupon_fee');
    if (price && meterial_fee && collection_fee) {
      this.setState({ mainPrice: JSON.parse(price) });
      this.setState({ meterial_fee: JSON.parse(meterial_fee) });
      this.setState({ collection_fee: JSON.parse(collection_fee) });
    }
    if (coupon_amount) {
      this.setState({ coupon_amount: JSON.parse(coupon_amount) });
      // console.log(coupon_amount);
    }

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');
    if (!token) {
      this.props.history.push('/login');
    } else if (!userId) {
      this.props.history.push('/login');
    } else {
      this.setState({ userLocalId: JSON.parse(userId) });
      this.getFees();
      setTimeout(() => {
        this.fetchPaymentStatus(this.props.match.params.id);
      }, 100);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('main_price');
    localStorage.removeItem('material_fee');
    localStorage.removeItem('collection_fee');
    localStorage.removeItem('coupon_fee');
  }

  render() {
    const { mainPrice, coupon_amount, collection_fee, meterial_fee } =
      this.state;

    return (
      <>
        <section className='payment-status'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-6'>
                <div className='header'>
                  <div className='img-section'>
                    {this.state.payment.status === 'VALID' ? (
                      <>
                        <img src={SuccessOne} alt='' className='circle' />
                        <img src={SuccessTwo} alt='' className='sign' />
                      </>
                    ) : (
                      <>
                        <img src={PaymentFailed} alt='' className='circle' />
                        {/* <img src={PaymentFailed} alt='' className='sign' /> */}
                      </>
                    )}
                  </div>
                  <h5
                    className={
                      'text-center ' +
                      (this.state.payment.status === 'VALID'
                        ? 'text-primary '
                        : 'text-failed ')
                    }
                  >
                    {this.state.payment.status === 'VALID'
                      ? 'Payment Successful'
                      : 'Payment Failed'}
                  </h5>
                </div>
                <div className='card'>
                  <h5 className='title'>Order Summary</h5>
                  <div className='cost'>
                    <div className='price'>
                      <p>Total Price</p>
                      <p className='bold-text'>৳ {mainPrice || '00'}</p>
                    </div>

                    <div className='price'>
                      <p>Material fee </p>
                      <p className='bold-text'>৳ {meterial_fee || '00'}</p>
                    </div>
                    <div className='price'>
                      <p>Collection fee</p>
                      <p className='bold-text'>৳ {collection_fee || '00'}</p>
                    </div>
                    {coupon_amount && coupon_amount !== '0' && (
                      <div className='price'>
                        <p>Discount</p>
                        <p className='bold-text'>- ৳ {coupon_amount || '00'}</p>
                      </div>
                    )}

                    <div className='break'></div>
                    <div className='price'>
                      <p className='bold-text'>Total:</p>
                      <p className='bold-text'>
                        ৳ {parseInt(this.state.payment.amount)}
                      </p>
                    </div>
                  </div>
                  <div className='terms'>
                    * By completing your purchase you agree to this{' '}
                    <Link to='/terms-of-service'>Terms of Service.</Link>
                  </div>
                  <Link
                    to='/order-history'
                    className='btn btn-primary btn-block'
                  >
                    View Order History
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PaymentSuccess;

PaymentSuccess.contextType = AuthContext;
